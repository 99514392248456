import {BR} from '../endpoints';
import {parseVideoElements} from './helpers/video_helpers';
import {contentTypes} from '../constants/contentTypes';
import {parseImages} from '../helpers/imageHelpers';

const initialState = {};
const editorialStreams = ['evergreen', 'featured', 'headlines', 'trending'];

const videoContentTypes = [
  contentTypes.LIVE_VIDEO,
  contentTypes.VIDEO,
  contentTypes.VIDEO_ARTICLE,
  contentTypes.HIGHLIGHT,
];

const REGEX_TEAMSTREAM_HREF = /^teamstream:\/\/[^/]+\//; // djay can have URLs with both "stream/" and "streams/" as of Jan 2017
const REGEX_YOUTUBE_ID_IN_URL = /(?:v=|\/(?:embed|v)\/|\.be\/)([\w-]+)\b/;

const processTrack = (track) => {
  if (!track || !track.content) {
    return false;
  }
  const content_type = track.content_type;
  const video_url = track.content && track.content.metadata && track.content.metadata.video_url;
  const embed_code = track.content && track.content.metadata && track.content.metadata.embed_code;
  const youtubeIdMatches = video_url && video_url.match(REGEX_YOUTUBE_ID_IN_URL);

  const newTrack = {
    ...track,
    content: {
      ...track.content,
      metadata: {
        ...track.content.metadata,
      },
    },
  };

  if (content_type === 'youtube_video' || content_type === 'lab_article') {
    // This is our solution for incorrect permalinks, in this case we want to use the url value as the article link
    newTrack.externalUrl = track.url;
  } else if (content_type === 'deeplink') {
    newTrack.url = newTrack.url.replace(REGEX_TEAMSTREAM_HREF, BR.baseURL());
  }
  if (youtubeIdMatches && youtubeIdMatches[1]) {
    newTrack.content.metadata = {
      ...newTrack.content.metadata,
      video_id: youtubeIdMatches[1],
    };
  }
  if (embed_code) {
    // Intentionally removing embed_code since causing syntax errors and not currently used
    // If re-adding make sure to test with bad data:
    // https://gist.github.com/pcanterini/81ab38091168780d017e6da68fea1cb0
    // http://djay.bleacherreport.com/playlists/nba_ts/tracks/11440138
    delete newTrack.content.metadata.embed_code;
  }
  return newTrack;
};

const assignTrackToId = (state, track) => {
  const newTrack = processTrack(track);
  if (newTrack) {
    state[track.id] = newTrack;
  }

  return state;
};

const addTracks = (payload, state = {}) => {
  if (payload.tracks && payload.tracks.length) {
    const tracks = JSON.parse(JSON.stringify(payload.tracks));
    return tracks
      .map(parseVideoElements)
      .map(parseImages)
      .reduce(assignTrackToId, state);
  }
  return state;
};

const addSections = (payload, state = {}) => {
  if (!payload.sections || payload.format !== 'editorial') {
    return state;
  }
  return editorialStreams.reduce((state, playlist) => {
    if (payload.sections[playlist]) {
      // Now we set data to the value of the state PLUS the accumulated tracks
      // then we return the updated state.
      return addTracks(payload.sections[playlist], state);
    }
    // return it, even if not modified, for the next loop.
    return state;
  }, state);
};

const checkForThumbnail = (track) => {
  if (!track || !track.content) {
    return false;
  }
  const thumbnailWhiteList = [
    'highlight',
    'instagram',
    'instagram_video',
    'instagram_image',
    'tweet',
    'video',
    'video_article',
    'youtube_video',
  ];
  const missingThumbnail =
    !thumbnailWhiteList.includes(track.content_type) && !track.content.thumbnail_url;
  if (missingThumbnail) {
    return false;
  }
  return track;
};

const extractId = (obj) => {
  // This function may seem pointless but it's really useful for maping over an array of objects.
  // if id_str exists then we're dealing with an id that is too large for a double (basically everything > 54bit signed floats)
  return obj.id_str || obj.id;
};

const isNotBlacklisted = (obj) => {
  return obj.content_type !== 'text';
};

const shiftThree = (payload) => {
  const [first, second, third, ...rest] = payload;
  return rest.concat(first, second, third);
};

function tracks(state = initialState, action) {
  const payload = action.payload;
  if (action.error) {
    throw action.error;
  }
  switch (action.type) {
    case 'FETCH_USER_TRACK':
      return {...state, ...addTracks(payload)};

    case 'LOAD_SECTION_DATA':
      return {...state, ...addTracks(payload, addSections(payload))};
    case 'UPDATE_LIVESTREAM': {
      return {
        ...state,
        ...addTracks(payload, state),
      };
    }
    case 'FETCH_STREAM_DATA':
      return {...state, ...addTracks(payload)};
    case 'FETCH_TRENDING_DATA':
      return {...state, ...addTracks(payload)};

    case 'UPDATE_SCROLL_PLAY_VIDEOS': {
      const [videoToPlay, playlist] = payload;
      const tracks = JSON.parse(JSON.stringify(state));

      if (!playlist) {
        return state;
      }

      playlist.forEach((trackID) => {
        const track = tracks[trackID];
        const isVideoTrack = videoContentTypes.includes(track.content_type);
        const trackId = parseInt(trackID, 10);
        const isTrackToPlay = videoToPlay === trackId;

        if (isTrackToPlay) {
          track.content.metadata['scrollPlay'] = true;
        } else if (isVideoTrack) {
          track.content.metadata['scrollPlay'] = false;
        }
      });

      return {
        ...state,
        ...tracks,
      };
    }

    default:
      return state;
  }
}

function parseStreamData(newState, payload, name, isBlended = false) {
  if (isBlended) {
    // isNotBlacklisted filters out unwanted content types for blended streams
    newState[name] = payload.tracks.filter(isNotBlacklisted).map(extractId);
  } else {
    newState[name] = payload.tracks.map(extractId);
  }
  return newState;
}

function playlists(state = initialState, action) {
  const payload = action.payload;

  if (action.error) {
    throw action.error;
  }

  switch (action.type) {
    case 'LOAD_SECTION_DATA': {
      let newState = {};
      if (payload.sections) {
        newState = editorialStreams.reduce((newStateInReducer, playlist) => {
          const data = payload.sections[playlist];
          if (data && data.tracks) {
            newStateInReducer[data.playlist] = data.tracks.map(extractId);
          }
          return newStateInReducer;
        }, newState);
      }
      if (payload.tracks) {
        let playlistName = `${payload.section}`;
        let isBlended = false;
        if (
          payload.section === 'front-page' ||
          payload.section === 'uk' ||
          (payload.streams && (payload.streams.length > 1 || payload.streams.length === 0))
        ) {
          playlistName = `${payload.section}-all`;
          isBlended = true;
        }
        const tracksState = parseStreamData({...state}, payload, playlistName, isBlended);
        newState = {...newState, ...tracksState};
      }
      return {...state, ...newState};
    }

    case 'FETCH_STREAM_DATA': {
      const name = payload.section === 'all' ? payload.streamName : payload.section;
      return parseStreamData({...state}, payload, name, payload.section === 'all');
    }

    case 'FETCH_TRENDING_DATA': {
      return parseStreamData({...state}, payload, payload.section);
    }

    // shifts playlist by three tracks
    case 'SHIFT_PLAYLIST': {
      return {
        ...state,
        [payload]: shiftThree(state[payload]),
      };
    }

    case 'UPDATE_LIVESTREAM':
      return {
        ...state,
        [payload.name]: payload.tracks.map(extractId),
      };

    default:
      return state;
  }
}

const assignToPermaLink = (tags, data) => {
  tags[data.permalink || data.unique_name] = data;
  return tags;
};

function tags(state = initialState, action) {
  const tags = action.payload && action.payload.tags ? action.payload.tags : [];
  if (action.error) {
    return state;
  }
  switch (action.type) {
    case 'LOAD_SECTION_DATA':
    case 'LOAD_ARTICLE_DATA':
    case 'LOAD_STUB_DATA':
    case 'LOAD_TAGS_METADATA':
    case 'LOAD_TEAM_SCHEDULE':
      return {
        ...state,
        ...tags.reduce(assignToPermaLink, {}),
      };
    default:
      return state;
  }
}

const addInternalModules = (modules) => {
  //to add adverts and trendings
  modules.splice(2, 0, {type: 'ad', slot: 'bnr_atf_02', id: 'ba2'});

  if (modules.length >= 5) {
    modules.splice(5, 0, {type: 'ad', slot: 'bnr_atf_03', id: 'ba3'});
  }
  if (modules.length >= 12) {
    modules.splice(12, 0, {type: 'ad', slot: 'bnr_atf_04', id: 'ba4'});
  }
  return modules;
};

function layout(state = initialState, action) {
  const payload = action.payload;
  if (action.error) {
    throw action.error;
  }

  switch (action.type) {
    case 'LOAD_SECTION_DATA': {
      if (!payload.modules) {
        return {...state, modules: []};
      }
      const modules = payload.modules.map((module) => {
        const processedTracks = module.tracks.reduce((tracks, track) => {
          const processedTrack = checkForThumbnail(processTrack(track));
          if (processedTrack) {
            tracks.push(parseVideoElements(processedTrack));
          }
          return tracks;
        }, []);

        return {
          ...module,
          tracks: processedTracks.map(parseImages),
        };
      });
      return {...state, modules: addInternalModules(modules)};
    }

    default:
      return state;
  }
}

export {layout, playlists, tracks, tags};
