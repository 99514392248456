import React from 'react';
import PropTypes from 'prop-types';
import Thumbnail from '../../thumbnail';
import {getOverlayProps, getNCAAOverlayProps} from './tveFreePreviewEndedOverlay';

const noop = () => {};

const TveFreePreviewEndedThumbnail = ({authInitialized, onTveLogin, posterImage, isNCAAGame}) => {
  const onclick = authInitialized ? onTveLogin : noop;

  return (
    <div>
      <Thumbnail
        isVideo={true}
        onClick={onclick}
        thumbnail={posterImage}
        overlay={isNCAAGame ? getNCAAOverlayProps() : getOverlayProps(onclick)}
      />
    </div>
  );
};

TveFreePreviewEndedThumbnail.propTypes = {
  authInitialized: PropTypes.bool,
  onTveLogin: PropTypes.func,
  posterImage: PropTypes.string,
};

export default TveFreePreviewEndedThumbnail;
