export const APPNEXUS_MEMBER_MAPPING = {
  domestic: '7745',
  international: '8353',
};

export const CRITEO_NETWORK_ID = 4157;
export const CRITEO_NETWORK_ID_INTL = 9264;
export const CRITEO_ZONE_ID_INTL = 9264;

export const IX_SITE_ID_MAPPING = {
  domestic: {
    desktop: {
      bnr_atf_01: '402219',
      bnr_atf_02: '402230',
      bnr_atf_03: '402231',
      bnr_atf_05: '402232',
      rect_atf_01: '402222',
      rect_atf_02: '402223',
      rect_atf_03: '402224',
    },
    mobile: {
      bnr_atf_01: '402226',
      bnr_atf_02: '402233',
      bnr_atf_03: '402234',
      bnr_atf_05: '402235',
      rect_atf_01: '402227',
      rect_atf_02: '402228',
      rect_atf_03: '402229',
    },
  },
  international: {
    desktop: {
      bnr_atf_01: '238516',
      bnr_atf_02: '238540',
      bnr_atf_03: '410962',
      bnr_atf_05: '401977',
      rect_atf_01: '238528',
      rect_atf_02: '238532',
      rect_atf_03: '238569',
    },
    mobile: {
      bnr_atf_01: '238527',
      bnr_atf_02: '238515',
      bnr_atf_03: '401979',
      bnr_atf_05: '401980',
      rect_atf_01: '238547',
      rect_atf_02: '238565',
      rect_atf_03: '238583',
    },
  },
};

export const RUBICON_ACCOUNT_ID = {
  domestic: '11078',
  international: '11016',
};

export const RUBICON_SITE_MAPPING = {
  domestic: {
    desktop: '237168',
    mobile: '237166',
  },
  international: {
    desktop: '237158',
    mobile: '237160',
  },
};

export const RUBICON_ZONE_MAPPING = {
  domestic: {
    desktop: '1170432',
    mobile: '1170424',
  },
  international: {
    desktop: '1170404',
    mobile: '1170406',
  },
};
