import {createAction} from 'redux-actions';
import BreportAPI from '../apis/breport_api';

const storeSearchQuery = createAction('STORE_SEARCH_QUERY');

const storeSearchResults = createAction('STORE_SEARCH_RESULTS');

export const siteSearch = (query) => async (dispatch) => {
  const type = 'site';
  dispatch(storeSearchQuery({query, type}));
  return await BreportAPI.fetchSuggestions(query).then((results) =>
    dispatch(storeSearchResults({results, query, type}))
  );
};

export const tagsSearch = (query) => async (dispatch) => {
  const type = 'tags';
  dispatch(storeSearchQuery({query, type}));
  return await BreportAPI.fetchTagSuggestions(query).then((results) =>
    dispatch(storeSearchResults({results, query, type}))
  );
};
