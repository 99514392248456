import React from 'react';
import {getTveProviderMessage} from '../../../../helpers/tveHelpers';

function SignInButton({handleLogin}) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="tve-footer__right"
      onClick={handleLogin}
    >
      {getTveProviderMessage()}
    </a>
  );
}

export default SignInButton;
