import {RECAPTCHA_VERIFICATION} from '../constants/pageTypes';
import {GET_DALTON_TOKEN, GET_DALTON_EMAILS} from '../constants/actionTypes';

const initialState = {
  orbisSession: {
    userID: null,
    sessionToken: null,
  },
};
const VALID_LEAGUES = ['nba', 'mlb', 'nfl'];

const REGEX_VALID_DEVICE_ID = /^[\w-]+$/;

function isValidDeviceId(id) {
  return id && REGEX_VALID_DEVICE_ID.test(id);
}

function user(state = initialState, action) {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case 'SET_PAGE': {
      if (action.payload.type === 'accountConfirmed') {
        return {
          ...state,
          confirmationResult: action.payload.result,
        };
      }

      if (action.payload.type === RECAPTCHA_VERIFICATION) {
        return {
          ...state,
          captcha: action.payload.captcha,
          confirmationToken: action.payload.confirmationToken,
        };
      }

      return state;
    }

    case 'LOAD_YAHOO_TEAMS': {
      return {
        ...state,
        fantasyTeams: action.payload.teams,
      };
    }

    case 'UPDATE_USER_EMAIL': {
      const {email, id, type} = action.payload;
      return {
        ...state,
        email,
        id,
        identities: {
          ...state.identities,
          email: type,
        },
      };
    }

    case 'ADD_USER':
    case 'LINK_FACEBOOK':
    case 'UPDATE_USER':
    case 'VERIFY_NAMES': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'REQUEST_PHONE_VERIFY': {
      return {
        ...state,
        phoneVerifyRequest: action.payload,
      };
    }

    case 'SET_ONE_TRUST_PREFERENCES': {
      return {
        ...state,
        oneTrustPreferences: action.payload,
      };
    }

    case 'SET_RESET_PASSWORD_TOKEN': {
      return {
        ...state,
        resetToken: action.payload,
      };
    }

    case 'STORE_QUERY_PARAMS': {
      const deviceId = action.payload.device_id;
      const league = action.payload.league;
      // A note on security:
      // Data should always be derived and or validated.
      // We don't store this data *directly* because _then_ it would be in any serialization
      // of the data. That would be a big security risk (hello XSS).
      return {
        ...state,
        device_id: isValidDeviceId(deviceId) ? deviceId : false,
        league: VALID_LEAGUES.includes(league) ? league : false,
      };
    }

    case 'STORE_VISITOR_COUNTRY': {
      return {
        ...state,
        visitorCountry: action.payload,
      };
    }

    case 'SET_ORBIS_SESSION': {
      return {
        ...state,
        orbisSession: {
          ...action.payload,
        },
      };
    }

    case GET_DALTON_TOKEN: {
      return {
        ...state,
        daltonToken: action.payload,
      };
    }

    case GET_DALTON_EMAILS: {
      const emails = action.payload;
      // No errors and at least one email returned
      const hasAtleastOneEmail = !emails.error || emails?.length > 0;

      return {
        ...state,
        daltonEmails: hasAtleastOneEmail ? emails : [],
      };
    }

    case 'GET_USER_ENTITLEMENTS': {
      return {
        ...state,
        entitlements: action.payload || [],
      };
    }

    case 'SAVE_USER_ENTITLEMENTS': {
      return {
        ...state,
        entitlements: action.payload || [],
      };
    }

    case 'GET_PREAUTH_ENTITLEMENTS': {
      return {
        ...state,
        preAuthEntitlements: action.payload.preAuthorizedEntitlements,
      };
    }

    case 'GET_MY_EVENTS': {
      return {
        ...state,
        myEvents: action.payload,
      };
    }

    case 'FETCH_PPV_ENTITLEMENT_BY_ID': {
      return {
        ...state,
        entitlements: action.payload || [],
      };
    }

    default: {
      return state;
    }
  }
}

export default user;
