/*
The following is the public api for the gatekeeper functions we need for login.
Creating internal routes would be another solution but there are too many for that to be practical here.
*/
import {createFetchService} from './fetch_utils';
import {Gatekeeper} from '../endpoints';
import * as StorageAPI from '../apis/storage_api';

const fetchJSON = createFetchService('Gatekeeper Register');
const fetchText = createFetchService('Gatekeeper Register', {format: 'text'});
const postJSON = createFetchService('Gatekeeper Register', {method: 'POST'});
const post = createFetchService('Gatekeeper Register', {method: 'POST', responseFormat: 'text'});

class FingerPrinter {
  constructor() {
    this.createCanvas();
    // Text with lowercase/uppercase/punctuation/unicode symbols
    this.text = 'B/R, <canvas> fingerprinter 1.0 «©»�';
    // Arial is the most common type, but we'll use others from the system fonts as well.
    this.typefaces = [
      'Arial',
      'sans-serif',
      'serif',
      'fantasy',
      'cursive',
      'monospace',
      '-no-font-',
    ];
    this.write = this.write.bind(this);
    this.typefaces.reduce(this.write, this.context);
    this.fingerprint = this.canvas.toDataURL();
  }

  createCanvas() {
    this.canvas = global.document.createElement('canvas');
    this.context = this.canvas.getContext('2d');

    // Now some basic options for the context/canvas
    this.context.textBaseline = 'top';
    this.context.textBaseline = 'alphabetic';
    this.context.fillStyle = '#f60';
    this.context.fillRect(125, 1, 62, 60);
  }

  write(context, typeface, index) {
    const ypos = 10 + 6 * index;
    const xpos = index;
    context.font = `14px ${typeface}`;
    // Some tricks for color mixing to increase the difference in rendering
    context.fillStyle = '#069';
    context.fillText(this.text, xpos, ypos);
    context.fillStyle = 'rgba(102, 204, 0, 0.7)';
    context.fillText(this.text, xpos + 2, ypos + 2);
    return context;
  }
}

export const storeRegistration = (state) => {
  if (state) {
    StorageAPI.set('registration', state, {ttl: StorageAPI.oneDay});
    return state;
  }
  return false;
};

export const validateName = (name, value) => {
  return fetchJSON(Gatekeeper.validateName(name, value)).catch((err) => {
    throw err;
  });
};

export const isUsernameAvailable = (username) => {
  if (!username) {
    return Promise.reject(new Error('No username provided'));
  }
  return fetchJSON(Gatekeeper.usernameCheck(username))
    .then(() => {
      return {
        available: true,
      };
    })
    .catch((err) => {
      return {
        available: false,
        data: err && err.data && err.data.json,
      };
    });
};

export const reserveUsername = (username) => {
  return new Promise((resolve, reject) => {
    if (!username) {
      reject(new Error('No username passed to reserveUsername'));
    }
    const fingerprint = new FingerPrinter().fingerprint;
    resolve(
      postJSON(Gatekeeper.usernameRegister(), {body: {username, owner: fingerprint}})
        .then(() => true)
        .catch((err) => {
          if (err.status && err.status === 304) {
            return true; // in this case the username is *already* reserved for this device.
          }
          return false;
        })
    );
  });
};

export const createUserWithEmail = ({email, password, username, first_name, last_name}) => {
  return postJSON(Gatekeeper.createUser(), {
    body: {
      account: {
        grant_type: 'br',
        email,
        password,
        password_confirmation: password,
      },
      user: {
        username,
        first_name,
        last_name,
      },
    },
  });
};

export const createUserWithFb = ({username, first_name, last_name, facebook}) => {
  return postJSON(Gatekeeper.authorizeFacebookUser(), {
    body: {
      facebook: {
        token: facebook.token,
        uid: facebook.uid,
        device: 'web',
      },
      user: {
        username,
        first_name,
        last_name,
      },
    },
  });
};

export const createUserWithPhone = ({phone, username, first_name, last_name}) => {
  return postJSON(Gatekeeper.registerPhone(), {
    body: {
      phone: {
        country: 1,
        phone,
      },
      user: {
        username,
        first_name,
        last_name,
      },
    },
  }).catch((err) => {
    throw err;
  });
};

export const confirmEmail = ({token, recaptchaToken}) => {
  const params = recaptchaToken
    ? {
        confirmation_token: token,
        recaptcha_token: recaptchaToken,
      }
    : {confirmation_token: token};

  return fetchText(Gatekeeper.confirmUser(params)).catch((err) => {
    throw err;
  });
};

export const verifyRegistrationCode = (code) => {
  const jwt = StorageAPI.get('br_jwt', true);
  return post(Gatekeeper.verifyRegistrationCode(code), {
    body: {
      registration_code: code,
    },
    jwt,
  })
    .then(() => {
      return {
        verified: true,
      };
    })
    .catch((err) => {
      throw err;
    });
};
