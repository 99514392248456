import {useEffect, useState} from 'react';
import {isCloudinaryHosted} from './imageHelpers';

const TOP_LEVEL_CLOUDINARY_DIRECTORIES = [
  'br-cms',
  'br-img-images',
  'br-img-article',
  'br-s3-cms',
  'br-img-slides',
  'br-s3-img-images',
  'br-s3-img-sildes',
  'br-s3-img-article',
];

function constructURL(url) {
  try {
    return new URL(url);
  } catch {
    // eslint-disable-next-line no-undefined
    return undefined;
  }
}

export const withCloudinary = (Component) => (props) => {
  const providedUrl = props.image || props.thumbnail_url || props.src;
  const [newUrl, setNewUrl] = useState(providedUrl);

  useEffect(() => {
    const url = constructURL(providedUrl);

    if (!url || !props.cloudinaryWidth || !isCloudinaryHosted(url.toString())) {
      // if something isn't right, just return
      return;
    }

    const paths = url.pathname.split('/').filter((path) => path); //excluding empty strings

    const paramsIndex = url.pathname.includes('image/upload')
      ? // if this is a image/upload URL, inject our overrides right before the v1231232 value (this ensures our overrides are also the last Cloudinary arguments)
        // if a v##### section isn't present, paths.findIndex((path) will return a -1 and by using a negative index to splice, it will count backwards from the end of the array
        paths.findIndex((path) => path.match(/^v[0-9]+/))
      : // otherwise, inject it before any of the top-level directories
        paths.findIndex((path) => TOP_LEVEL_CLOUDINARY_DIRECTORIES.includes(path));

    paths.splice(paramsIndex, 0, `w_${props.cloudinaryWidth}`);

    setNewUrl(`${url.origin}/${paths.join('/')}`);
  }, [providedUrl, props.cloudinaryWidth]);

  return <Component {...props} src={newUrl} thumbnail_url={newUrl} image={newUrl} />;
};
