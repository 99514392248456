import {Articles} from '../endpoints';
import {createFetchService} from './fetch_utils';

const fetchJSON = createFetchService('Articles');

const errorHandler = (err) => {
  return {
    error: err,
  };
};

// In order for the third-party ad code to hydrate & render the ads in the content,
// an `id` attribute is needed somehow; otherwise the first one does not render, while
// later ones do. This difference may be due to the LazyAdvert behavior.
let adNumber = 0;

const fetchArticle = (articleID) => {
  if (articleID) {
    return fetchJSON(Articles.pageArticle(articleID))
      .then((data) => {
        return {
          ...data,
          elements: data.elements.map((element) => {
            if (element.content_type === 'ad' && !element.id) {
              return {
                ...element,
                id: ++adNumber,
              };
            }
            return element;
          }),
        };
      })
      .catch(errorHandler);
  }
  return Promise.reject(new Error('No Article ID passed to Articles API'));
};
const fetchArticles = (articleIDs) => {
  if (articleIDs) {
    return fetchJSON(Articles.articles(articleIDs)).catch(errorHandler);
  }
  return Promise.reject(new Error('No Article IDs passed to Articles API'));
};
const previewArticle = (articleID, jwt) => {
  if (articleID) {
    if (jwt) {
      return fetchJSON(Articles.previewData(articleID), {jwt}).catch(errorHandler);
    }
    return Promise.reject(new Error('No JWT passed to Articles API'));
  }
  return Promise.reject(new Error('No Preview Article ID passed to Articles API'));
};

export default {
  fetchArticle,
  fetchArticles,
  previewArticle,
};
