import React from 'react';

interface BackIconProps {
  classnames: string;
}

export const BackIcon = ({classnames}: BackIconProps) => {
  return (
    <svg
      className={classnames}
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.000257164 10.0027L11.3307 20.0002L13.3051 18.2581L1.97461 8.26062L0.000257164 10.0027Z"
        fill="white"
      />
      <path
        d="M1.97631 11.739L13.3061 1.74212L11.3318 4.72784e-05L0.00195312 9.99695L1.97631 11.739Z"
        fill="white"
      />
    </svg>
  );
};
