class AdSize {
  width;
  height;
  constructor(width, height) {
    this.width = Number(width);
    this.height = Number(height);
  }
  get array() {
    return [this.width, this.height];
  }
  get string() {
    return `${this.width}x${this.height}`;
  }
  equalsArray([width, height]) {
    return width === this.width && height === this.height;
  }
}

export const SIZES = {
  HALF_PAGE: new AdSize(300, 600),
  LARGE_MOBILE_BANNER: new AdSize(300, 100),
  LEADER: new AdSize(728, 90),
  MEDIUM_RECT: new AdSize(300, 250),
  MOBILE_LEADER: new AdSize(320, 50),
  SIDEKICK: new AdSize(970, 250),
  SLIDER: new AdSize(970, 90),
};
