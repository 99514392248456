import React, {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {useInView} from 'react-intersection-observer';
import {ui} from '../../selectors/topLevelSelectors';
import {imgSizes, useImgSrcSet} from '../../helpers/imageHelpers';
import {withCloudinary} from '../../helpers/cloudinaryHelper';

const loadImage = ({src, srcSet, sizes, elem}) =>
  new Promise((resolve, reject) => {
    elem.onload = () => resolve(elem);
    elem.onerror = reject;
    if (srcSet) {
      elem.srcset = srcSet;
      elem.sizes = sizes;
    }
    elem.src = src;
  });

const LazyImage = (props) => {
  const {
    alt,
    assetURL,
    moduleType,
    src,
    width,
    height,
    maintainAspectRatio,
    ...additionalImgProps
  } = props;
  const [updatedSrc, srcSet] = useImgSrcSet({src, width, height, moduleType});
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });
  const imgRef = useRef(null);

  useEffect(() => {
    if (inView && !isImgLoaded) {
      loadImage({
        src: updatedSrc,
        srcSet,
        sizes: imgSizes,
        elem: imgRef.current,
      }).then(() => {
        setIsImgLoaded(true);
      });
    }
  }, [isImgLoaded, inView, updatedSrc, srcSet]);

  if (!src) {
    return null;
  }

  const classes = classnames({
    atom: true,
    lazyImage: true,
    loaded: isImgLoaded,
    maintainAspectRatio,
    ignoreAspectRatio: !maintainAspectRatio,
  });

  return (
    <div className={classes} ref={ref}>
      <img
        alt={alt}
        ref={imgRef}
        moduleType={moduleType}
        src={`${assetURL}/img/atoms/lazyImage/logo.png`}
        {...additionalImgProps}
      />
    </div>
  );
};

LazyImage.defaultProps = {
  alt: '',
};

const mapStateToProps = (state) => {
  const {assetURL} = ui(state);
  return {
    assetURL,
  };
};

export default connect(mapStateToProps)(withCloudinary(LazyImage));
