import {CDN, Static} from '../endpoints';
import logger from '../logger';

function appStoreBadge(whichStore) {
  switch (whichStore) {
    case 'apple':
      return `${Static.images()}atoms/appStoreBadge/badge-appstore-apple.svg`;

    case 'google':
      return `${Static.images()}atoms/appStoreBadge/badge-appstore-google.png`;

    default:
      logger.error('ImagesAPI unrecognized store:', whichStore);
      return false;
  }
}

function brLogo(size) {
  switch (size) {
    case 'height630':
      return `${Static.images()}br_630_height.png`;

    case 'height60':
    default:
      return `${Static.images()}br_60_height.png`;
  }
}

function randomIntegerBetween(min, max) {
  // Both min and max are inclusive (value may be either of them).
  // h/t Mozilla
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#Getting_a_random_integer_between_two_values_inclusive
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function contentUnavailable(number = randomIntegerBetween(1, 5)) {
  return CDN.contentUnavailableImage(number);
}

function teamLogo(teamFilename, size) {
  switch (size) {
    case 'large':
      return `${CDN.images()}/team_logos/328x328/${teamFilename}`;

    case 'small':
    default:
      return `${CDN.images()}/team_logos/64x64/${teamFilename}`;
  }
}

export default {
  appStoreBadge,
  brLogo,
  contentUnavailable,
  teamLogo,
};
