import React from 'react';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';

import {Button, SVG} from '@br/br-components';
import {isFullyInViewport} from '../helpers/domHelpers';

// Events
const EVENTS = {
  PROMO_DISMISSED: {
    promo_name: 'app_large_banner',
    promo_type: 'app_promo',
    tag_manager_event: 'promo_closed',
  },
  PROMO_IMPRESSION: {
    promo_name: 'app_large_banner',
    promo_type: 'app_promo',
    tag_manager_event: 'promo_impression',
  },
  PROMO_SELECTED: {
    promo_name: 'app_large_banner',
    promo_type: 'app_promo',
    tag_manager_event: 'promo_selected',
  },
};

class MobileWebAppPromo extends React.PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      browser: PropTypes.string,
      installLink: PropTypes.string,
      title: PropTypes.string,
    }),
    displayTag: PropTypes.shape({
      short_name: PropTypes.string,
    }),
    trackEvent: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.fireEvent = this.fireEvent.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentDidMount() {
    this.props.onMount(this.props.displayTag);

    if (isFullyInViewport(this.element)) {
      this.fireEvent('impression');
    }
  }

  fireEvent(eventName) {
    switch (eventName) {
      case 'impression':
        return this.props.trackEvent(EVENTS.PROMO_IMPRESSION);

      case 'dismiss':
        return this.props.trackEvent(EVENTS.PROMO_DISMISSED);

      default:
        return this.props.trackEvent(EVENTS.PROMO_SELECTED);
    }
  }

  handleContinue(event) {
    this.props.onCloseNotification(event, 'mobile_web_app_promo');
    this.fireEvent('dismiss');
  }

  render() {
    const classes = classnames('molecule', 'mobileWebAppPromo');
    const browserIcon = this.props.data.browser && (
      <SVG type="icon" target={this.props.data.browser} />
    );

    return (
      <div className={classes} ref={(el) => (this.element = el)}>
        <h3>{this.props.data.title}</h3>
        <section>
          <div className="label">
            <SVG type="icon" target="brAppRound" />
            <span>Use the Bleacher Report app!</span>
          </div>
          <Button href={this.props.data.installLink} onClick={this.fireEvent}>
            Open App
          </Button>
        </section>
        <section>
          <div className="label">
            {browserIcon} Continue in{' '}
            <span className="browser-name">{this.props.data.browser}</span>
          </div>
          <Button classes="inverse" onClick={this.handleContinue}>
            Continue
          </Button>
        </section>
      </div>
    );
  }
}

MobileWebAppPromo.defaultProps = {
  data: {},
};

export default MobileWebAppPromo;
