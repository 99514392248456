import {mParticleEventNameMap, mParticleEventKeysMap} from '../../constants/mParticle';

const getMparticleEventName = (tagManagerEvent) => {
  if (!tagManagerEvent) {
    return 'Event';
  }
  return mParticleEventNameMap.hasOwnProperty(tagManagerEvent)
    ? mParticleEventNameMap[tagManagerEvent]
    : tagManagerEvent;
};

/*
 * We moved mParticle's *Event Logging* code from TagManager directly to NR (see src/apis/analytics_api.js > track() ).
 * Prior to this all the event keys were passed through TagManager and had to be snake_cased to conform to TagManager convention.
 * This function converts the snake_cased keys (used all over the codebase in AnalyticsApi.track(event) calls) to camelCase, to conform to mParticle conventions (i.e. analogous to TagManager's "data-mappings" to mParticle)
 *
 * PS: We plan to discontinue TagManager use altogether eventually, but currently we leverage the Load Rules functionality to determine when and where various tags are loaded.
 */
export const formatMparticleEventObj = (eventData) => {
  const mParticleEvent = {};
  for (const eventDataKey of Object.keys(eventData)) {
    const mParticleKey = mParticleEventKeysMap[eventDataKey]
      ? mParticleEventKeysMap[eventDataKey]
      : eventDataKey;
    mParticleEvent[mParticleKey] = eventData[eventDataKey];
  }
  mParticleEvent.mParticleEventName = getMparticleEventName(mParticleEvent.tag_manager_event);
  return mParticleEvent;
};
