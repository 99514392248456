import {isGameStream} from '../helpers/streamHelpers';

const initialState = {};

const REGEX_AUTHOR_ID_IN_PROFILE_URL = /\/users\/(\d+)(?:-|$)/;

function extractAuthorIdFromProfileUrl(profileUrl) {
  if (profileUrl) {
    const match = profileUrl.match(REGEX_AUTHOR_ID_IN_PROFILE_URL);
    if (match && match[1]) {
      return match[1];
    }
  }
  return false;
}

function extractAuthorInfo({author_name, author_profile_url}) {
  if (author_name && author_profile_url) {
    return {
      id: extractAuthorIdFromProfileUrl(author_profile_url),
      name: author_name,
    };
  }
  return false;
}

function isVideo(item) {
  return (
    (item.content_type === 'video' && item.content.metadata.video_id !== null) ||
    item.content_type === 'video_article' ||
    item.content_type === 'highlight'
  );
}

function sections(state = initialState, action) {
  if (action.error) {
    throw action.error;
  }
  switch (action.type) {
    case 'LOAD_SECTION_DATA': {
      const payload = action.payload;

      if (!payload.name) {
        return state;
      }

      const videoList = payload.tracks
        ? payload.tracks.filter(isVideo).map((track) => {
            return track.id;
          })
        : [];

      const playlists = payload.sections
        ? Object.entries(payload.sections).reduce((output, [key, value]) => {
            output[key] = value.playlist;
            return output;
          }, {})
        : {};
      const newState = {
        [payload.name]: {
          format: payload.format,
          layout: payload.layout === 'LEM' ? 'L1L5' : payload.layout,
          ...playlists,
          videoList,
        },
      };

      if (isGameStream(payload.type)) {
        newState[payload.name].layout = payload.type;
      }
      if (payload.embed_code) {
        newState.widget = {
          code: payload.embed_code,
          position: payload.embed_position,
        };
      }
      if (payload.streams) {
        const sectionData = newState[[payload.section]] || {};
        newState[[payload.section]] = {
          ...sectionData,
          tags: payload.streams,
        };
      }
      return {
        ...state,
        ...newState,
      };
    }

    case 'LOAD_PLAYLIST_METADATA': {
      if (action.payload.about) {
        const authorInfo = extractAuthorInfo(action.payload.about);
        if (authorInfo) {
          return {
            ...state,
            [action.payload.permalink]: {
              ...state[action.payload.permalink],
              liveData: {
                ...action.payload,
                authorInfo,
              },
            },
          };
        }
      }
      return {
        ...state,
        [action.payload.permalink]: {
          ...state[action.payload.permalink],
          liveData: action.payload,
        },
      };
    }

    case 'LOAD_TEAM_GAMES': {
      const {schedule} = action.payload;
      return {
        ...state,
        teamGames: {
          season: schedule.season,
          games: schedule.schedule_groupings,
        },
      };
    }

    default:
      return state;
  }
}

export default sections;
