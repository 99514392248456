import {createSelector} from 'reselect';

import {articles, tags} from './topLevelSelectors';

export const defaultTrack = createSelector(articles, (articleState) =>
  articleState.tracks.length > 0 ? articleState.tracks[0] : {}
);

const playlist = createSelector(articles, (articleState) => articleState.video_playlist_ids || []);
const videos = createSelector(articles, (articleState) => articleState.video_playlist || {});
export const date = createSelector(articles, (articleState) => articleState.published_at || '');
const elements = createSelector(articles, (articleState) => articleState.elements || []);
export const timezone = createSelector(articles, (articleState) => articleState.timezone);
const articleImage = createSelector(articles, (articleState) => articleState.image || '');

export const articleType = createSelector(articles, (articles) => {
  if (articles.br_mag) {
    return 'mag';
  }
  // Article and Uber Article are the same and can be grouped as Standard Article
  return articles.render_strategy === 'slideshow' ? 'slideshow' : 'standard_article';
});

export const videoPlaylist = createSelector([playlist, videos], (playlist, videos) => {
  return playlist.map((id) => videos[id]);
});

export const displayTag = createSelector([tags, articles], (tags, articles) => {
  if (articles.br_mag) {
    return {
      name: 'B/R Mag',
      link: 'http://mag.bleacherreport.com',
    };
  }

  const formattedTags = Object.values(tags).reduce((formattedTagsObj, theTag) => {
    if (theTag.type) {
      const type = theTag.type.toLowerCase();
      formattedTagsObj[type] = theTag;
    }
    return formattedTagsObj;
  }, {});

  return (
    formattedTags.team ||
    formattedTags.event ||
    formattedTags.topic ||
    formattedTags.division ||
    formattedTags.league ||
    formattedTags.sport ||
    formattedTags.tag ||
    formattedTags.person ||
    formattedTags.editorial
  );
});

export const selectLeadImage = createSelector(
  [elements, articleImage],
  ([element], articleImage) => {
    // leadImage is not always populated
    const isSlideShow = element && element.content_type === 'slide';

    if (isSlideShow) {
      const firstChildElement = element && element.elements && element.elements[0];

      return (
        firstChildElement &&
        firstChildElement.content &&
        (articleImage || firstChildElement.content.url)
      );
    }

    return element && element.content && (articleImage || element.content.url);
  }
);
