// Desktop Navbar Dropdown for top level navigation

import React from 'react';
import {NavLink} from '../../Shared/NavLink/NavLink';
import {NavBarLink} from '../types';
import {Variant} from '../../Shared/Typography/Typography';

export interface NavBarDropDownProps {
  links?: NavBarLink[];
  showWunderkind?: boolean;
  onLinkClick: (link: NavBarLink) => void;
}

export const NavBarDropdown: React.FC<NavBarDropDownProps> = ({
  links,
  showWunderkind,
  onLinkClick,
}) => {
  const columns = 6;
  const rows = Math.ceil(links?.length / columns);

  return (
    <div className="navBarDropdown">
      <div className={showWunderkind ? 'navBarDropdown__wunderkind' : ''}>
        {links && (
          <div className="navBarDropdown__grid" style={{gridTemplateRows: `repeat(${rows}, 1fr)`}}>
            {links.map((link) => (
              <NavLink
                href={link.href}
                key={link.title}
                variant={Variant.H3}
                className="navBarDropdown__link"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => {
                  onLinkClick(link);
                }}
              >
                {link.title}
              </NavLink>
            ))}
          </div>
        )}

        {showWunderkind && (
          <div className="navBarDropdown__verticalRule">
            <div className="navBarDropdown__wunderkindPlaceholder" />
          </div>
        )}
      </div>
    </div>
  );
};
