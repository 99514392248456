import {parseVideoElements} from './helpers/video_helpers';
import {removeScriptTag} from './helpers/sanitize_helpers';
import {parseImages} from '../helpers/imageHelpers';

const initialState = {
  tracks: [],
  data: [],
};

function assignTrackToId(state, track) {
  state[track.content.metadata.video_id] = track;
  return state;
}

export const addTracks = (tracks, state = {}) => {
  return tracks.reduce(assignTrackToId, state);
};

export const getVideos = (item) => {
  return (
    (item.content_type === 'video' && item.content.metadata.video_id !== null) ||
    item.content_type === 'highlight'
  );
};

export const extractTrackIds = (track) => {
  return track.id;
};

export const extractIds = (track) => {
  return track.content.metadata.video_id;
};

export const determineLeadMedia = (firstElement, payload) => {
  const leadMediaContentType = firstElement.content_type;
  let leadMedia;

  if (leadMediaContentType === 'video') {
    leadMedia = firstElement.content.metadata;
  } else if (payload.image) {
    leadMedia = payload.image;
  } else {
    leadMedia = firstElement.content.url;
  }

  return {
    leadMedia,
    leadMediaContentType,
  };
};

function elementIsPlayer(elem) {
  return elem.content_type === 'video' && elem.content.metadata.video_id === this; // eslint-disable-line no-invalid-this
}

function getCurrentVideoIndex(player, elements) {
  return elements.findIndex(elementIsPlayer, player);
}

function videoIsPlayer(video) {
  return video === this; // eslint-disable-line no-invalid-this
}

function videos(state = initialState, action) {
  if (action.error) {
    return state;
  }
  const payload = action.payload;
  switch (action.type) {
    case 'LOAD_VIDEO_METADATA':
      const vid_id = payload.videoId ? payload.videoId : payload.id;
      const vid_md = state.video_metadata;
      return {
        ...state,
        trendingVideoData: payload,
        video_metadata: {
          ...vid_md,
          [vid_id]: {
            ...parseImages(payload.videoMetadata),
          },
        },
      };
    case 'LOAD_VIDEO_PLAYLIST': {
      if (!payload.tracks || payload.tracks.length === 0) {
        return state;
      }
      const newData = payload.tracks.filter(getVideos);
      if (newData.length === 0) {
        return state;
      }
      return {
        ...state,
        video_playlist_ids: newData.map(extractIds),
        video_playlist: addTracks(newData),
        video_playlist_status: {
          ...state.video_playlist_status,
          flyin: newData[0].content.metadata.video_id,
        },
      };
    }
    case 'PLAY_NEXT_PLAYLIST_VIDEO': {
      // will be 0 if not found (-1 + 1) and the index + 1 if found (desired behavior)
      const nextVideoIndex = state.video_playlist_ids.findIndex(videoIsPlayer, payload.player) + 1;
      const nextVideo = state.video_playlist_ids[nextVideoIndex];
      if (!nextVideo) {
        // if you're at the end of the playlist
        return {
          ...state,
          video_playlist_status: {
            ...state.video_playlist_status,
            [payload.playlist]: false,
          },
        };
      }
      // this to avoid state being mutated on deeply nested objects
      const elements = JSON.parse(JSON.stringify(state.elements));
      elements[
        getCurrentVideoIndex(payload.player, elements)
      ].content.metadata.video_id = nextVideo;
      return {
        ...state,
        elements,
        video_playlist_status: {
          ...state.video_playlist_status,
          [payload.playlist]: nextVideo,
        },
      };
    }
    case 'PLAY_SPECIFIC_VIDEO': {
      // this to avoid state being mutated on deeply nested objects
      let elements = [];
      if (state.elements && state.elements.length) {
        elements = JSON.parse(JSON.stringify(state.elements));
        const currElm = elements[getCurrentVideoIndex(payload.player, elements)];
        if (currElm) {
          currElm.content.metadata.video_id = payload.selectedVideo;
        } else {
          elements.push(payload.selectedVideo);
        }
      } else {
        elements.push(payload.selectedVideo);
      }
      return {
        ...state,
        elements,
        video_playlist_status: {
          ...state.video_playlist_status,
          [payload.playlist]: payload.selectedVideo,
        },
      };
    }
    case 'UPDATE_SCROLL_PLAY_VIDEOS': {
      if (!state.elements) {
        return false;
      }

      const [videoToPlay] = payload;
      const elements = JSON.parse(JSON.stringify(state.elements));

      elements.forEach((element) => {
        if (videoToPlay === element.id && element.content_type === 'video') {
          element.content.metadata['scrollPlay'] = true;
        } else if (element.content_type === 'video') {
          element.content.metadata['scrollPlay'] = false;
        }
      });

      return {
        ...state,
        elements,
      };
    }
    case 'LOAD_ARTICLE_DATA': {
      const rawElements = JSON.parse(removeScriptTag(JSON.stringify(payload.elements || [])));
      const elements = rawElements.map(parseVideoElements).map(parseImages);
      const videoList = payload.elements
        ? payload.elements.filter(getVideos).map(extractTrackIds)
        : [];
      if (payload.br_mag) {
        // The first element in the article content will be skipped; instead we'll show the media from this element above the headline
        const [firstElement, ...otherElements] = elements;
        return {
          ...state,
          ...payload,
          ...determineLeadMedia(firstElement, payload),
          elements: otherElements,
          videoList,
        };
      }

      if (payload.render_strategy === 'slideshow') {
        const elementsWithoutAds = elements.reduce((slides, elem) => {
          if (elem.content_type === 'slide') {
            slides.push(elem);
          }
          return slides;
        }, []);

        return {
          ...state,
          ...payload,
          elements: elementsWithoutAds,
          slide_count: state.slide_count || payload.slide_count || elementsWithoutAds.length,
          videoList,
        };
      }

      return {
        ...state,
        ...payload,
        elements,
        videoList,
      };
    }
    case 'GET_TRACK_BY_NAME': {
      const {tags, tracks} = payload;
      const videoMetadataArray = [];
      for (const track of tracks) {
        videoMetadataArray.push(track.content.metadata);
      }
      return {...state, [tags.unique_name]: videoMetadataArray};
    }
    default:
      return state;
  }
}

export default videos;
