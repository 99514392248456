//HP Redesign breakpoints

const homePageBreakpoints = {
  starter: 375, // 375px native
  pointGuard: 768,
  shootingGuard: 1024,
  smallForward: 1280,
  powerForward: 1366,
  center: 1440,
};

/*Max image widths for hp modules
  Screen widths:
    -desktop 1024+
    -tablet 768-1024
    -mobile 0-768
*/
const moduleImageSizes = {
  hero_lite: {
    desktop: 719,
    tablet: 965,
    mobile: 326,
  },
  top_10: {
    desktop: 705,
    tablet: 705,
    mobile: 343,
  },
  trending: {
    desktop: 390,
    tablet: 627,
    mobile: 306,
  },
};

export {homePageBreakpoints, moduleImageSizes};
