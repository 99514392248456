import {createAction} from 'redux-actions';
import StorageAPI from '../apis/storage_api';
import TwilioAPI from '../apis/twilio_api';
import {isValidEmail, isValidPhone} from '../validations';
import {BR, Promos} from '../endpoints';
import logger from '../logger';
import {
  appInstallLink,
  brDeeplinkPath,
  determineBranchChannel,
  determineAlertBannerDeepLink,
  determineCommentsUpsellDeepLink,
} from '../helpers/promoHelpers';
import {
  MOBILE_WEB_APP_PROMO_MOUNTED,
  SET_COMMENTS_UPSELL_DATA,
  SET_ALERT_BANNER_DATA,
  SET_SMART_BANNER_DATA,
} from '../constants/actionTypes';

const setSmartBannerData = createAction(SET_SMART_BANNER_DATA);

/**
 * Extracts the short name for a given tag.
 *
 * @param {Object} displayTag - the tag being displayed.
 * @param {Object} teamStream - the stream object to which a tag belongs.
 * @param {Object} page - the current page.
 *
 * Note that info.short_name and tag.short_name are not necessarily the same.
 */
function getShortName({displayTag, teamStream, page}) {
  if (page.type === 'section') {
    return teamStream.info ? teamStream.info.short_name : '';
  }
  return displayTag?.short_name;
}

function smartBannerMounted(displayTag, teamStream) {
  return (dispatch, getState) => {
    const {page, ui} = getState();
    const shortTagName = getShortName({displayTag, teamStream, page});
    const deepLinkPath = brDeeplinkPath(page);
    const channel = determineBranchChannel(ui.customBrowser);
    const installLink = appInstallLink(ui.userAgent, 'banner', deepLinkPath, channel);
    dispatch(setSmartBannerData({shortTagName, installLink}));
  };
}

function weekFrom(date) {
  date.setDate(date.getDate() + 7);
  return date;
}

function determineCountsStorageKey(campaign) {
  switch (campaign) {
    case 'facebook':
      return 'facebook_account_counts';
    case 'twitter':
      return 'twitter_account_counts';
    case 'send2phone':
      return 'send2phone_counts';
    case 'androidTsPromo':
      return 'android_ts_promo_counts';
    default:
      logger.warn(`determineCountsStorageKey saw unrecognized campaign: ${campaign}`);
      return null;
  }
}

function determineSnoozeStorageKey(campaign) {
  switch (campaign) {
    case 'facebook':
      return 'facebook_liked_accounts';
    case 'twitter':
      return 'twitter_followed_accounts';
    case 'send2phone':
      return 'send2phone_kill';
    case 'androidTsPromo':
      return 'android_ts_promo_kill';
    default:
      logger.warn(`determineSnoozeStorageKey saw unrecognized campaign: ${campaign}`);
      return null;
  }
}

function getInstallLink(page, tags) {
  const tagKeys = Object.keys(tags);
  const tag = tags && tagKeys.length ? tagKeys[0] : '';
  const deeplinkPath =
    page.type === 'article'
      ? `article/bleacherreport.com/articles/${page.article}`
      : `stream/${tag}`;

  return Promos.teamstream({
    deeplink_path: deeplinkPath,
    channel: 'mobile_web',
    feature: 'bottom_promo',
  });
}

const setFlyinClosedData = (campaign, socialAccount) => () => {
  // TODO change the keys we use for data in local storage
  const countsStorageKey = determineCountsStorageKey(campaign);
  const countsStorageData = StorageAPI.get(countsStorageKey) || {};
  if (!countsStorageData[socialAccount]) {
    countsStorageData[socialAccount] = {closed: 0};
  } else if (!countsStorageData[socialAccount].closed) {
    countsStorageData[socialAccount].closed = 0;
  }
  ++countsStorageData[socialAccount].closed;
  StorageAPI.set(countsStorageKey, countsStorageData);
  const snoozeStorageKey = determineSnoozeStorageKey(campaign);
  const snoozeStorageData = StorageAPI.get(snoozeStorageKey) || {};
  if (!snoozeStorageData[socialAccount]) {
    snoozeStorageData[socialAccount] = {expires: null};
  }
  snoozeStorageData[socialAccount].expires = Date.parse(weekFrom(new Date()));
  StorageAPI.set(snoozeStorageKey, snoozeStorageData);
};

function createDefaultStoredData(key, socialAccount) {
  const storedData = {
    [socialAccount]: {shown: 0},
  };
  StorageAPI.set(key, storedData);
  return storedData;
}

function createPromoExpiredData(key, socialAccount) {
  const storedData = {
    [socialAccount]: {expires: null},
  };
  StorageAPI.set(key, storedData);
  return storedData;
}

const MAX_VIEWS = 3;

const setFlyinInfo = createAction('SET_FLYIN_INFO', (data) => {
  const {campaign, social_account} = data;
  const key = `${campaign}_counts`;
  const socialAccount = campaign === 'send2phone' ? 'true' : social_account;
  const impressionsData = StorageAPI.get(key) || createDefaultStoredData(key, socialAccount);
  if (!impressionsData[socialAccount]) {
    impressionsData[socialAccount] = {shown: 0};
  }
  const impressions = Number(
    (impressionsData[socialAccount] && impressionsData[socialAccount].shown) || 0
  );
  ++impressionsData[socialAccount].shown;
  StorageAPI.set(key, impressionsData);
  if (impressions >= MAX_VIEWS) {
    const expiredKey = `${campaign}_kill`;
    const storedPromoExpiredData =
      StorageAPI.get(expiredKey) || createPromoExpiredData(expiredKey, socialAccount);
    if (!storedPromoExpiredData[socialAccount]) {
      storedPromoExpiredData[socialAccount] = {};
    }
    storedPromoExpiredData[socialAccount].expires = Date.parse(weekFrom(new Date()));
    StorageAPI.set(expiredKey, storedPromoExpiredData);
  }

  return data;
});

const sendDownloadAppEmail = createAction('SEND_DOWNLOAD_APP_EMAIL', async (emailAddress) => {
  const body = new FormData();
  body.append('to', emailAddress);
  body.append('short_name', 'Bleacher Report');

  fetch(BR.sendGrid(), {
    method: 'POST',
    body,
  });
});

const sendDownloadAppLink = createAction('SEND_DOWNLOAD_APP_LINK', (phoneNumber) => {
  return TwilioAPI.sendDownloadAppTextMessage(phoneNumber);
});

const updateMessage = createAction('UPDATE_MESSAGE');

const sendTextToDownloadApp = () => (dispatch, getState) => {
  const phoneNumber = getState().promo.send2phone.data.contactInfo;
  if (isValidPhone(phoneNumber)) {
    return dispatch(sendDownloadAppLink(phoneNumber)).catch((err) => {
      const errorMessage =
        err.data && err.data.json
          ? err.data.json.message
          : 'Could not send text message. Please try again later.';
      dispatch(updateMessage(errorMessage));
    });
  }
  return Promise.reject('Input does not look like a phone number:', phoneNumber);
};

const updateFlyinData = createAction('UPDATE_FLYIN_DATA');

const closeFlyin = (campaign, socialAccount) => (dispatch) => {
  dispatch(updateFlyinData({campaign, userClosed: true, visibility: 'hidden'}));
  dispatch(setFlyinClosedData(campaign, socialAccount));
};

const contactInfoEntered = createAction('CONTACT_INFO_ENTERED', (contactInfo) => {
  return {
    campaign: 'send2phone',
    contactInfo,
    isValid: isValidEmail(contactInfo) || isValidPhone(contactInfo),
  };
});

const createFlyin = (campaign) => async (dispatch, getState) => {
  const state = getState();
  const {page, tags} = state;
  if (campaign === 'androidTsPromo') {
    dispatch(
      setFlyinInfo({
        appInstallLink: getInstallLink(page, tags),
        campaign: 'androidTsPromo',
        social_account: false,
      })
    );
  }
  return;
};

const hideFlyin = createAction('HIDE_FLYIN');
const showFlyin = createAction('SHOW_FLYIN');

const sendAppInfo = () => (dispatch, getState) => {
  const contactInfo = getState().promo.send2phone.data.contactInfo;
  if (isValidEmail(contactInfo)) {
    dispatch(sendDownloadAppEmail(contactInfo));
    dispatch(closeFlyin('send2phone', 'true'));
    return;
  }
  if (isValidPhone(contactInfo)) {
    dispatch(sendDownloadAppLink(contactInfo));
    dispatch(closeFlyin('send2phone', 'true'));
    return;
  }
};

const storeVisitorLocation = createAction('STORE_VISITOR_LOCATION');
const setCommentsUpsellData = createAction(SET_COMMENTS_UPSELL_DATA);

const commentsUpsellMounted = ({customBrowser, track, userAgent, variation}) => (dispatch) =>
  dispatch(
    setCommentsUpsellData({
      deepLink: determineCommentsUpsellDeepLink(customBrowser, userAgent, track),
      variation,
    })
  );

const setAlertBannerData = createAction(SET_ALERT_BANNER_DATA);

const alertBannerMounted = () => (dispatch, getState) => {
  const {ui = {}, page} = getState();
  dispatch(
    setAlertBannerData({
      deepLink: determineAlertBannerDeepLink(ui.customBrowser, ui.userAgent, page),
    })
  );
};

const mobileWebAppPromoMounted = createAction(MOBILE_WEB_APP_PROMO_MOUNTED);

const onMobileWebAppPromoMounted = (displayTag) => (dispatch, getState) => {
  const {ui, page} = getState();
  const {userAgent} = ui;
  const browserList = ['chrome', 'edge', 'firefox', 'opera', 'safari'];
  let browser = browserList.find((item) => {
    const re = new RegExp(`${item}`, 'i');
    return re.exec(userAgent.family) !== null;
  });

  if (!browser) {
    browser = userAgent.os.family === 'iOS' ? 'safari' : 'chrome';
  }

  const data = {
    browser,
    installLink: appInstallLink(
      ui.userAgent,
      'large_banner',
      brDeeplinkPath(page),
      determineBranchChannel(ui.customBrowser)
    ),
  };

  // Set promo title
  if (page.type === 'article' || page.type === 'section') {
    data.title = `Get the Latest ${displayTag.short_name} News`;
  } else {
    data.title = 'Get the Latest News and Scores';
  }

  dispatch(mobileWebAppPromoMounted(data));
};

export {
  alertBannerMounted,
  closeFlyin,
  commentsUpsellMounted,
  contactInfoEntered,
  createFlyin,
  getShortName,
  hideFlyin,
  onMobileWebAppPromoMounted,
  sendAppInfo,
  sendTextToDownloadApp,
  showFlyin,
  smartBannerMounted,
  storeVisitorLocation,
  updateMessage,
};
