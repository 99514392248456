import {Statmilk} from '../../endpoints';
import {createFetchService} from '../fetch_utils';
import {format} from 'date-fns';

const fetchJSON = createFetchService('StatMilk');
const tz = new Date().getTimezoneOffset() * 60 * -1;
const errorHandler = (err) => {
  return {
    error: err,
  };
};
/**
 *
 * @param {Stirng} league
 */
const fetchTodayGames = (league, context) => {
  const date = format(new Date(), 'YYYY-MM-DD');
  return fetchJSON(Statmilk.schedules(league, date, context)).catch(errorHandler);
};
const fetchScoresCarouel = (league, team, context) => {
  return fetchJSON(Statmilk.scoresCarousel(tz, league, team, context)).catch(errorHandler);
};
const fetchGamesByTeam = (team) => {
  if (team) {
    return fetchJSON(Statmilk.schedulesByTeam(team)).catch(errorHandler);
  }
  return Promise.reject(new Error('No teamSlug passed for StatMilk API'));
};
export default {
  fetchTodayGames,
  fetchScoresCarouel,
  fetchGamesByTeam,
};
