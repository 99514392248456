import {LOAD_TODAY_GAMES} from '../actions/scheduleActions';
import {Leagues} from '../constants/leagues';

function determineMonthKeys(month, currentlySelectedMonth, teamGames, team) {
  var selectedMonth, monthAfter, monthBefore;
  if (month === 'current') {
    selectedMonth = teamGames.findIndex(
      (game) => game.current !== undefined && game.current === true
    );
    if (selectedMonth < 0) {
      if (teamGames.length > 0) {
        selectedMonth = teamGames.length - 1;
      } else {
        throw new Error(`No games found for: ${team}`);
      }
    }
  } else if (month === 'next') {
    selectedMonth = currentlySelectedMonth + 1;
  } else if (month === 'previous') {
    selectedMonth = currentlySelectedMonth - 1;
  } else {
    throw new Error('unexpected payload for SHOW_MONTH', month);
  }

  if (selectedMonth !== null) {
    if (selectedMonth !== 0) {
      monthBefore = selectedMonth - 1;
    }
    if (selectedMonth !== teamGames.length - 1) {
      monthAfter = selectedMonth + 1;
    }
  }
  return {
    selectedMonth,
    monthAfter,
    monthBefore,
  };
}
function categorizedGames(game_groups) {
  return game_groups.map((gameGroup) => ({
    league: gameGroup.name || '',
    games: gameGroup.games,
  }));
}
const leaguesWithGrouping = [Leagues.WORLD_FOOTBALL, Leagues.COLLEGE_BASKETBALL];
function mapGames(isTopGames, schedules, league) {
  if (!schedules) {
    return [];
  }

  if (!isTopGames) {
    if (leaguesWithGrouping.includes(league.toUpperCase())) {
      return categorizedGames(schedules.game_groups);
    }
    //Games are fetched by a league, so we only return one gameGroup if league is not SOCCER
    const games = schedules.game_groups.reduce((result, gameGroup) => {
      result.push.apply(result, gameGroup.games);
      return result;
    }, []);

    return [{games, league: schedules.site || ''}];
  }

  // TopGames
  return categorizedGames(schedules.game_groups);
}
function makeTodayGames(data) {
  const {schedules, league} = data;
  const isTopGames = !league || league === '';
  const gameGroups = mapGames(isTopGames, schedules, league);
  return {
    isTopGames,
    gameGroups,
    league,
  };
}
function schedules(state = {}, action) {
  if (action.error) {
    throw action.error;
  }
  switch (action.type) {
    case 'LOAD_TEAM_SCHEDULE': {
      const {team, schedule} = action.payload;
      return {
        ...state,
        [team]: {
          ...state[team],
          season: schedule.season,
          games: schedule.schedule_groupings,
        },
      };
    }

    case 'SET_PAGE':
      return {
        ...state,
        selectedTeam: action.payload.id,
      };

    case 'SHOW_TEAM_SCHEDULE_MONTH': {
      const teamData = state[state.selectedTeam];
      const {selectedMonth, monthAfter, monthBefore} = determineMonthKeys(
        action.payload,
        state.selectedMonth,
        teamData.games,
        state.selectedTeam
      );
      return {
        ...state,
        selectedMonth,
        isNextVisible:
          teamData.games[monthAfter] &&
          teamData.games[monthAfter].entries &&
          !!teamData.games[monthAfter].entries.length,
        isPrevVisible:
          teamData.games[monthBefore] &&
          teamData.games[monthBefore].entries &&
          teamData.games[monthBefore].entries.length > 0,
      };
    }

    case LOAD_TODAY_GAMES:
      return {
        ...state,
        todayGames: makeTodayGames(action.payload),
      };
    default:
      return state;
  }
}

export default schedules;
