import assets from '../../assets.json';

const initialStyles = [];

if (assets.global) {
  initialStyles.push({
    href: assets.global.css,
    key: 'globalCSS',
    type: 'text/css',
    rel: 'stylesheet',
  });
}

if (assets.atomic) {
  initialStyles.push({
    href: assets.atomic.css,
    key: 'atomicCSS',
    type: 'text/css',
    rel: 'stylesheet',
  });
}

export {initialStyles};
