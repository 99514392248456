import * as JWTAPI from './jwt_api';
import * as Fetch from './helpers/fetch';

function refreshJWTFactory(service, url, args, responseFormat, fetchService) {
  return (error) => {
    if (error.isBoom && error.output.statusCode === 401) {
      const opts = args[1]; // second arg is the options object
      const refresh = opts.refresh; // get refresh if available
      return JWTAPI.updateAuth(refresh)
        .then((jwt) => {
          opts.jwt = jwt; // overwrite the old jwt with the new one
          if (opts.headers) {
            opts.headers.set('Authorization', `Bearer ${opts.jwt}`);
          }
          return fetchService(service, url, args, responseFormat);
        })
        .catch(Fetch.logAndThrow(args));
    }
    throw error;
  };
}

function fetchServiceFactory(service, url, args, responseFormat = 'json') {
  const fetchFailLogger = Fetch.logAndThrow(args);
  const refreshJWTchecker = refreshJWTFactory(
    service,
    url,
    args,
    responseFormat,
    fetchServiceFactory
  );

  return Fetch.fetchServiceFactory(service, url, args, responseFormat)
    .catch(refreshJWTchecker)
    .catch(fetchFailLogger);
}

// eslint-disable-next-line id-length
function fetchServiceFactoryWithoutJWTRefresh(service, url, args, responseFormat = 'json') {
  const fetchFailLogger = Fetch.logAndThrow(args);
  return Fetch.fetchServiceFactory(service, url, args, responseFormat).catch(fetchFailLogger);
}

const getFetchArgs = (url, method, opts, args) => {
  // allows passed options to (optionally) override the default timeout
  const options = {timeout: Fetch.FETCH_TIMEOUT_LENGTH, ...opts};
  const headers = new Headers(opts.headers || {});
  if (method !== 'GET') {
    // If we're doing a POST/PUT/etc we always default to a JSON body
    headers.append('Content-Type', 'application/json');
    if (opts.body) {
      options.body = JSON.stringify(opts.body);
    }
  }
  if (opts.jwt) {
    // if we have auth data we're going to need the JWT for this request.
    headers.append('Authorization', `Bearer ${opts.jwt}`);
  }

  if (opts.daltonToken) {
    // if we have auth data we're going to need the daltonToken for this request.
    headers.append('Authorization', `${opts.daltonToken}`);
  }
  options.method = method;
  options.headers = headers;
  return [url, options, ...args];
};

export const createFetchService = (service, fetchOptions = {}) => {
  // By default, this will return a service that GETs JSON.
  // The optional second argument can be used to configure how the service will
  // be fetched. The properties to be looked at are:
  //   `responseFormat`: specify the format which the response should be in.
  //           Default: "JSON"
  //   `method`: specify the HTTP verb used to create the request. If the request
  //           requires a body, pass it as `body` in the `opts` param to the
  //           service. Default: "GET"
  const serviceMethod = fetchOptions.method || 'GET';

  return (url, opts = {}, ...args) => {
    if (!url) {
      return Promise.reject('undefined provided as URL', [url, opts, ...args]);
    }
    const method = opts.method || serviceMethod; // allows overrides per request
    const fetchArgs = getFetchArgs(url, method, opts, args);
    return fetchServiceFactory(service, url, fetchArgs, fetchOptions.responseFormat);
  };
};

export const createFetchServiceCustomJWT = (service, fetchOptions = {}) => {
  const serviceMethod = fetchOptions.method || 'GET';

  return (url, opts = {}, ...args) => {
    if (!url) {
      return Promise.reject('undefined provided as URL', [url, opts, ...args]);
    }
    const method = opts.method || serviceMethod; // allows overrides per request
    const fetchArgs = getFetchArgs(url, method, opts, args);
    return fetchServiceFactoryWithoutJWTRefresh(
      service,
      url,
      fetchArgs,
      fetchOptions.responseFormat
    );
  };
};
