function margins(el) {
  const {marginLeft, marginRight} = global.getComputedStyle(el);
  return {
    left: parseInt(marginLeft, 10),
    right: parseInt(marginRight, 10),
  };
}

function bodyScrollTop() {
  const {documentElement, body} = global.document;
  const documentScrollTop = documentElement && documentElement.scrollTop;
  const bodyScrollTop = body && body.scrollTop;

  // Cross-browser support for scrollTop
  return documentScrollTop || bodyScrollTop || 0;
}

function outerWidth(el) {
  const {left, right} = margins(el);
  return el.offsetWidth + left + right;
}

const getDocumentWidth = () => {
  return global.document && global.document.body
    ? global.document.body.clientWidth || global.document.documentElement.clientWidth
    : 0;
};

/**
 * @param {selector} string element selector
 * @returns {number} the height of the dom element.
 */
function getElementHeight(selector) {
  const element = global.document.querySelector(selector);

  if (!element) {
    return 0;
  }

  return Math.max(0, element.clientHeight, element.scrollHeight, element.offsetHeight);
}

/**
 * A handy function to get the width of a dom element.
 * @param {object} attr Should have an id or className property, like so: {className: "some-class"}, {id: "some-id"}, ...
 * @returns {number}    Returns the width of the dom element.
 */
function getElementWidth(attr) {
  let element;

  if (attr.hasOwnProperty('id')) {
    element = global.document.getElementById(attr.id);
  }

  if (attr.hasOwnProperty('className')) {
    element = global.document.getElementsByClassName(attr.className).item(0);
  }

  return element ? outerWidth(element) : 0;
}

function getPageHeight() {
  // A cross-platform way of identifying the pixel height of the entire page.
  // Need to wait until the document is ready before getting page height.
  const {body, documentElement} = global.document;
  // Math.max() to return the largest value; ignore `undefined` values.
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    documentElement.clientHeight,
    documentElement.scrollHeight,
    documentElement.offsetHeight
  );
}

function getWindowHeight() {
  return global.innerHeight || global.document.documentElement.clientHeight;
}

function isFullyInViewport(elem) {
  if (!elem || !global.document.contains(elem)) {
    return false;
  }
  const {top, left, bottom, right} = elem.getBoundingClientRect();

  return (
    top >= 0 &&
    left >= 0 &&
    bottom <= (global.innerHeight || document.documentElement.clientHeight) &&
    Math.floor(right) <= (global.innerWidth || document.documentElement.clientWidth)
  );
}

function isPartiallyInViewport(elem) {
  if (!elem) {
    return false;
  }

  const rect = elem.getBoundingClientRect();
  const windowHeight = getWindowHeight();

  return rect.top < windowHeight && rect.bottom > 0;
}

function percentInWindow(elem) {
  if (!elem) {
    return false;
  }

  const rect = elem.getBoundingClientRect();
  const windowHeight = getWindowHeight();
  let percentage = 0;

  // if the top and bottom of the elem extend beyond the viewport
  if (rect.top < 0 && windowHeight - rect.bottom < 0) {
    percentage = 100;
    // if the top of elem is within the viewport
  } else if (rect.top > 0) {
    percentage = ((windowHeight - rect.top) / windowHeight) * 100;
    // if the bottom of the elem is within the viewport
  } else if (rect.bottom > 0) {
    percentage = (rect.bottom / windowHeight) * 100;
  }

  return percentage;
}

function smoothScroll(destinationOffset, duration = 200, callback) {
  if (!destinationOffset) {
    return false;
  }

  const startPosition = global.scrollY;
  const startTime = Date.now();

  const documentHeight = getPageHeight();
  const windowHeight = getWindowHeight();
  const destination =
    documentHeight - destinationOffset < windowHeight
      ? documentHeight - windowHeight
      : destinationOffset;

  const scroll = () => {
    const currentTime = Date.now();
    const time = Math.min(1, (currentTime - startTime) / duration);

    global.scroll(0, time * (destination - startPosition) + startPosition);

    // It's important not to directly compare scroll and destination, because if
    // the viewport has been zoomed at all, the scroll value can be a decimal.
    // Instead we see far how away the scroll value is from the destination; if
    // it's a pixel or closer we are effectively at the destination.
    if (Math.abs(global.scrollY - destination) <= 1) {
      if (callback) {
        callback();
      }

      return false;
    }

    return global.requestAnimationFrame(scroll);
  };

  return scroll();
}

function valuesOfElements(nodelist) {
  // This function is a helper for getting the values of elements in a NodeList.
  // Use it to get an array of input values, for example, without having to
  // write a `for` loop yourself. (NodeLists don't support `.map()` across all
  // browsers...)
  const nodelistLength = nodelist.length;
  const values = [];
  for (let index = 0; index < nodelistLength; index++) {
    values.push(nodelist[index].value);
  }
  return values;
}
function generateNoScriptTag(url) {
  const noscript = document.createElement('noscript');
  const img = document.createElement('img');
  img.src = url;
  img.width = 1;
  img.height = 1;
  img.alt = '';
  noscript.appendChild(img);
  global.document.body.appendChild(noscript);
}
export {
  bodyScrollTop,
  generateNoScriptTag,
  getDocumentWidth,
  getElementHeight,
  getElementWidth,
  getPageHeight,
  getWindowHeight,
  isFullyInViewport,
  isPartiallyInViewport,
  margins,
  outerWidth,
  percentInWindow,
  smoothScroll,
  valuesOfElements,
};
