/**
 * Helper function which returns a promise plus a function which resolves the promise.
 *
 * @returns {object} {promise: Promise, resolver: function}
 */
export function createPromiseAndResolver() {
  let resolver = () => {
    throw new Error('createPromiseAndResolver — unrecognized state');
  };
  const promise = new Promise((resolve, _reject) => {
    resolver = resolve;
  });
  return {promise, resolver};
}
