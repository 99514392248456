import {combineReducers} from 'redux';
import {format} from 'date-fns';

import logger from '../logger';

import ImagesAPI from '../apis/images_api';
import {setBasicTitle} from '../actions/appActions';

import {AndroidApp, BR, Embeder, IosApp, Syndication, Teamstream, Static} from '../endpoints';
import {isGameStream} from '../helpers/streamHelpers';
import {whichMetaDescription} from './helpers/meta_description_helper';
import {homePageTags} from '../constants/tags';
import {VIDEO} from '../constants/pageTypes';
import initialMeta from '../store/metaTags';
import initialScripts from '../store/scripts';
import {initialStyles} from '../store/styles';
import initialLinks from '../store/links';

// this builds an article specific JSON-LD object based on project requirements
// see Google documentation this here: https://developers.google.com/schemas/formats/json-ld
const buildArticleSchema = (payload) => {
  // this should eventually become a schema factory and be abstracted
  const leadSlot = payload.elements[0];
  const isLeadSlotImage = leadSlot && leadSlot.content_type === 'image';
  const leadImage = isLeadSlotImage ? leadSlot.content.url : ImagesAPI.brLogo('height60');
  const articleImage = payload.image || leadImage;

  const meta = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    about: payload.description,
    author: {
      '@type': 'Person',
      name: payload.author.name,
      jobTitle: payload.author.title,
    },
    dateModified: payload.updated_at,
    datePublished: payload.published_at,
    description: payload.description,
    headline: payload.title,
    image: {
      type: 'ImageObject',
      url: articleImage,
    },
    inLanguage: 'English',
    isFamilyFriendly: true,
    keywords: payload.page.tags,
    mainEntityOfPage: payload.description,
    publisher: {
      '@type': 'Organization',
      name: 'Bleacher Report',
      url: 'https://bleacherreport.com',
      logo: {
        type: 'ImageObject',
        url: ImagesAPI.brLogo('height60'),
        width: '80',
        height: '60',
      },
    },
    thumbnailURL: articleImage,
  };
  return {
    key: 'NewsArticleSchema',
    content: JSON.stringify(meta),
    type: 'application/ld+json',
  };
};

const addVideoToNewsArticleSchema = (scripts, videoData) => {
  //find newsArticleSchema if it exists
  const newsArticleSchema = scripts.filter((script) => script.key === 'NewsArticleSchema')[0];
  if (!newsArticleSchema) {
    return scripts;
  }

  const videoObjectSchema = {
    '@type': 'VideoObject',
    description: videoData.description ? videoData.description : videoData.title,
    author: videoData.author_name,
    name: videoData.title,
    thumbnailUrl: videoData.thumbnail_url,
    duration: `PT${videoData.duration}S`,
    uploadDate: videoData.analytics ? videoData.analytics.published_at : '',
    contentUrl: videoData.mp4,
  };

  const schemaContent = JSON.parse(newsArticleSchema.content);

  if (videoData && !videoData.error) {
    if (schemaContent.video) {
      schemaContent.video.push(videoObjectSchema);
    } else {
      schemaContent.video = [videoObjectSchema];
    }
  }

  return scripts.map((script) => {
    if (script.key === 'NewsArticleSchema') {
      return {...script, content: JSON.stringify(schemaContent)};
    }
    return script;
  });
};

// Return tag with type League or null if not present
const leagueTag = (payload) => payload?.tags?.find((tag) => tag?.type === 'League');

// Return page tag details or null if not present
const pageTag = (payload) => payload?.tags?.find((tag) => tag?.tag_id === payload?.page?.tag_id);

const excludeTags = (excludedTags) => {
  return (tag) => !excludedTags.includes(tag?.unique_name);
};

const buildArticleBreadcrumbSchema = (payload) => {
  if (payload?.tags) {
    // exclude no-ads tags from payload by unique_name
    payload.tags = payload?.tags?.filter(excludeTags(['no-ads']));
  }
  // Return Tag with type League or the last card in the array if tag of type league isn't present
  const sectionInfo = leagueTag(payload) || payload?.tags?.[payload?.tags?.length - 1];

  //Page tag info
  const tagInfo = pageTag(payload);
  const isSectionInfoComplete = sectionInfo && sectionInfo.display_name && sectionInfo.unique_name;
  const isSectionAndTagSame = sectionInfo && sectionInfo.display_name === tagInfo?.display_name;

  const schemaObject = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@id': `https://${process.env.BR_HOST}`,
          name: 'Home',
        },
      },
    ],
  };

  //If sectionInfo (which holds the 'section' of the article) is defined then add the middle breadcrumb
  if (isSectionInfoComplete) {
    schemaObject.itemListElement.push({
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': `https://${process.env.BR_HOST}/${sectionInfo.unique_name}`,
        name: sectionInfo.display_name,
      },
    });
  }

  //If section name is not the same as tag name then add the middle breadcrumb
  if (!isSectionAndTagSame && tagInfo) {
    schemaObject.itemListElement.push({
      '@type': 'ListItem',
      position: isSectionInfoComplete ? 3 : 2,
      item: {
        '@id': `https://${process.env.BR_HOST}/${tagInfo.unique_name}`,
        name: tagInfo.display_name,
      },
    });
  }

  return {
    key: 'BreadcrumbSchema',
    content: JSON.stringify(schemaObject),
    type: 'application/ld+json',
  };
};

const buildStubVideoObjectSchema = (payload) => {
  const schemaObject = {
    '@context': 'https://schema.org/',
    '@type': 'VideoObject',
    name: `${payload.stub.title}`,
    '@id': `${payload.stub.permalink}`,
    datePublished: `${format(new Date(payload.stub.published_at), 'YYYY-MM-DD')}`,
  };
  return {
    key: 'StubPageVideoObjectSchema',
    content: JSON.stringify(schemaObject),
    type: 'application/ld+json',
  };
};

const buildLiveVideoObjectSchema = (payload) => {
  const schemaObject = {
    '@context': 'https://schema.org/',
    '@type': 'VideoObject',
    name: `${payload.liveVideoMetadata.name}`,
    thumbnailUrl: `${payload.liveVideoMetadata.thumbnail_url}`,
    description: `${payload.liveVideoMetadata.name}`,
    publication: {
      '@type': 'BroadcastEvent',
      name: `${payload.liveVideoMetadata.name}`,
      isLiveBroadcast: true,
      startDate: `${payload.liveVideoMetadata.starts_at}`,
      endDate: `${payload.liveVideoMetadata.ends_at}`,
    },
    uploadDate: `${format(
      new Date(payload.liveVideoMetadata.analytics.published_at),
      'YYYY-MM-DD'
    )}`,
  };
  return {
    key: 'LiveVideoPageVideoObjectSchema',
    content: JSON.stringify(schemaObject),
    type: 'application/ld+json',
  };
};

const buildVideoObjectSchema = (payload) => {
  const schemaObject = {
    '@context': 'https://schema.org/',
    '@type': 'VideoObject',
    name: `${payload.title}`,
    thumbnailUrl: `${payload.thumbnail_url}`,
    description: `${payload.description}`,
    contentUrl: `${payload.mp4}`,
    embedUrl: `${payload.embed_code}`,
    uploadDate: `${format(new Date(payload.analytics.published_at), 'YYYY-MM-DD')}`,
    potentialAction: {
      '@type': 'SeekToAction',
      target: `https://bleacherreport.com/videos/${`${payload.id} ${payload.title}`
        .replace(/ +/g, '-')
        .toLowerCase()}?t={seek_to_second_number}`,
      'startOffset-input': 'required name=seek_to_second_number',
    },
  };
  return {
    key: 'VideoPageVideoObjectSchema',
    content: JSON.stringify(schemaObject),
    type: 'application/ld+json',
  };
};

const buildHighlightVideoObjectSchema = (payload) => {
  const schemaObject = {
    '@context': 'https://schema.org/',
    '@type': 'VideoObject',
    name: `${payload.title}`,
    thumbnailUrl: `${payload.thumbnail_url}`,
    description: `${payload.title}`,
    contentUrl: `${payload.mp4}`,
    embedUrl: `${payload.embed_code}`,
    uploadDate: `${format(new Date(payload.analytics.published_at), 'YYYY-MM-DD')}`,
    potentialAction: {
      '@type': 'SeekToAction',
      target: `https://bleacherreport.com/videos/${`${payload.id} ${payload.title}`
        .replace(/ +/g, '-')
        .toLowerCase()}?t={seek_to_second_number}`,
      'startOffset-input': 'required name=seek_to_second_number',
    },
  };
  return {
    key: 'HighlightPageVideoObjectSchema',
    content: JSON.stringify(schemaObject),
    type: 'application/ld+json',
  };
};

export const initialStates = {
  title: 'Bleacher Report | Sports. Highlights. News. Now.',
  html: {
    className: 'no-js', // The .no-js class will be replaced with .has-js in the browser if JavaScript is available.
    lang: 'en',
  },
  meta: initialMeta,
  styles: initialStyles,
  scripts: initialScripts,
  links: initialLinks,
};

const titleSuffix = ' | Bleacher Report';

function staticTitleFor(page) {
  switch (page) {
    case 'about':
      return `About Us${titleSuffix}`;
    case 'advertise':
      return `Advertising${titleSuffix}`;
    case 'careers':
      return `Careers${titleSuffix}`;
    case 'comcaptains':
      return `Community Captains${titleSuffix}`;
    case 'comguidelines':
      return `Community Guidelines${titleSuffix}`;
    case 'official-rules':
      return `Betting Official Rules${titleSuffix}`;
    case 'contact':
      return `Contact Us${titleSuffix}`;
    case 'cookiepolicy':
      return `Cookie Policy${titleSuffix}`;
    case 'licenses':
      return `Licenses${titleSuffix}`;
    case 'privacy':
      return `Privacy Policy${titleSuffix}`;
    case 'pregame-picks-rules':
      return `Pregame Picks Rules${titleSuffix}`;
    case 'privacy_old':
      return `Old Privacy Policy${titleSuffix}`;
    case 'terms':
      return `Terms of Use${titleSuffix}`;
    case 'usepolicy':
      return `Use Policy${titleSuffix}`;
    default:
      return `${page}${titleSuffix}`;
  }
}

function altTitleFor(page) {
  switch (page?.title) {
    case 'All Elite Wrestling PPV':
      return `AEW PPV | Bleacher Report`;
    case 'Video Landing Page':
      return `Highlights and Live Video from Bleacher Report`;
    default:
      return null;
  }
}

function determineKeywordsFor(sectionType, league) {
  switch (sectionType) {
    case 'League':
      return league === 'Professional_Wrestling'
        ? 'News, Scores, Highlights, Stats, and Rumors'
        : 'News, Scores, Highlights, Stats, Standings, and Rumors';
    case 'Team':
      return 'News, Scores, Highlights, Injuries, Stats, Standings, and Rumors';
    case VIDEO:
      return 'Highlights and Live Video from Bleacher Report';
    default:
      return 'News, Scores, Highlights, Stats, and Rumors';
  }
}

function determineTitleSuffix(sectionType) {
  switch (sectionType) {
    case VIDEO:
      return '';
    default:
      return titleSuffix;
  }
}

function determineFullLeagueName(acronym) {
  switch (acronym) {
    case 'NBA':
      return 'National Basketball Association, ';
    case 'NFL':
      return 'National Football League, ';
    case 'MLB':
      return 'Major League Baseball, ';
    case 'NHL':
      return 'National Hockey League, ';
    case 'MCBB':
      return "Men's College Baseketball, ";
    case 'WCBB':
      return "Women's College Baseketball, ";
    case 'CFB':
      return 'College Football, ';
    case 'AEW':
      return 'All Elite Wrestling, ';
    default:
      return '';
  }
}

function title(state = initialStates.title, action) {
  switch (action.type) {
    case setBasicTitle().type:
      return action.payload.title;
    case 'UPDATE_STATIC_TITLE':
      return staticTitleFor(action.payload);

    case 'UPDATE_TITLE': {
      if (typeof action.payload !== 'object') {
        throw new Error('Unexpected title payload:', action.payload);
      }
      const fullLeagueName = determineFullLeagueName(action.payload.league);
      const tagTypeKeywords = determineKeywordsFor(
        action.payload.sectionType,
        action.payload.league
      );

      const titleSuffix = determineTitleSuffix(action.payload.sectionType);

      const altTitle = altTitleFor(action.payload);
      const altTags = action.payload.altTags?.replaceAll('_', ' ');
      if (tagTypeKeywords) {
        if (altTitle) {
          return altTitle;
        } else if (action.payload.title === '') {
          return `${altTags} | ${fullLeagueName}${tagTypeKeywords}${titleSuffix}`;
        }
        return `${action.payload.title} | ${fullLeagueName}${tagTypeKeywords}${titleSuffix}`;
      }
      return `${action.payload.title}${titleSuffix}`;
    }

    case 'LOAD_STUB_DATA': {
      const stubTitle = action.payload.stub && action.payload.stub.title;
      if (stubTitle) {
        return `${action.payload.stub.title}${titleSuffix}`;
      }
      return state;
    }

    case 'FETCH_USER_POST':
      return 'Bleacher Report';

    default:
      return state;
  }
}

const IMAGE_URL_BR_LOGO = ImagesAPI.brLogo('height630');

function truncateWithEllipsis(string, limit) {
  if (string.length <= limit) {
    return string;
  }
  return `${string.substr(0, limit - 1)}…`;
}

function getLargeImageUrl(imageUrl = '') {
  // replaces the values in a url's w/h query params with 1200 (FB's recommended size)
  return imageUrl ? imageUrl.replace(/([?&])([wh])=\d+/g, '$1$2=1200') : '';
}

function determineArticleImage({content_type, content, elements}) {
  if (content_type === 'image' && content.url) {
    return getLargeImageUrl(content.url);
  }
  if (content_type === 'slide') {
    const url = elements[0] && elements[0].content && elements[0].content.url;
    return url ? getLargeImageUrl(url) : IMAGE_URL_BR_LOGO;
  }
  return IMAGE_URL_BR_LOGO;
}

function determineWhereToFindImage({format, section, tags}) {
  if (format === 'editorial') {
    // front-page and uk
    return IMAGE_URL_BR_LOGO;
  }
  if (format === 'stream') {
    const primaryTag = tags.find((tag) => tag.permalink === section);
    if (primaryTag && primaryTag.logo_filename) {
      return ImagesAPI.teamLogo(primaryTag.logo_filename, 'large');
    }
  }
  return IMAGE_URL_BR_LOGO;
}

function addCloudinaryParams({url, ...cloudinaryParams}) {
  // expects url to be a string e.g. https://media.bleacherreport.com/image/upload/v1651163464/ss5hkswulniu6ctpbyxy.jpg
  if (!url || typeof url !== 'string') {
    logger.error('addCloudinaryParams: unexpected url', arguments[0]);
    throw new Error('addCloudinaryParams: unexpected url');
  }
  const urlBreakpoint = url.indexOf('/v');
  if (urlBreakpoint === -1) {
    // this is not a cloudinary url... return untouched url
    return url;
  }
  const urlStart = url.substr(0, urlBreakpoint + 1); // https://media.bleacherreport.com/image/upload/
  const urlEnd = url.substr(urlBreakpoint); // /v1651163464/ss5hkswulniu6ctpbyxy.jpg
  const cloudinaryParamsString = Object.entries(cloudinaryParams)
    .map(([key, val]) => `${key}_${val}`)
    .join(',');
  return `${urlStart}${cloudinaryParamsString}${urlEnd}`;
}

function meta_tags(state = initialStates.meta, action) {
  const payload = action.payload;
  switch (action.type) {
    case 'LOAD_ARTICLE_DATA': {
      const primaryImage = payload.image
        ? getLargeImageUrl(payload.image)
        : determineArticleImage(payload.elements[0]);
      const imageRect = addCloudinaryParams({
        url: primaryImage,
        c: 'fill',
        g: 'faces',
        w: 3800,
        h: 2000,
        q: 95,
      }); // 1.9:1 ratio
      const imageSquare = addCloudinaryParams({
        url: primaryImage,
        c: 'fill',
        g: 'faces',
        w: 3800,
        h: 2000,
        q: 95,
      }); // 1.9:1 ratio
      const imageThumbnail = addCloudinaryParams({
        url: primaryImage,
        c: 'fill',
        g: 'faces',
        w: 1600,
        h: 1600,
        q: 95,
      });
      const articleURL = BR.article(payload.permalink);
      const appURL = Teamstream.article(payload.permalink);

      return {
        ...state,
        'al:android:url': articleURL,
        'al:ipad:url': appURL,
        'al:iphone:url': appURL,
        'al:web:url': articleURL,
        'article:publisher': 'https://www.facebook.com/bleacherreport',
        author: payload.author.name,
        description: payload.description,
        'og:description': payload.description,
        'og:image': imageSquare,
        'og:image:width': 1200,
        'og:image:height': 630,
        'og:title': payload.title,
        'og:type': 'article',
        'og:url': articleURL,
        pubdate: payload.published_at,
        thumbnail: imageThumbnail,
        'twitter:app:url:googleplay': articleURL,
        'twitter:app:url:ipad': appURL,
        'twitter:app:url:iphone': appURL,
        'twitter:card': 'summary_large_image',
        'twitter:description': payload.description,
        'twitter:image': imageRect,
        'twitter:title': payload.title,
        'twitter:url': articleURL,
      };
    }

    case 'LOAD_STUB_DATA': {
      const stubData = payload.stub;
      const title = stubData.title;
      const url = stubData.permalink; // n.b. unlike most other pieces of data in BR, this "permalink" is a full URL
      const description = stubData.description || state.description;
      const image = stubData.image || ImagesAPI.brLogo('height60');
      let ogDescription = description,
        ogTitle = title,
        ogImage = image,
        ogVideoObject = {},
        twitterDescription = description,
        twitterTitle = title,
        twitterImage = image;
      if (stubData.embeds) {
        if (stubData.embeds.open_graph) {
          ogDescription = stubData.embeds.open_graph.description;
          ogTitle = stubData.embeds.open_graph.headline;
          ogImage = stubData.embeds.open_graph.thumbnail;
          if (stubData.embeds.open_graph.hls_url) {
            ogVideoObject = {'og:video': stubData.embeds.open_graph.hls_url};
          }
        }
        if (stubData.embeds.twitter) {
          twitterDescription = stubData.embeds.twitter.description;
          twitterTitle = stubData.embeds.twitter.headline;
          twitterImage = stubData.embeds.twitter.thumbnail;
        }
      }
      return {
        ...state,
        ROBOTS: 'NOINDEX, NOFOLLOW',
        'article:publisher': 'https://www.facebook.com/bleacherreport',
        description,
        'og:description': ogDescription,
        'og:image': ogImage,
        'og:title': ogTitle,
        'og:type': 'article',
        'og:url': url,
        ...ogVideoObject,
        thumbnail: image,
        'twitter:description': twitterDescription,
        'twitter:image': twitterImage,
        'twitter:title': twitterTitle,
        'twitter:url': url,
      };
    }

    case 'LOAD_SECTION_DATA': {
      let overrides = {};
      if (!homePageTags.us.includes(payload.section)) {
        const sectionURL = BR.section(payload.section);
        const appURL = Teamstream.stream(payload.section);
        const tag = payload.tags.find((tag) => tag.unique_name === payload.section) || {
          display_name: '',
          type: 'unknown',
        };
        const altTags = payload.page?.tags?.replaceAll('_', ' ');
        const description = whichMetaDescription(tag, payload);
        overrides = {
          'al:android:url': sectionURL,
          'al:ipad:url': appURL,
          'al:iphone:url': appURL,
          'al:web:url': sectionURL,
          description,
          'og:description': description,
          'og:title': tag.display_name || altTags,
          'og:type': tag.type.toLowerCase() === 'team' ? 'sports_team' : 'website',
          'og:url': sectionURL,
          'twitter:description': description,
        };
      }
      const primaryImage = determineWhereToFindImage(payload);
      return {
        ...state,
        'og:description':
          'From BleacherReport.com, your destination for the latest news on your teams and topics in sports.',
        'og:image': primaryImage,
        'og:title': 'Bleacher Report',
        'og:type': 'website',
        'og:url': 'https://bleacherreport.com/',
        thumbnail: primaryImage,
        'twitter:card': 'app',
        'twitter:description':
          "Get Your Team's News First. Download For Free On Your iPhone, iPad, or Android.",
        'twitter:image': primaryImage,
        ...overrides,
      };
    }

    case 'LOAD_PLAYLIST_METADATA': {
      const livestreamURL = BR.liveblogs(payload.permalink);
      const description = payload.description
        ? truncateWithEllipsis(payload.description.replace(/\s+/g, ' '), 160)
        : '';
      return {
        ...state,
        'al:web:url': livestreamURL,
        description,
        'og:description': description,
        'og:title': payload.headline,
        'og:url': livestreamURL,
        'twitter:card': 'summary_large_image',
        'twitter:description': description,
        'twitter:title': payload.headline,
      };
    }

    case 'SET_EMBEDDED_MODE': {
      if (action.payload.isEmbedded) {
        return {
          ...state,
          viewport: 'width=device-width, initial-scale=1, user-scalable=no',
        };
      }
      // fallback, always return correct default.
      return {
        ...state,
        viewport: 'width=device-width, initial-scale=1',
      };
    }

    case 'SET_LIVE_IMAGE': {
      const image = getLargeImageUrl(action.payload);
      return {
        ...state,
        'og:image': image,
        thumbnail: image,
        'twitter:image': image,
      };
    }

    case 'LOAD_TEAM_SCHEDULE': {
      let [teamTag] = action.payload.tags;
      // Tag data can be blank, sometimes. It *shouldn't be* but... graceful failure.
      // Same applies to the year.
      teamTag = teamTag || {display_name: '', short_name: ''};
      const year = action.payload.schedule.season;
      const description = `The ${teamTag.display_name} Schedule with dates, opponents, and links to tickets for the ${year} preseason and regular season.`;
      return {
        ...state,
        description,
      };
    }

    case 'LOAD_USER_PROFILE': {
      const {first_name, last_name} = action.payload.user;
      if (first_name && last_name) {
        return {
          ...state,
          description: `${first_name} ${last_name} | ${state.description}`,
        };
      }
      return state;
    }

    case 'SET_METADATA_TAGS': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'FETCH_USER_POST': {
      const userPost = action.payload;
      const username = userPost.author.username;
      const canonicalUrl = BR.userPost(userPost.id);
      return {
        ...state,
        description: '',
        author: username,
        'og:type': 'article',
        'og:url': canonicalUrl,
        pubdate: userPost.inserted_at,
        'twitter:url': canonicalUrl,
      };
    }

    case 'SET_PAGE': {
      if (process.env.NODE_ENV !== 'production' || process.env.RELEASE_ENV !== 'production') {
        return {
          ...state,
          robots: 'noindex, nofollow',
          googlebot: 'noindex, nofollow',
        };
      }
      return state;
    }

    default:
      return state;
  }
}

function links(state = initialStates.links, action) {
  const payload = action.payload;
  switch (action.type) {
    case 'LOAD_ARTICLE_DATA': {
      const articleUrl = BR.article(payload.permalink);
      const rssLinks = payload.tags?.map((value) => ({
        key: 'rss',
        rel: 'alternate',
        type: 'application/rss+xml',
        href: `https://bleacherreport.com/sitemaps/feed?tag_id=${value.tag_id}`,
      }));

      return [
        ...state,
        ...(rssLinks || []),
        {key: 'ampLink', rel: 'amphtml', href: Syndication.article(payload.permalink)},
        {key: 'androidLink', rel: 'alternate', href: AndroidApp.article(payload.permalink)},
        {key: 'androidTsLink', rel: 'alternate', href: AndroidApp.teamstream(payload.permalink)},
        {key: 'iosLink', rel: 'alternate', href: IosApp.article(payload.permalink)},
        {key: 'canonicalLink', rel: 'canonical', href: articleUrl},
        {
          key: 'oembedJSON',
          rel: 'alternate',
          href: Embeder.oembed(articleUrl),
          type: 'application/json+oembed',
        },
      ];
    }

    case 'SET_PAGE': {
      if (payload.type === 'static') {
        return [...state, {key: 'canonicalLink', rel: 'canonical', href: payload.url}];
      }
      return state;
    }

    case 'LOAD_STUB_DATA': {
      // NR-198: Amp page only for video / hightlight stub pages
      const contentType = payload.stub && payload.stub.content_type;
      const canonicalUrl = payload.stub.permalink.replace(/^http:/, 'https:');
      if (contentType === 'highlight' || contentType === 'video') {
        return [
          ...state,
          {key: 'canonicalLink', rel: 'canonical', href: canonicalUrl},
          {
            key: 'ampLink',
            rel: 'amphtml',
            type: Syndication.post(payload.stubTag, payload.stub.id),
          },
        ];
      }
      return [...state, {key: 'canonicalLink', rel: 'canonical', href: canonicalUrl}];
    }

    case 'LOAD_SECTION_DATA': {
      if (isGameStream(payload.type)) {
        return [
          ...state,
          {key: 'ampLink', rel: 'amphtml', href: Syndication.liveblog(payload.section)},
          {key: 'canonicalLink', rel: 'canonical', href: BR.liveblogs(payload.section)},
        ];
      }
      // The front-page is a special case for canonical urls and AMP links.
      if (homePageTags.us.includes(payload.section) || homePageTags.uk.includes(payload.section)) {
        // We filter this because we want to make sure there is no AMP link.
        return [...state, {key: 'canonicalLink', rel: 'canonical', href: BR.section('')}].filter(
          (meta) => meta.key !== 'ampLink'
        );
      }
      return [
        ...state,
        {key: 'ampLink', rel: 'amphtml', href: Syndication.section(payload.section)},
        {key: 'canonicalLink', rel: 'canonical', href: BR.section(payload.section)},
      ];
    }

    case 'LOAD_TEAM_SCHEDULE': {
      return [
        ...state,
        {key: 'canonicalLink', rel: 'canonical', href: BR.teamSchedule(payload.team)},
      ];
    }
    default:
      return state;
  }
}

function styles(state = initialStates.styles, action) {
  switch (action.type) {
    case 'SET_PAGE':
      // The br shell page uses harcoded assets (still generated after every build on prod)
      // When testing the shell locally make sure to first run `npm run build:prod` to generate the assets
      // Note that assets will only load locally for the br shell page after turning the HapiSass plugin off in plugins.js
      if (action.payload.type === 'brShell') {
        return [
          {
            href: Static.assets('/css/global.shell.css'),
            key: 'globalCSS',
            type: 'text/css',
            rel: 'stylesheet',
          },
          {
            href: Static.assets('/css/atomic.shell.css'),
            key: 'atomicCSS',
            type: 'text/css',
            rel: 'stylesheet',
          },
        ];
      }

      return state;

    case 'ADD_STYLE':
      const {key = '', href = ''} = action.payload;
      return [...state, {href, key, type: 'text/css', rel: 'stylesheet'}];
    default:
      return state;
  }
}

function html(state = initialStates.html) {
  return state;
}

const keyMap = (obj) => obj.key;
const addScriptIfUnique = (state, script) => {
  const scripts = state.map(keyMap);
  if (!scripts.includes(script.key)) {
    // Script is not there, so lets add it.
    return [...state, script];
  }
  // Script already exists, return original state.
  return state;
};

function scripts(state = initialStates.scripts, action) {
  const payload = action.payload;
  switch (action.type) {
    case 'ADD_SCRIPT':
      return addScriptIfUnique(state, payload);
    case 'ADD_SCRIPT_PROMISE': {
      const scriptIndex = state.findIndex((obj) => payload.key === obj.key);
      if (scriptIndex > -1) {
        state[scriptIndex] = {
          ...state[scriptIndex],
          promise: payload.promise,
        };
      }
      return state;
    }
    case 'LOAD_STUB_DATA': {
      if (
        payload.stub.content_type === 'video' &&
        payload.stub.title &&
        payload.stub.published_at
      ) {
        return addScriptIfUnique(state, buildStubVideoObjectSchema(payload));
      }
      return state;
    }
    case 'LOAD_LIVE_VIDEO_METADATA': {
      if (
        payload.eventId &&
        payload.liveVideoMetadata.starts_at &&
        payload.liveVideoMetadata.ends_at &&
        payload.liveVideoMetadata.name
      ) {
        return addScriptIfUnique(state, buildLiveVideoObjectSchema(payload));
      }
      return state;
    }
    case 'LOAD_VIDEO_DATA': {
      if (
        (payload.type === 'video' || payload.type === 'highlight') &&
        payload.title &&
        payload.description &&
        payload.thumbnail_url &&
        payload.analytics.published_at
      ) {
        return addScriptIfUnique(state, buildVideoObjectSchema(payload));
      } else if (
        payload.type === 'highlight' &&
        payload.title &&
        payload.thumbnail_url &&
        payload.analytics.published_at
      ) {
        return addScriptIfUnique(state, buildHighlightVideoObjectSchema(payload));
      }
      return state;
    }
    case 'LOAD_ARTICLE_DATA': {
      const stateWithArticleSchema = addScriptIfUnique(state, buildArticleSchema(payload));
      //Only add breadcrumb schema if all info required for it is defined
      if (payload.title && payload.permalink) {
        return addScriptIfUnique(stateWithArticleSchema, buildArticleBreadcrumbSchema(payload));
      }
      return stateWithArticleSchema;
    }

    case 'LOAD_VIDEO_METADATA': {
      return addVideoToNewsArticleSchema(state, payload.videoMetadata);
    }

    default:
      return state;
  }
}

const metaReducers = combineReducers({
  html,
  title,
  meta_tags,
  scripts,
  styles,
  links,
});

export default metaReducers;
