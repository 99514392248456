'use server';

import React, {useEffect, useState} from 'react';
import {Tabs} from '../../Shared/Tabs/Tabs';
import {Tab} from '../../Shared/Tab/Tab';
import {DefaultDropdown, DropdownType, NavBarLink, TeamsDropdown} from '../types';
import {NavLink} from '../../Shared/NavLink/NavLink';
import {Variant} from '../../Shared/Typography/Typography';
import {Color} from '../../types';
import {DivisionDropdown} from '../DivisionDropdown/DivisionDropdown';
import {LeagueDropdown} from '../LeagueDropdown/LeagueDropdown';
import {NavBarDropdown} from '../NavBarDropdown/NavBarDropdown';
import {Drawer, Position} from '../../Shared/Drawer/Drawer';
import {AutoHeight} from '../../Shared/AutoHeight/AutoHeight';

export interface NavigationTabsProps {
  links: NavBarLink[];
  initialValue?: string;
  value?: string;
  className?: string;
  style?: React.CSSProperties;
  variant?: Variant;
  color?: Color;
  // In order to give parent components full control over this the state, we accept a couple of `set` functions that this component uses to update the parent's state.
  // This allows parents to change the value/initialValue whenever they would like, and this component can request that the parent update it, as well.
  setValue: (key: string) => void;
  setInitial: (key: string) => void;
  onLinkClick: (link: NavBarLink) => void;
}

// This component wraps the Tabs component, but will reset the 'value' to the original state when the user stops hovering over the navigation
export const NavigationTabs: React.FC<NavigationTabsProps> = ({
  links,
  style,
  initialValue,
  value,
  variant = Variant.H4,
  color = Color.Tertiary,
  setValue,
  setInitial,
  onLinkClick,
}) => {
  const [dropdown, setDropdown] = useState<NavBarLink>();
  const showWunderkind =
    dropdown?.title?.toLowerCase() === 'sports' &&
    process.env.NAVIGATION_CAMPAIGN_ENABLED === 'true';
  return (
    <Tabs
      className="navigationTabs"
      style={style}
      value={value || initialValue}
      // eslint-disable-next-line react/jsx-no-bind
      onMouseLeave={() => {
        setValue(initialValue);
        setDropdown(null);
      }}
    >
      {links?.map((link) => (
        <Tab key={link.title} value={link.title}>
          <NavigationTab
            link={link}
            variant={variant}
            color={color}
            /* eslint-disable react/jsx-no-bind */
            onNavMenuEnter={() => {
              setValue(link.title);
              setDropdown(link);
            }}
            onClick={() => {
              setInitial(link.title);
              onLinkClick(link);
            }}
            /* eslint-enable react/jsx-no-bind */
          />
        </Tab>
      ))}

      <div className="navigationTabs__drawer">
        <Drawer position={Position.Top} open={true} hideBackdrop={true}>
          <AutoHeight>
            {dropdown?.children?.type === DropdownType.Default && (
              <NavBarDropdown
                links={(dropdown.children as DefaultDropdown).links}
                onLinkClick={onLinkClick}
                showWunderkind={showWunderkind}
              />
            )}

            {dropdown?.children?.type === DropdownType.Division && (
              <DivisionDropdown divisions={(dropdown.children as TeamsDropdown).sections} />
            )}

            {dropdown?.children?.type === DropdownType.League && (
              <LeagueDropdown leagues={(dropdown.children as TeamsDropdown).sections} />
            )}
          </AutoHeight>
        </Drawer>
      </div>
    </Tabs>
  );
};

interface NavigationTabProps {
  link: NavBarLink;
  variant: Variant;
  color: Color;
  onNavMenuLeave?: () => void;
  onNavMenuEnter?: () => void;
  onClick?: React.MouseEventHandler;
}

const NavigationTab: React.FC<NavigationTabProps> = ({
  link,
  variant,
  color,
  onNavMenuEnter = () => {},
  onNavMenuLeave = () => {},
  onClick = () => {},
}) => {
  const [open, setOpen] = useState(false);

  // when the page unloads, close our dropdown so that it isn't visible in bfcache
  function handlePageHide() {
    setOpen(false);
  }

  useEffect(() => {
    window.addEventListener('pagehide', handlePageHide);
    return () => window.removeEventListener('pagehide', handlePageHide);
  }, []);

  function handleNavMenuEnter() {
    onNavMenuEnter();
  }

  function handleNavMenuLeave() {
    onNavMenuLeave();
  }

  return (
    <div
      className="navigationTabs__link"
      onMouseEnter={handleNavMenuEnter}
      onMouseLeave={handleNavMenuLeave}
      onFocus={handleNavMenuEnter}
      onBlur={handleNavMenuLeave}
      role="menuitem"
    >
      <NavLink
        href={link.href}
        variant={variant}
        color={color}
        external={link.external}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={onClick}
      >
        {link.title}
      </NavLink>
    </div>
  );
};
