const whichMetaDescription = (tag, payload, metaDescription = '') => {
  const {page} = payload;
  const metaDescriptionName = tag.display_name;
  let description = metaDescription;
  const schools = [
    {school: 'Cincinnati Bearcats Football', mascot: 'Bearcats'},
    {school: 'East Carolina Football', mascot: 'Pirates'},
    {school: 'Houston Cougars Football', mascot: 'Cougars'},
    {school: 'Memphis Tigers Football', mascot: 'Tigers'},
    {school: 'Navy Football', mascot: 'Midshipmen'},
    {school: 'SMU Mustangs Football', mascot: 'Mustangs'},
    {school: 'South Florida Bulls Football', mascot: 'Bulls'},
    {school: 'Temple Football', mascot: 'Owls'},
    {school: 'Tulane Football', mascot: 'Green Wave'},
    {school: 'Tulsa Football', mascot: 'Golden Hurricane'},
    {school: 'UCF Knights Football', mascot: 'Knights'},
    {school: 'Boston College Football', mascot: 'Eagles', mcbb_school: 'Boston College Basketball'},
    {school: 'Clemson Football', mascot: 'Tigers', mcbb_school: 'Clemson Basketball'},
    {school: 'Duke Football', mascot: 'Blue Devils', mcbb_school: 'Duke Basketball'},
    {
      school: 'Florida State Football',
      mcbb_school: 'Florida State Basketball',
      mascot: 'Seminoles',
    },
    {
      school: 'Georgia Tech Football',
      mcbb_school: 'Georgia Tech Basketball',
      mascot: 'Yellow Jackets',
    },
    {
      school: 'Louisville Cardinals Football',
      mcbb_school: 'Louisville Cardinals Basketball',
      mascot: 'Cardinals',
    },
    {
      school: 'Miami Hurricanes Football',
      mcbb_school: 'Miami Hurricanes Basketball',
      mascot: 'Hurricanes',
    },
    {school: 'UNC Football', mcbb_school: 'UNC Basketball', mascot: 'Tar Heels'},
    {school: 'NC State Football', mcbb_school: 'NC State Basketball', mascot: 'Wolfpack'},
    {school: 'Pitt Football', mcbb_school: 'Pitt Basketball', mascot: 'Panthers'},
    {school: 'Syracuse Football', mcbb_school: 'Syracuse Basketball', mascot: 'Orange'},
    {school: 'UVa Football', mcbb_school: 'UVA Basketball', mascot: 'Cavaliers'},
    {school: 'Virginia Tech Football', mcbb_school: 'Virginia Tech Basketball', mascot: 'Hokies'},
    {
      school: 'Wake Forest Football',
      mcbb_school: 'Wake Forest Basketball',
      mascot: 'Demon Deacons',
    },
    {school: 'Baylor Football', mcbb_school: 'Baylor Basketball', mascot: 'Bears'},
    {school: 'Iowa State Football', mcbb_school: 'Iowa State Basketball', mascot: 'Cyclones'},
    {
      school: 'Kansas Jayhawks Football',
      mcbb_school: 'Kansas Jayhawks Basketball',
      mascot: 'Jayhawks',
    },
    {school: 'Kansas State Football', mcbb_school: 'Kansas State Basketball', mascot: 'Wildcats'},
    {
      school: 'Oklahoma Sooners Football',
      mcbb_school: 'Oklahoma Sooners Basketball',
      mascot: 'Sooners',
    },
    {
      school: 'Oklahoma State Football',
      mcbb_school: 'Oklahoma State Basketball',
      mascot: 'Cowboys',
    },
    {school: 'TCU Football', mcbb_school: 'TCU Basketball', mascot: 'Horned Frogs'},
    {
      school: 'Texas Longhorns Football',
      mcbb_school: 'Texas Longhorns Basketball',
      mascot: 'Longhorns',
    },
    {school: 'Texas Tech Football', mcbb_school: 'Texas Tech Basketball', mascot: 'Red Raiders'},
    {school: 'WVU Football', mcbb_school: 'WVU Basketball', mascot: 'Mountaineers'},
    {
      school: 'Illinois Fighting Illini Football',
      mcbb_school: 'Illinois Fighting Illini Basketball',
      mascot: 'Fighting Illini',
    },
    {
      school: 'Indiana Hoosiers Football',
      mcbb_school: 'Indiana Hoosiers Basketball',
      mascot: 'Hoosiers',
    },
    {school: 'Iowa Hawkeyes Football', mcbb_school: 'Iowa Hawkeyes Basketball', mascot: 'Hawkeyes'},
    {
      school: 'Maryland Terrapins Football',
      mcbb_school: 'Maryland Terrapins Basketball',
      mascot: 'Terrapins',
    },
    {
      school: 'Michigan Wolverines Football',
      mcbb_school: 'Michigan Wolverines Basketball',
      mascot: 'Wolverines',
    },
    {
      school: 'Michigan State Football',
      mcbb_school: 'Michigan State Basketball',
      mascot: 'Spartans',
    },
    {
      school: 'Minnesota Golden Gophers Football',
      mcbb_school: 'Minnesota Golden Gophers Basketball',
      mascot: 'Golden Gophers',
    },
    {school: 'Northwestern Football', mcbb_school: 'Northwestern Basketball', mascot: 'Wildcats'},
    {school: 'Ohio State Football', mcbb_school: 'Ohio State Basketball', mascot: 'Buckeyes'},
    {school: 'Penn State Football', mcbb_school: 'Penn State Basketball', mascot: 'Nittany Lions'},
    {school: 'Purdue Football', mcbb_school: 'Purdue Basketball', mascot: 'Boilermakers'},
    {school: 'Rutgers Football', mcbb_school: 'Rutgers Basketball', mascot: 'Scarlet Knights'},
    {
      school: 'Wisconsin Badgers Football',
      mcbb_school: 'Wisconsin Badgers Basketball',
      mascot: 'Badgers',
    },
    {
      school: 'Arizona Wildcats Football',
      mcbb_school: 'Arizona Wildcats Basketball',
      mascot: 'Wildcats',
    },
    {
      school: 'Arizona State Football',
      mcbb_school: 'Arizona State Basketball',
      mascot: 'Sun Devils',
    },
    {school: 'Cal Bears Football', mcbb_school: 'Cal Bears Basketball', mascot: 'Golden Bears'},
    {
      school: 'Colorado Buffaloes Football',
      mcbb_school: 'Colorado Buffaloes Basketball',
      mascot: 'Buffaloes',
    },
    {school: 'Oregon Ducks Football', mcbb_school: 'Oregon Ducks Basketball', mascot: 'Ducks'},
    {school: 'Oregon State Football', mcbb_school: 'Oregon State Basketball', mascot: 'Beavers'},
    {school: 'Stanford Football', mcbb_school: 'Stanford Basketball', mascot: 'Cardinals'},
    {school: 'UCLA Football', mcbb_school: 'UCLA Basketball', mascot: 'Bruins'},
    {school: 'USC Football', mcbb_school: 'USC Basketball', mascot: 'Trojans'},
    {school: 'Utah Utes Football', mcbb_school: 'Utah Utes Basketball', mascot: 'Utes'},
    {
      school: 'Washington Huskies Football',
      mcbb_school: 'Washington Huskies Basketball',
      mascot: 'Huskies',
    },
    {
      school: 'Washington State Football',
      mcbb_school: 'Washington State Basketball',
      mascot: 'Cougars',
    },
    {
      school: 'Alabama Crimson Tide Football',
      mcbb_school: 'Alabama Crimson Tide Basketball',
      mascot: 'Crimson Tide',
    },
    {
      school: 'Arkansas Razorbacks Football',
      mcbb_school: 'Arkansas Razorbacks Basketball',
      mascot: 'Razorbacks',
    },
    {school: 'Auburn Football', mcbb_school: 'Auburn Basketball', mascot: 'Tigers'},
    {school: 'Florida Gators Football', mcbb_school: 'Florida Gators Basketball', mascot: 'Gators'},
    {
      school: 'Georgia Bulldogs Football',
      mcbb_school: 'Georgia Bulldogs Basketball',
      mascot: 'Bulldogs',
    },
    {
      school: 'Kentucky Wildcats Football',
      mcbb_school: 'Kentucky Wildcats Basketball',
      mascot: 'Wildcats',
    },
    {school: 'LSU Football', mcbb_school: 'LSU Basketball', mascot: 'Tigers'},
    {school: 'Ole Miss Football', mcbb_school: 'Ole Miss Basketball', mascot: 'Rebels'},
    {
      school: 'Mississippi State Football',
      mcbb_school: 'Mississippi State Basketball',
      mascot: 'Bulldogs',
    },
    {
      school: 'Missouri Tigers Football',
      mcbb_school: 'Missouri Tigers Basketball',
      mascot: 'Tigers',
    },
    {
      school: 'South Carolina Football',
      mcbb_school: 'South Carolina Basketball',
      mascot: 'Gamecocks',
    },
    {
      school: 'Tennessee Volunteers Football',
      mcbb_school: 'Tennessee Volunteers Basketball',
      mascot: 'Volunteers',
    },
    {school: 'Texas A&M Football', mcbb_school: 'Texas A&M Basketball', mascot: 'Aggies'},
    {school: 'Vanderbilt Football', mcbb_school: 'Vanderbilt Basketball', mascot: 'Commodores'},
    {school: 'Army Football', mascot: 'Black Knights'},
    {school: 'BYU Football', mascot: 'Cougars'},
    {school: 'Liberty Football', mascot: 'Flames'},
    {school: 'Notre Dame Football', mascot: 'Fighting Irish', mcbb_school: 'Notre Dame Basketball'},
    {school: 'UConn Football', mcbb_school: 'UConn Basketball', mascot: 'Huskies'},
    {school: 'UMass Football', mcbb_school: 'UMass Basketball', mascot: 'Minutemen'},
    {mcbb_school: 'NC State Basketball', mascot: 'Tar Heels'},
    {mcbb_school: 'Davidson Basketball', mascot: 'Wildcats'},
    {mcbb_school: 'Dayton Basketball', mascot: 'Flyers'},
    {mcbb_school: 'Duquesne Basketball', mascot: 'Dukes'},
    {mcbb_school: 'Fordham Basketball', mascot: 'Rams'},
    {mcbb_school: 'George Mason Basketball', mascot: 'Patriots'},
    {mcbb_school: 'GW Basketball', mascot: 'Colonials'},
    {mcbb_school: 'La Salle Basketball', mascot: 'Explorers'},
    {mcbb_school: 'Rhode Island Rams Basketball', mascot: 'Rams'},
    {mcbb_school: 'Richmond Spiders Basketball', mascot: 'Spiders'},
    {mcbb_school: 'Saint Louis Billikens Basketball', mascot: 'Billikens'},
    {mcbb_school: "St Joseph's Basketball", mascot: 'Hawks'},
    {mcbb_school: 'St. Bonaventure Basketball', mascot: 'Bonnies'},
    {mcbb_school: 'Virginia Commonwealth Basketball', mascot: 'Rams'},
    {mcbb_school: 'Butler Basketball', mascot: 'Bulldogs'},
    {mcbb_school: 'Creighton Basketball', mascot: 'Jays'},
    {mcbb_school: 'DePaul Basketball', mascot: 'Blue Demons'},
    {mcbb_school: 'Georgetown Basketball', mascot: 'Hoyas'},
    {mcbb_school: 'Marquette Basketball', mascot: 'Golden Eagles'},
    {mcbb_school: 'Providence Friars Basketball', mascot: 'Friars'},
    {mcbb_school: 'Seton Hall Basketball', mascot: 'Pirates'},
    {mcbb_school: "St John's Basketball", mascot: 'Red Storm'},
    {mcbb_school: 'Villanova Basketball', mascot: 'Wildcats'},
    {mcbb_school: 'Xavier Basketball', mascot: 'Musketeers'},
  ];
  const teams = [
    {teamName: 'New England Patriots', shortName: 'Pats'},
    {teamName: 'Washington Football Team', shortName: 'Washington'},
    {teamName: 'Tampa Bay Buccaneers', shortName: 'Bucs'},
    {teamName: 'San Francisco 49ers', shortName: 'Niners'},
    {teamName: 'Boston Celtics', shortName: 'Celts'},
    {teamName: 'Philadelphia 76ers', shortName: 'Sixers'},
    {teamName: 'Cleveland Cavaliers', shortName: 'Cavs'},
    {teamName: 'Minnesota Timberwolves', shortName: 'Wolves'},
    {teamName: 'Portland Trail Blazers', shortName: 'Blazers'},
    {teamName: 'Dallas Mavericks', shortName: 'Mavs'},
    {teamName: 'New York Yankees', shortName: 'Yanks'},
    {teamName: 'Oakland Athletics', shortName: "A's"},
    {teamName: 'Arizona Diamondbacks', shortName: 'D-backs'},
    {teamName: 'All Elite Wrestling', shortName: 'AEW'},
    {teamName: 'Brighton__Hove_Albion', shortName: 'Brighton'},
    {teamName: 'Leeds United', shortName: 'Leeds'},
    {teamName: 'Leicester_City', shortName: 'Leicester'},
    {teamName: 'Tottenham Hotspur', shortName: 'Spurs'},
    {teamName: 'Boston Bruins', shortName: "B's"},
    {teamName: 'FC Barcelona', shortName: 'Barca'},
    {teamName: 'Borussia Dortmund', shortName: 'Dortmund'},
    {teamName: 'NK Dinamo Zagreb', shortName: 'Dinamo Zagreb'},
    {teamName: 'FC Salzburg', shortName: 'Salzburg'},
    {teamName: 'Galatasaray', shortName: 'Gala'},
    {teamName: 'Juventus', shortName: 'Juve'},
    {teamName: 'Olympique Lyonnais', shortName: 'OL'},
    {teamName: 'RB Leipzig', shortName: 'Leipzig'},
    {teamName: 'Real Madrid', shortName: 'Real'},
    {teamName: 'Shakhtar Donetsk', shortName: 'Shakhtar'},
    {teamName: 'FC Union Berlin', shortName: 'Union'},
    {teamName: 'Hertha BSC', shortName: 'Hertha'},
    {teamName: 'FSV Mainz 05', shortName: 'Mainz'},
    {teamName: 'New England Revolution', shortName: 'Revs'},
    {teamName: 'Montreal Impact', shortName: 'Montreal'},
    {teamName: 'Aston Villa', shortName: tag.short_name},
    {teamName: 'Crystal Palace', shortName: tag.short_name},
    {teamName: 'Newcastle United', shortName: tag.short_name},
    {teamName: 'Norwich City', shortName: tag.short_name},
    {teamName: 'West Ham United', shortName: tag.short_name},
    {teamName: 'Wolverhampton Wanderers', shortName: tag.short_name},
    {teamName: 'Detroit Red Wings', shortName: tag.short_name},
    {teamName: 'Toronto Maple Leafs', shortName: tag.short_name},
    {teamName: 'Colorado Avalanche', shortName: tag.short_name},
    {teamName: 'Nashville Predators', shortName: tag.short_name},
    {teamName: 'Carolina Hurricanes', shortName: tag.short_name},
    {teamName: 'Pittsburgh Penguins', shortName: tag.short_name},
    {teamName: 'Washington Capitals', shortName: tag.short_name},
    {teamName: 'Formula 1', shortName: tag.short_name},
    {teamName: 'Atletico Madrid', shortName: tag.short_name},
    {teamName: 'Bayer Leverkusen', shortName: tag.short_name},
    {teamName: 'FC Bayern Munich', shortName: tag.short_name},
    {teamName: 'S.L. Benfica', shortName: tag.short_name},
    {teamName: 'Club Brugge', shortName: tag.short_name},
    {teamName: 'KRC Genk', shortName: tag.short_name},
    {teamName: 'Inter Milan', shortName: tag.short_name},
    {teamName: 'Lille OSC', shortName: tag.short_name},
    {teamName: 'Lokomotiv Moscow', shortName: tag.short_name},
    {teamName: 'Manchester City', shortName: tag.short_name},
    {teamName: 'Manchester United', shortName: tag.short_name},
    {teamName: 'Paris Saint-Germain FC', shortName: tag.short_name},
    {teamName: 'Valencia CF', shortName: tag.short_name},
    {teamName: 'Zenit St Petersburg', shortName: tag.short_name},
    {teamName: 'Sporting Portugal', shortName: tag.short_name},
    {teamName: 'CD Alaves', shortName: tag.short_name},
    {teamName: 'Cadiz CF', shortName: tag.short_name},
    {teamName: 'CA Osasuna', shortName: tag.short_name},
    {teamName: 'Elche CF', shortName: tag.short_name},
    {teamName: 'Getafe CF', shortName: tag.short_name},
    {teamName: 'SD Huesca', shortName: tag.short_name},
    {teamName: 'Real Betis', shortName: tag.short_name},
    {teamName: 'Real Sociedad', shortName: tag.short_name},
    {teamName: 'Real Valladolid', shortName: tag.short_name},
    {teamName: 'Villarreal CF', shortName: tag.short_name},
    {teamName: 'RCD Espanyol', shortName: tag.short_name},
    {teamName: 'RCD Mallorca', shortName: tag.short_name},
    {teamName: 'Benevento Calcio', shortName: tag.short_name},
    {teamName: 'Chievo Verona', shortName: tag.short_name},
    {teamName: 'FC Crotone', shortName: tag.short_name},
    {teamName: 'Frosinone Calcio', shortName: tag.short_name},
    {teamName: 'AS Roma', shortName: tag.short_name},
    {teamName: 'Spezia Calcio', shortName: tag.short_name},
    {teamName: 'SSC Venezia', shortName: tag.short_name},
    {teamName: 'Sportiva Salernitana', shortName: tag.short_name},
    {teamName: 'Arminia Bielefeld', shortName: tag.short_name},
    {teamName: 'Borussia Monchengladbach', shortName: tag.short_name},
    {teamName: 'FC Augsburg', shortName: tag.short_name},
    {teamName: 'FC Cologne', shortName: tag.short_name},
    {teamName: 'SC Freiburg', shortName: tag.short_name},
    {teamName: 'Schalke 04', shortName: tag.short_name},
    {teamName: 'TSG Hoffenheim', shortName: tag.short_name},
    {teamName: 'VfB Stuttgart', shortName: tag.short_name},
    {teamName: 'VfL Wolfsburg', shortName: tag.short_name},
    {teamName: 'SpVgg Greuther Furth', shortName: tag.short_name},
    {teamName: 'Atlanta United FC', shortName: tag.short_name},
    {teamName: 'Chicago Fire', shortName: tag.short_name},
    {teamName: 'Colorado Rapids', shortName: tag.short_name},
    {teamName: 'Columbus Crew', shortName: tag.short_name},
    {teamName: 'Houston Dynamo', shortName: tag.short_name},
    {teamName: 'Los Angeles FC', shortName: tag.short_name},
    {teamName: 'Los Angeles Galaxy', shortName: tag.short_name},
    {teamName: 'New York Red Bulls', shortName: tag.short_name},
    {teamName: 'Philadelphia Union', shortName: tag.short_name},
    {teamName: 'Portland Timbers', shortName: tag.short_name},
    {teamName: 'Real Salt Lake', shortName: tag.short_name},
    {teamName: 'San Jose Earthquakes', shortName: tag.short_name},
    {teamName: 'Seattle Sounders FC', shortName: tag.short_name},
    {teamName: 'Sporting Kansas City', shortName: tag.short_name},
    {teamName: 'Vancouver Whitecaps', shortName: tag.short_name},
    {teamName: 'Rayo Vallecano', shortName: tag.short_name},
  ];
  const teams2 = [
    {teamName: 'Leicester_City', shortName: 'Leicester'},
    {teamName: 'Brighton__Hove_Albion', shortName: 'Brighton'},
    {teamName: 'Watford', shortName: 'Watford'},
    {teamName: '_FK_Red_Star_Belgrade', shortName: 'Red Star'},
    {teamName: 'Celta_de_Vigo', shortName: 'Celta'},
    {teamName: 'Austria_National_Football', shortName: 'Austria National Football Team'},
    {teamName: 'Argentina_National_Football', shortName: 'Argentina National Football Team'},
    {teamName: 'Brazil_National_Football', shortName: 'Brazil National Football Team'},
    {teamName: 'Egypt_National_Football', shortName: 'Egypt National Football Team'},
    {teamName: 'England_National_Football', shortName: 'England National Football Team'},
    {teamName: 'France_National_Football', shortName: 'France National Football Team'},
    {teamName: 'Germany_National_Football', shortName: 'Germany National Football Team'},
    {teamName: 'Ireland_National_Football', shortName: 'Ireland National Football Team'},
    {teamName: 'Italy_National_Football', shortName: 'Italy National Football Team'},
    {teamName: 'Netherlands_National_Football', shortName: 'Netherlands National Football Team'},
    {
      teamName: 'Northern_Ireland_National_Football',
      shortName: 'Northern Ireland National Football Team',
    },
    {teamName: 'Poland_National_Football', shortName: 'Poland National Football Team'},
    {teamName: 'Russia_National_Football', shortName: 'Russia National Football Team'},
    {teamName: 'Scotland_National_Football', shortName: 'Scotland National Football Team'},
    {teamName: 'Spain_National_Football', shortName: 'Spain National Football Team'},
    {teamName: 'Sweden_National_Football', shortName: 'Sweden National Football Team'},
    {teamName: 'Switzerland_National_Football', shortName: 'Switzerland National Football Team'},
    {teamName: 'Tunisia_National_Football', shortName: 'Tunisia National Football Team'},
    {teamName: 'Ukraine_National_Football', shortName: 'Ukraine National Football Team'},
  ];
  const divisions = [
    {division: 'AAC_Football', shortName: 'AAC Football'},
    {division: 'ACC_Football', shortName: 'ACC Football'},
    {division: 'Big_12_Football', shortName: 'Big 12 Football'},
    {division: 'Big_Ten_Football', shortName: 'Big Ten Football'},
    {division: 'Pac_12_Football', shortName: 'Pac-12 Football'},
    {division: 'SEC_Football', shortName: 'SEC Football'},
    {division: 'ACC_Basketball', shortName: 'ACC Basketball'},
    {division: 'Big_12_Basketball', shortName: 'Big 12 Basketball'},
    {division: 'Big_Ten_Basketball', shortName: 'Big Ten Basketball'},
    {division: 'Pac-12_Basketball', shortName: 'Pac-12 Basketball'},
    {division: 'SEC_Basketball', shortName: 'SEC Basketball'},
    {division: 'Atlantic_Ten_Basketball', shortName: 'A-10 Basketball'},
  ];
  const school = schools.find(({school}) => school === tag.display_name);
  const mcbbSchool = schools.find(({mcbb_school}) => mcbb_school === tag.display_name);
  const team = teams.find(({teamName}) => teamName === tag.display_name);
  const team2 = teams2.find(({teamName}) => teamName === page?.team);
  const division = divisions.find(({division}) => division === page?.division);

  if (page?.team === 'Nebraska_Huskers_Football') {
    description = `Keep up with the Cornhuskers on Bleacher Report. Get the latest Nebraska Football storylines, highlights, expert analysis, scores and more.`;
    return description;
  } else if (page?.team === 'Nebraska_Huskers_Basketball') {
    description = `Keep up with the Cornhuskers on Bleacher Report. Get the latest Nebraska Basketball storylines, highlights, expert analysis, scores and more.`;
    return description;
  } else if (page?.league === 'College_Football' && page?.team !== 'none') {
    description = `Keep up with the ${school?.mascot} on Bleacher Report. Get the latest ${school?.school} storylines, highlights, expert analysis, scores and more.`;
    return description;
  } else if (page?.league === 'College_Basketball' && page?.team !== 'none') {
    description = `Keep up with the ${mcbbSchool?.mascot} on Bleacher Report. Get the latest ${mcbbSchool?.mcbb_school} storylines, highlights, expert analysis, scores and more.`;
    return description;
  } else if (teams.find(({teamName}) => teamName === tag.display_name)) {
    description = `Be the best ${team?.shortName} fan you can be with Bleacher Report. Keep up with the latest storylines, expert analysis, highlights, scores and more.`;
    return description;
  } else if (teams2.find(({teamName}) => teamName === page?.team)) {
    description = `Be the best ${team2?.shortName} fan you can be with Bleacher Report. Keep up with the latest storylines, expert analysis, highlights, scores and more.`;
    return description;
  } else if (divisions.find(({division}) => division === page?.division)) {
    description = `Be the best ${division?.shortName} fan you can be with Bleacher Report. Keep up with the latest storylines, expert analysis, highlights, scores and more.`;
    return description;
  } else if (tag.display_name === 'Premier League') {
    description = `Follow the ${tag.display_name} with Bleacher Report. Keep up with the latest storylines, expert analysis, highlights, scores and more.`;
    return description;
  } else if (tag.display_name === 'UEFA Champions League') {
    description = `Follow the ${tag.display_name} with Bleacher Report. Keep up with the latest storylines, expert analysis, highlights, scores and more.`;
    return description;
  } else if (page?.event === 'Transfer_News') {
    description = `Stay up to date on the world football transfer window with Bleacher Report. Keep up with the latest storylines, expert analysis and predictions.`;
    return description;
  } else if (tag.display_name === 'Sports Odds') {
    description = `B/R Betting is your destination for sports betting news and culture. Check out the biggest wins, the closest calls and more from Bleacher Report.`;
    return description;
  } else if (tag.display_name === "United States (Women's Football)") {
    description = `Stay up to date on the USWNT with Bleacher Report. Keep up with the biggest storylines, expert analysis, highlights, scores and more.`;
    return description;
  } else if (tag.display_name === "Women's College Basketball") {
    description = `Be the best Women's College Basketball fan you can be with Bleacher Report. Keep up with the latest storylines, expert analysis, highlights, scores and more.`;
    JSON.stringify(description).replace(/"/g, '&quot;');
    return description;
  } else if (page?.team === 'United_States_National_Football') {
    description = `Stay up to date on the USMNT with Bleacher Report. Keep up with the biggest storylines, expert analysis, highlights, scores and more.`;
    return description;
  } else if (tag.display_name === 'NBA2K') {
    description = `Stay up to date on all things NBA 2K with Bleacher Report. Keep up with the biggest storylines, game updates, highlights and more.`;
    return description;
  } else if (tag.display_name === 'Little League World Series') {
    description = `Get the latest ${tag.short_name} news with Bleacher Report. Keep up with the latest storylines, expert analysis, highlights, scores and more.`;
    return description;
  } else if (tag.short_name === 'The Lefkoe Show') {
    description = `Keep up with the latest Adam Lefkoe news, tweets and more with Bleacher Report.`;
    return description;
  } else if (tag.display_name === 'The Champions') {
    description = `Check out the best highlights and new episodes from Bleacher Report’s original animated series, ${tag.short_name}.`;
    return description;
  } else if (tag.display_name === 'Gridiron Heights') {
    description = `Check out the best highlights and new episodes from Bleacher Report’s original animated series, ${tag.display_name}.`;
    return description;
  } else if (tag.display_name === 'Taylor Rooks') {
    description = `Check out the best interviews, highlights and new episodes from ${tag.display_name} and her show, Take It There.`;
    return description;
  } else if (tag.display_name === 'Untold Stories') {
    description = `Check out the best highlights and new episodes from Bleacher Report's original series, ${tag.display_name}.`;
    JSON.stringify(description).replace(/"/g, '&quot;');
    return description;
  } else if (tag.display_name === 'The Arena') {
    description = `Check out best highlights from ${tag.display_name}, a show that offers a thought-provoking forum for content on topics occurring on and off the court of play.`;
    return description;
  } else if (tag.display_name === 'Kicks') {
    description = `B/R Kicks is your destination for sneaker style and culture. Get the latest news, release dates and player exclusives from Bleacher Report.`;
    return description;
  } else if (tag.display_name === 'Gaming') {
    description = `It's game time. Keep up with all of the latest gaming news, drops, special events and more with B/R Gaming.`;
    JSON.stringify(description).replace(/"/g, '&quot;');
    return description;
  } else if (tag.short_name === 'The Match') {
    description = `Capital One’s The Match is a premier golf event featuring big-time athletes and celebrities. Check out the latest news and updates on Bleacher Report.`;
    return description;
  } else if (page?.division === 'Independents_Football') {
    description = `Stay up to date on college football's indepedents with Bleacher Report. Keep up with the latest standings, storylines, game updates, highlights and more.`;
    JSON.stringify(description).replace(/"/g, '&quot;');
    return description;
  } else if (tag.display_name === 'Bracketology') {
    description = `Stay up to date on all things ${tag.display_name} with Bleacher Report. Keep up with the biggest storylines, expert analysis, predictions and more.`;
    return description;
  } else if (tag.display_name === 'College Basketball Recruiting') {
    description = `Stay up to date on ${tag.display_name} with Bleacher Report. Keep up with the biggest storylines, expert analysis, highlights, predictions and more.`;
    return description;
  } else if (tag.display_name === 'Fantasy Football') {
    description = `Stay up to date on all things ${tag.display_name} with Bleacher Report. Keep up with the biggest storylines, expert analysis, highlights, predictions and more.`;
    return description;
  } else if (tag.display_name === 'NFL Draft') {
    description = `Stay up to date on the ${tag.display_name} with Bleacher Report. Keep up with the biggest storylines, expert analysis, highlights, predictions and more.`;
    return description;
  } else if (tag.display_name === 'Video Landing Page') {
    description = `We have you covered with the latest highlights, live events and exclusive video content on Bleacher Report.`;
    return description;
  } else if (tag.display_name === 'All Elite Wrestling PPV') {
    description = `Watch the latest AEW pay-per-views on Bleacher Report. Get live and on-demand access to the best that AEW has to offer.`;
    return description;
  }
  description = `Be the best ${metaDescriptionName} fan you can be with Bleacher Report. Keep up with the latest storylines, expert analysis, highlights, scores and more.`;
  return description;
};

export {whichMetaDescription};
