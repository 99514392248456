import global from 'core-js/stable/global-this';

// now our application specific stuff
import createStore from './store';
import {handleScripts, primeScriptCache} from './clientScripts';

// This is a fix for Safari and browsers that don't support this API.
if (!global.Error.captureStackTrace) {
  global.Error.captureStackTrace = (err) => {
    return err.stack;
  };
}
// global deprecated as obselete/removed from core-js since its replaced by globalThis for web.
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md
window.global = global;

// This is required for ad setup when the GPT script in unavailable
global.googletag = global.googletag || {};
global.googletag.cmd = global.googletag.cmd || [];

// Set a class to show that this is a JS enabled browser.
// Note - htmlElement.classList isn't supported in IE9 so we have to do it like this
const htmlElement = global.document.getElementsByTagName('html')[0];
htmlElement.className = htmlElement.className.replace(/\bno-js\b/, 'has-js');

// The store will pick up the initial app state from the window.
// It's injected by the server into a script tag as INITAL_STORE_STATE.
export const store = createStore();

// Attach the script listener to the store.
store.subscribe(handleScripts(store));

const state = store.getState();
// Now we prime the cache so that the server-side rendered script tags are
// correctly marked as loaded and we don't try to load them again.
primeScriptCache(state.template.scripts);
