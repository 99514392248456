import React from 'react';
import {AuthAccountSideMenu} from './AuthAccountSideMenu';
import {UnauthAccountSideMenu} from './UnauthAccountSideMenu';
import {UserType} from '../../types';

export interface AccountSideMenuProps {
  userType?: UserType;
  profileId?: Number;
}

export const AccountSideMenu: React.FC<AccountSideMenuProps> = ({userType, profileId}) => {
  return userType === UserType.Anonymous ? (
    <UnauthAccountSideMenu />
  ) : (
    <AuthAccountSideMenu profileId={profileId} />
  );
};
