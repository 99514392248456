import {RECAP, PREVIEW, LIVE} from '../../constants/gameStatus';

export const getGameStatus = (status = '') => {
  switch (status.toLowerCase()) {
    case 'closed':
    case 'complete':
      return RECAP;
    case 'inprogress':
      return LIVE;
    case 'delayed':
    case 'scheduled':
    case 'suspended':
    case 'tobedetermined':
    case 'ifneccessary':
    case 'unknown':
    default:
      return PREVIEW;
  }
};

/**
 * A gamecast module
 * @typedef {Object} GamecastModule
 * @property {id} string
 * @property {string} key
 * @property {moduleType} string
 */
const bettingLink = {key: 'betting_link', moduleType: 'BettingLink'};
const bettingDisclaimer = {key: 'betting_disclaimer', moduleType: 'BettingDisclaimer'};
const comparison = {key: 'comparison', moduleType: 'Comparison'};
const injuries = {key: 'injuries', moduleType: 'Injuries'};
const leagueRankings = {key: 'league_rankings', moduleType: 'Comparison'};
const lineScore = {key: 'linescore', moduleType: 'LineScore'};
const lineups = {key: 'lineup', moduleType: 'Lineups'};
const matchInfo = {key: 'match_info', moduleType: 'MatchInfo'};
const matchTimeline = {key: 'match_timeline', moduleType: 'MatchTimeline'};
const odds = {key: 'odds', moduleType: 'Odds'};
const pitchers = {key: 'wls_pitchers', moduleType: 'Pitchers'};
const plays = {key: 'pbp', moduleType: 'Plays'};
const standings = {key: 'standings', moduleType: 'Standings'};
const statLeaders = {key: 'stat_leaders', moduleType: 'StatLeaders'};
const stats = {key: 'player_stats', moduleType: 'Stats'};
const teamPerformance = {key: 'team_performance', moduleType: 'TeamPerformance'};

// these are dynamic modules not returned on the gamecast response from statmilk
const adSlot1 = {id: 'ra1', key: 'ad', slot: 'rect_atf_01'};
const adSlot2 = {id: 'ra2', key: 'ad', slot: 'rect_atf_02'};
const spredfast = {key: 'ti'};
const teamStream = {key: 'ts'};

/**
 * @description Gamecast modules are essetntially UI components.
 * Statmilk returns a flat JSON object with keys .ie linescore that directly relate
 * to a component that exist within src/components/organisms/scores/... directory.
 * The gamecastModules object defines a key/value pair that can be used to do a lookup
 * when said response is returned and or if components need to be dynamically used.
 */
export const gamecastModules = {
  adSlot1,
  adSlot2,
  bettingDisclaimer,
  bettingLink,
  comparison,
  injuries,
  leagueRankings,
  lineScore,
  lineups,
  matchInfo,
  matchTimeline,
  odds,
  pitchers,
  plays,
  spredfast,
  standings,
  statLeaders,
  stats,
  teamPerformance,
  teamStream,
};
