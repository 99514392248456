const initialState = {
  site: {
    query: '',
    results: [],
  },
  tags: {
    // MyBr teams search
    query: '',
    results: [],
  },
};

function search(state = initialState, action) {
  switch (action.type) {
    case 'STORE_SEARCH_QUERY': {
      const searchType = (action.payload && action.payload.type) || '';

      return {
        ...state,
        [searchType]: {
          ...state[searchType],
          query: action.payload.query || '',
        },
      };
    }

    case 'STORE_SEARCH_RESULTS': {
      const searchType = (action.payload && action.payload.type) || '';

      if (action.payload.query === state[searchType].query) {
        return {
          ...state,
          [searchType]: {
            ...state[searchType],
            results: action.payload.results || [],
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
}

export default search;
