import {BranchDotIo, BR} from '../endpoints';

import {fetchUserPost} from '../actions/userPostActions';

function extractAttachment({data, type} = {}) {
  let src = '';
  if (data) {
    if (data.url) {
      src = data.url;
    } else if (data.original && data.original.url) {
      src = data.original.url;
    }
  }
  return {
    src,
    type,
  };
}

function user_post(state = {}, action) {
  if (action.error) {
    throw action.error;
  }
  const payload = action.payload;
  switch (action.type) {
    case fetchUserPost.toString(): {
      const {aggregateData, author, content = {}, id, inserted_at} = payload;
      const {attachments} = content;
      const media = [];
      if (attachments && Array.isArray(attachments)) {
        media.push(...content.attachments.map(extractAttachment));
      }
      return {
        ...state,
        appDotLinkUrl: BranchDotIo.userPostDeeplink(id),
        authorInfo: {
          ...author,
          photo_url: author.photo_path,
        },
        content,
        inserted_at,
        media,
        numComments: aggregateData.comments,
        numLikes: aggregateData.likes,
        shareUrl: BR.userPost(id),
      };
    }

    default:
      return state;
  }
}

export default user_post;
