import React from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';

import * as AnalyticsActions from '../../actions/analyticsActions';
import Badge from '../atoms/badge';
import Overlay from '../atoms/overlay';
import EventOverlay from '../atoms/eventOverlay';
import {determineTarget, determineRel} from '../helpers/homePageHelpers';
import LazyImage from '../atoms/lazyImage';
import {getScreenType} from '../../constants/mParticle';

const Thumbnail = ({
  badge,
  children,
  classes,
  eventStartedOverlay,
  isVideo,
  moduleType,
  onClick,
  overlay,
  readyToPlay,
  trackEvent,
  thumbnail,
  thumbnail_url,
  title,
  ui,
  url,
  cloudinaryWidth,
}) => {
  const target = determineTarget(url);
  const rel = determineRel(target);
  const additionalImgProps = {moduleType};

  const imgSrc = thumbnail_url || thumbnail;
  if (!imgSrc) {
    return null;
  }

  const handleClick = (event) => {
    if (onClick) {
      event.preventDefault();
      onClick();
    }
    trackEvent({
      tag_manager_event: 'content_selected',
      urlHash: url,
      screen: getScreenType(ui?.pageType, ui?.pageSubType),
      contentType: 'thumbnail',
      springType: 'hyperlink',
      title,
    });
  };

  let content = null;
  if (eventStartedOverlay) {
    content = <EventOverlay />;
  } else if (overlay && overlay.text) {
    content = <Overlay {...overlay} />;
  } else if (badge && badge.text) {
    content = <Badge {...badge} />;
  }

  return (
    <a
      href={url}
      rel={rel}
      target={target}
      className={classnames('molecule thumbnail', classes, readyToPlay)}
      onClick={handleClick} //eslint-disable-line react/jsx-no-bind
    >
      {content}
      <LazyImage
        {...additionalImgProps}
        alt={title}
        src={imgSrc}
        maintainAspectRatio={isVideo}
        cloudinaryWidth={cloudinaryWidth}
      />
      {children}
    </a>
  );
};

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Thumbnail);
