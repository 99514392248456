import {valid, gte} from 'semver';
import {createSelector} from 'reselect';
import * as CommentsUpsellVariations from '../constants/commentsUpsellVariations';

export const ads = (state) => state.ads || {};
export const ui = (state) => state.ui || {};
export const page = (state) => state.page || {};
export const promo = (state) => state.promo || {};
export const profiles = (state) => state.profiles || {};
export const notification = (state) => state.notification;
export const sections = (state) => state.sections;
export const playlists = (state) => state.playlists;
export const tracks = (state) => state.tracks;
export const tags = (state) => state.tags;
export const nav = (state) => state.nav || {};
export const search = (state) => state.search || {};
export const scores = (state) => state.scores || {};
export const template = (state) => state.template;
export const user = (state) => state.user || {};
export const articles = (state) => state.articles || {};
export const stub = (state) => state.stub || {};
export const modules = (state) => state.layout.modules || [];
export const userPost = (state) => state.user_post || {author: {}};
export const sitemap = (state) => state.sitemap || {};
export const video = (state) => state.video || {};

function assignAsTagIdValue(newObject, tagObject) {
  newObject[tagObject.tag_id] = tagObject;
  return newObject;
}

export const tagsDictionary = createSelector([tags], (tags) =>
  Object.values(tags).reduce(assignAsTagIdValue, {})
);

export const showCommentsUpsell = createSelector([articles, ui], ({hide_skin}, ui) => {
  if (hide_skin) return false;

  const {
    appVersion = '',
    commentsUpsellVariation,
    showCommentsUpsell,
    showCommentsUpsellInApp,
  } = ui;

  if (commentsUpsellVariation) {
    const isEmbeddedBrowser = commentsUpsellVariation === CommentsUpsellVariations.EMBEDDED;
    const isValidAppVersion = valid(appVersion) && gte(appVersion, '8.11.0');
    return isEmbeddedBrowser ? isValidAppVersion && showCommentsUpsellInApp : showCommentsUpsell;
  }
  return false;
});
