import React from 'react';
import ReactDOM from 'react-dom';
import {SVG} from '@br/br-components';
import {getTveProviderMessage} from '../../../../helpers/tveHelpers';

const helpContent = (
  <span>
    Having Trouble? <a href="/pages/help">Get Help</a>
  </span>
);

const noProviderBodyContent = (
  <p>
    This service is only available through participating TV providers.
    <br className="show-desktop" /> Your TV provider is not available at this time.
  </p>
);

function removeElements(selector, parent = document) {
  if (!parent) return;
  parent.querySelectorAll(selector).forEach((el) => el.remove());
}
const rememberedProvidersBodyContent = (
  <p>Sign in to your TV service provider to continue watching</p>
);

function renderWithWrapper(root, content, className, prepend = false) {
  const wrapper = document.createElement('div');
  wrapper.className = className;

  ReactDOM.render(content, wrapper);

  if (prepend) {
    root.prepend(wrapper);
  } else {
    root.appendChild(wrapper);
  }
}

function setupPickByLogoSlate(root) {
  const pickByLogoSlate = root.querySelector('.taui-pickbylogo');
  const pickByLogoFooter = pickByLogoSlate.querySelector('.taui-footer');

  if (!pickByLogoFooter.querySelector('.taui-gethelp')) {
    renderWithWrapper(pickByLogoFooter, helpContent, 'taui-gethelp');
  }
}
function setupRememberedProviderSlate(root) {
  const rememberedProvidersSlate = root.querySelector('.taui-remembered');
  const rememberedProvidersBody = rememberedProvidersSlate.querySelector('.taui-rememberedmessage');

  const rememberedBodyWrapper = document.createElement('div');
  rememberedBodyWrapper.className = 'taui-remembered-wrapper';
  if (rememberedProvidersSlate && rememberedProvidersBody) {
    ReactDOM.render(rememberedProvidersBodyContent, rememberedBodyWrapper);

    process.nextTick(() => {
      rememberedProvidersBody.prepend(rememberedBodyWrapper);
    });
  }
}

function renderNoProviderFooter(noProviderSlate) {
  const noProviderContent = (
    <>
      <button className="taui-noproviderokbutton">
        <span>OK</span>
      </button>
      <div className="taui-gethelp">{helpContent}</div>
    </>
  );
  renderWithWrapper(noProviderSlate, noProviderContent, 'taui-footer');
}

function setupNoProviderSlate(root) {
  const noProviderSlate = root.querySelector('.taui-noprovider');
  const noProviderBody = noProviderSlate.querySelector('.taui-noprovidermessage');

  if (noProviderSlate && noProviderBody) {
    ReactDOM.render(noProviderBodyContent, noProviderBody);
  }

  const noFooterRendered = noProviderSlate && !noProviderSlate.querySelector('.taui-footer');
  if (noFooterRendered) {
    removeElements('.taui-noproviderokbutton', noProviderSlate);
    renderNoProviderFooter(noProviderSlate);
  }
}

function setupSearchSlate(root) {
  const dontSeeButton = root.querySelector('.taui-dontseebutton span');
  dontSeeButton.innerText = `I Don't See My Provider`;

  // Fix search placeholder text
  const search = root.querySelector('.taui-mvpdsearch');
  if (search) {
    const placeholder = 'Search TV Provider...';
    search.value = search.placeholder = placeholder;
    process.nextTick(() => {
      search.value = placeholder;
    });
    search.addEventListener('focus', (event) => {
      if (event.target.value === placeholder) search.value = '';
    });
    search.addEventListener('blur', (event) => {
      if (event.target.value === '') search.value = placeholder;
    });
  }

  const searchWrapper = root.querySelector('.taui-searchfield');
  if (searchWrapper) {
    const iconContainer = document.createElement('div');
    iconContainer.classList.add('searchicon-wrapper');
    ReactDOM.render(<SVG type="icon" target="search" />, iconContainer);
    searchWrapper.prepend(iconContainer);
  }
}

function setupHeaderClose(root) {
  const tauiHeader = root.querySelector('.taui-header');
  removeElements('.taui-close', root);
  renderWithWrapper(tauiHeader, <SVG type="icon" target="close" />, 'taui-close');
}

function renderSignInLoadingFooter(signinLoadingSlate) {
  const footer = (
    <>
      <button className="taui-signincancelbutton">
        <span>Cancel</span>
      </button>
      <div className="taui-gethelp">{helpContent}</div>
    </>
  );
  renderWithWrapper(signinLoadingSlate, footer, 'taui-footer');
}

function setupSignInLoadingSlate(root) {
  const signinLoadingSlate = root.querySelector('.taui-signin');

  removeElements(
    '.taui-signinmessage,.taui-signinloadingbody,.taui-spinnerbox',
    signinLoadingSlate
  );

  const noFooterRendered = signinLoadingSlate && !signinLoadingSlate.querySelector('.taui-footer');
  if (noFooterRendered) {
    removeElements('.taui-signincancelbutton', signinLoadingSlate);
    renderSignInLoadingFooter(signinLoadingSlate);
  }
}

function renderLoginSuccessFooter(loginSuccessSlate) {
  const successContent = (
    <>
      <button className="taui-watchnowbutton">
        <span>Done</span>
      </button>
      <div className="taui-gethelp">{helpContent}</div>
    </>
  );
  renderWithWrapper(loginSuccessSlate, successContent, 'taui-footer');
}

function setupLoginSuccessSlate(root) {
  const loginSuccessSlate = root.querySelector('.taui-success');
  const noFooterRendered = loginSuccessSlate && !loginSuccessSlate.querySelector('.taui-footer');
  if (noFooterRendered) {
    renderLoginSuccessFooter(loginSuccessSlate);
  }
}

export function renderLoginSuccessLogo(imageURL) {
  const root = document.getElementById('taui-mvpdpicker');
  const loginSuccessSlate = root.querySelector('.taui-success');

  removeElements(
    '.taui-loginsuccessbody,.taui-successmessage,:scope > .taui-watchnowbutton',
    loginSuccessSlate
  );

  const successBody = (
    <>
      <div className="login-success-header mobile">Congrats! You can now watch live games.</div>
      <div className="login-success-subheader mobile">TV Provider:</div>
      <div className="login-success-header desktop">Congratulations!</div>
      <div className="login-success-subheader desktop">You can now watch events with</div>
      <div>
        <img className="taui-logo" src={imageURL} />
      </div>
    </>
  );
  renderWithWrapper(loginSuccessSlate, successBody, 'taui-loginsuccessbody', true);
}

export function renderSignInLoadingSlateLogo(imageURL) {
  const root = document.getElementById('taui-mvpdpicker');
  const signinLoadingSlate = root.querySelector('.taui-signin');

  removeElements('.taui-signinloadingbody', signinLoadingSlate);

  const loadingBody = (
    <>
      <div className="taui-spinner" />
      <div className="signinloadingbody-text">Connecting to...</div>
      <div className="taui-logo-container">
        <img className="taui-logo" src={imageURL} />
      </div>
    </>
  );
  renderWithWrapper(signinLoadingSlate, loadingBody, 'taui-signinloadingbody', true);
}

export function tveUICustomizations() {
  const id = 'taui-mvpdpicker';
  const root = document.getElementById(id);
  const title = root.querySelector('.taui-title span');
  title.innerText = 'Select TV Provider';

  setupHeaderClose(root);
  setupPickByLogoSlate(root);
  setupNoProviderSlate(root);
  setupSearchSlate(root);
  setupSignInLoadingSlate(root);
  setupLoginSuccessSlate(root);
  setupRememberedProviderSlate(root);
}

export function setupTVEPickerTitle(result) {
  const id = 'taui-mvpdpicker';
  const root = document.getElementById(id);
  const title = root.querySelector('.taui-title span');

  if (result.pickerView === 'providerRemembered' || result.pickerView === 'providerLogin') {
    title.innerText = getTveProviderMessage();
  } else if (result.pickerView === 'providerLoginSuccess') {
    title.innerText = 'Watch Event';
  } else {
    title.innerText = 'Select TV Provider';
  }
}
