import React from 'react';
import classnames from 'classnames';
import {SVG} from '@br/br-components';

import {SOCIAL_LINKS} from '../../constants/socialLinks';
import {Static, WarnerMedia} from '../../endpoints';

const currentYear = new Date().getFullYear();

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate() {
    return false; // should NEVER need to update.
  }

  showCookiePreferenceCenter() {
    const linkAction = global.WM.UserConsent.getLinkAction();

    linkAction();
  }

  render() {
    if (this.props.hide) {
      return false;
    }
    const {siteLocale} = this.props.user;
    const socialLinks = siteLocale === 'USA' ? SOCIAL_LINKS.US : SOCIAL_LINKS.UK;

    const classes = classnames({
      molecule: true,
      footer: true,
    });
    const adChoicesLink = (
      <a
        href="https://www.wbdprivacy.com/policycenter/b2c"
        target="_blank"
        rel="noopener noreferrer"
      >
        AdChoices
      </a>
    );
    const isUSuser = this.props.ui.countryCode === 'US';
    const manageCookiePreferencesLink = (
      <a href="#" onClick={this.showCookiePreferenceCenter}>
        {isUSuser ? 'Do Not Sell or Share My Personal Information' : 'Cookie Preferences'}
      </a>
    );

    return (
      <footer className={classes}>
        {!this.props.hideFooterLinks ? (
          <div>
            <SVG type="logo" target="brPlateLight" />
            {/** Wunderkind depends on this DOM element in order to inject its custom campaign */}
            <div className="footer newsletterSubsModule" />
            <ul className="social">
              <li>
                <a href={socialLinks.FB}>
                  <SVG type="icon" target="facebookFilled" />
                </a>
              </li>
              <li>
                <a href={socialLinks.TW}>
                  <img
                    className="x-icon"
                    src={`${Static.images()}/atoms/svg/icons/x-icon.svg`}
                    alt="X.com Logo"
                  />
                </a>
              </li>
              <li>
                <a href={socialLinks.IG}>
                  <SVG type="icon" target="instagram" />
                </a>
              </li>
            </ul>
            <ul className="links">
              <li>
                <a href={`${this.props.brHost}/pages/about`}>About</a>
              </li>
              <li>
                <a href={`${this.props.brHost}/pages/advertise`}>Advertise</a>
              </li>
              <li>
                <a href={`${this.props.brHost}/pages/contact`}>Contact Us</a>
              </li>
              <li>
                <a href="https://support.bleacherreport.com/">Get Help</a>
              </li>
              <li>
                <a href="https://careers.bleacherreport.com/">Careers</a>
              </li>
              <li>
                <a href={`${this.props.brHost}/sitemap`}>Sitemap</a>
              </li>
              <li>
                <a href={`${this.props.brHost}/pages/comguidelines`}>Community Guidelines</a>
              </li>
              <li>
                <a href={WarnerMedia.privacy()}>Privacy</a>
              </li>
              <li>{manageCookiePreferencesLink}</li>
              <li>
                <a href={`${this.props.brHost}/pages/terms`}>Terms Of Use</a>
              </li>
              <li>{adChoicesLink}</li>
              <li>
                <a href="https://www.max.com/sports">B/R Sports on MAX</a>
              </li>
              <li>
                <a href="https://creators.bleacherreport.com/">B/R Creators Program</a>
              </li>
            </ul>
          </div>
        ) : (
          false
        )}
        <small className="copyright">
          Copyright © {currentYear} Bleacher Report, Inc. A Warner Bros. Discovery Company. All
          Rights Reserved.
        </small>
      </footer>
    );
  }
}

Footer.defaultProps = {
  addScript: () => {}, // stub
  assetURL: '',
  brHost: '',
};

export default Footer;
