import Boom from 'boom';
import {createAction} from 'redux-actions';

import {setTitle, setUiShowData} from './appActions';
import {createFlyin} from './promoActions';
import {hideAds} from './adsActions';
import {loadVideoMetadataData, loadVideoPlaylist} from './videoActions';
import {contentTypes} from '../constants/contentTypes';
import {
  LOAD_COMMENT_DATA_FOR_ARTICLE,
  LOAD_ALERT_DETAILS,
  LOAD_TRACK_DATA_FOR_ARTICLE,
} from '../constants/actionTypes';

import {determineTimezone} from '../helpers/dateHelpers';

import ArticlesAPI from '../apis/articles_api';
import FaustAPI from '../apis/faust_api';
import LayserAPI from '../apis/layser_api';

const loadCommentData = createAction(LOAD_COMMENT_DATA_FOR_ARTICLE, async (trackUrlHash) => {
  return await LayserAPI.fetchCommentData(trackUrlHash);
});

const loadTrackData = createAction(LOAD_TRACK_DATA_FOR_ARTICLE, async (permalink) => {
  return await LayserAPI.reverseLookupArticle(permalink);
});

const ARTICLE_ID_REGEX = /^\b\d+\b/g;

const trendingTagID = '23319';
const trendingTagName = 'TRENDING';

const sanitizeArticleId = (articleID) => {
  if (!articleID) {
    return false;
  }
  const id_match = articleID.match(ARTICLE_ID_REGEX);
  const id = id_match ? id_match[0] : false;
  return id;
};

function isTagForRelatedContent(tag) {
  return tag.type === 'Team' || tag.type === 'League';
}

function tagToTsSlug(tag) {
  return `${tag.unique_name}_ts`;
}

const loadRelatedContent = createAction('LOAD_RELATED_CONTENT', async (articleData) => {
  const tags = articleData.tags;
  const relatedTags = process.env.RELATED_CONTENT_OVERRIDE_TAG
    ? process.env.RELATED_CONTENT_OVERRIDE_TAG.trim()
    : tags
        .filter(isTagForRelatedContent)
        .map(tagToTsSlug)
        .join(',');

  let {tracks} = await LayserAPI.stream(relatedTags || 'featured', 100);

  if (!tracks || !tracks.length) {
    ({tracks} = await LayserAPI.stream('featured', 100));
  }

  return {
    articleId: articleData.breport_id,
    tracks,
  };
});

const loadVideoRecommendations = createAction('LOAD_VIDEO_RECOMMENDATIONS', async (articleId) => {
  return await LayserAPI.fetchVideoRecommendations(articleId);
});

const loadRecommendedArticles = createAction('LOAD_RECOMMENDED_ARTICLES', async (articleId) => {
  let data = [];
  const recommendedArticles = await LayserAPI.fetchRecommendedArticles(articleId);
  if (recommendedArticles && recommendedArticles.recommendations) {
    const {recommendations} = recommendedArticles;
    const promises = [];
    recommendations.forEach((article) => {
      promises.push(LayserAPI.fetchArticle(article.related_article_id));
    });
    await Promise.all(promises)
      .then((responses) => {
        data = responses;
      })
      .catch((error) => {
        return Boom.wrap(error);
      });
  }

  return {
    articleId,
    recommendations: data,
  };
});
function sortArticles(articles, rankedList) {
  const rankedArticles = articles.map((article) => {
    const rank = rankedList.find((trending) => trending.article_id === article.breport_id)?.rank;
    return {...article, rank};
  });
  const sortedArticles = rankedArticles.sort(function(current, next) {
    return current.rank - next.rank;
  });
  return sortedArticles;
}
const loadTrendingArticles = createAction('LOAD_TRENDING_ARTICLES', async () => {
  try {
    const trendingArticles = await LayserAPI.fetchTrendingArticles();
    if (trendingArticles && trendingArticles.trending) {
      const {trending} = trendingArticles;
      const articleIds =
        trending && trending.length > 0 && trending.map((article) => article.article_id);
      const result = await ArticlesAPI.fetchArticles(articleIds);
      const sortedArticles = result?.articles ? sortArticles(result.articles, trending) : [];
      return {
        trendingArticles: sortedArticles,
      };
    }
  } catch (error) {
    return Boom.wrap(error);
  }
});
const loadArticleData = createAction('LOAD_ARTICLE_DATA', async (articleID) => {
  const id = sanitizeArticleId(articleID);
  if (!id) {
    throw new Error('Incorrect or No article id passed to loadArticleData');
  }
  const articleData = await ArticlesAPI.fetchArticle(id);
  if (!articleData.elements || articleData.elements.length < 1) {
    throw articleData.error || Boom.notFound(`Problem loading article ${articleID}`);
  }
  const metadata = await FaustAPI.fetchArticleMetaData(id);
  return {
    ...articleData,
    tags: metadata.article_metadata.tags,
    page: metadata.article_metadata.page,
  };
});

const loadArticlePreviewData = createAction('LOAD_ARTICLE_DATA', async (articleID, jwt) => {
  const id = sanitizeArticleId(articleID);
  if (!id) {
    throw new Error('Incorrect or No article id passed to loadArticlePreviewData');
  }
  const articleData = await ArticlesAPI.previewArticle(id, jwt);
  if (!articleData.elements || articleData.elements.length < 1) {
    throw articleData.error || Boom.notFound(`Problem loading preview of article ${articleID}`);
  }
  return {
    ...articleData,
    page: {
      tags: articleData.tag_list,
    },
  };
});

const loadAlertDetails = createAction(LOAD_ALERT_DETAILS, async ({tags, tagId, tracks}) => {
  if (!tags || !tagId || !tracks) {
    throw new Error('Incorrect data passed to loadAlertDetails');
  }
  let tagAlerts = [];
  let tagName = '';
  let tagInfo,
    trendingTagInfo = {};
  const tagNames = tags.map((tag) => tag.unique_name);
  // Fetch alerts for all available tags
  const allAlerts = tagNames && (await LayserAPI.fetchAlerts(tagNames.join()));
  const isAllAlertsPopulated = Array.isArray(allAlerts) && allAlerts.length;
  const isTrackDataPopulated = Array.isArray(tracks) && tracks.length;
  const articleAlert =
    isAllAlertsPopulated &&
    isTrackDataPopulated &&
    allAlerts.find((alert) => {
      return tracks.find((track) => track.original_url_sha === alert.original_url_sha);
    });
  if (articleAlert) {
    tagInfo = tags.find((tag) => tag.tag_id === tagId);
    if (tagInfo) {
      const {display_name = '', unique_name = ''} = tagInfo;
      tagName = display_name;
      // Fetch alerts for the tag the article belongs to
      tagAlerts = await LayserAPI.fetchAlerts(unique_name);

      // We request for trending alerts if a tag has less than 5 alerts or no alerts
      if (!tagAlerts.length || tagAlerts.length < 5) {
        if (!tagAlerts.length) {
          tagName = trendingTagName;
          tagAlerts = [];
        }
        const trendingTag = await FaustAPI.getTagInfo(trendingTagID);
        const trendingAlerts = await LayserAPI.fetchAlerts(trendingTagName.toLowerCase());
        trendingTagInfo = trendingTag && trendingTag[0];
        tagAlerts = trendingAlerts && tagAlerts.concat(trendingAlerts);
      }
    }
  }

  return {
    createdAt: articleAlert && articleAlert.created_at,
    alerts: tagAlerts,
    tagName,
    tagInfo,
    trendingTagInfo,
  };
});

const fetchArticleData = (articleID) => {
  return (dispatch, getState) => {
    const id = sanitizeArticleId(articleID);
    const {user} = getState();
    const {page} = getState();
    const inAppView = page.tsm === 1; //tsm value is set to 1 for in-app view

    if (!id) {
      return Promise.reject(Boom.notFound(`${articleID} is not a valid Article ID`));
    }
    return dispatch(loadArticleData(articleID))
      .then((action) => {
        if (action.payload.tags && action.payload.tags.length) {
          const articleHasVideo = action.payload.elements.some((element) => {
            return element.content_type === contentTypes.VIDEO;
          });
          const promises = [dispatch(setTitle({title: action.payload.title}))];
          const hasNoAdsTag = action.payload.tags.find((tag) => tag.unique_name === 'no-ads');
          if (hasNoAdsTag) {
            promises.push(dispatch(hideAds()));
          }
          const shouldLoadVideoRecommendations =
            // For this phase we only want to load video recommendations
            // on articles with no video elements and that arent in-app (US)
            action.payload.render_strategy === 'article' &&
            user.visitorCountry === 'US' &&
            !hasNoAdsTag &&
            !articleHasVideo &&
            !inAppView;

          if (shouldLoadVideoRecommendations) {
            // Fetch video recommendations for articles without a video
            promises.push(dispatch(loadVideoRecommendations(articleID)));
          } else {
            // Don't load video playlist for video recommendations
            promises.push(dispatch(loadVideoPlaylist(action.payload.tags)));
          }

          if (action.payload.render_strategy !== 'slideshow') {
            promises.push(dispatch(loadRelatedContent(action.payload)));
          }
          return Promise.all(promises);
        }
        return false;
      })
      .then(() => {
        const {articles} = getState();
        // Return promise for each video element that may need metadata
        return Promise.all(
          [
            ...articles.elements,
            ...(articles.videoRecommendations || []), // Only available on mobile for now
          ].reduce((elements, element) => {
            const hasRequiredMetadata =
              element.content && element.content.metadata && element.content.metadata.video_url;
            const isVideoElement = element.content_type === contentTypes.VIDEO;

            // Add promise for each video
            if (isVideoElement && !hasRequiredMetadata) {
              const videoId = element.video_id || element.content.metadata.video_id;
              return [...elements, dispatch(loadVideoMetadataData(videoId))];
            }
            return elements;
          }, [])
        );
      });
  };
};

const fetchArticlePreviewData = (articleID, jwt) => {
  return (dispatch) => {
    const id = sanitizeArticleId(articleID);
    if (!id) {
      return Promise.reject(Boom.notFound(`${articleID} is not a valid Article ID`));
    }
    if (!jwt) {
      return Promise.reject(Boom.notFound('missing JWT'));
    }
    return dispatch(loadArticlePreviewData(articleID, jwt));
  };
};

const determineFlyins = () => (dispatch, getState) => {
  const {ui} = getState();

  // The related content flyin should not display if the android promo is visible
  if (ui.show_android_ts_promo) {
    dispatch(createFlyin('androidTsPromo'));
  }
  dispatch(createFlyin());
};

const setTimezone = createAction('SET_TIMEZONE');

const articleMounted = ({
  hidePublishedDate,
  hideFlyin,
  hideSkin,
  user,
  page,
  ui,
  notification,
  defaultTrack,
}) => (dispatch) => {
  const promises = [];
  if (!hidePublishedDate) {
    promises.push(dispatch(setTimezone({timezone: determineTimezone()})));
    if (defaultTrack.url_hash) promises.push(dispatch(loadCommentData(defaultTrack.url_hash)));
  }
  if (!hideFlyin) {
    promises.push(
      dispatch(setUiShowData({hideSkin, user, page, ui, notification})),
      dispatch(determineFlyins())
    );
  }
  return Promise.all(promises);
};

const updateCurrentSlide = createAction('UPDATE_CURRENT_SLIDE');

export {
  articleMounted,
  determineFlyins,
  fetchArticleData,
  fetchArticlePreviewData,
  loadAlertDetails,
  loadArticleData,
  loadCommentData,
  loadRecommendedArticles,
  loadTrackData,
  loadTrendingArticles,
  loadVideoMetadataData,
  loadVideoPlaylist,
  loadVideoRecommendations,
  setTimezone,
  updateCurrentSlide,
};
