import {createAction} from 'redux-actions';
import md5 from 'crypto-js/md5';

import * as UserActions from './userActions';

import StorageAPI from '../apis/storage_api';
import FaustAPI from '../apis/faust_api';
import JobScoreAPI from '../apis/jobscore_api';
import {fetchGizmoProducts} from '../apis/dalton_api';

import {
  ARTICLE,
  BETSIFRAME,
  GAMECAST,
  MINIMAL,
  RECAPTCHA_VERIFICATION,
  SCHEDULE,
  STATIC,
  STUB,
  USER_POST,
  YAHOO_SYNC,
  VIDEO,
} from '../constants/pageTypes';

import {BR} from '../endpoints';
import {
  STORE_APP_VERSION,
  SET_TVE_AUTH_CONTEXT,
  SET_TVE_PROVIDER_LOGO,
  SET_TEALIUM_LOAD_RULES,
  DISABLE_USER_CONSENT_SCRIPT,
} from '../constants/actionTypes';

const storeAppVersion = createAction(STORE_APP_VERSION);

const setBasicTitle = createAction('SET_BASIC_TITLE');

const setContent = createAction('SET_STATIC_CONTENT');

const setTitle = createAction('UPDATE_TITLE');

const setStaticTitle = createAction('UPDATE_STATIC_TITLE');

const setAdData = createAction('SET_AD_DATA');

const setMetadataTags = createAction('SET_METADATA_TAGS');

const setPage = createAction('SET_PAGE', async (page) => {
  switch (page.type) {
    case RECAPTCHA_VERIFICATION:
      return {
        ...page,
        redirectUrl: BR.recaptchaVerified({
          confirmation_token: page.confirmationToken,
        }),
      };
    case ARTICLE:
      return {
        ...page,
        url: BR.article(page.id),
      };

    case BETSIFRAME:
      return {
        ...page,
        url: BR.betsIframe(page.id),
      };

    case USER_POST:
      return {
        ...page,
        url: BR.userPost(page.postId),
      };

    case STUB: {
      return {
        ...page,
        url: BR.stub(page),
      };
    }

    case STATIC: {
      return {
        ...page,
        url: BR.section(`pages/${page.id}`),
      };
    }

    case SCHEDULE:
      return {
        ...page,
        url: BR.teamSchedule(page.id),
      };
    case GAMECAST:
      return {
        ...page,
        url: BR.gamecast(page.id),
      };

    case MINIMAL:
    case YAHOO_SYNC:
      return {
        ...page,
      };
    case VIDEO:
      return {
        ...page,
        url: BR.video(page.id),
      };
    default:
      return {
        ...page,
        url: BR.section(page.id),
      };
  }
});

const setUiShowData = createAction('SET_UI_SHOW_DATA', (data) => {
  const promoKey = StorageAPI.get('android_ts_promo_kill');
  const hasClosedAndroidPromo = promoKey && Object.keys(promoKey).length > 0;

  return {
    ...data,
    hasClosedAndroidPromo,
  };
});

const addScript = createAction('ADD_SCRIPT', (script) => {
  let scriptConfig = {};
  if (typeof script === 'string') {
    scriptConfig.src = scriptConfig.key = script;
  } else {
    scriptConfig = {...script};
  }
  return scriptConfig;
});

const addStyle = createAction('ADD_STYLE');

const addScriptPromise = createAction('ADD_SCRIPT_PROMISE');

const incrementLimit = createAction('INCREMENT_LIMIT', async (limit) => {
  return limit;
});

const storeUA = createAction('STORE_UA');

const storeCountryCode = createAction('STORE_COUNTRY_CODE');

const storeRegionCode = createAction('STORE_REGION_CODE');

const storeReferrer = createAction('STORE_REFERRER');

const storeQueryParams = createAction('STORE_QUERY_PARAMS');

const storeUnavailableImageUrl = createAction('STORE_UNAVAILABLE_IMAGE_URL');

const setReturnUrl = createAction('SET_RETURN_URL');

const setScreen = createAction('SET_SCREEN');

const setFromCnn = createAction('SET_FROM_CNN');

const loadBaseAppData = (request) => {
  return (dispatch) => {
    const actions = [
      dispatch(storeReferrer(request.info.referrer)),
      dispatch(storeQueryParams(request.query)),
      dispatch(setFromCnn(request.pre.isFromCnn)),
      // The country and region headers should never be empty on any deployed envs since it's done by Fastly
      // However, locally we don't use Fastly, so we add a default
      dispatch(storeCountryCode(request.headers['geo-country-code'] || 'US')),
      dispatch(storeRegionCode(request.headers['geo-region-code'] || 'NY')),
    ];
    if (request.pre.user) {
      actions.push(dispatch(UserActions.add(request.pre.user)));
    }
    if (request.plugins.scooter) {
      actions.push(dispatch(storeUA(request.plugins.scooter)));
    }
    return Promise.all(actions).catch((err) => {
      throw err;
    });
  };
};

const loadTagsMetadata = createAction('LOAD_TAGS_METADATA', async (tags) => {
  const tagsList = Array.isArray(tags) ? tags : [tags];
  const tagsInfo = tagsList.length ? await FaustAPI.getTagInfo(tagsList.join(',')) : [];

  return {
    tags: tagsInfo,
  };
});

// LoadScript is a promisified version of addScript above.
// It is usful when you want to perform an action after a script has loaded.
// A simple Promise chain is all that is required:
// `AppActions.loadScript(obj).then(postLoadFunction);`
// Note that the result of any existing onLoad function is passed when resolved.
// As it is resolved by the scripts onLoad event it will not be useful for server-side logic.
const loadScript = (script) => {
  return (dispatch, getState) => {
    let scriptConfig = {};
    if (typeof script === 'string') {
      scriptConfig.src = scriptConfig.key = script;
    } else {
      scriptConfig = {...script};
    }

    // rejecting multiple requests of the same script key
    const scripts = getState().template.scripts;
    const scriptIndex = scripts.findIndex((obj) => obj.key === scriptConfig.key);
    if (scriptIndex > -1) {
      return scripts[scriptIndex].promise;
    }

    const loadPromise = new Promise((resolve) => {
      const onLoad = () => {
        if (typeof scriptConfig.onLoad === 'function') {
          resolve(scriptConfig.onLoad());
        }
        resolve();
      };
      dispatch(addScript({...scriptConfig, onLoad}));
    });

    dispatch(addScriptPromise({key: scriptConfig.key, promise: loadPromise}));

    return loadPromise;
  };
};

const setEmbeddedMode = createAction('SET_EMBEDDED_MODE', ({cookieState, queryParams}) => {
  const {tsm, tst} = queryParams;
  if (tsm === '0' || tst === '0') {
    return {
      isEmbedded: false,
      isSettingCookie: true,
    };
  }
  if (tsm === '1' || tst === '1') {
    return {
      isEmbedded: true,
      isSettingCookie: true,
    };
  }
  if (tsm === '2' || tst === '2') {
    return {
      isEmbedded: true,
      isSettingCookie: false,
    };
  }
  return {
    isEmbedded: cookieState.embeddedMode === 'true',
    isSettingCookie: false,
  };
});

const updateVids = createAction('UPDATE_VIDEOS_IN_VIEWPORT', async (data) => {
  return data;
});

const updateScrollPlayVideos = createAction('UPDATE_SCROLL_PLAY_VIDEOS');

const updateVideosInViewport = (data) => (dispatch, getState) => {
  dispatch(updateVids(data)).then(() => {
    const state = getState();
    const videosInViewport = state.ui.videosInViewport;
    const currentPage = state.ui.currentPage;
    const videoTrackList =
      state.articles.videoList || state.videos.videoList || state.sections[currentPage].videoList;
    let videoToPlay = videosInViewport[0];

    if (videosInViewport.length > 1 && videoTrackList) {
      const firstVideo = videoTrackList.findIndex((video) => videosInViewport.includes(video));

      videoToPlay = videoTrackList[firstVideo];
    }

    dispatch(updateScrollPlayVideos([videoToPlay, state.playlists[currentPage]]));

    return false;
  });
};

const getCareers = createAction('GET_CAREERS', async (department) => {
  const jobs = await JobScoreAPI.getJobs();
  const departments = [department];
  switch (department) {
    case 'content':
      departments.push('house of highlights');
      break;
    case 'engineering':
      departments.push('design', 'product management');
      break;
    case 'finance':
      departments.push('human resources');
      break;
    case 'marketing':
      departments.push('marketing/pr');
      break;
    case 'operations':
      departments.push('revenue operations');
      break;
    case 'video':
      departments.push('branded content');
      break;
    default:
      break;
  }
  return {
    jobs,
    departments,
  };
});

const setTveAuthContext = createAction(SET_TVE_AUTH_CONTEXT, async (tveAuth = {}) => {
  const context = (tveAuth && tveAuth.context) || {};
  const authenticatedProvider = context.authenticatedProvider || {};

  return {
    tveAuthContext: {
      ...tveAuth,
      context: {
        ...context,
        authenticatedProvider: {
          ...authenticatedProvider,
          ...(authenticatedProvider &&
            authenticatedProvider.id && {
              hashId: md5(authenticatedProvider.id.toLowerCase()).toString(),
            }),
        },
      },
    },
  };
});

const setTveProviderLogo = createAction(SET_TVE_PROVIDER_LOGO, async (imageURL) => {
  return {tveProviderLogo: imageURL};
});

const loadPPVProductsAction = createAction('LOAD_PPV_PRODUCTS');

const loadPPVProducts = async (eventId) => async (dispatch, getState) => {
  const {userAgent} = getState().ui;
  const data = await fetchGizmoProducts(userAgent);
  return dispatch(
    loadPPVProductsAction({
      eventId,
      products: data.gizmoProducts,
    })
  );
};

const disableUserConsentScript = createAction(DISABLE_USER_CONSENT_SCRIPT);
const setTagManagerLoadRules = createAction(SET_TEALIUM_LOAD_RULES);

export {
  addScript,
  addStyle,
  disableUserConsentScript,
  getCareers,
  incrementLimit,
  loadBaseAppData,
  loadPPVProducts,
  loadScript,
  loadTagsMetadata,
  setAdData,
  setBasicTitle,
  setContent,
  setEmbeddedMode,
  setFromCnn,
  setMetadataTags,
  setPage,
  setReturnUrl,
  setScreen,
  setStaticTitle,
  setTagManagerLoadRules,
  setTitle,
  setTveAuthContext,
  setTveProviderLogo,
  setUiShowData,
  storeAppVersion,
  storeQueryParams,
  storeReferrer,
  storeUA,
  storeUnavailableImageUrl,
  updateVideosInViewport,
};
