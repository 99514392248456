import {Button} from '@br/br-components';

const EventOverlay = ({assetUrl = ''}) => {
  /* TODO: Use specific time elapsed since event started when data contract is
    established and plug onClick handlers into buttons */

  return (
    <div className="atom event-overlay">
      <div className="text-wrapper">
        <h2>This Event Has Already Started</h2>
        <span>Where do you want to pick up?</span>
      </div>
      <Button classes="live-btn">
        Watch LIVE{' '}
        <img
          className="video-play"
          src={`${assetUrl}/img/video-player/live-arrow.svg`}
          alt="video play button"
        />
      </Button>
      <Button classes="restart-btn">Start from Beginning</Button>
    </div>
  );
};

export default EventOverlay;
