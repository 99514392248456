const determineTarget = (url = '') => {
  const BRregex = /^https?:\/\/(?:\w+\.)?(?:\d)?bleacherreport\.com\//;
  const isBRurl = url.indexOf('/') === 0 || BRregex.test(url);
  return isBRurl ? '_self' : '_blank';
};

const determineRel = (target) => {
  return target === '_blank' ? 'noopener noreferrer' : '';
};

export {determineTarget, determineRel};
