import {contentTypes} from '../../constants/contentTypes';

export const parseVideoElements = (obj) => {
  const {content, content_type} = obj;
  const isInternalVideo = [
    contentTypes.HIGHLIGHT,
    contentTypes.VIDEO,
    contentTypes.LIVE_VIDEO, // considered internal until product requirements change
  ].includes(content_type);
  const metadata = (content && content.metadata) || {};
  const url = (metadata && metadata.share_url) || '';

  let mediaFields = {};
  if (content_type !== contentTypes.LIVE_VIDEO && content) {
    mediaFields = {
      mediaHeight: content.media_height,
      mediaWidth: content.media_width,
    };
  }

  if (isInternalVideo) {
    return {
      ...obj,
      metadata: {
        ...metadata,
        ...mediaFields,
      },
      url,
    };
  }

  return obj;
};

export const addContentType = (obj) => {
  return {
    ...obj,
    content_type: contentTypes.VIDEO,
  };
};

export const isVideo = (contentType) => {
  return (
    contentType === contentTypes.LIVE_VIDEO ||
    contentType === contentTypes.VIDEO_ARTICLE ||
    contentType === contentTypes.VIDEO
  );
};
