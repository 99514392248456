import React, {useEffect} from 'react';
import {MobileNavBar} from './MobileNavBar/MobileNavBar';
import {Navigation} from './types';
import {UserType} from '../types';
import {DesktopNavBar} from './DesktopNavBar/DesktopNavBar';

export interface NavBarProps {
  navigation?: Navigation;
  injectH1?: Boolean;
  userType?: UserType;
  profileId?: Number;
  path: string[];
  handleTrackEvent: (eventName: string) => void;
}

export const NavBar: React.FC<NavBarProps> = ({
  navigation,
  injectH1,
  userType,
  profileId,
  path,
  handleTrackEvent,
}) => {
  useEffect(() => {
    if (navigation.subNavigation) {
      document.body.classList.add('-hasSubNav');
    } else {
      document.body.classList.remove('-hasSubNav');
    }
  }, [navigation]);

  return (
    <div className="navBarFonts navBar" data-fallback={navigation.fallback}>
      <div className="navBar__mobileNavBar">
        <MobileNavBar
          navigation={navigation}
          userType={userType}
          profileId={profileId}
          path={path}
          handleTrackEvent={handleTrackEvent}
        />
      </div>

      <div className="navBar__desktopNavBar">
        <DesktopNavBar
          navigation={navigation}
          injectH1={injectH1}
          userType={userType}
          profileId={profileId}
          path={path}
          handleTrackEvent={handleTrackEvent}
        />
      </div>
    </div>
  );
};
