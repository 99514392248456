export const contentTypes = {
  AD: 'ad',
  BET: 'bet',
  BET_TRACK: 'bet_track',
  CREATORS: 'creators',
  DEEPLINK: 'deeplink',
  DIV: 'div',
  EXTERNAL_ARTICLE: 'external_article',
  FACEBOOK_IMAGE_POST: 'facebook_image_post',
  FACEBOOK_POST: 'facebook_post',
  FACEBOOK_VIDEO_POST: 'facebook_video_post',
  GIF: 'gif',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  HIGHLIGHT: 'highlight',
  HR: 'hr',
  HR_TRANSPARENT: 'hr_transparent',
  IFRAME: 'iframe',
  IMAGE: 'image',
  INSTAGRAM: 'instagram',
  INSTAGRAM_IMAGE: 'instagram_image',
  INSTAGRAM_VIDEO: 'instagram_video',
  INTERNAL_ARTICLE: 'internal_article',
  LAB_ARTICLE: 'lab_article',
  LIVE_VIDEO: 'live_video',
  OBJECT: 'object',
  OL: 'ol',
  PARAGRAPH: 'paragraph',
  PHOTO: 'photo',
  POLL: 'poll',
  QUOTE_INDENT: 'quote_indent',
  QUOTE_OLD: 'blockquote',
  QUOTE_PULL: 'quote_pull',
  SLIDE: 'slide',
  STATMILK: 'statmilk',
  TABLE: 'table',
  TEXT: 'text',
  TICKETS_PROMO: 'tickets_promo',
  TOP_AD: 'top_ad',
  TWEET: 'tweet',
  UL: 'ul',
  USER_TEXT: 'user_text',
  VIDEO: 'video',
  VIDEO_ARTICLE: 'video_article',
  YOUTUBE_VIDEO: 'youtube_video',
};
