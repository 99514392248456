// eslint-disable-next-line no-unused-vars
import {useEffect} from 'react';
import {connect} from 'react-redux';

import StorageAPI from '../../apis/storage_api';

import logger from '../../logger';

import {Tealium, AdobeLaunch} from '../../endpoints';

import {setOneTrustPreferences} from '../../actions/userActions';
import * as TopSelectors from '../../selectors/topLevelSelectors';

import {euCountryTable, formatTagManagerObject} from '../../apis/helpers/tagManager_helpers';

const shouldLoadTealiumScript = process.env.DISABLE_TEALIUM !== 'true';
const shouldLoadAdobeLaunchScript = process.env.ENABLE_ADOBE_LAUNCH === 'true';

const TagManager = ({addScript, loadRules, utagData, setOneTrustPreferences}) => {
  useEffect(() => {
    if (!loadRules) {
      return;
    }

    const updatedUtagData = {
      ...utagData,
      ...loadRules,
    };

    // Set comScore consent variable based on load rules
    updatedUtagData.comscore_ucfr = loadRules.fire_advertising && loadRules.fire_vendor ? '1' : '0';
    const innerHTML = `var utag_data = ${JSON.stringify(updatedUtagData)};`;
    const oneTrustSettings = {
      advertising: updatedUtagData.fire_advertising,
      performance: updatedUtagData.fire_performance,
      personalization: updatedUtagData.fire_personalization,
      social: updatedUtagData.fire_social,
      strictlyNeeded: updatedUtagData.fire_strictly_needed,
      vendor: updatedUtagData.fire_vendor,
    };

    // The absence of either DISABLE_TEALIUM and ENABLE_ADOBE_LAUNCH env var results in existing functionality
    // that will control which tag manager will be loaded. This gives us the option to load them both concurrently
    // if we need to and switch between either without a code update. Each tag should only be enabled in one of the
    // Tag manager services at a given time to prevent them from loading twice.
    if (shouldLoadTealiumScript) {
      addScript({
        innerHTML,
        key: 'tealium-tagManager-utag-data',
        noSrc: true,
        onLoad: () =>
          addScript({
            key: 'TealiumTagManager',
            src: Tealium.scriptSrc(),
            defer: true,
            onLoad: () => {
              setOneTrustPreferences(oneTrustSettings);
              logger.info('Tealium TagManager loaded!');
            },
          }).catch((err) => logger.warn(`Tealium failed to load - ${err}`)),
      });
    }

    if (shouldLoadAdobeLaunchScript) {
      addScript({
        innerHTML,
        key: 'adobe-tagManager-utag-data',
        noSrc: true,
        onLoad: () =>
          addScript({
            key: 'AdobeTagManager',
            src: AdobeLaunch.scriptSrc(),
            defer: true,
            onLoad: () => {
              setOneTrustPreferences(oneTrustSettings);
              logger.info('Adobe TagManager loaded!');
            },
          }).catch((err) => logger.warn(`Adobe TagManager failed to load - ${err}`)),
      });
    }
  }, [addScript, loadRules, setOneTrustPreferences, utagData]);

  return false;
};

// utagData contains all the parameters required for the events called
// Keys are snake case to match standard in TagManager
const generateUtagData = (ads, article, page, ui, user, video) => {
  // This data is used to set up the data layer
  // Data here can be accessed to load the tags
  // Most of this data is used within the TagManager GPT custom template
  return {
    article_id: page.article,
    author_name: article.author && article.author.name,
    buzz: ads.buzz,
    cnn: ads.cnn,
    country_code: user.country || '',
    division: ads.division,
    embedded: ads.embedded,
    eu_country: (user.country && euCountryTable[user.country]) || '',
    event: ads.event,
    fantasy: ads.fantasy,
    gp_flag: ads.gp_flag,
    krux_id: ads.krux_id,
    layout: ads.layout,
    league: ads.league,
    override: ads.override,
    portmerion_id: StorageAPI.get('portmeirion_id', true),
    render_strategy: ads.render_strategy,
    sections: `${page.site},br`,
    site: ads.site ? ads.site : page.site,
    social: ads.social,
    tag_id: ads.tag_id,
    tags: ads.tags ? ads.tags : page.tags,
    tag_manager_event: ui.pageType,
    team: ads.team,
    tracking_id: user.trackingId,
    video_id: video.id,
    video_title: video.title,
    zone: ads.zone,
  };
};

const mapStateToProps = (state) => {
  const ads = TopSelectors.ads(state);
  const article = TopSelectors.articles(state);
  const page = TopSelectors.page(state);
  const ui = TopSelectors.ui(state);
  const user = TopSelectors.user(state);
  const video = TopSelectors.video(state);

  const utagData = generateUtagData(ads, article, page, ui, user, video);
  const formattedUtagData = formatTagManagerObject(utagData);

  return {
    loadRules: ui.tagManagerLoadRules,
    utagData: formattedUtagData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOneTrustPreferences: (utagData) => dispatch(setOneTrustPreferences(utagData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagManager);
