import {createAction} from 'redux-actions';
import AnalyticsApi from '../apis/analytics_api';
import * as DaltonAPI from '../apis/dalton_api';

export const article = createAction('ANALYTICS_ARTICLE');

export const trackEvent = createAction('TRACK_EVENT', (event) => {
  return AnalyticsApi.track(event);
});

export const mParticleLogout = createAction('MPARTICLE_LOGOUT', () => {
  return AnalyticsApi.mParticleLogout();
});

export const mParticleModifyIdentity = createAction('MPARTICLE_MODIFY_IDENTITY', (event) => {
  return AnalyticsApi.mParticleModifyIdentity(event);
});

export const mParticleLogin = createAction(
  'MPARTICLE_LOGIN',
  async (userAgent, event, userAttributes = {}) => {
    const authToken = await DaltonAPI.getAuthToken(userAgent);
    const userProfile = await DaltonAPI.getUserProfile(authToken);
    AnalyticsApi.mParticleLogin(
      {
        ...event,
        other2: userProfile?.tid || '',
      },
      {...userAttributes}
    );
  }
);

export const mParticleUpdateAttributes = createAction('MPARTICLE_UPDATE_ATTRIBUTES', (event) => {
  AnalyticsApi.mParticleUpdateAttributes({
    ...event,
  });
});
