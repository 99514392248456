import React from 'react';
import classnames from 'classnames';

const BrLogo = (props) => {
  const classes = classnames({
    atom: true,
    logo: true,
    brLogo: true,
    svg: true,
    br: true,
  });

  return (
    <svg
      className={classes}
      onClick={props.onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1600 1200"
    >
      <title>Bleacher Report</title>
      <g>
        <path d="M484.85 200H200v800h284.85L600 884.85v-229.7L544.85 600 600 544.85v-229.7zM320 540V320h115.15L480 364.85v130.3L435.15 540zm115.15 120L480 704.85v130.3L435.15 880H320V660zM1415 1000l-99.35-370.79 84.35-84.36v-229.7L1284.85 200H1000v800h120V660h83.9l91.1 340zm-295-680h115.15l44.85 44.85v130.3L1235.15 540H1120zM700 770.71l200-200V429.29l-200 200v141.42z" />
      </g>
    </svg>
  );
};

export default BrLogo;
