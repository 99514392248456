import {JobScore} from '../endpoints';
import {createFetchService} from './fetch_utils';

const fetchJSON = createFetchService('LayserBeam');

function errorHandler(err) {
  return {
    error: err,
  };
}

export const getJobs = () => {
  return fetchJSON(JobScore.allJobs())
    .then((data) => {
      return data.jobs;
    })
    .catch(errorHandler);
};

export default {
  getJobs,
};
