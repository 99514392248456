const escapeHeaderAttribute = function(attribute) {
  // Allowed value characters: !#$%&'()*+,-./:;<=>?@[]^_`{|}~ and space, a-z, A-Z, 0-9, \, "
  return attribute.replace(/\\/g, '\\\\').replace(/"/g, '\\"'); // Escape quotes and slash
};

const assert = function() {}; // no-op

module.exports = {
  assert,
  escapeHeaderAttribute,
};
