export const ARTICLE = 'article';
export const BETSIFRAME = 'betsIframe';
export const CHECKOUT = 'checkout';
export const GAMECAST = 'gamecast';
export const MINIMAL = 'minimal';
export const SCHEDULE = 'schedule';
export const SECTION = 'section';
export const STUB = 'stub';
export const HOME = 'home';
export const RECAPTCHA_VERIFICATION = 'recaptchaVerification';
export const USER_POST = 'user_post';
export const YAHOO_SYNC = 'yahooSync';
export const EDIT_ACCOUNT = 'editAccount';
export const MY_EVENTS = 'myEvents';
export const NEWSLETTER_SUBSCRIPTION = 'newsletterSubscription';
export const VIDEO = 'video';
export const LIVE_EVENTS = 'liveEvents';

// Static Pages
export const ABOUT = 'about';
export const ADVERTISE = 'advertise';
export const CAREERS = 'careers';
export const CONTACT = 'contact';
export const COMMUNITY_CAPTAINS = 'comcaptains';
export const COMMUNITY_GUIDELINES = 'comguidelines';
export const COOKIE_POLICY = 'cookiepolicy';
export const LICENSES = 'licenses';
export const OFFICIAL_RULES = 'official-rules';
export const PREGAME_RULES = 'pregame-picks-rules';
export const PRIVACY = 'privacy';
export const TERMS = 'terms';
export const USERPOLICY = 'usepolicy';
export const STATIC = 'static';
export const VIDEO_FAQ = 'help';

export const StaticPages = {
  ABOUT,
  ADVERTISE,
  CAREERS,
  COMMUNITY_CAPTAINS,
  COMMUNITY_GUIDELINES,
  CONTACT,
  COOKIE_POLICY,
  LICENSES,
  OFFICIAL_RULES,
  PREGAME_RULES,
  PRIVACY,
  RECAPTCHA_VERIFICATION,
  TERMS,
  USERPOLICY,
  VIDEO_FAQ,
};
