import {parseVideoElements, addContentType} from './helpers/video_helpers';
import {removeScriptTag} from './helpers/sanitize_helpers';
import {
  LOAD_ALERT_DETAILS,
  LOAD_COMMENT_DATA_FOR_ARTICLE,
  LOAD_TRACK_DATA_FOR_ARTICLE,
} from '../constants/actionTypes';
import {contentTypes} from '../constants/contentTypes';
import {addAlertdata, isMoreThanOneDayAgo, setAlertTimeAgo} from './helpers/article_helpers';
import {parseImages} from '../helpers/imageHelpers';
import {addTracks, getVideos, extractIds, extractTrackIds, determineLeadMedia} from './videos';

const initialState = {tracks: []};

function filterRelatedContent({articleId}) {
  return (item) =>
    (item.content_type === 'internal_article' && !item.permalink.includes(articleId)) ||
    item.content_type === 'external_article' ||
    item.content_type === 'video_article';
}

function parseStatmilks(element) {
  if (element.content_type === 'statmilk' && element.content && !element.content.height) {
    element.content.height = '450'; // 'default' height of a Statmilk widget embed
    // Note: if the default height is changed, it also needs to be updated in the Content Tools (src/utils/editorjs/tools/statmilk/index.js)
    // so that the display of Statmilk embeds stays consistent between the internal tool and the published article.
  }
  return element;
}

function sanitizeAuthorInfo(author) {
  const photoUrl = author && author.photo_url;
  // When the photo_url is incorrect we still get the prepended cdn url, displaying a broken image in the article page.
  if (photoUrl && photoUrl === 'https://cdn.bleacherreport.net') {
    return {
      ...author,
      photo_url: '',
    };
  }
  return author;
}
function sanitizeArticles(data) {
  const articles = data.reduce(function(result, article) {
    if (article?.breport_id) {
      const {breport_id, title, image, updated_at, permalink} = article;
      result.push({
        id: breport_id,
        title,
        image,
        url: `https://bleacherreport.com/articles/${permalink}`,
        updatedDate: updated_at,
      });
    }
    return result;
  }, []);
  return articles;
}
function articles(state = initialState, action) {
  if (action.error) {
    return state;
  }
  const payload = action.payload;
  switch (action.type) {
    case 'LOAD_ARTICLE_DATA': {
      const rawElements = JSON.parse(removeScriptTag(JSON.stringify(payload.elements || [])));
      const elements = rawElements
        .map(parseVideoElements)
        .map(parseImages)
        .map(parseStatmilks);
      const videoList = payload.elements
        ? payload.elements.filter(getVideos).map(extractTrackIds)
        : [];

      if (payload.br_mag) {
        // The first element in the article content will be skipped; instead we'll show the media from this element above the headline
        const [firstElement, ...otherElements] = elements;
        return {
          ...state,
          ...payload,
          ...determineLeadMedia(firstElement, payload),
          author: sanitizeAuthorInfo(payload.author),
          elements: otherElements,
          videoList,
        };
      }

      if (payload.render_strategy === 'slideshow') {
        const elementsWithoutAds = elements.reduce((slides, elem) => {
          if (elem.content_type === 'slide') {
            slides.push(elem);
          }
          return slides;
        }, []);

        return {
          ...state,
          ...payload,
          elements: elementsWithoutAds,
          published_at:
            state.published_at ||
            payload.published_at ||
            payload.updated_at ||
            new Date().toISOString(),
          slide_count: state.slide_count || payload.slide_count || elementsWithoutAds.length,
          videoList,
        };
      }
      const betModule = {content_type: 'bet'};
      return {
        ...state,
        ...payload,
        elements: [...elements, betModule],
        videoList,
      };
    }

    case LOAD_ALERT_DETAILS: {
      const {alerts, createdAt, tagInfo, tagName, trendingTagInfo} = payload;
      return {
        ...state,
        alertTimeAgo: createdAt ? setAlertTimeAgo(createdAt) : null,
        alerts: alerts.length && addAlertdata(alerts, tagInfo, trendingTagInfo),
        shouldShowAlertBanner: createdAt && !isMoreThanOneDayAgo(createdAt),
        tagName,
      };
    }

    case LOAD_COMMENT_DATA_FOR_ARTICLE: {
      const {comments = {}, likes = {}} = payload;
      return {
        ...state,
        comments: comments.data,
        numberOfComments: comments.count,
        numberOfLikes: likes.count,
      };
    }

    case LOAD_TRACK_DATA_FOR_ARTICLE: {
      return {
        ...state,
        tracks: payload.map(parseImages),
      };
    }

    case 'LOAD_RELATED_CONTENT':
      if (!payload.tracks) {
        return state;
      }

      // Reusing existing logic here but we should probably find a way to only fetch the data we need
      // instead of all these tracks to then filter and trim to a few we show in the UI
      const relatedContentTracks = payload.tracks
        .filter(
          filterRelatedContent({
            articleId: action.payload.articleId,
          })
        )
        .splice(0, 4)
        .map(parseImages);

      return {
        ...state,
        related_content: relatedContentTracks,
      };

    case 'LOAD_VIDEO_METADATA': //same action exists in videos, but they can't share states
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          [payload.videoId]: {
            ...parseImages(payload.videoMetadata),
          },
        },
      };

    case 'LOAD_VIDEO_PLAYLIST': {
      if (!payload.tracks || payload.tracks.length === 0) {
        return state;
      }
      const newData = payload.tracks.filter(getVideos);
      if (newData.length === 0) {
        return state;
      }
      return {
        ...state,
        video_playlist_ids: newData.map(extractIds),
        video_playlist: addTracks(newData),
        video_playlist_status: {
          ...state.video_playlist_status,
          flyin: newData[0].content.metadata.video_id,
        },
      };
    }

    case 'LOAD_RECOMMENDED_ARTICLES':
      let articlesData = [];
      if (payload.recommendations.length > 0) {
        articlesData = sanitizeArticles(payload.recommendations);
      }
      return {
        ...state,
        articleRecommendations: articlesData,
      };
    case 'LOAD_TRENDING_ARTICLES': {
      return {
        ...state,
        trendingArticles: payload.trendingArticles.length
          ? sanitizeArticles(payload.trendingArticles)
          : [],
      };
    }
    case 'LOAD_VIDEO_RECOMMENDATIONS': {
      const {model, version, recommendations = []} = payload;
      const videoRecommendations = recommendations.map(addContentType);
      const experimentType = model ? `${model}_${version}` : '';
      const adIndex = state.elements.map((el) => el.content_type).indexOf('ad');
      const insertIndex = Math.min(adIndex + 4, state.elements.length); // Video recommendation should be inserted 3 slots below the first ad element or at the end of the article if there aren't enough paragraghs
      const shouldInsertVideoRecommendation = videoRecommendations.length && adIndex >= 0;
      const elements = shouldInsertVideoRecommendation
        ? [
            ...state.elements.slice(0, insertIndex),
            {
              id: 'recommendedVideo',
              content_type: contentTypes.VIDEO,
              content: {
                metadata: {
                  video_id: videoRecommendations[0].video_id,
                },
              },
            },
            ...state.elements.slice(insertIndex, state.elements.length),
          ]
        : state.elements;

      return {
        ...state,
        elements,
        experimentType,
        videoRecommendations,
      };
    }

    case 'GET_COMMENT_COUNT':
      // payload reflects facebook comment count from Graph API
      return {
        ...state,
        commentCount: payload.share.comment_count,
      };

    case 'UPDATE_CURRENT_SLIDE': {
      return {
        ...state,
        currentSlide: payload,
      };
    }

    case 'SET_TIMEZONE':
      return {
        ...state,
        timezone: payload.timezone,
      };

    default:
      return state;
  }
}

export default articles;
