import React from 'react';
import {DropdownData} from '../types';
import {Variant, Typography} from '../../Shared/Typography/Typography';
import {Color} from '../../types';
import {TeamListing} from './TeamListing/TeamListing';
import {NavLink} from '../../Shared/NavLink/NavLink';
import {Scroll} from '../../Shared/Scroll/Scroll';

export interface DivisionDropdownProps {
  divisions: DropdownData[];
  hideScrollbar?: boolean;
}

export const DivisionDropdown: React.FC<DivisionDropdownProps> = ({
  divisions,
  hideScrollbar = false,
}) => (
  <div className="navBarFonts divisionDropdown">
    <Scroll className="divisionDropdown__scrollContainer" hideScrollbar={hideScrollbar}>
      <div className="divisionDropdown__grid">
        {divisions.map((division: DropdownData) => (
          <div className="divisionDropdown__wrapper" key={division.name}>
            <div className="divisionDropdown__title">
              <Typography color={Color.Disabled} variant={Variant.H6}>
                {division.name}
              </Typography>
            </div>
            {division.teams.map((team) => (
              <div className="divisionDropdown__items" key={team.title}>
                <NavLink href={team.href} key={team.title} variant={Variant.Body1}>
                  <TeamListing team={team.title} icon={team.icon as string} />
                </NavLink>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Scroll>
  </div>
);
