import {createSelector} from 'reselect';
import {nav, ui} from './topLevelSelectors';

export const navData = createSelector(nav, (nav) => {
  return nav;
});

// for a given pathname, determine if the link is 'active'
const findActive = (path) => {
  return (link) => {
    const linkUrl = new URL(link.href, `https://${process.env.BR_HOST}`);

    if (linkUrl.host !== process.env.BR_HOST) {
      // if the link is not under our domain, we know that it will never be 'active'
      return false;
    }

    const links =
      link.children?.links || link.children?.sections?.flatMap((section) => section.teams);

    const isActive = path === linkUrl.pathname;

    if (isActive) {
      return true;
    }

    const isChildActive = links?.some(findActive(path));

    return isChildActive;
  };
};

// finds the 'active' links of each nav level
export const navPath = createSelector([nav, ui], (nav, ui) => {
  if (!nav.mainNavigation || !ui.url) {
    // if the nav hasn't been populated in state, just return an empty array
    return [];
  }

  const pagePath = new URL(ui.url).pathname;

  const main = nav.mainNavigation.links.find(
    // if there is a subnav, use its 'href' to determine activeness instead of the page path
    // this is because the pagepath can be nested in the tag hierarchy and may not have immediate parents in the main navbar
    // the subnav will always represent a value on the main navbar, so we should use that to find the active link
    // ex: seattle-seahawks is not represented on our main navbar, but nfl (its root) is
    findActive(nav.subNavigation?.href || pagePath)
  );
  const sub = nav.subNavigation?.links.find(findActive(pagePath));

  return [main?.title, sub?.title];
});
