import {
  MOBILE_WEB_APP_PROMO_MOUNTED,
  SET_COMMENTS_UPSELL_DATA,
  SET_ALERT_BANNER_DATA,
  SET_SMART_BANNER_DATA,
} from '../constants/actionTypes';
import {DESKTOP as commentsUpsellDesktopVariation} from '../constants/commentsUpsellVariations';

const initialState = {};

const campaignsMapping = {
  facebook: 'SocialPromo',
  twitter: 'SocialPromo',
  send2phone: 'Send2PhonePromo',
};

function promo(state = initialState, action) {
  switch (action.type) {
    case 'SET_FLYIN_INFO': {
      const data = action.payload;
      if (!data) {
        return state;
      }

      const campaign = data.campaign;
      const promoCampaign = campaignsMapping[campaign];

      if (/Send2Phone/.test(promoCampaign)) {
        return {
          ...state,
          [campaign]: {
            data: {
              contentHtml: data.body_text,
              teamColor: data.team_color,
              teamLogo: data.team_logo,
              isValid: false,
            },
            visibility: 'hidden',
          },
        };
      }

      return {
        ...state,
        [campaign]: {
          appInstallLink: data.appInstallLink,
          data: {
            campaign,
            content: data.body_text,
            contentTag: data.body_text_tag,
            ctaText: data.cta_text,
            socialAccount: data.social_account,
            socialUrl: data.social_url,
            showOverlay: data.show_overlay,
            teamColor: data.team_color,
          },
          visibility: 'hidden',
        },
      };
    }

    case SET_SMART_BANNER_DATA: {
      return {
        ...state,
        smartBanner: {
          ...state.smartBanner,
          ...action.payload,
        },
      };
    }

    case 'SHOW_FLYIN':
      if (!state[action.payload]) {
        return state;
      }

      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          visibility: 'visible',
        },
      };

    case 'HIDE_FLYIN':
      if (!state[action.payload]) {
        return state;
      }

      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          visibility: 'hidden',
        },
      };

    case 'UPDATE_FLYIN_DATA': {
      const {campaign, userClosed, visibility} = action.payload;

      if (!state[campaign]) {
        return state;
      }

      return {
        ...state,
        [campaign]: {
          ...state[campaign],
          userClosed,
          visibility,
        },
      };
    }

    case 'SET_PAGE':
      if (action.payload.type === 'app-install') {
        return {
          ...state,
          send2phone: {},
        };
      }
      return state;

    case 'CONTACT_INFO_ENTERED': {
      const {contactInfo, isValid, campaign} = action.payload;

      if (!state[campaign]) {
        return state;
      }

      return {
        ...state,
        [campaign]: {
          ...state[campaign],
          data: {
            ...state[campaign].data,
            contactInfo,
            isValid,
          },
        },
      };
    }

    case 'SEND_DOWNLOAD_APP_LINK':
    case 'UPDATE_MESSAGE':
      return {
        ...state,
        message: action.payload,
      };

    case MOBILE_WEB_APP_PROMO_MOUNTED: {
      return {
        ...state,
        mobileWebAppPromo: action.payload,
      };
    }

    case SET_COMMENTS_UPSELL_DATA:
      const {deepLink, variation} = action.payload;
      const updatedState = {
        ...state,
        commentsUpsell: {
          ...state.commentsUpsell,
          deepLink,
        },
      };
      if (variation === commentsUpsellDesktopVariation) {
        updatedState.send2phone = {};
      }
      return updatedState;

    case SET_ALERT_BANNER_DATA:
      return {
        ...state,
        alertBanner: {
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export default promo;
