import PortmeirionApi from './portmeirion_api';
import StorageAPI from './storage_api';

import logger from '../logger';
import {formatMparticleEventObj} from './helpers/mparticle_helpers';
import {LiveRamp} from '../endpoints';

let baseAnalyticsPayload = {};

const getTrackingId = (uuid, warehouseId) => {
  if (uuid) {
    return PortmeirionApi.login(warehouseId, uuid);
  }
  return PortmeirionApi.identify();
};

const setAnalyticsDefaultPayload = (data) => {
  baseAnalyticsPayload = data;
};

function kruxRetrieve(name) {
  const kname = `kx${name}`;

  try {
    if (global.localStorage) {
      return global.localStorage[kname] || '';
    }
  } catch (exception) {
    logger.info('localStorage is unavailable.', exception);
  }

  if (global.navigator && global.navigator.cookieEnabled) {
    const matches = document.cookie.match(`${kname}=([^;]*)`);
    return (matches && unescape(matches[1])) || '';
  }

  return '';
}

const liveRampSync = () => {
  const wmukid = global.WM && global.WM.PSM.getWMUKID();
  const liveRampWmukid = StorageAPI.get('liveramp_wmukid', true);
  if (!liveRampWmukid) {
    try {
      const script = document.createElement('script');
      script.src = LiveRamp.userSyncPixel(wmukid);
      document.head.appendChild(script);
      StorageAPI.set('liveramp_wmukid', wmukid, {
        maxAge: 86000,
      });
    } catch (err) {
      logger.log('Unable to sync liveramp pixel:', err);
    }
  }
};

const kruxTrack = () => {
  global.Krux ||
    ((global.Krux = function() {
      global.Krux.q.push(arguments);
    }).q = []);
  global.Krux.user = kruxRetrieve('user');
  global.Krux.segments = (kruxRetrieve('segs') && kruxRetrieve('segs').split(',')) || [];
};

let missedMParticleEvents = []; // used by the exported `function track(event)`

const track = (event) => {
  // Exit early in case we do not want to track mParticle
  if (!process.env.MPARTICLE_ENABLED) {
    return null;
  }

  //global.mParticleTagIsReady is set when TagManager loads the mParticle tag.
  if (global.mParticleTagIsReady) {
    // Use default payload unless flag is off
    const basePayload = event.useDefaults === false ? {} : baseAnalyticsPayload;
    const formattedEvent = formatMparticleEventObj({
      ...basePayload,
      ...event,
      platform: 'web',
      url: global.location.href,
    });
    global.mParticle.logEvent(
      formattedEvent.mParticleEventName,
      global.mParticle.EventType.Other,
      formattedEvent
    );
  }

  // This happens when TagManager has not loaded the mParticle tag yet
  // and we need to capture those events
  return missedMParticleEvents.push(event);
};

// This method ensures that tracked events that occur before
// TagManager has fully loaded will be tracked
const trackMissedEvents = () => {
  missedMParticleEvents.forEach((event) => {
    track(event);
  });
  missedMParticleEvents = [];
};

const mParticleModifyIdentity = (identity) => {
  if (!process.env.MPARTICLE_ENABLED) {
    return null;
  }
  if (global.mParticleTagIsReady) {
    const identityRequest = {
      userIdentities: identity,
    };

    global.mParticle.Identity.modify(identityRequest);
  }
};

const onMParticleReady = () => {
  const currentUser = global.mParticle.Identity && global.mParticle.Identity.getCurrentUser();
  const {userIdentities} = currentUser?.getUserIdentities() || {userIdentities: {}};
  const wmukid = global.WM && global.WM.PSM.getWMUKID();
  const isNewWmukid = userIdentities?.other3 !== wmukid;

  if (isNewWmukid && currentUser && wmukid !== 'Unknown') {
    mParticleModifyIdentity({other3: wmukid});
  }

  global.mParticle.config.identifyRequest = {userIdentities};

  trackMissedEvents();
};

const identityCallback = (result, userAttributes) => {
  // Copy attributes from previous user to current user and add additional attributes
  const user = result.getUser();
  user.setUserAttributes({
    ...result.getPreviousUser().getAllUserAttributes(),
    ...userAttributes,
    warehouse_id: StorageAPI.get('portmeirion_id', true) || '',
  });

  // Create and send the alias request
  if (result.getPreviousUser()) {
    const aliasRequest = global.mParticle.Identity.createAliasRequest(
      result.getPreviousUser(),
      result.getUser()
    );
    global.mParticle.Identity.aliasUsers(aliasRequest);
  }
};

const onLogoutCallback = (result) => {
  const user = result.getUser();
  user.setUserAttributes({loggedIn: false});
};

const mParticleLogin = (identity, userAttributes) => {
  if (!process.env.MPARTICLE_ENABLED) {
    return null;
  }

  const trackingId = StorageAPI.get('portmeirion_id', true) || '';

  const identityRequest = {
    userIdentities: {
      customerid: trackingId,
      ...identity,
    },
  };

  if (global.mParticleTagIsReady) {
    global.mParticle.Identity.login(identityRequest, (result) => {
      identityCallback(result, userAttributes);
    });
  }
};

const mParticleLogout = () => {
  if (!process.env.MPARTICLE_ENABLED) {
    return null;
  }

  track({tag_manager_event: 'logout'});

  if (global.mParticleTagIsReady) {
    global.mParticle.Identity.logout({}, onLogoutCallback);
  }
};

const mParticleUpdateAttributes = (userAttributes) => {
  if (!process.env.MPARTICLE_ENABLED || !global.mParticleTagIsReady) {
    return null;
  }
  global.mParticle.Identity.getCurrentUser().setUserAttributes({
    ...userAttributes,
  });
};

const setMparticleConfig = () => {
  const isDevelopmentMode =
    process.env.NODE_ENV !== 'production' && process.env.RELEASE_ENV !== 'production';

  global.mParticle = global.mParticle || {};
  global.mParticle.config = global.mParticle.config || {};
  global.mParticle.config.isDevelopmentMode = isDevelopmentMode;
  global.mParticle.config.useNativeSdk = false; // Force events to be sent via XHR call instead of native SDK when embedded in TS
  global.mParticle.config.rq = global.mParticle.config.rq || [];
};

let mParticleInit = false;
const initMParticle = () => {
  if (process.env.MPARTICLE_ENABLED && !mParticleInit) {
    mParticleInit = true;
    setMparticleConfig();
  }
};

export default {
  getTrackingId,
  kruxTrack,
  liveRampSync,
  initMParticle,
  onMParticleReady,
  setAnalyticsDefaultPayload,
  setMparticleConfig,
  track,
  mParticleLogin,
  mParticleLogout,
  mParticleModifyIdentity,
  mParticleUpdateAttributes,
};
