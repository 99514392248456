import React, {ReactNode, useState, useEffect, useRef} from 'react';
import classnames from 'classnames';

export enum Position {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
}

export interface DrawerProps {
  children: ReactNode;
  position: Position;
  open: Boolean;
  onClose?: (event: MouseEvent | KeyboardEvent) => void;
  hideBackdrop?: boolean;
  disableBodyScroll?: boolean;
  className?: string;
}

export const Drawer: React.FC<DrawerProps> = ({
  children,
  open,
  position,
  onClose = () => {},
  hideBackdrop = false,
  disableBodyScroll = false,
  className,
}) => {
  /*
    The `shouldRender` flag controls whether props.children is rendered to the DOM or not.
    By divorcing this from the 'open' prop, we can make sure we animate out before unmounting.
    It is set to false when the 'onTransitionEnd' SyntheticEvent is fired (when the css transition is compete).
  */
  const [shouldRender, setShouldRender] = useState<Boolean>(open);
  const drawerRef = useRef<HTMLDivElement>();

  const classes = classnames({
    drawer: true,
    [`${className}`]: className,
    [`-${position}`]: position,
    '-open': open,
  });

  function handleClickOutside(event: MouseEvent) {
    if (!drawerRef.current.contains(event.target as Node)) {
      onClose(event);
    }
  }

  function handleEscapeKey(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      onClose(event);
    }
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('mouseup', handleClickOutside);
      document.addEventListener('keyup', handleEscapeKey);
    }
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('keyup', handleEscapeKey);
    };
    // don't add listeners to dependency array as these functions change on each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open) {
      setShouldRender(true);
    }
  }, [open]);

  useEffect(() => {
    if (disableBodyScroll && open) {
      document.body.classList.add('-noScroll');
    } else if (disableBodyScroll && !open) {
      // prevent a closed drawer from overriding an open drawer's 'noScroll' behaviour
      document.body.classList.remove('-noScroll');
    }
  }, [disableBodyScroll, open]);

  function handleTransitionEnd() {
    if (!open) {
      setShouldRender(false);
    }
  }

  return (
    <>
      {!hideBackdrop && <div className={classnames({drawer__backDrop: true, '-open': open})} />}
      <div className={classes} onTransitionEnd={handleTransitionEnd} ref={drawerRef}>
        {shouldRender && <div className="drawer__childrenContainer">{children}</div>}
      </div>
    </>
  );
};
