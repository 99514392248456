import {parseVideoElements} from './helpers/video_helpers';
import {removeScriptTag} from './helpers/sanitize_helpers';
import {LOAD_COMMENT_DATA_FOR_STUB, LOAD_TRACK_DATA_FOR_STUB} from '../constants/actionTypes';
import {parseImages} from '../helpers/imageHelpers';

function stub(state = {}, action) {
  if (action.error) {
    throw action.error;
  }
  const payload = action.payload;
  switch (action.type) {
    case LOAD_COMMENT_DATA_FOR_STUB: {
      const {comments = {}, likes = {}} = payload;
      return {
        ...state,
        comments: comments.data,
        numberOfComments: comments.count,
        numberOfLikes: likes.count,
      };
    }

    case 'LOAD_STUB_DATA': {
      const elements = JSON.parse(removeScriptTag(JSON.stringify(payload.stub.elements || [])));
      const parsedElements = elements.map(parseVideoElements).map(parseImages);

      return {
        ...state,
        ...action.payload.stub,
        elements: parsedElements,
        ...action.payload.tag,
      };
    }

    case 'LOAD_STUB_METADATA':
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          [payload.videoId]: {
            ...parseImages(payload.videoMetadata),
          },
        },
      };
    case 'LOAD_POLL_DATA':
      return {
        ...state,
        poll_data: {
          ...state.poll_data,
          [payload.pollId]: {
            ...payload.pollData,
          },
        },
      };
    case 'LOAD_BET_TRACK_DATA':
      return {
        ...state,
        bet_track_data: {
          ...state.bet_track_data,
          [payload.betTrackId]: {
            ...payload.betTrackData,
          },
        },
      };
    case 'LOAD_LIVE_VIDEO_METADATA':
      return {
        ...state,
        live_video_metadata: {
          ...state.live_video_metadata,
          [payload.eventId]: payload.liveVideoMetadata,
        },
      };

    case 'LOAD_PPV_PRODUCTS': {
      const {products = [], eventId} = payload;
      const eventMetadata = state.live_video_metadata[eventId];
      const gizmoProduct = products.find(
        (product) => product.gizmoProductId === eventMetadata.product?.sku
      );

      if (gizmoProduct) {
        return {
          ...state,
          gizmoProduct,
        };
      }

      return state;
    }

    case 'SET_TIMEZONE':
      return {
        ...state,
        timezone: payload.timezone,
      };

    case LOAD_TRACK_DATA_FOR_STUB: {
      return {
        ...state,
        tracks: payload,
      };
    }

    default:
      return state;
  }
}

export default stub;
