const meta = {
  'aol-te-auth': '1c424580-0f86-4d9b-88b2-bc8c0d029d4c',
  blitz: 'mu-6e4ce5cd-57f20d11-7c0ecee9-d55c79e2',
  'msvalidate.01': '7A63840181953B2A5A1FEA25FB45A991',
  robots: 'NOODP,NOYDIR',
  'verify-v1': '+Ntj422Jc4V03qgBqLYbF3LMvrursV0X2btn2Zoqn9w=',
  description:
    'Fan easier, fan faster and fan better with Bleacher Report. Keep up with the latest storylines, expert analysis, highlights and scores for all your favorite sports.',
  viewport: 'width=device-width, initial-scale=1',
  'fb:app_id': '135174055162',
  'al:iphone:url': 'teamstream://',
  'al:iphone:app_store_id': '418075935',
  'al:iphone:app_name': 'Bleacher Report',
  'al:ipad:url': 'teamstream://',
  'al:ipad:app_store_id': '484725748',
  'al:ipad:app_name': 'Bleacher Report',
  'al:android:url': '',
  'al:android:app_name': 'Bleacher Report',
  'al:android:package': 'com.bleacherreport.android.teamstream',
  'al:web:url': 'https://bleacherreport.com/',
  'og:site_name': 'Bleacher Report',
  'og:locale': 'en_US',
  'p:domain_verify': '0c768466449ebb550411234d6d4ffc30',
  'theme-color': '#000', // android chrome toolbar color
  'twitter:app:name:iphone': 'Bleacher Report',
  'twitter:app:id:iphone': '418075935',
  'twitter:app:name:ipad': 'Bleacher Report',
  'twitter:app:id:ipad': '484725748',
  'twitter:app:name:googleplay': 'Bleacher Report',
  'twitter:app:id:googleplay': 'com.bleacherreport.android.teamstream',
  'twitter:widgets:new-embed-design': 'on',
  'twitter:site': '@bleacherreport',
};

export default meta;
