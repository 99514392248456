import React from 'react';
import {format} from 'date-fns';

const noop = () => {};
const formatTime = (ms) => format(ms, 'm:ss');

function FreePreviewTimer({config}) {
  const {show, label, onClick, displayTime} = config;

  if (!show) return null;

  if (displayTime < 0) return null;
  return (
    <div onClick={label === 'Start Preview' ? onClick : noop}>
      <span className="countdown">{formatTime(displayTime)}</span>
      <span className="preview-label">{label}</span>
    </div>
  );
}

export default FreePreviewTimer;
