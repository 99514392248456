import {createFetchService} from './fetch_utils';
import {Dalton} from '../endpoints';
import {get} from '../apis/storage_api';
import * as jwtAPI from './jwt_api';
import logger from '../logger';

const postText = createFetchService('Dalton', {responseFormat: 'text'});
const postJSON = createFetchService('Dalton');
const fetchJSON = createFetchService('Dalton');

const errorHandler = (err) => {
  return {
    error: err,
  };
};

const createClientInfoHeader = (userAgent) => {
  const brUser = get('br_user', true);
  let brUserInfo;
  if (!brUser) {
    return {};
  }

  try {
    brUserInfo = JSON.parse(Buffer.from(brUser, 'base64'));
  } catch (error) {
    return error;
  }

  const {device, os, family, major, minor, patch} = userAgent;
  const deviceString =
    device.family === 'Other'
      ? device.family
      : `${device.family} ${device.major}.${device.minor}.${device.patch}`;
  // device|OS|client|user
  return {
    'X-Client-Application':
      `${deviceString}|` +
      `${os.family} ${os.major}.${os.minor}.${os.patch}|` +
      `${family} ${major}.${minor}.${patch}|` +
      `${brUserInfo.id}`,
  };
};

export const getAuthToken = async (userAgent) => {
  const accessToken = get('br_jwt', true);

  if (accessToken) {
    const body = {credential: accessToken};
    const opts = {
      method: 'POST',
      body,
      headers: createClientInfoHeader(userAgent),
    };
    return postText(Dalton.auth(), opts).catch(errorHandler);
  }
  return 'No accessToken passed to Dalton API';
};

export const chargeUser = async ({daltonToken, purchaseData, userAgent}) => {
  logger.log('==>> Charge user - Dalton token:', daltonToken);
  const body = {...purchaseData};
  const opts = {
    method: 'POST',
    body,
    daltonToken,
    headers: createClientInfoHeader(userAgent),
  };

  return postJSON(Dalton.chargeUser(), opts).catch(errorHandler);
};

export const getUserEntitlements = async (daltonToken, userAgent) => {
  const opts = {
    daltonToken,
    method: 'POST',
    headers: createClientInfoHeader(userAgent),
  };
  return postJSON(Dalton.entitlements(), opts).catch(errorHandler);
};

export const getEmails = async (daltonToken, userAgent) => {
  return fetchJSON(Dalton.emails(), {
    daltonToken,
    headers: createClientInfoHeader(userAgent),
  }).catch(errorHandler);
};

export const getViewAuth = async ({daltonToken, entitlements, mediaId, userAgent}) => {
  const body = {
    organization: 'bleacherreport',
    mediaIds: [mediaId],
    preAuthzs: entitlements.preAuthorizedEntitlements,
  };

  const opts = {
    daltonToken,
    body,
    method: 'POST',
    headers: createClientInfoHeader(userAgent),
  };
  return postJSON(Dalton.preAuthToken(), opts).catch(errorHandler);
};

export const fetchGizmoProducts = async (userAgent) => {
  try {
    return fetchJSON(Dalton.products(), {
      headers: createClientInfoHeader(userAgent),
    });
  } catch (err) {
    return errorHandler(err);
  }
};

export const getUserProfile = async (daltonToken) => {
  return fetchJSON(Dalton.userProfile(), {daltonToken}).catch(errorHandler);
};

export const fetchPPVEntitlementById = async (mediaId, userAgent) => {
  try {
    await jwtAPI.checkAndRefreshJWT();
    const daltonToken = await getAuthToken(userAgent);
    const entitlements = await getUserEntitlements(daltonToken, userAgent);
    const viewAuth = await getViewAuth({
      daltonToken,
      entitlements,
      mediaId,
      userAgent,
    });

    return viewAuth?.results;
  } catch (err) {
    return errorHandler(err);
  }
};
