const getNanoSeconds = () => {
  const hr = process.hrtime();
  return hr[0] * 1e9 + hr[1];
};

/**
 * Returns a function which tells you the time elapsed since the app started up.
 *
 * @returns {number} milliseconds since the app started
 */
const getNow = () => {
  let loadTime;
  if (process && process.hrtime) {
    // we're in Node.
    loadTime = getNanoSeconds();
    return () => {
      return (getNanoSeconds() - loadTime) / 1e6;
    };
  } else if (Date.now) {
    // Maybe really old versions of Node or older browsers.
    loadTime = Date.now();
    return () => {
      Date.now() - loadTime;
    };
  }
  // very old browsers with no support for Date.now
  loadTime = new Date().getTime();
  return () => {
    new Date().getTime() - loadTime;
  };
};

const now = getNow();

export {now};
