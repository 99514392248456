import {Static} from '../endpoints';

const links = [
  {key: 'manifest', rel: 'manifest', href: '/manifest.json'},
  {
    key: 'safariMaskIcon',
    rel: 'mask-icon',
    href: '/img/favicon/safariMaskIcon.svg',
    color: '#000000',
  },
  {
    key: 'appleTouchIcon',
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/img/favicon/appleTouchIcon.png',
  },
  {key: 'icon32', rel: 'icon', sizes: '32x32', href: '/img/favicon/favicon32.png'},
  {key: 'icon16', rel: 'icon', sizes: '16x16', href: '/img/favicon/favicon16.png'},
  {key: 'prefetch-adnxs-ib', rel: 'dns-prefetch', href: 'https://ib.adnxs.com'},
  {key: 'prefetch-adnxs-prebid', rel: 'dns-prefetch', href: 'https://prebid.adnxs.com'},
  {key: 'prefetch-adnxs-secure', rel: 'dns-prefetch', href: 'https://secure.adnxs.com'},
  {
    key: 'prefetch-rubicon-fastlane',
    rel: 'dns-prefetch',
    href: 'https://fastlane.rubiconproject.com',
  },
  {
    key: 'prefetch-rubicon-optimizedby',
    rel: 'dns-prefetch',
    href: 'https://optimized-by.rubiconproject.com',
  },
  {key: 'prefetch-rubicon-ads', rel: 'dns-prefetch', href: 'https://ads.rubiconproject.com'},
  {
    key: 'prefetch-rubicon-fastlaneadv',
    rel: 'dns-prefetch',
    href: 'https://fastlane-adv.rubiconproject.com',
  },
  {key: 'prefetch-amazon-aax', rel: 'dns-prefetch', href: 'https://aax.amazon-adsystem.com'},
  {key: 'prefetch-amazon-c', rel: 'dns-prefetch', href: 'https://c.amazon-adsystem.com'},
  {key: 'prefetch-criteo-bidder', rel: 'dns-prefetch', href: 'https://bidder.criteo.com'},
  {key: 'prefetch-criteo-static', rel: 'dns-prefetch', href: 'https://static.criteo.net'},
  {key: 'prefetch-indexww', rel: 'dns-prefetch', href: 'https://js-sec.indexww.com'},
  {key: 'prefetch-casale', rel: 'dns-prefetch', href: 'https://as-sec.casalemedia.com'},
  {key: 'prefetch-observe-aniview', rel: 'dns-prefetch', href: 'https://observe.aniview.com'},
  {key: 'prefetch-outbrain', rel: 'dns-prefetch', href: 'https://mcdp-chidc2.outbrain.com'},
  {key: 'prefetch-outbrainimg', rel: 'dns-prefetch', href: 'https://log.outbrainimg.com'},
  {
    key: 'prefetch-outbrain-videoclientsservicescalls',
    rel: 'dns-prefetch',
    href: 'https://videoclientsservicescalls.outbrain.com',
  },
  {key: 'prefetch-vid-play-serv', rel: 'dns-prefetch', href: 'https://serv.vid-play.com'},
  {key: 'prefetch-vid-play-servt', rel: 'dns-prefetch', href: 'https://servt.vid-play.com'},
  {key: 'prefetch-track-aniview', rel: 'dns-prefetch', href: 'https://track.aniview.com'},
  {
    key: 'prefetch-bleacherreport-pistyll',
    rel: 'dns-prefetch',
    href: 'https://pistyll.bleacherreport.com',
  },
  {key: 'prefetch-moatads', rel: 'dns-prefetch', href: 'https://geo.moatads.com'},
  {
    key: 'prefetch-truste-preferences',
    rel: 'dns-prefetch',
    href: 'https://preferences.truste.com',
  },
  {key: 'prefetch-vi-serve-vis', rel: 'dns-prefetch', href: 'https://vis.vi-serve.com'},
  {key: 'prefetch-vi-serve-t', rel: 'dns-prefetch', href: 'https://t.vi-serve.com'},
  {key: 'prefetch-vi-serve-s', rel: 'dns-prefetch', href: 'https://s.vi-serve.com'},
  {
    key: 'prefetch-imrworldwide-secure-us',
    rel: 'dns-prefetch',
    href: 'https://secure-us.imrworldwide.com',
  },
  {key: 'prefetch-facebook', rel: 'dns-prefetch', href: 'https://www.facebook.com'},
  {key: 'prefetch-facebook-connect', rel: 'dns-prefetch', href: 'https://connect.facebook.net'},
  {
    key: 'prefetch-adsafeprotected',
    rel: 'dns-prefetch',
    href: 'https://pixel.adsafeprotected.com',
  },
  {key: 'prefetch-instagram', rel: 'dns-prefetch', href: 'https://www.instagram.com'},
  {
    key: 'prefetch-instagram-platform',
    rel: 'dns-prefetch',
    href: 'https://platform.instagram.com',
  },
  {key: 'prefetch-tiqcdn-tags', rel: 'dns-prefetch', href: 'https://tags.tiqcdn.com'},
  {
    key: 'prefetch-cloudfront',
    rel: 'dns-prefetch',
    href: 'https://cadmus.script.ac/d2uap9jskdzp2/script.js',
  },
  {
    key: 'preconnect-cloudfront',
    rel: 'preconnect',
    href: 'https://cadmus.script.ac/d2uap9jskdzp2/script.js',
  },
  {
    key: 'preconnect-bleacherreportnet-static-assets',
    rel: 'preconnect',
    href: 'https://static-assets.bleacherreport.net',
  },
  {
    key: 'prefetch-bleacherreportnet-static-assets',
    rel: 'dns-prefetch',
    href: 'https://static-assets.bleacherreport.net',
  },
  {
    key: 'preconnect-bleacherreportnet-cdn',
    rel: 'preconnect',
    href: 'https://cdn.bleacherreport.net',
  },
  {
    key: 'prefetch-bleacherreportnet-cdn',
    rel: 'dns-prefetch',
    href: 'https://cdn.bleacherreport.net',
  },
  {
    key: 'preconnect-bitmovin-cdn',
    rel: 'preconnect',
    href: 'https://cdn.bitmovin.com',
  },
  {
    key: 'prefetch-bitmovin-cdn',
    rel: 'dns-prefetch',
    href: 'https://cdn.bitmovin.com',
  },
  {
    key: 'prefetch-ugdturner',
    rel: 'dns-prefetch',
    href: 'https://www.ugdturner.com',
  },
  {
    key: 'prefetch-krxd-cdn',
    rel: 'dns-prefetch',
    href: 'https://cdn.krxd.net',
  },
  {
    key: 'prefetch-i-cdn-turner',
    rel: 'dns-prefetch',
    href: 'https://i.cdn.turner.com',
  },
  {
    key: 'prefetch-jssdkcdns-mparticle',
    rel: 'dns-prefetch',
    href: 'https://jssdkcdns.mparticle.com',
  },
  {
    key: 'prefetch-platform-twitter',
    rel: 'dns-prefetch',
    href: 'https://platform.twitter.com',
  },
  {
    as: 'font',
    key: 'prefetch-proxima-nova-regular-woff2',
    rel: 'preload',
    href: Static.assets('/fonts/proxima-nova/proxima-nova-regular.woff2'),
  },
  {
    as: 'font',
    key: 'prefetch-proxima-nova-regular-italic-woff2',
    rel: 'preload',
    href: Static.assets('/fonts/proxima-nova/proxima-nova-regular-italic.woff2'),
  },
  {
    as: 'font',
    key: 'prefetch-proxima-nova-medium-woff2',
    rel: 'preload',
    href: Static.assets('/fonts/proxima-nova/proxima-nova-medium.woff2'),
  },
  {
    as: 'font',
    key: 'prefetch-proxima-nova-bold-woff2',
    rel: 'preload',
    href: Static.assets('/fonts/proxima-nova/proxima-nova-bold.woff2'),
  },
  {
    as: 'font',
    key: 'prefetch-effra-regular-woff2',
    rel: 'preload',
    href: Static.assets('/fonts/effra/effra-regular.woff2'),
  },
  {
    as: 'font',
    key: 'prefetch-effra-medium-woff2',
    rel: 'preload',
    href: Static.assets('/fonts/effra/effra-medium.woff2'),
  },
  {
    as: 'font',
    key: 'prefetch-effra-bold-woff2',
    rel: 'preload',
    href: Static.assets('/fonts/effra/effra-bold.woff2'),
  },
];

export default links;
