import Boom from 'boom';
import {createAction} from 'redux-actions';

import FaustAPI from '../apis/faust_api';
import LayserAPI from '../apis/layser_api';
import StatMilkAPI from '../apis/statmilk/schedules_api';
import {Leagues} from '../constants/leagues';

export const LOAD_TODAY_GAMES = 'LOAD_TODAY_GAMES';

export const loadSchedule = createAction('LOAD_TEAM_SCHEDULE', async (team) => {
  const [scheduleData, tagInfoArray, layserData] = await Promise.all([
    StatMilkAPI.fetchGamesByTeam(team),
    FaustAPI.getTagInfo(team),
    LayserAPI.section(team),
  ]);
  if (!scheduleData.schedule_groupings || !scheduleData.schedule_groupings.length) {
    throw Boom.notFound(`No games found for: ${team}`);
  }
  return {
    adData: layserData.page || layserData.page_data,
    schedule: scheduleData,
    tags: tagInfoArray,
    team,
  };
});
const noGames = {schedules: null, league: ''};
const checkScheduleData = (scheduleData) => {
  return !scheduleData || !scheduleData.game_groups || !scheduleData.game_groups.length;
};
const fetchTodayGames = async (league) => {
  const context = league && league === Leagues.COLLEGE_BASKETBALL ? 'CBBD1ConferenceGroup' : '';
  const scheduleData = await StatMilkAPI.fetchTodayGames(league, context);
  const noData = checkScheduleData(scheduleData);
  return {
    scheduleData,
    noData,
  };
};

const sectionPagesCarouselAPI = async (league, team, context) => {
  const scheduleData = await StatMilkAPI.fetchScoresCarouel(league, team, context);
  const noData = checkScheduleData(scheduleData);
  return {
    scheduleData,
    noData,
  };
};
const fetchScoresCarousel = async (league, team) => {
  const context = team && team !== 'none' ? 'team' : 'league';
  let result = await sectionPagesCarouselAPI(league, team, context);

  if (context === 'team' && result.noData) {
    result = await sectionPagesCarouselAPI(league, team, 'league');
  }
  return result;
};
export const loadTodayGames = createAction(LOAD_TODAY_GAMES, async (league, team) => {
  let search = league;
  let result = league ? await fetchScoresCarousel(league, team) : await fetchTodayGames(league);
  if (league && league.length > 0 && result.noData) {
    // To get top games if there is no relevent games to the league
    result = await fetchTodayGames();
    search = '';
  }
  if (result.noData) {
    return noGames;
  }

  return {schedules: result.scheduleData, league: search};
});

export const showTeamScheduleMonth = createAction('SHOW_TEAM_SCHEDULE_MONTH');
