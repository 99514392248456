import {createAction} from 'redux-actions';
import VidAPI from '../apis/vid_api';
import LayserAPI from '../apis/layser_api';
import {hideAds} from './adsActions';

const VIDEO_ID_REGEX = /^\b\d+\b/g;

const getTrackByName = createAction('GET_TRACK_BY_NAME', async (name) => {
  return await LayserAPI.stream(name);
});

const mountData = (trackName) => (dispatch) => {
  return dispatch(getTrackByName(trackName));
};

const loadTrendingVideos = createAction('LOAD_VIDEO_METADATA', async () => {
  const videoMetadata = await LayserAPI.fetchTrendingVideos();
  const promises = [];
  videoMetadata.trending.forEach((video) => {
    promises.push(VidAPI.fetchVideo(video.video_id));
  });
  const data = await Promise.all(promises);
  return {
    videoMetadata,
    data,
  };
});

const getTrendingVideos = () => async (dispatch) => {
  return await dispatch(loadTrendingVideos());
};

const sanitizeVideoId = (videoID) => {
  if (!videoID) {
    return false;
  }
  const id_match = videoID.match(VIDEO_ID_REGEX);
  const id = id_match ? id_match[0] : false;
  return id;
};

const loadVideoData = createAction('LOAD_VIDEO_DATA', async (videoID) => {
  const id = sanitizeVideoId(videoID);
  if (!id) {
    throw new Error('Incorrect or No video id passed to loadVideoData');
  }
  const videoData = await VidAPI.fetchVideo(id);
  if (videoData.error) {
    throw videoData.error;
  }
  return {
    ...videoData,
  };
});

const fetchVideoData = (videoID) => {
  return (dispatch) => {
    dispatch(hideAds());
    return dispatch(loadVideoData(videoID));
  };
};

function isBlendableTag(tag) {
  return (
    tag.unique_name === 'nfl' ||
    tag.unique_name === 'fantasy-football' ||
    tag.unique_name === 'nba' ||
    tag.unique_name === 'college-football' ||
    tag.unique_name === 'world-football' ||
    tag.unique_name === 'e-sports'
  );
}

function appendV(tag) {
  return `${tag.unique_name}_v`;
}

const loadVideoPlaylist = createAction('LOAD_VIDEO_PLAYLIST', async (tags) => {
  if (tags[0].content_type === 'video') {
    //track list received from video home page
    return {tracks: tags};
  }
  const blendableTag = tags.find(isBlendableTag);

  // we only blend article tags if an "approved" tag is present otherwise we use the default "featured_v"
  const playlistTags = blendableTag ? tags.map(appendV).join(',') : 'featured_v';

  return await LayserAPI.stream(playlistTags, 20);
});

const loadVideoMetadataData = createAction('LOAD_VIDEO_METADATA', async (videoId) => {
  const videoMetadata = await VidAPI.fetchVideo(videoId);
  return {
    videoMetadata,
    videoId,
  };
});

// TODO AR-265 optimize how params get passed
const playSpecificVideo = createAction(
  'PLAY_SPECIFIC_VIDEO',
  async (player, selectedVideo, playlist) => {
    return {
      player,
      selectedVideo,
      playlist,
    };
  }
);

const loadSpecificVideo = (player, selectedVideo, playlist, loadNewData) => {
  return (dispatch) => {
    if (loadNewData) {
      return dispatch(loadVideoMetadataData(selectedVideo)).then(() => {
        dispatch(playSpecificVideo(player, selectedVideo, playlist));
      });
    }
    return dispatch(playSpecificVideo(player, selectedVideo, playlist));
  };
};

// TODO AR-265 optimize how params get passed
const playNextVideo = createAction(
  'PLAY_NEXT_PLAYLIST_VIDEO',
  async (player, playlistClicked, playlist) => {
    return {
      player,
      playlistClicked,
      playlist,
    };
  }
);

export {
  fetchVideoData,
  getTrackByName,
  getTrendingVideos,
  loadSpecificVideo,
  loadVideoMetadataData,
  loadVideoPlaylist,
  mountData,
  playNextVideo,
  playSpecificVideo,
};
