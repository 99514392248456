import {createSelector} from 'reselect';
import {page, profiles, user, tagsDictionary} from './topLevelSelectors';

const EDIT_THIS_ARTICLE = 'EDIT_THIS_ARTICLE';
const FORCE_RECACHE = 'FORCE_RECACHE';
const VIEW_ADMIN_MENU = 'VIEW_ADMIN_MENU';
const VIEW_ARTICLE_HISTORY = 'VIEW_ARTICLE_HISTORY';
const VIEW_WRITER_MENU = 'VIEW_WRITER_MENU';

export const isLoggedInUser = createSelector(
  user,
  (user) => user.type && user.type !== 'Anonymous'
);

const permissions = createSelector(user, (userState) => userState.permissions || []);
const isAdmin = createSelector([permissions], (permissions) =>
  permissions.includes(VIEW_ADMIN_MENU)
);
const isWriter = createSelector([permissions], (permissions) =>
  permissions.includes(VIEW_WRITER_MENU)
);
const canEdit = createSelector([permissions], (permissions) =>
  permissions.includes(EDIT_THIS_ARTICLE)
);
const canRecache = createSelector([permissions], (permissions) =>
  permissions.includes(FORCE_RECACHE)
);
const canViewHistory = createSelector([permissions], (permissions) =>
  permissions.includes(VIEW_ARTICLE_HISTORY)
);

export const selectCurrentProfile = createSelector([page, profiles], (page, profiles) => {
  if (page.type === 'profile') {
    const info = profiles[page.id];
    return {
      photo_url: info.photo_path,
      name: `${info.first_name} ${info.last_name}`,
      twitter_handle: info.twitter_handle,
      id: info.id,
      title: info.title,
      username: info.username,
      tracks: info.tracks,
      bio: info.bio,
    };
  }
  return {};
});

export const selectPermissions = createSelector(
  [isAdmin, isWriter, canEdit, canRecache, canViewHistory],
  (isAdmin, isWriter, canEdit, canRecache, canViewHistory) => {
    return {
      isAdmin,
      isWriter,
      canEdit,
      canRecache,
      canViewHistory,
    };
  }
);

export const userTags = createSelector([user], (user) => user.tags);

export const tagsWithMetadata = createSelector(
  [userTags, tagsDictionary],
  (userTags, tagsDictionary) => {
    return userTags.filter((tag) => {
      return tagsDictionary && tagsDictionary[tag];
    });
  }
);
