import React, {useCallback, useRef} from 'react';

export interface AutoHeightProps {
  children: React.ReactNode;
}

export const AutoHeight: React.FC<AutoHeightProps> = ({children}) => {
  const parentRef = useRef<HTMLDivElement>();

  const setupObserver = useCallback((element: Element) => {
    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      const height = entries[0].contentRect?.height;
      if (parentRef.current) {
        parentRef.current.style.height = `${height}px`;
      }
    });

    observer.observe(element);
  }, []);

  return (
    <div className="autoheight" ref={parentRef}>
      <div ref={setupObserver}>{children}</div>
    </div>
  );
};
