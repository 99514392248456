import {Static, BR} from '../endpoints';
import Assets from '../../assets.json';

import * as CommentsUpsellVariations from '../constants/commentsUpsellVariations';
import {
  STORE_APP_VERSION,
  SET_TVE_AUTH_CONTEXT,
  SET_TVE_PROVIDER_LOGO,
  SET_TEALIUM_LOAD_RULES,
  DISABLE_USER_CONSENT_SCRIPT,
} from '../constants/actionTypes';
import * as PAGE_TYPES from '../constants/pageTypes';

const bundleName = Assets.bundle ? Assets.bundle.js : 'bundle.js';

const initialState = {
  assetURL: Static.assets(),
  blurArticleLeadImage: process.env.BLUR_ARTICLE_LEAD_IMAGE === 'true',
  bundleName,
  comments: true,
  disableFbSignup: process.env.FB_SIGNUP_DISABLED === 'true',
  oneTrust: {
    scriptUrl: process.env.ONE_TRUST_SCRIPT_URL,
    domainScript: process.env.ONE_TRUST_DOMAIN_SCRIPT,
  },
  scrollPlay: true,
  showCommentsUpsell: process.env.SHOW_COMMENTS_UPSELL === 'true',
  showCommentsUpsellInApp: process.env.SHOW_COMMENTS_UPSELL_IN_APP === 'true',
  showEmailSignup: process.env.SHOW_EMAIL_SIGNUP === 'true',
  showNewsletterSubsModule: process.env.SHOW_NEWSLETTER_SUBS_MODULE === 'true',
  showRecommendedArticlesCarousel: process.env.SHOW_RECOMMENDED_ARTICLES_CAROUSEL === 'true',
  showScoreCardCarousel: process.env.SHOW_SCORE_CARD_CAROUSEL === 'true',
  showTve: process.env.TVE_ENABLED === 'true',
  showWebAlerts: process.env.SHOW_WEB_ALERTS,
  skinAd_exists: true,
  teamStreamLimit: 60,
  teamStreamLoading: false,
  topAd_exists: true,
  trendingLimit: 60,
  tveAuthContext: {authBlock: null, context: null},
  tveProviderLogo: '',
  userAgent: {},
  videosInViewport: [],
};

const REGEX_GOOGLEBOT = /googlebot/i;
const REGEX_GOOGLE_APP_PACKAGE_ID = /\bcom\.google\.android\.googlequicksearchbox\b/i;
const REGEX_GOOGLE_SEARCH_APP_UA = /GSA\/[.\d]* Mobile/;
const REGEX_PROTOCOL_GOOGLE_DOMAIN = /\/\/[^/]*\bgoogle(apis)?\.com\//i;
const REGEX_UA_FACEBOOK = /fban|fbios|fbforiphone|messengerforios/i;
const REGEX_UA_INSTAGRAM = /\bInstagram\b/;
const REGEX_UA_TWITTER_APP = /twitter( for iphone|android)/i;

export function detectCustomBrowser(userAgent) {
  if (REGEX_UA_INSTAGRAM.test(userAgent)) {
    return 'instagram';
  }
  if (REGEX_UA_FACEBOOK.test(userAgent)) {
    return 'facebook';
  }
  if (REGEX_UA_TWITTER_APP.test(userAgent)) {
    return 'twitter';
  }
  return false;
}

function isGoogleBot(ua) {
  return REGEX_GOOGLEBOT.test(ua.family);
}

function isGoogleSearchApp(ua, referrer) {
  return REGEX_GOOGLE_SEARCH_APP_UA.test(ua.family) || REGEX_GOOGLE_APP_PACKAGE_ID.test(referrer);
}

function isReferredByGoogle(referrer) {
  return REGEX_PROTOCOL_GOOGLE_DOMAIN.test(referrer);
}

function isFirstPageViewOfSecondSession(state) {
  return (
    state.user &&
    state.user.session &&
    state.user.session.count === 2 &&
    state.user.session.isFirstPageView
  );
}

function isFromGoogle(state) {
  const UI = state.ui;
  // Note: We do not want the interstitial to show when it is the google crawler,
  // viewed in the google search app, or referred by google
  return (
    isGoogleBot(UI.userAgent) || isGoogleSearchApp(UI.userAgent) || isReferredByGoogle(UI.referrer)
  );
}

export function isAndroid(ua = {}) {
  const {os = {}} = ua;
  return os.family === 'Android';
}

export function isIos(ua = {}) {
  const {os = {}} = ua;
  return os.family === 'iOS';
}

function isMobile(ua) {
  return isAndroid(ua) || isIos(ua);
}

function isSlideShowArticle(state) {
  return state.page.render_strategy === 'slideshow';
}

function shouldShowAndroidTsPromo(state) {
  return state && state.ui && state.page
    ? isAndroid(state.ui.userAgent) &&
        state.page.type !== 'brShell' &&
        isMobile(state.ui.userAgent) &&
        (isGoogleSearchApp(state.ui.userAgent) || isReferredByGoogle(state.ui.referrer)) &&
        !isGoogleBot(state.ui.userAgent) &&
        (state.page.type === 'section' || state.page.type === 'article') &&
        !state.hasClosedAndroidPromo &&
        !state.page.tsm &&
        !state.page.tst &&
        state.page.embedded !== 'true' &&
        !(state.page.fromNonCNNPartner || state.page.fromCNNApp)
    : false;
}

function determineCommentsUpsellVariation(state = {}) {
  const {ui} = state;
  if (ui) {
    if (ui.isEmbedded) {
      return CommentsUpsellVariations.EMBEDDED;
    }

    if (isIos(ui.userAgent)) {
      return CommentsUpsellVariations.IOS;
    }

    if (isAndroid(ui.userAgent)) {
      return CommentsUpsellVariations.ANDROID;
    }
    return CommentsUpsellVariations.DESKTOP;
  }
  return '';
}

function shouldShowMobileInterstitial(state) {
  return state && !state.hideSkin && state.page && state.notification && state.ui
    ? (state.page.type === 'article' &&
        state.page.zone !== 'video' &&
        state.notification.mobile_interstitial &&
        isMobile(state.ui.userAgent) &&
        !isFromGoogle(state) &&
        !state.page.tsm &&
        !state.page.tst &&
        state.page.embedded !== 'true' &&
        !state.user.session.isFirstPageView &&
        !(state.page.fromNonCNNPartner || state.page.fromCNNApp)) ||
        state.page.force_promo
    : false;
}

function shouldShowMobileWebAppPromo(state = {}) {
  const {hideSkin, notification = {}, ui = {}} = state;

  return (
    !hideSkin &&
    ui.isMobileDevice &&
    notification.mobile_web_app_promo &&
    !notification.mobile_interstitial &&
    (isSlideShowArticle(state) || isFirstPageViewOfSecondSession(state))
  );
}

function shouldShowSmartBanner(state) {
  const showingInterstitial = shouldShowMobileInterstitial(state);
  const showingAndroidTsPromo = shouldShowAndroidTsPromo(state);

  return state && state.notification && state.page && state.user && state.ui
    ? (state.notification.smart_banner &&
        state.page.type !== 'brShell' &&
        // don't show banner in article pages if the insterstitial is visible
        (state.page.type !== 'article' ||
          (state.page.type === 'article' && !showingInterstitial)) &&
        // Don't show banner in article or section page if the android promo is visible
        (state.page.type === 'article' || state.page.type === 'section') &&
        !showingAndroidTsPromo &&
        // Don't show banner on slideshow articles
        state.page.zone !== 'slideshow' &&
        isMobile(state.ui.userAgent) &&
        !state.page.tsm &&
        !state.page.tst &&
        state.page.embedded !== 'true' &&
        state.page.sic !== 'ngtv' &&
        state.page.sic !== 'partner') ||
        (state.notification.smart_banner &&
          (state.page.type !== 'article' ||
            (state.page.type === 'article' && !showingInterstitial)) &&
          isMobile(state.ui.userAgent) &&
          // Don't show banner in article or section page if the android promo is visible
          (state.page.type === 'article' || state.page.type === 'section') &&
          !showingAndroidTsPromo &&
          state.page.fromCNNApp)
    : false;
}

function shouldShowNewsletterSubsModule(state) {
  return state && state.ui && state.ui.showNewsletterSubsModule && !state.ui.isEmbedded;
}

function isEmbeddedMode(state, queryParams) {
  return (
    state.isEmbedded ||
    queryParams.tsm === '1' ||
    queryParams.tsm === '2' ||
    queryParams.tst === '1' ||
    queryParams.tst === '2' ||
    queryParams.embedded === 'true'
  );
}

function ui(state = initialState, action = {}) {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case 'FETCH_AND_STORE_PARAMS':
      return {
        ...state,
        params: action.payload,
      };

    case 'LOAD_TODAY_GAMES': {
      const {schedules} = action.payload;
      const hasGameGroups = schedules && schedules.game_groups && schedules.game_groups.length > 0;
      return {
        ...state,
        showScoreCardCarousel: process.env.SHOW_SCORE_CARD_CAROUSEL === 'true' && hasGameGroups,
      };
    }

    case 'HIDE_ADS':
      return {
        ...state,
        topAd_exists: false,
        skinAd_exists: false,
      };

    case 'UPDATE_SELECTED_STREAM':
      return {
        ...state,
        teamStreamSelected:
          action.payload.section === 'all' ? action.payload.streamName : action.payload.section,
      };

    case 'INCREMENT_LIMITS': {
      const newState = {};
      let trendingLimit = (action.payload && action.payload.trendingLimit) || 0;
      let teamStreamLimit = (action.payload && action.payload.tsLimit) || 0;
      if (action.payload && action.payload.trendingLimit) {
        newState['trendingLimit'] = trendingLimit += 20;
      }
      newState['teamStreamLimit'] = teamStreamLimit += 20;
      return {
        ...state,
        ...newState,
      };
    }

    case 'LOAD_SECTION_DATA': {
      if (action.payload.format === 'editorial') {
        return {
          ...state,
          scrollPlay: false,
        };
      }
      return state;
    }

    case 'LOADER_STATUS':
      return {
        ...state,
        teamStreamLoading: action.payload,
      };

    case 'RESET_LIMITS':
      return {
        ...state,
        tsLimit: 60,
        trendingLimit: 60,
      };

    case 'SET_EMBEDDED_MODE': {
      const {isEmbedded, isSettingCookie} = action.payload;
      if (isEmbedded) {
        return {
          ...state,
          isEmbedded: true,
          hideFooterLinks: true,
          hideNav: true,
          hideShare: true,
          isSettingCookie,
        };
      }
      return {
        ...state,
        isEmbedded,
        isSettingCookie,
      };
    }

    case 'SET_FROM_CNN':
      return {
        ...state,
        isFromCnn: action.payload,
      };

    case 'SET_UI_SHOW_DATA': {
      const payload = action.payload;
      return {
        ...state,
        commentsUpsellVariation: determineCommentsUpsellVariation(payload),
        show_mobile_interstitial: shouldShowMobileInterstitial(payload),
        show_mobile_web_app_promo: shouldShowMobileWebAppPromo(payload),
        showNewsletterSubsModule: shouldShowNewsletterSubsModule(payload),
        show_smart_banner: shouldShowSmartBanner(payload),
        show_android_ts_promo: shouldShowAndroidTsPromo(payload),
        show_email_confirmation:
          payload && payload.notification && payload.notification.email_confirmation,
      };
    }

    case 'SET_PAGE': {
      const defaults = {
        ...state,
        theme: action.payload.theme || 'light',
        pageType: action.payload.type,
        pageSubType: action.payload.subType,
        currentPage: action.payload.id,
        url: action.payload.url,
      };
      switch (action.payload.type) {
        case PAGE_TYPES.MINIMAL:
          return {
            ...state,
            ...defaults,
            bundleName: Assets.minimal ? Assets.minimal.js : 'minimal.js',
          };

        case 'brShell':
          return {
            ...defaults,
            brHost: `https://${BR.host()}`,
            // Use custom bundleName for brShell page
            bundleName: 'bundle.shell.js', // When debugging brShell locally you might want to comment this out
          };

        case PAGE_TYPES.YAHOO_SYNC:
          return {
            ...defaults,
            topAd_exists: false,
          };

        case 'accountConfirmed':
        case PAGE_TYPES.RECAPTCHA_VERIFICATION:
        case 'choosePassword':
        case 'login':
        case 'signup':
          return {
            ...defaults,
            brHost: `https://${BR.host()}`,
            show_mobile_interstitial: false,
            show_android_ts_promo: false,
            skinAd_exists: false,
            theme: 'dark',
            topAd_exists: false,
            redirectUrl: action.payload.redirectUrl,
          };

        case PAGE_TYPES.USER_POST:
          return {
            ...defaults,
            bundleName: '/js/minimal.js',
            show_privacy_notice: true,
          };

        case 'app-install':
          return {
            ...defaults,
            isEmbedded: true,
            hideNav: false,
            skinAd_exists: false,
            topAd_exists: false,
          };

        case PAGE_TYPES.NEWSLETTER_SUBSCRIPTION:
          return {
            ...defaults,
            hideFooter: true,
            hideNav: false,
            skinAd_exists: false,
            topAd_exists: false,
          };

        case PAGE_TYPES.CHECKOUT:
          return {
            ...defaults,
            skinAd_exists: false,
            topAd_exists: false,
          };

        case PAGE_TYPES.VIDEO:
          return {
            ...state,
            pageType: action.payload.pageType,
          };

        case PAGE_TYPES.HOME:
          return {
            ...defaults,
            adTest: true, //remove this line when adTest is over
            skinAd_exists: false,
          };

        case PAGE_TYPES.BETSIFRAME:
          return {
            ...defaults,
            hideFooter: true,
            hideNav: true,
            skinAd_exists: false,
            topAd_exists: false,
          };

        case PAGE_TYPES.GAMECAST:
          return {
            ...defaults,
            skinAd_exists: false,
          };

        case PAGE_TYPES.MY_EVENTS:
          return {
            ...defaults,
            skinAd_exists: false,
            topAd_exists: false,
          };

        case PAGE_TYPES.STATIC:
          if (
            action.payload.id === PAGE_TYPES.COMMUNITY_GUIDELINES ||
            action.payload.id === PAGE_TYPES.COMMUNITY_CAPTAINS
          ) {
            return {
              ...defaults,
              skinAd_exists: false,
              topAd_exists: false,
            };
          }
          return defaults;

        default:
          return defaults;
      }
    }

    case 'SET_VIDEO_PAGE_TYPE': {
      return {
        ...state,
        videoPageType: action.payload,
      };
    }

    case 'UPDATE_VIDEOS_IN_VIEWPORT': {
      const [videoID, isInViewport] = action.payload;
      const videosInViewport = state.videosInViewport.slice(0);
      const alreadyInArray = videosInViewport.includes(videoID);
      const index = videosInViewport.indexOf(videoID);

      // if it's already in the array but no longer in the viewport
      if (alreadyInArray && !isInViewport && index !== -1) {
        videosInViewport.splice(index, 1);
      }

      // if it's not in the array and now in the viewport
      if (!alreadyInArray && isInViewport) {
        videosInViewport.push(videoID);
      }

      return {
        ...state,
        videosInViewport,
      };
    }

    case STORE_APP_VERSION: {
      return {
        ...state,
        appVersion: action.payload,
      };
    }

    case 'STORE_COUNTRY_CODE': {
      return {
        ...state,
        countryCode: action.payload,
      };
    }

    case 'STORE_REGION_CODE': {
      return {
        ...state,
        regionCode: action.payload,
      };
    }

    case 'STORE_QUERY_PARAMS': {
      // A note on security:
      // Data should always be derived and or validated.
      // We don't store this data *directly* because _then_ it would be in any serialization
      // of the data. That would be a big security risk (hello XSS).
      const queryParams = action.payload;
      const isEmbedded = isEmbeddedMode(state, queryParams);
      const fromPartnerSite =
        queryParams.sic === 'ngtv' ||
        queryParams.sic === 'cnn-app' ||
        queryParams.sic === 'partner';
      const hideSomeThings = isEmbedded || fromPartnerSite;

      return {
        ...state,
        hideGettyCopyright: fromPartnerSite,
        hideFooterLinks: hideSomeThings,
        hideNav: hideSomeThings,
        hideShare: hideSomeThings,
        hide: {
          ...state.hide,
        },
        isEmbedded,
        // Comments should use the previous state or not shown if this is from a partner site
        comments: state.comments || !fromPartnerSite,
      };
    }

    case 'STORE_UA':
      return {
        ...state,
        customBrowser: detectCustomBrowser(action.payload.source),
        isMobileDevice: isMobile(action.payload),
        os: isAndroid(action.payload) ? 'android' : isIos(action.payload) ? 'ios' : null,
        userAgent: action.payload,
      };

    case 'STORE_REFERRER':
      return {
        ...state,
        referrer: action.payload.replace(/[<>]/g, ''),
      };

    case 'STORE_UNAVAILABLE_IMAGE_URL':
      return {
        ...state,
        unavailableImageUrl: action.payload,
      };

    case SET_TVE_AUTH_CONTEXT:
      return {
        ...state,
        tveAuthContext: action.payload.tveAuthContext,
      };

    case SET_TVE_PROVIDER_LOGO:
      return {
        ...state,
        tveProviderLogo: action.payload.tveProviderLogo,
      };

    case SET_TEALIUM_LOAD_RULES:
      return {
        ...state,
        tagManagerLoadRules: action.payload,
      };

    case DISABLE_USER_CONSENT_SCRIPT:
      return {
        ...state,
        disableUserConsentScript: true,
      };

    default:
      return state;
  }
}

export default ui;
