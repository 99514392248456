import React from 'react';
import {Button} from '@br/br-components';
import classnames from 'classnames';

const Overlay = ({text = '', secondaryText = '', onReloadClicked, classes}) => {
  return (
    <div className={classnames('atom overlay', classes)}>
      <div className="text-wrapper">
        <div className="primary-text">{text}</div>
        <div className="secondary-text">{secondaryText}</div>
      </div>
      {onReloadClicked && <Button onClick={onReloadClicked}>Reload</Button>}
    </div>
  );
};

export default Overlay;
