import React, {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import classnames from 'classnames';

export enum ScrollDirection {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export interface ScrollProps {
  children?: ReactNode;
  scrollDirection?: ScrollDirection;
  className?: string;
  style?: React.CSSProperties;
  hideScrollbar?: boolean;
}

export const Scroll = ({
  children,
  scrollDirection = ScrollDirection.Vertical,
  className,
  style,
  hideScrollbar = false,
}: ScrollProps) => {
  const classes = classnames({
    [`${className}`]: className,
    scroll: true,
  });
  const [observer, setObserver] = useState<IntersectionObserver>();
  const [isStartHidden, setIsStartHidden] = useState(true);
  const [isEndHidden, setIsEndHidden] = useState(true);
  const startRef = useRef();
  const endRef = useRef();

  useEffect(() => {
    observer?.observe(startRef.current);
    observer?.observe(endRef.current);

    return () => {
      observer?.disconnect();
    };
  }, [observer]);

  const setupScroll = useCallback((element: Element) => {
    const interceptorOptions = {root: element, threshold: 0};
    // eslint-disable-next-line no-use-before-define
    setObserver(new IntersectionObserver(handleIntersection, interceptorOptions));
  }, []);

  function handleIntersection(value: IntersectionObserverEntry[]) {
    for (const entry of value) {
      if (entry.target === startRef.current) {
        setIsStartHidden(!entry.isIntersecting);
      } else if (entry.target === endRef.current) {
        setIsEndHidden(!entry.isIntersecting);
      }
    }
  }

  return (
    <div className={classes} style={style}>
      {isStartHidden && <div className={`scroll__blur -${scrollDirection} -start`} />}
      {isEndHidden && <div className={`scroll__blur -${scrollDirection} -end`} />}
      <div
        ref={setupScroll}
        className={classnames({
          scroll__childrenContainer: true,
          [`-${scrollDirection}`]: true,
          '-hideScrollbar': hideScrollbar,
        })}
      >
        <div ref={startRef} className="scroll__boundary" />
        {children}
        <div ref={endRef} className="scroll__boundary" />
      </div>
    </div>
  );
};
