import {createAction} from 'redux-actions';
import jwtDecode from 'jwt-decode';
import {addDays} from 'date-fns';

import * as AccountAPI from '../apis/account_api';
import * as AppActions from './appActions';
import * as jwtAPI from '../apis/jwt_api';
import * as RegisterAPI from '../apis/register_api';
import * as StorageAPI from '../apis/storage_api';
import * as VidApi from '../apis/vid_api';
import * as DaltonAPI from '../apis/dalton_api';
import * as BrazeAPI from '../apis/braze_api';
import LayserAPI from '../apis/layser_api';
import {GET_DALTON_TOKEN, GET_DALTON_EMAILS} from '../constants/actionTypes';
import layser_api from '../apis/layser_api';
import AnalyticsAPI from '../apis/analytics_api';

export function setUserCookies({auth, user, userEncoded}) {
  StorageAPI.setAccountCookies({
    auth: {
      access_token: auth.token,
      refresh_token: auth.refresh,
    },
    user,
  });

  if (auth && !user) {
    // if we have auth but no user we should clear the user cookie
    // we'll get updated data next refresh
    StorageAPI.remove('br_user', true);
  }
  return {auth, user, userEncoded};
}

function formatUserData(data = {}) {
  const accessToken = data.access_token || StorageAPI.get('br_jwt', true);
  let identities = data.identities || {};

  if (accessToken) {
    identities = jwtDecode(accessToken).identities;
  }

  return {
    ...data,
    name: `${data.first_name} ${data.last_name}`,
    identities,
  };
}

export const add = createAction('ADD_USER', (user) => formatUserData(user));
export const update = createAction('UPDATE_USER', async ({info, userID}) => {
  return AccountAPI.updateUserInfo({info, userID}).then((data) => {
    StorageAPI.remove('br_user', true);
    return formatUserData(data);
  });
});

export const updateUserEmail = createAction('UPDATE_USER_EMAIL', ({info, userID}) => {
  return AccountAPI.updateUserEmail({info, userID}).then(async (data) => {
    // Need to get the new JWT because identities on the current token is outdated
    await jwtAPI.updateAuth();
    return data;
  });
});

export const linkFB = createAction('LINK_FACEBOOK', async ({accessToken, userID}) => {
  if (!accessToken || !userID) {
    return Promise.reject(new Error('Missing FB oauth data.'));
  }

  await AccountAPI.linkFacebook(accessToken, userID).then((data) => {
    setUserCookies({
      auth: {
        token: data.access_token,
        refresh: data.refresh_token,
      },
    });
    return data;
  });

  return {
    facebook_id: userID,
  };
});

export const authEmail = (data, userAgent) => async () => {
  // note: no dispatch here. Not technically an action.
  const authData = await AccountAPI.authEmail(data).then((data) => {
    return {
      auth: {
        token: data.access_token,
        refresh: data.refresh_token,
      },
    };
  });
  setUserCookies(authData);

  const authToken = await DaltonAPI.getAuthToken(userAgent);
  const userProfile = await DaltonAPI.getUserProfile(authToken);
  const uid = userProfile?.tid || '';
  const {sub: gatekeeper_id} = jwtDecode(authData?.auth?.token);

  AnalyticsAPI.mParticleLogin(
    {
      email: data?.email,
      other2: uid,
    },
    {
      loggedIn: true,
      gatekeeper_id,
      account_verified: true,
      signup_source: 'email',
    }
  );
};

export const validateName = createAction('VERIFY_NAMES', async ({name, value}) => {
  return await RegisterAPI.validateName(name, value);
});

// Bulk tags endpoint (PUT)
export const saveUserTags = (userTags, userId) => async () => {
  return await AccountAPI.updateUserTags(userTags, userId);
};

export const updateUserTags = (tags) => {
  return (dispatch, getState) => {
    const state = getState();
    const actions = [
      dispatch(AppActions.loadTagsMetadata(tags)),
      dispatch(add({...state.user, tags})),
      StorageAPI.remove('br_user', true),
    ];

    return Promise.all(actions);
  };
};

export const getUser = (userId, jwt, refresh) => {
  return async (dispatch) => {
    // Update auth if refresh token is available before fetching private user data
    const newJWT = refresh ? await jwtAPI.updateAuth(refresh) : jwt;
    const data = await AccountAPI.fetchUser(userId, newJWT, refresh);
    const {identities} = jwtDecode(newJWT);
    const userData = {
      ...data.user,
      identities,
    };

    return Promise.all([
      dispatch(add(userData)),
      dispatch(AppActions.loadTagsMetadata(userData.tags)),
    ]);
  };
};

export const attemptFbLogin = (data) => async () => {
  const {accessToken, userID} = data;

  if (!accessToken || !userID) {
    return Promise.reject(new Error('Missing FB oauth data.'));
  }

  const payload = {
    facebook: {
      device: 'web',
      token: accessToken,
      uid: userID,
    },
  };

  return AccountAPI.fetchUserJWTViaFbAuth(payload).then((data) => {
    setUserCookies({
      auth: {
        token: data.access_token,
        refresh: data.refresh_token,
      },
    });
    return data;
  });
};

export const changePassword = ({password1, password2, token}) => async () => {
  if (password1 !== password2) {
    return Promise.reject(new Error('passwords do not match'));
  }

  return await AccountAPI.changePassword({token, password1});
};

export const setOneTrustPreferences = createAction('SET_ONE_TRUST_PREFERENCES', (preferences) => {
  // Here we set global variables so that the rest of the code can use this
  globalThis.videoAnalytics = !!preferences.performance;
  globalThis.videoAds = !!preferences.advertising;
  return preferences;
});
export const setPasswordResetToken = createAction('SET_RESET_PASSWORD_TOKEN');

export const requestPhoneVerify = createAction('REQUEST_PHONE_VERIFY', async (phone, userID) => {
  return AccountAPI.authPhone(phone, userID);
});

export const storeVisitorCountry = createAction('STORE_VISITOR_COUNTRY');

export const submitPhoneVerify = createAction('SUBMIT_PHONE_VERIFY', async (data) => {
  return AccountAPI.verifyPhone(data).then((data) => {
    const {sub: id} = jwtDecode(data.access_token);
    setUserCookies({
      auth: {
        token: data.access_token,
        refresh: data.refresh_token,
      },
    });
    StorageAPI.set('br_userid', id, {ttl: StorageAPI.oneMonth});
    return data;
  });
});

// The following is a thunk but dispatches no actions!
// Probably shouldn't be here (or should be refactored to BE actions.)
export const requestPasswordReset = ({email}) => async () => {
  return await AccountAPI.requestPasswordReset({email}).then((data) => data);
};

const REGEX_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const loadUserProfile = createAction('LOAD_USER_PROFILE', async (uid) => {
  if (REGEX_UUID.test(uid)) {
    const userData = await AccountAPI.getProfileFromUuid(uid);
    const latestWrittenArticles = await AccountAPI.getLatestWrittenFromUuid(uid);
    return {
      user: userData.user,
      articles: latestWrittenArticles,
    };
  }
  const userData = await AccountAPI.getProfile(uid);
  const latestWrittenArticles = await AccountAPI.getLatestWritten(uid);
  return {
    user: userData.user,
    articles: latestWrittenArticles,
  };
});

export const redirectUser = (redirectingUrl) => {
  return async (_dispatch, getState) => {
    const {page} = getState();

    if (page.returnUrl) {
      // We need to set the br_user before we redirect because the br_user is set on the server
      const {sub} = jwtDecode(StorageAPI.get('br_jwt', true));
      const user = await AccountAPI.fetchUser(sub);
      StorageAPI.setAccountCookies(user);
      StorageAPI.remove('returnUrl', true);
      const queryParams = page.embedded ? '?tsm=1' : '';
      global.window.location.replace(`${page.returnUrl}${queryParams}`);
    } else if (redirectingUrl) {
      global.window.location.replace(redirectingUrl);
    } else {
      global.window.location = '/';
    }
  };
};

export const setOrbisSession = createAction('SET_ORBIS_SESSION');

/**
 * @name registerOrbisAnonymousUser
 */

export const registerOrbisAnonymousUser = () => {
  return async (dispatch) => {
    let orbisSession = StorageAPI.get('orbis_session', true);
    orbisSession = orbisSession ? JSON.parse(orbisSession) : {};

    if (orbisSession.sessionToken) {
      dispatch(setOrbisSession(orbisSession));
      return;
    }

    // Step 1: Register an anonymous user with Orbis API:
    const {
      account: {uid},
    } = await VidApi.registerAnonymousUser();

    // Step 2: Login an anonymous user with the Orbis API:
    const {sessionToken} = await VidApi.authenticateAnonymousUser(uid);
    orbisSession = {
      userID: uid,
      sessionToken,
    };

    const expires = addDays(new Date(), 60);
    StorageAPI.set('orbis_session', JSON.stringify(orbisSession), {
      expires,
    });

    dispatch(setOrbisSession(orbisSession));
  };
};

const getDaltonAuthTokenAction = createAction(GET_DALTON_TOKEN);

export const getDaltonAuthToken = () => async (dispatch, getState) => {
  const {userAgent} = getState().ui;
  await jwtAPI.checkAndRefreshJWT();
  const authToken = await DaltonAPI.getAuthToken(userAgent);
  return dispatch(getDaltonAuthTokenAction(authToken));
};

const getDaltonEmailsAction = createAction(GET_DALTON_EMAILS);

export const getDaltonEmails = async (daltonToken) => async (dispatch, getState) => {
  const {userAgent} = getState().ui;
  await jwtAPI.checkAndRefreshJWT();
  const emails = await DaltonAPI.getEmails(daltonToken, userAgent);
  return dispatch(getDaltonEmailsAction(emails));
};

export const subscribeToNewsletter = async (emailAddress) => {
  return await LayserAPI.newsletterSubscription(emailAddress);
};

const getPreAuthEntitlementsAction = createAction('GET_PREAUTH_ENTITLEMENTS');

export const getPreAuthEntitlements = async (daltonToken) => async (dispatch, getState) => {
  const {userAgent} = getState().ui;
  const preAuthEntitlements = await DaltonAPI.getUserEntitlements(daltonToken, userAgent);
  return dispatch(getPreAuthEntitlementsAction(preAuthEntitlements));
};

const getUserEntitlementsAction = createAction('GET_USER_ENTITLEMENTS');

export const getUserEntitlements = async (daltonToken, mediaId) => async (dispatch, getState) => {
  const {userAgent} = getState().ui;
  const entitlements = await DaltonAPI.getUserEntitlements(daltonToken, userAgent);
  const viewAuth = await DaltonAPI.getViewAuth({daltonToken, entitlements, mediaId, userAgent});
  return dispatch(getUserEntitlementsAction(viewAuth.results));
};

const fetchPPVEntitlementByIdAction = createAction('FETCH_PPV_ENTITLEMENT_BY_ID');

export const fetchPPVEntitlementById = async (mediaId) => async (dispatch, getState) => {
  const {userAgent} = getState().ui;
  const entitlements = await DaltonAPI.fetchPPVEntitlementById(mediaId, userAgent);
  return Promise.resolve(dispatch(fetchPPVEntitlementByIdAction(entitlements)));
};

export const saveUserEntitlements = createAction('SAVE_USER_ENTITLEMENTS');

export const getMyEvents = createAction('GET_MY_EVENTS', async (products) => {
  const productIds = products.map((product) => product.universalProductIndicator);
  return await layser_api.fetchMyEvents(productIds);
});

const chargeUserAction = createAction('CHARGE_USER');

export const chargeUser = ({purchaseData, daltonToken}) => async (dispatch, getState) => {
  const {userAgent} = getState().ui;
  const charges = await DaltonAPI.chargeUser({purchaseData, daltonToken, userAgent});
  return Promise.resolve(dispatch(chargeUserAction(charges)));
};

const updateSubscriptionGroupAction = createAction('UPDATE_SUBSCRIPTION_GROUP');

export const updateSubscriptionGroup = (data) => async (dispatch) => {
  const subscriptionGroup = await BrazeAPI.setSubscriptionGroupByUsersTrack(data);
  return Promise.resolve(dispatch(updateSubscriptionGroupAction(subscriptionGroup)));
};
