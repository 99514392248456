import {createSelector} from 'reselect';
import {page} from './topLevelSelectors';

function determineGooglePageType({team, league, render_strategy, site, type}) {
  if (render_strategy === 'slideshow') {
    return 'slideshow';
  }
  if (type === 'article') {
    return 'article';
  }
  if (type === 'static' || type === 'login' || type === 'signup' || type === 'profile') {
    return 'other';
  }
  if (team && team !== 'none') {
    return 'team';
  }
  if (league) {
    return 'section';
  }
  if (site === 'home_page') {
    return 'home_page';
  }
  return 'other';
}

export const analyticsPageType = createSelector([page], (page) => determineGooglePageType(page));
