const determineTimezoneAbbreviation = () => {
  let abbreviation;
  try {
    abbreviation = new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];
  } catch (_error) {
    abbreviation = 'EST';
  }
  return abbreviation;
};

const DEFAULT_TIMEZONE = 'America/New_York';

const determineTimezone = () => {
  const datetimeOptions =
    global.Intl && global.Intl.DateTimeFormat && new global.Intl.DateTimeFormat().resolvedOptions();
  if (datetimeOptions && datetimeOptions.timeZone) {
    return datetimeOptions.timeZone;
  }
  return DEFAULT_TIMEZONE;
};

export {determineTimezoneAbbreviation, determineTimezone};
