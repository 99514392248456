import React, {useState, useRef, useEffect} from 'react';
import {Button} from '../../Shared/Button/Button';
import {BrLogo} from '../../Icons/BrLogo/BrLogo';
import {NavBarLink, Navigation} from '../types';
import {Color, UserType} from '../../types';
import {AccountSideMenu} from '../AccountSideMenu/AccountSideMenu';
import {SubNavBar} from '../SubNavBar/SubNavBar';
import {Drawer, Position} from '../../Shared/Drawer/Drawer';
import {NavigationTabs} from '../NavigationTabs/NavigationTabs';

export interface DesktopNavBarProps {
  navigation: Navigation;
  injectH1?: Boolean;
  userType?: UserType;
  profileId?: Number;
  path: string[];
  handleTrackEvent: (eventName: string) => void;
}

export const DesktopNavBar: React.FC<DesktopNavBarProps> = ({
  navigation,
  injectH1,
  userType,
  profileId,
  path,
  handleTrackEvent,
}) => {
  const {mainNavigation, subNavigation} = navigation;
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [initialValue, setInitialValue] = useState<string | null>(path[0]);
  const [value, setValue] = useState<string | null>(path[0]);
  const accountDropdownRef = useRef(null);
  const accountButtonRef = useRef(null);
  const hideAccountOption = process.env.HIDE_ACCOUNT_OPTION === 'true';

  const LogoElement = injectH1 ? 'h1' : 'div';

  // when the page unloads, reset our values so that they aren't stored in bfcache
  function handlePageHide() {
    setInitialValue(null);
    setValue(null);
  }

  useEffect(() => {
    window.addEventListener('pagehide', handlePageHide);
    return () => window.removeEventListener('pagehide', handlePageHide);
  }, []);

  function handleAccountClick() {
    setShowAccountDropdown(!showAccountDropdown);
  }

  function handleAccountClose() {
    setShowAccountDropdown(false);
  }

  function handleLogoClick() {
    setInitialValue(null);
    setValue(null);
  }

  function handleLinkClick(link: NavBarLink) {
    if (link.eventName) {
      handleTrackEvent(link.eventName);
    }
  }

  return (
    <>
      <div>
        <div className="desktopNavBar" ref={accountDropdownRef}>
          <a className="desktopNavBar__brLogo" onClick={handleLogoClick} href="/">
            <LogoElement className="desktopNavBar__logoWrapper">
              <BrLogo />
            </LogoElement>
          </a>

          {mainNavigation && (
            <NavigationTabs
              links={mainNavigation.links}
              initialValue={initialValue}
              value={value}
              // eslint-disable-next-line react/jsx-no-bind
              setValue={(key: string) => setValue(key)}
              // eslint-disable-next-line react/jsx-no-bind
              setInitial={(key: string) => setInitialValue(key)}
              onLinkClick={handleLinkClick}
            />
          )}

          {!hideAccountOption && (
            <div className="desktopNavBar__button">
              <Button
                onMouseUp={handleAccountClick}
                color={showAccountDropdown ? Color.Secondary : Color.Primary}
                ref={accountButtonRef}
                aria-label="Toggle Account Navigation Menu"
              >
                Account
              </Button>
            </div>
          )}
        </div>

        {!hideAccountOption && (
          <div className="desktopNavBar__drawerWrapper">
            <Drawer
              open={showAccountDropdown}
              position={Position.Right}
              hideBackdrop={true}
              onClose={handleAccountClose}
            >
              <AccountSideMenu userType={userType} profileId={profileId} />
            </Drawer>
          </div>
        )}

        {subNavigation && (
          <div className="desktopNavBar__subnav">
            <SubNavBar
              title={subNavigation.title}
              links={subNavigation.links}
              href={subNavigation.href}
              value={path[1]}
              onLinkClick={handleLinkClick}
            />
          </div>
        )}
      </div>
      <hr className="desktopNavBar__nav-border" />
    </>
  );
};
