// this is where we'll hold any 3rd party scripts (if they exist) and their loading state.
const scriptCache = {};

// This function simply turns the script object from the store into a DOM-native script tag
// then it injects it as the last child of the Head element. The Browser will do the rest.
const appendScript = (scriptConf) => {
  const script = document.createElement('script');
  if (scriptConf.noSrc) {
    script.innerHTML = scriptConf.innerHTML;
    script.id = scriptConf.key;
    document.head.appendChild(script);
    scriptConf.onLoad();
    return;
  }
  if (!scriptConf || !scriptConf.src) {
    return;
  }
  script.src = scriptConf.src;
  script.async = scriptConf.async;
  script.defer = scriptConf.defer;
  script.onload = scriptConf.onLoad;
  script.id = scriptConf.key;
  if (scriptConf.data) {
    Object.keys(scriptConf.data).map((key) => {
      if (script.dataset) {
        script.dataset[key] = scriptConf.data[key];
      } else {
        script.setAttribute(`data-${key}`, scriptConf.data[key]);
      }
    });
  }
  document.head.appendChild(script);
};

// This first checks to see if the script NEEDS to be loaded by checking the cache.
// If it does need to be loaded then set sets a simple onLoad event on the object
// (while preserving the original onLoad) to update the cache after load.
const loadScripts = (config) => {
  if (scriptCache[config.key] !== 'loaded' && scriptCache[config.key] !== 'pending') {
    scriptCache[config.key] = 'pending';
    appendScript({
      ...config,
      onLoad: (...args) => {
        scriptCache[config.key] = 'loaded';
        if (config.onLoad) {
          config.onLoad(...args);
        }
      },
    });
  }
};

// Listener function that is triggered on every store update.
export const handleScripts = (store) => {
  let lastScripts;
  return () => {
    const scripts = store.getState().template.scripts;
    if (scripts !== lastScripts) {
      scripts.map(loadScripts);
      lastScripts = scripts;
    }
  };
};

// Allows adding scripts to the cache with an already loaded state.
export const primeScriptCache = (scripts) => {
  scripts.map((config) => {
    scriptCache[config.key] = 'loaded';
  });
};
