import {Gatekeeper, Articles} from '../endpoints';
import * as JWTAPI from '../apis/jwt_api';
import {createFetchService} from './fetch_utils';
import StorageAPI from '../apis/storage_api';
import {isValidEmail} from '../validations';

const fetchJSON = createFetchService('Account API');
const postJSON = createFetchService('Account API', {method: 'POST'});
const putJSON = createFetchService('Account API', {method: 'PUT', responseFormat: 'json'});
const putText = createFetchService('Account API', {method: 'PUT', responseFormat: 'text'});
const postText = createFetchService('Account API', {method: 'POST', responseFormat: 'text'});
const deleteJSON = createFetchService('Account API', {method: 'DELETE'});

export function authPhone(phone, userID) {
  const jwt = StorageAPI.get('br_jwt', true);
  return postJSON(Gatekeeper.authorizePhone(userID), {
    body: {
      phone: {
        country: 1, // only supporting US atm so hardcoding.
        phone,
      },
    },
    jwt,
  }).catch((err) => {
    throw err;
  });
}

export const verifyPhone = ({phone, code, userID}) => {
  const jwt = StorageAPI.get('br_jwt', true);
  return postJSON(Gatekeeper.verifyPhone(userID), {
    body: {
      phone: {
        country: 1,
        phone,
        verification_code: code,
      },
    },
    jwt,
  }).catch((err) => {
    throw err;
  });
};

export const fetchUserJWTViaFbAuth = (body) => {
  return postJSON(Gatekeeper.authorizeFacebookUser(), {body});
};

export function updateUserEmail({info, userID}) {
  const jwt = StorageAPI.get('br_jwt', true);
  const body = {
    user: {
      ...info,
    },
  };

  return putJSON(Gatekeeper.userEmail(userID), {body, jwt}).then((data) => data.user);
}

export function updateUserInfo({info, userID}) {
  const jwt = StorageAPI.get('br_jwt', true);
  const body = {
    user: {
      ...info,
    },
  };

  return putJSON(Gatekeeper.user(userID, false), {body, jwt}).then((data) => data.user);
}

export function authEmail({email, password}) {
  const grant = {
    grant_type: 'password',
    password,
    username: email,
  };

  return JWTAPI.fetchJWT(grant);
}

export function changePassword({token, password1}) {
  const jwt = StorageAPI.get('br_jwt', true);

  if (!token || !password1) {
    return Promise.reject(new Error('Missing data.'));
  }

  const body = {
    user: {
      password: password1,
      password_confirmation: password1,
    },
  };

  return putText(Gatekeeper.passwordResetsWithToken(token), {body, jwt})
    .then((data) => data)
    .catch((err) => {
      throw err;
    });
}

export function requestPasswordReset({email}) {
  const jwt = StorageAPI.get('br_jwt', true);

  if (!email || !isValidEmail(email)) {
    return Promise.reject(new Error('Missing or invalid email.'));
  }

  return postText(Gatekeeper.passwordResets(), {body: {user: {email}}, jwt});
}

export function getProfile(id) {
  return fetchJSON(Gatekeeper.userProfile(id));
}

export function getProfileFromUuid(id) {
  return fetchJSON(Gatekeeper.user(id, false));
}

export function linkFacebook(token, uid) {
  const jwt = StorageAPI.get('br_jwt', true);
  return postJSON(Gatekeeper.linkFacebookUser(), {
    body: {
      facebook: {
        token,
        uid,
      },
    },
    jwt,
  })
    .then((data) => data)
    .catch((err) => {
      throw err;
    });
}

export function unlinkFacebook(device) {
  const jwt = StorageAPI.get('br_jwt', true);
  return deleteJSON(Gatekeeper.authorizeFacebookUser(), {body: {device}, jwt})
    .then((data) => data)
    .catch((err) => {
      throw err;
    });
}

export const updateUserTags = (userTags, userId) => {
  const jwt = StorageAPI.get('br_jwt', true);
  return putText(Gatekeeper.userTags(userId), {
    body: {
      tag_ids: userTags,
    },
    jwt,
  });
};

export const fetchUser = (userId, jwt, refresh) => {
  if (userId) {
    if (jwt) {
      return fetchJSON(Gatekeeper.userPrivate(userId), {
        jwt,
        refresh,
      });
    }
    return fetchJSON(Gatekeeper.user(userId));
  }
  return Promise.reject(new Error('No User ID passed to Account API'));
};

export const getLatestWritten = (userID) => {
  return fetchJSON(Articles.latestWritten(userID))
    .then((data) => data)
    .catch(() => {
      // When user has written no articles it returns a 404
      return;
    });
};

export const getLatestWrittenFromUuid = (uuid) => {
  return fetchJSON(Articles.latestWrittenByUuid(uuid)).then((data) => data);
};
