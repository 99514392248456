import React, {ReactNode} from 'react';

export interface TabProps {
  value: string; // property used on <Tabs/> to determine current active Tab to move indicator
  children: ReactNode;
}

// Using this component to compare `value` prop on <Tabs/>.
// Help us determine current tab
export const Tab: React.FC<TabProps> = ({children}) => {
  return <>{children}</>;
};
