import React from 'react';
import classnames from 'classnames';
import {PREVIEW} from '../../../constants/gameStatus';
import {Static} from '../../../endpoints';

import BrLogo from '../../atoms/brLogo';

const TeamContent = ({team, status, isEmpty}) => {
  const commonClass = classnames({
    shine: isEmpty,
    winner: !isEmpty && team.is_winner,
  });
  const classes = {
    logo: classnames('logo', commonClass),
    teamName: classnames('teamName', commonClass),
    score: classnames('score', commonClass),
    rank: 'rank',
  };

  const caretLeftIcon = `${Static.images()}/atoms/svg/icons/caret-left.svg`;
  const info = {
    logo: '',
    teamName: '',
    score: '',
  };
  if (!isEmpty) {
    info.logo =
      team.logo && team.logo.length > 0 ? (
        <img alt={`${team.abbrev}-logo`} src={team.logo} />
      ) : (
        <BrLogo />
      );
    info.teamName = team.abbrev;
    info.rank = team.rank || '';
    const record = team.record || '';
    const isPreview = status === PREVIEW;
    info.score = !isPreview ? team.score : record;
  }

  return (
    <div className="teamContainer">
      <div className={classes.logo}>{info.logo}</div>
      <div className={classes.rank}>{info.rank}</div>
      <div className={classes.teamName}>{info.teamName}</div>
      <div className={classes.score}>{info.score}</div>
      {!isEmpty && team.is_winner && (
        <img alt="left-icon" className="leftIcon" src={caretLeftIcon} />
      )}
    </div>
  );
};
export default TeamContent;
