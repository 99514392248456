function video(state = {}, action) {
  if (action.error) {
    throw action.error;
  }
  switch (action.type) {
    case 'LOAD_VIDEO_DATA': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

export default video;
