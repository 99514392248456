import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {Button, SVG} from '@br/br-components';
import {isFullyInViewport} from '../helpers/domHelpers';

// Events
export const EVENTS = {
  PROMO_DISMISSED: {
    promo_name: 'app_banner',
    promo_type: 'app_promo',
    tag_manager_event: 'promo_closed',
  },
  PROMO_IMPRESSION: {
    promo_name: 'app_banner',
    promo_type: 'app_promo',
    tag_manager_event: 'promo_impression',
  },
  PROMO_SELECTED: {
    promo_name: 'app_banner',
    promo_type: 'app_promo',
    tag_manager_event: 'promo_selected',
  },
};

class SmartBanner extends React.PureComponent {
  static propTypes = {
    displayTag: PropTypes.shape({
      short_name: PropTypes.string,
    }),
    installLink: PropTypes.string,
    onCloseNotification: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    teamStream: PropTypes.shape({
      short_name: PropTypes.string,
    }),
    trackEvent: PropTypes.func.isRequired,
  };

  static defaultProps = {
    appInstallLink: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      steps: 2,
      currentStep: 1,
    };
    this.closeSmartBanner = this.closeSmartBanner.bind(this);
    this.fireEvent = this.fireEvent.bind(this);
  }

  componentDidMount() {
    const {displayTag, teamStream} = this.props;
    this.props.onMount(displayTag, teamStream);
    if (isFullyInViewport(this.button)) {
      this.fireEvent('impression');
    }
  }

  closeSmartBanner(event) {
    if (this.state.currentStep < this.state.steps) {
      this.setState({currentStep: this.state.currentStep + 1});
      return;
    }
    this.props.onCloseNotification(event, 'smart_banner');
    this.fireEvent('dismiss');
  }

  fireEvent(eventName) {
    switch (eventName) {
      case 'impression':
        return this.props.trackEvent(EVENTS.PROMO_IMPRESSION);

      case 'dismiss':
        return this.props.trackEvent(EVENTS.PROMO_DISMISSED);

      default:
        return this.props.trackEvent(EVENTS.PROMO_SELECTED);
    }
  }

  render() {
    const classes = classnames({
      smartBanner: true,
      molecule: true,
      [`currentStep${this.state.currentStep}`]: true,
    });
    const {installLink, shortTagName} = this.props;
    const description = shortTagName
      ? `Get the Latest ${shortTagName} News`
      : 'News. Scores. Highlights. Your teams.';

    const stepOne = (
      <div className="stepOne">
        <SVG type="logo" target="brApp" />
        <div className="description">
          <h3>Bleacher Report App - Free!</h3>
          <p>{description}</p>
        </div>
        <div className="button-wrapper" ref={(el) => (this.button = el)}>
          <Button className="atom button" href={installLink} onClick={this.fireEvent}>
            Install
          </Button>
        </div>
      </div>
    );

    return (
      <div className={classes}>
        <Button classes="close" onClick={this.closeSmartBanner}>
          <SVG type="icon" target="close" />
        </Button>
        {stepOne}
        <div className="stepTwo">
          <Button href={installLink}>I Have the App!</Button>
          <Button onClick={this.closeSmartBanner}>Remind Me Later</Button>
        </div>
      </div>
    );
  }
}

export default SmartBanner;
