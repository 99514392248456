import {setAds} from '../actions/adsActions';

const KRUX_IDS = {
  US: 'ITb9Q03y',
  non_US: 'teff52x79',
};

const initialState = {};
/*
Note: the full list of properties used by Google Ads targeting is:

fantasy
render_strategy
zone
site
layout
sitePlatform
embedded
cnn
social
cobrand
article
division
event
team
buzz
tags
gp_flag
tag_id
referrer

Used by TagManager:
krux_id

Anything else isn't passed through so can be considered *not* ads data.
*/
function ads(state = initialState, action) {
  if (action.error) {
    throw action.error;
  }
  switch (action.type) {
    case 'LOAD_ARTICLE_DATA':
    case 'LOAD_SECTION_DATA': {
      return {
        ...state,
        ...action.payload.page,
      };
    }

    case setAds().type:
    case 'LOAD_TEAM_SCHEDULE': {
      return {
        ...state,
        ...action.payload.adData,
      };
    }

    case 'STORE_QUERY_PARAMS': {
      // A note on security:
      // Data should always be derived and or validated.
      // We don't store this data *directly* because _then_ it would be in any serialization
      // of the data. That would be a big security risk (hello XSS).
      const newState = {
        cnn: action.payload.utm_source === 'cnn',
        embedded: Boolean(Number(action.payload.tsm) || Number(action.payload.tst)),
        layout: 'default', // will be removed?
        override: action.payload.ads === '0',
        social: 'false', // check header,
      };
      return {
        ...state,
        ...newState,
      };
    }

    case 'ADD_USER': {
      const krux_id = action.payload.country === 'US' ? KRUX_IDS.US : KRUX_IDS.non_US;
      return {
        ...state,
        krux_id,
      };
    }

    default:
      return state;
  }
}

export default ads;
