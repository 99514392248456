import React, {useEffect, useState, useRef} from 'react';
import Carousel from '@br/react-multi-carousel';
import classnames from 'classnames';
import {connect} from 'react-redux';
import ScoreCard from '../atoms/scoreCard';
import * as ScheduleActions from '../../actions/scheduleActions';
import {Leagues} from '../../constants/leagues';
import {SECTION} from '../../constants/pageTypes';
import * as AnalyticsActions from '../../actions/analyticsActions';

const arrowWidth = 34;
const duration = 30000;
const responsiveSizes = {
  superLargeDesktop: {
    breakpoint: {max: 4000, min: 3000},
    items: 12,
    slidesToSlide: 12,
    partialVisibilityGutter: 1.5,
  },
  desktop_large: {
    breakpoint: {max: 3000, min: 2012},
    items: 10, // The items to show in a slide
    slidesToSlide: 10,
    partialVisibilityGutter: 5,
  },
  desktop_small: {
    breakpoint: {max: 2012, min: 1024},
    items: 7,
    slidesToSlide: 7,
    partialVisibilityGutter: 10,
  },
  tablet_large: {
    breakpoint: {max: 1024, min: 744},
    items: 4,
    slidesToSlide: 4,
    partialVisibilityGutter: 20,
  },
  tablet_small: {
    breakpoint: {max: 744, min: 464},
    items: 3,
    slidesToSlide: 3,
    partialVisibilityGutter: 17,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 20,
  },
};

const Arrow = ({onClick, isLeft}) => {
  const container = classnames('arrowContainer', {
    right: !isLeft,
    left: isLeft,
  });
  const icon = classnames('arrow', {
    rightArrow: !isLeft,
    leftArrow: isLeft,
  });
  return (
    <div className={container} onClick={onClick}>
      <div className={icon} />
    </div>
  );
};

const ScoreCardCarousel = (props) => {
  const [additionalTransform, setAdditionalTransform] = useState(0);
  const {getTodayGames, league, team, isSection} = props;
  const interval = useRef(null);

  useEffect(() => {
    //To prevent caching data
    getTodayGames(league, team, isSection);
    //To get the latest result of the games every 30 seconds
    interval.current = setInterval(() => {
      getTodayGames(league, team, isSection);
    }, duration);

    return () => {
      clearInterval(interval.current);
    };
  }, [league, team, isSection, getTodayGames]);

  //To make skeleton rendering before showing game cards
  const beforeLoadingCarousel = () => {
    // isMobile is true on both mobile and tablet devices
    const count = props.isMobile
      ? responsiveSizes['tablet_large'].items
      : responsiveSizes['desktop_large'].items;
    return new Array(count).fill(0).map((_item, index) => <ScoreCard key={index} />);
  };

  const classes = classnames({
    atom: true,
    'score-card-carousel': true,
  });
  const carouselContainerClass = classnames({
    mobile: props.isMobile,
  });
  function onScoreCardClick(game) {
    const {team_one, team_two, game_progress, status, site, gamecast, game_date} = game;
    props.trackEvent({
      awayScore: team_one && team_one.score,
      awayTeamName: team_one && team_one.short_name,
      gamePeriod: game_progress && game_progress.primary,
      gameStart: game_date,
      gameStatus: status,
      homeScore: team_two && team_two.score,
      homeTeamName: team_two && team_two.short_name,
      league: site,
      platform: 'web',
      springType: `${props.page.type}Carousel`,
      streamID: team_two.permalink,
      streamName: gamecast,
      tag_manager_event: 'gamecast_selected',
      title: `${team_one.abbrev} @ ${team_two.abbrev}`,
    });
  }
  const scoreCards = () => {
    return props.todayGames.gameGroups.map((group) => {
      return group.games.map((game, index) => {
        let title = '';
        if (index === 0) {
          // to show league if it is topGames or if the league has multi categories like soccer
          title =
            props.todayGames.isTopGames ||
            (!props.todayGames.isTopGames && props.todayGames.gameGroups.length > 1)
              ? group.league
              : '';
        }
        return (
          <ScoreCard game={game} key={index} title={title} onScoreCardClick={onScoreCardClick} />
        );
      });
    });
  };
  const makeCarousel = () => {
    const showCarousel =
      props.todayGames && props.todayGames.gameGroups && props.todayGames.gameGroups.length > 0;
    // This will show shimmering effect on cards before loading games and if there is no game , [] will be returned
    let content = [];
    if (!props.todayGames) {
      content = beforeLoadingCarousel();
    }
    if (showCarousel) {
      content = scoreCards();
    }
    return (
      <Carousel
        additionalTransfrom={additionalTransform}
        ssr={true}
        centerMode={false}
        arrows={!props.isMobile}
        containerClass={carouselContainerClass}
        sliderClass="slider"
        responsive={responsiveSizes}
        customLeftArrow={<Arrow isLeft={true} />}
        customRightArrow={<Arrow isLeft={false} />}
        noTransform={props.isMobile}
        beforeChange={(nextSlide, responsive) => {
          if (props.isMobile) return;
          //To show partialVisibility of the last card at the end of each slide and make the card fully visible in the next slide
          const {containerWidth, deviceType, currentSlide} = responsive;
          const {partialVisibilityGutter} = responsiveSizes[deviceType];
          const partialVisibilityGutterWidth = (partialVisibilityGutter * containerWidth) / 100;

          let transform = partialVisibilityGutterWidth + arrowWidth;
          if (nextSlide !== 0 && nextSlide > currentSlide) {
            if (additionalTransform !== 0) {
              transform = partialVisibilityGutterWidth + additionalTransform;
            }
            setAdditionalTransform(transform);
          }
          if (nextSlide === 0 || nextSlide < currentSlide) {
            setAdditionalTransform(0);
          }
        }}
      >
        {content}
      </Carousel>
    );
  };

  return <div className={classes}>{makeCarousel()}</div>;
};

const mapStateToProps = (state) => {
  return {
    todayGames: state.schedules && state.schedules.todayGames,
    page: state.page,
    league: Leagues[state.page.league?.toUpperCase()] || '',
    isSection: state.page.type === SECTION,
    team: state.page.team,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTodayGames: (league, team, isSection) =>
      dispatch(ScheduleActions.loadTodayGames(league, team, isSection)),
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScoreCardCarousel);
