import {buildQueryString} from 'url-params-helper';

import {Layser, BRLive, NGTV, Vid} from '../endpoints';
import {createFetchService, createFetchServiceCustomJWT} from './fetch_utils';

const fetchJSON = createFetchService('Vid');
const fetchJSONLive = createFetchServiceCustomJWT('LiveVideo');

const errorHandler = (err) => {
  return {
    error: err,
  };
};

export const fetchVideo = (videoId) => {
  if (videoId) {
    return fetchJSON(Vid.videos(videoId)).catch(errorHandler);
  }
  return Promise.reject(new Error('No video ID passed to Vid API'));
};

/**
 * Fetches live video metadata
 * @typedef {Object} liveVideo
 * @property {number} id
 * @property {string} state
 * @property {number} media_id asset id for live or vod
 * @property {string} thumbnail_url
 * @property {string} type
 * @returns {Promise<liveVideo>} A promise that contains the live video meta data
 * when fulfilled.
 */
export const fetchLiveVideoMetadata = (liveVideoEventId) => {
  return fetchJSON(Layser.liveVideoEvent(liveVideoEventId));
};

export const registerAnonymousUser = () => {
  return fetchJSONLive(BRLive.anonymousRegistration(), {
    jwt: process.env.ORBIS_API_TOKEN,
    method: 'POST',
  });
};

export const authenticateAnonymousUser = (userID) => {
  return fetchJSONLive(BRLive.anonymousLogin(), {
    jwt: process.env.ORBIS_API_TOKEN,
    method: 'POST',
    body: {
      userID,
    },
  });
};

export const retrieveVideoUrl = (assetId, sessionToken) => {
  return fetchJSONLive(BRLive.videoEntities(assetId), {
    jwt: sessionToken,
  });
};

export const retrieveEntitlementToken = ({userID, sessionToken, assetID, playbackUrl}) => {
  return fetchJSONLive(BRLive.userAccountEntitlement(userID), {
    jwt: sessionToken,
    method: 'POST',
    body: {
      assetID,
      playbackUrl,
    },
  });
};

const ORBIS_APP_ID =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwbGF0Zm9ybSI6ImRlc2t0b3AiLCJwcm9kdWN0IjoiYnJsaXZlIiwibmV0d29yayI6ImJsZWFjaGVycmVwb3J0IiwiYXBwSWQiOiJibGVhY2hlcnJlcG9ydC1icmxpdmUtZGVza3RvcC1oOTdsbGIifQ.mowT8kgzB0_qqKXMiBHJV0TAINi-UiKTEVibS4BezOM';
export const retrieveNGTVtoken = (entitlementToken, path) => {
  return fetchJSONLive(
    `${NGTV.token()}?${buildQueryString({
      accessTokenType: 'jws',
      accessToken: entitlementToken,
      appId: ORBIS_APP_ID,
      format: 'json',
      path: `${path}/*`,
      profile: 'catchsports',
    })}`
  );
};

export const retrieveBlackoutInformation = (appId, feed, titleId) => {
  return fetchJSON(NGTV.blackout(appId, feed, titleId));
};

export default {
  fetchVideo,
};
