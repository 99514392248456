import React, {ReactNode} from 'react';
import classnames from 'classnames';
import {Color} from '../../types';

export const enum Variant {
  H1 = 'heading-1',
  H2 = 'heading-2',
  H3 = 'heading-3',
  H4 = 'heading-4',
  H5 = 'heading-5',
  H6 = 'heading-6',
  Body1 = 'body-1',
  Body2 = 'body-2',
  Button = 'button',
}

export enum TypographyClass {
  Primary = 'text--primary',
  Secondary = 'text--secondary',
  Tertiary = 'text--tertiary',
  Disabled = 'text--disabled',
  Inherit = 'text--inherit',
}

const elements = {
  [Variant.H1]: 'h1',
  [Variant.H2]: 'h2',
  [Variant.H3]: 'h3',
  [Variant.H4]: 'h4',
  [Variant.H5]: 'h5',
  [Variant.H6]: 'h6',
  [Variant.Body1]: 'p',
  [Variant.Body2]: 'p',
  [Variant.Button]: 'span',
};

export type TypographyProps<C extends React.ElementType> = {
  variant?: Variant;
  element?: C;
  uppercase?: boolean;
  children?: ReactNode;
  color?: Color;
  className?: string;
  onMouseEnter?: React.MouseEventHandler;
} & React.ComponentPropsWithoutRef<C>;

export const Typography = <C extends React.ElementType = 'span'>({
  variant = Variant.Body1,
  element,
  uppercase,
  children,
  color = Color.Primary,
  className,
  ...rest
}: TypographyProps<C>) => {
  const Element = element || (elements[variant] as keyof JSX.IntrinsicElements);
  const classes = classnames({
    typography: true,
    [`-${variant}`]: true,
    [`-uppercase`]: uppercase,
    [`-${TypographyClass[color]}`]: true,
    [`${className}`]: className,
  });

  return (
    <Element className={classes} {...rest}>
      {children}
    </Element>
  );
};
