import React from 'react';
import {format, isValid} from 'date-fns';
import {PREVIEW, LIVE} from '../../../constants/gameStatus';
import classnames from 'classnames';

const Header = ({gameDate, gameProgress, tbd, status, isEmpty, showLive}) => {
  const classes = classnames('title', {
    shine: isEmpty,
  });
  let title = '';
  let state = '';
  if (!isEmpty) {
    const date = new Date(gameDate);
    const gameTime = isValid(date) ? format(date, 'h:mm A') : 'N/A';
    state = gameProgress.primary;
    title = gameProgress.header;
    if (status === PREVIEW) {
      state = tbd ? 'TBD' : gameTime;
    }
    if (showLive && status === LIVE) {
      // We won't show "Live" if the league is MLB (in this case title will be Top|Mid|Bot)
      title = LIVE.toUpperCase();
    }
  }
  return (
    <div className="header">
      <div className={classes}>{title}</div>
      <div className="state">{state}</div>
    </div>
  );
};
export default Header;
