import {createFetchService} from './fetch_utils';
import {BR} from '../endpoints';
import logger from '../logger';

const fetchJSON = createFetchService('BReport');
const fetchText = createFetchService('BReport', {responseFormat: 'text'});

const errorHandler = (err) => {
  return {
    error: err,
  };
};

function fetchTagSuggestions(query) {
  if (query) {
    return fetchJSON(BR.tagSuggestionsSearch(query)).catch(errorHandler);
  }
  return Promise.reject(new Error('No Query passed to BReport API'));
}

function fetchSuggestions(query) {
  if (query) {
    return fetchJSON(BR.suggestionsSearch(query)).catch(errorHandler);
  }
  return Promise.reject(new Error('No Query passed to BReport API'));
}

function articleUrl(page) {
  if (page) {
    return Promise.resolve(BR.article(page));
  }
  return Promise.reject(new Error('No Page passed to BReport API'));
}

function slideUrl(article, slide) {
  if (article) {
    return Promise.resolve(BR.slide(article, slide));
  }
  return Promise.reject(new Error('No Article passed to BReport API'));
}

function liveblogUrl(slug) {
  if (slug) {
    return Promise.resolve(BR.liveblogs(slug));
  }
  return Promise.reject(new Error('No Slug passed to BReport API'));
}

function sendDownloadAppTextMessage(phoneNumber) {
  return fetchText(BR.twilioGenericDownloadLink({phoneNumber})).catch((error) => {
    logger.error('Error kicking off text message.', error, error.stack);
    throw new Error(error);
  });
}

export default {
  articleUrl,
  fetchTagSuggestions,
  fetchSuggestions,
  liveblogUrl,
  sendDownloadAppTextMessage,
  slideUrl,
};
