const nav = (state = {}, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case 'LOAD_NAV': {
      return action.payload;
    }

    default:
      return state;
  }
};

export default nav;
