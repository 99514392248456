import {createFetchService} from './fetch_utils';
import {Faust} from '../endpoints';

const fetchJSON = createFetchService('Faust');

const failedArticleResponseData = {
  article_metadata: {
    tags: [],
    page: {},
  },
};

const getPlaylistMetadata = (playlistId) => {
  if (playlistId) {
    return fetchJSON(Faust.playlistMetadata(playlistId));
  }
  return Promise.reject(new Error('No Playlist Id passed to Faust API'));
};

function fetchArticleMetaData(articleID) {
  if (articleID) {
    return fetchJSON(Faust.articleMeta(articleID))
      .then((faust) => faust)
      .catch(() => failedArticleResponseData);
  }
  return Promise.reject(new Error('No Article Id passed to Faust API'));
}

function getTagInfo(tagIds, ordered) {
  // tagIds is a string; can be comma-separated list of ids.
  // This will resolve with an array, or error.
  if (tagIds) {
    // split by comma, sort, then join. Keeps things ordered consistently.
    const tags = ordered
      ? tagIds
      : tagIds
          .split(',')
          .sort((first, second) => {
            // First we need to deal with non-numeric strings (shouldn't happen, but *can*)
            if (Number.isNaN(Number(first)) && Number.isNaN(Number(second))) {
              return first.localeCompare(second);
            }
            // that takes care of both of them being strings.
            // But if only one is a string - we should sort it correctly
            if (Number.isNaN(Number(first))) {
              return -1;
            }
            if (Number.isNaN(Number(second))) {
              return 1;
            }
            // now the numeric (or evaluatable as a number anyhow) case
            return Number(first) - Number(second);
          })
          .join(',');
    return fetchJSON(Faust.tags(tags, ordered))
      .then(({tag}) => {
        // Despite being named in the singular, this value is sometimes an array of tag objects.
        return Array.isArray(tag) ? tag : [tag];
      })
      .catch(() => []);
  }
  return Promise.reject(new Error('No Tag Ids passed to Faust API'));
}

export default {
  fetchArticleMetaData,
  getPlaylistMetadata,
  getTagInfo,
};
