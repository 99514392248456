// Authorized account Menu in top level navigation

import React from 'react';
import {Variant, Typography} from '../../Shared/Typography/Typography';
import {Color} from '../../types';
import {UserType} from '../../types';
import {useViewportHeight} from '../useViewportHeight';

export interface AuthAccountSideMenuProps {
  userType?: UserType;
  profileId?: Number;
}

export const AuthAccountSideMenu: React.FC<AuthAccountSideMenuProps> = ({profileId}) => {
  const fullViewportHeight = useViewportHeight();
  return (
    <div
      className="accountSideMenu"
      style={{height: `calc(${fullViewportHeight}px - var(--br-navbar_height))`}}
    >
      <nav role="navigation">
        <Typography
          variant={Variant.H3}
          color={Color.Primary}
          element="a"
          className="accountSideMenu__anchor"
          href={`/users/${profileId}`}
        >
          profile
        </Typography>
        <Typography
          variant={Variant.H3}
          color={Color.Primary}
          element="a"
          className="accountSideMenu__anchor"
          href="/accounts/edit"
        >
          settings
        </Typography>
        <Typography
          variant={Variant.H3}
          color={Color.Primary}
          element="a"
          className="accountSideMenu__anchor"
          href="https://support.bleacherreport.com/"
        >
          get help
        </Typography>
        <Typography
          variant={Variant.H3}
          color={Color.Primary}
          element="a"
          className="accountSideMenu__anchor"
          href="/logout"
        >
          log out
        </Typography>
      </nav>
    </div>
  );
};
