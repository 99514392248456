export const SOCIAL_LINKS = {
  US: {
    FB: 'https://facebook.com/bleacherreport/',
    TW: 'https://twitter.com/bleacherreport/',
    IG: 'https://instagram.com/bleacherreport/',
  },
  UK: {
    FB: 'https://www.facebook.com/BleacherReportFootball/',
    TW: 'https://twitter.com/brfootball/',
    IG: 'https://www.instagram.com/brfootball/',
  },
};
