import xss from 'xss';

const initialState = {};

function sanitizeString(string = '') {
  //sanitize the query string using xss package to protect against xss attacks.
  return xss(string).replace(/[\x00-\x1F\x7F]/g, ''); // eslint-disable-line no-control-regex
}

function page(state = initialState, action) {
  if (action.error) {
    throw action.error;
  }
  const payload = action.payload;

  switch (action.type) {
    case 'LOAD_ARTICLE_DATA':
    case 'LOAD_SECTION_DATA': {
      if (payload.page.site === 'Video_Landing_Page') {
        payload.page.site = 'video';
      }
      return {
        ...state,
        ...payload.page,
        format: payload.format,
      };
    }

    case 'SET_AD_DATA':
    case 'LOAD_TEAM_SCHEDULE': {
      return {
        ...state,
        ...payload.adData,
      };
    }

    case 'SET_RETURN_URL': {
      return {
        ...state,
        returnUrl: payload,
      };
    }

    case 'SET_SCREEN': {
      return {
        ...state,
        screen: payload,
      };
    }

    case 'SET_PAGE': {
      return {
        ...state,
        id: payload.id,
        type: payload.type,
      };
    }

    case 'SET_STATIC_CONTENT': {
      return {
        ...state,
        static_content: payload,
      };
    }

    case 'STORE_QUERY_PARAMS': {
      // A note on security:
      // Data should always be derived and or validated.
      // We don't store this data *directly* because _then_ it would be in any serialization
      // of the data. That would be a big security risk (hello XSS).
      const fromNonCNNPartner = payload.sic === 'ngtv' || payload.sic === 'partner';
      const newState = {
        cnn: payload.utm_source === 'cnn',
        embedded: Boolean(Number(payload.tsm) || Number(payload.tst)), // param
        force_promo: !!payload.force_promo,
        fromCNNApp: payload.sic === 'cnn-app',
        fromNonCNNPartner,
        layout: 'default', // will be removed?
        purchaseState: payload.state,
        returnUrl: sanitizeString(payload.return),
        screen: sanitizeString(payload.screen),
        site: sanitizeString(payload.site),
        social: 'false', // check header,
        tsm: Number(payload.tsm) || 0,
        tst: Number(payload.tst) || 0,
        utm_source: sanitizeString(payload.utm_source),
        zone: sanitizeString(payload.zone),
      };
      return {
        ...state,
        ...newState,
      };
    }
    case 'LOAD_VIDEO_DATA':
    case 'LOAD_VIDEO_METADATA':
      return payload.tags
        ? {
            ...state,
            tags: payload.tags.join(),
            site: 'video',
          }
        : {
            ...state,
            site: 'video',
          };

    default: {
      return state;
    }
  }
}

export default page;
