import Boom from 'boom';
import {now} from './date_time';
import logger from '../../logger';

// using the process object mock the browser property will be set to true
// see here for more: https://github.com/webpack/node-libs-browser/blob/master/mock/process.js
const isNode = process && process.browser !== true;
export const FETCH_TIMEOUT_LENGTH = Number(process.env.FETCH_TIMEOUT_LENGTH) || 3000; // 3 seconds

const timerFactory = (...args) => {
  if (!isNode) {
    return (response) => response;
  }
  const start = now();
  return (response) => {
    const end = now();
    const total = (end - start).toFixed(2);
    if (response && !response.nologs) {
      const verb = (args[2] && args[2].method) || 'GET';
      logger.log(`External: ${args[0].padStart(10)}: ${total.padStart(6)}ms to ${verb} ${args[1]}`);
    }
    return response;
  };
};

function isStatus2xx(response = {}) {
  return response && response.status >= 200 && response.status < 300;
}

function isStatus4xx(response = {}) {
  return response && response.status >= 400 && response.status < 500;
}

export const checkStatus = async (response) => {
  if (isStatus2xx(response)) {
    return response;
  }
  // Our APIs sometimes, but not always, return useful json data in their error responses.
  // The following normalizes the errors, no matter the status.
  const data = {
    status: response ? response.status : null,
    json: await new Promise((resolve) => resolve(response.json())).catch(() => null),
  };
  let error;
  if (isStatus4xx(response)) {
    error = Boom.create(response.status, response.statusText, data);
  } else {
    const errorMessage = response ? response.statusText : 'No response';
    error = Boom.internal(errorMessage, data);
  }
  error.response = response;
  return Promise.reject(error);
};

export const logAndThrow = (args) => {
  return (err) => {
    if (
      err &&
      (!err.output || err.output.statusCode !== 404) &&
      err.response &&
      !err.response.nologs
    ) {
      logger.error('Error while fetching: ', args[0], err);
    }
    throw err;
  };
};

export const parseJSON = (response) => {
  try {
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const parseText = (response) => {
  try {
    return response.text();
  } catch (error) {
    throw error;
  }
};

export const fetchJSON = (...args) => {
  return global
    .fetch(...args)
    .then(timerFactory(...args))
    .then(checkStatus)
    .then(parseJSON)
    .catch(logAndThrow(args));
};

export function fetchText(...args) {
  return global
    .fetch(...args)
    .then(timerFactory(...args))
    .then(checkStatus)
    .then(parseText)
    .catch(logAndThrow(args));
}

export function statusCheckFailFactory(service, url, options) {
  return (thrownError) => {
    const metaData = {
      service: {
        service,
        url,
        options,
        thrownError,
      },
    };
    // Reject so with Boom error if possible else create a 500 error.
    const error =
      thrownError && thrownError.isBoom
        ? thrownError
        : Boom.internal(`Problem loading service ${service}`, metaData);
    // now force all errors to have the service data and prepend service name to error message.
    // eg: "LayserBeam: Service Unavailable" or "Faust: Bad Request".
    error.output.payload.error = `${service}: ${error.output.payload.error}`;
    error.data = {
      ...error.data,
      service,
      options,
      errorCode: error.output.statusCode,
    };
    return Promise.reject(error);
  };
}

function fetchWithTimeout(fetch, service = '') {
  return new Promise((resolve, reject) => {
    const timeout = global.setTimeout(() => {
      reject(Boom.gatewayTimeout(`Service timout: ${service} failed to load in time.`));
    }, FETCH_TIMEOUT_LENGTH);

    fetch
      .then((response) => {
        global.clearTimeout(timeout);
        resolve(response);
      })
      .catch(reject);
  });
}

export function fetchServiceFactory(service, url, args, responseFormat = 'json') {
  const statusCheckFail = statusCheckFailFactory(service, url, args[1]);
  const timer = timerFactory(service, ...args);
  const parser = responseFormat === 'json' ? parseJSON : parseText;

  return fetchWithTimeout(global.fetch(...args), service)
    .then(timer)
    .then(checkStatus)
    .catch(statusCheckFail)
    .then(parser);
}
