import {Component} from 'react';
import classnames from 'classnames';

// Note: It would be nice to get this data from somewhere
// rather than hardcoding it eventually.
const responsiveSizes = {
  article: [
    [
      [970, 0], // this number is based off of window size, not content area
      ['fluid', [970, 250], [970, 66], [728, 90], [300, 250], [2, 2]],
    ],
    [
      [728, 0],
      ['fluid', [728, 90], [300, 250], [2, 2]],
    ],
    [
      [0, 0],
      ['fluid', [300, 250], [320, 50], [2, 2]],
    ],
  ],
  articleThirdRect: [
    [
      [970, 0], // this number is based off of window size, not content area
      ['fluid', [970, 250], [970, 66], [728, 90], [300, 250]],
    ],
    [
      [728, 0],
      ['fluid', [728, 90], [300, 250]],
    ],
    [
      [0, 0],
      ['fluid', [300, 250], [320, 50]],
    ],
  ],
  fpTest: [
    [
      [1280, 0],
      ['fluid', [300, 250]],
    ],
    [
      [970, 0],
      ['fluid', [300, 250]],
    ],
    [
      [728, 0],
      ['fluid', [300, 250]],
    ],
    [
      [0, 0],
      ['fluid', [300, 250]],
    ],
  ],
  gamecast: [
    [
      [1280, 0], // browser size [width, height]
      ['fluid', [728, 90], [300, 250], [300, 100], [320, 50]], // waterfall of requested ad sizes
    ],
    [
      [728, 0],
      ['fluid', [300, 250], [300, 100], [320, 50]],
    ],
    [
      [0, 0],
      ['fluid', [300, 250], [300, 100], [320, 50]],
    ],
  ],
  inBody: [
    [
      [1280, 0], // browser size [width, height]
      ['fluid', [1280, 100], [970, 250], [970, 66], [728, 90], [2, 2]], // waterfall of requested ad sizes
    ],
    [
      [970, 0],
      ['fluid', [970, 250], [970, 66], [728, 90], [2, 2]],
    ],
    [
      [728, 0],
      ['fluid', [728, 90], [300, 250], [300, 100], [320, 50], [2, 2]],
    ],
    [
      [0, 0],
      ['fluid', [300, 250], [300, 100], [320, 50], [2, 2]],
    ],
  ],
  sidebar: [
    [
      [728, 0],
      ['fluid', [300, 250]],
    ],
    [[0, 0], ['fluid']],
  ],
  skin: [
    [[970, 0], [[3, 3]]],
    [[0, 0], []],
  ],
  sticky: [
    [
      [1280, 0],
      ['fluid', [1280, 100], [728, 90]],
    ],
    [
      [728, 0],
      ['fluid', [728, 90]],
    ],
    [
      [0, 0],
      ['fluid', [320, 50]],
    ],
  ],
  sticky_mobile: [
    [[500, 0], []],
    [[0, 0], [[320, 50]]],
  ],
  wide: [
    [
      [1280, 0], // browser size [width, height]
      ['fluid', [1280, 100], [970, 250], [970, 66], [728, 90], [1, 2]], // waterfall of requested ad sizes
    ],
    [
      [970, 0],
      ['fluid', [970, 250], [970, 66], [728, 90], [1, 2]],
    ],
    [
      [728, 0],
      ['fluid', [728, 90], [300, 100], [320, 50], [1, 2]],
    ],
    [
      [0, 0],
      ['fluid', [300, 100], [320, 50], [1, 2]],
    ],
  ],
};

export const getResponsiveSize = (size) => {
  if (Array.isArray(size)) {
    return JSON.stringify(size);
  }
  if (responsiveSizes[size]) {
    return JSON.stringify(responsiveSizes[size]);
  } else if (size) {
    return size;
  }
  return null;
};

class Advert extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.getRef(this._ad);
    if (this.props.country) {
      global.brVisitorLocale = this.props.country; // AdsAPI requires this when requesting Prebid ads.
    }
    if (this.props.isVisitorOnMobile) {
      global.brIsVisitorOnMobile = true; // AdsAPI requires this to request ads via Prebid.
    }
  }
  render() {
    const responsiveSize = getResponsiveSize(this.props.responsiveSize);
    if (!this.props.size || !responsiveSize || !this.props.position || !this.props.id) {
      return false;
    }

    // Classes on the main ad container so we can control additional ads elements via css styles
    const classes = classnames({
      ad: true,
    });

    const adWrapperClasses = classnames(this.props.classes, {
      'br-ad-wrapper': true, // required for ads
      atom: true,
      advert: true,
      collapsed: this.props.collapsed,
      'auto-height': this.props.autoHeight,
    });
    const isCollapsed = this.props.collapsed ? 1 : 0;
    return (
      <this.props.tag className={classes}>
        {this.props.closeButton && (
          <div id="closeAd" className="close" onClick={this.props.hide}>
            X
          </div>
        )}
        <div className={adWrapperClasses}>
          <div
            ref={(elem) => {
              this._ad = elem;
            }}
            id={`br-ad-${this.props.id}`}
            className={this.props.isLazy ? 'br-ad-lazy' : 'br-ad'}
            data-collapsed={isCollapsed}
            data-country={this.props.country}
            data-position={this.props.position}
            data-reload={this.props.reload}
            data-responsive-size={getResponsiveSize(this.props.responsiveSize)}
            data-size={JSON.stringify(this.props.size)}
          />
        </div>
      </this.props.tag>
    );
  }
}

Advert.defaultProps = {
  tag: 'div',
  collapsed: false,
  reload: 2,
  autoHeight: false,
  getRef: () => {},
  closeButton: false,
};

export default Advert;
