import {useEffect} from 'react';
import loadable from '@loadable/component';
import {connect} from 'react-redux';

const RegisterServiceWorker = loadable(() => import('./atoms/registerServiceWorker'));
import Website from './template/website';
import logger from '../logger';

import * as AnalyticsActions from '../actions/analyticsActions';
import * as AppActions from '../actions/appActions';
import * as ArticleActions from '../actions/articleActions';
import * as NotificationActions from '../actions/notificationActions';
import * as PromoActions from '../actions/promoActions';
import * as SearchActions from '../actions/searchActions';

import * as ArticleSelectors from '../selectors/articleSelectors';
import * as NavSelectors from '../selectors/navSelectors';
import * as PageSelectors from '../selectors/pageSelectors';
import * as TopSelectors from '../selectors/topLevelSelectors';
import * as UserSelectors from '../selectors/userSelectors';
import {selectTeamStream} from '../selectors/sectionSelectors';

async function loadPolyfills() {
  if (typeof global.window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
}

function App(props) {
  useEffect(() => {
    (async () => await loadPolyfills())();
  }, []);

  // We will use this helper function to remove critical css after main css is loaded.
  function cssLoaded(href) {
    return () => {
      if (href.startsWith('atomic') && href !== 'atomic.shell.css') {
        const criticalCSS = document.getElementById('critical-css');
        if (criticalCSS) criticalCSS.remove();
      }
    };
  }

  // Since we are using critical rendering path we have to load all css files from javascript.
  useEffect(() => {
    if (props.styles) {
      const head = document.getElementsByTagName('HEAD')[0];
      const styles = props.styles.reduce((acc, cur) => {
        acc[cur.key] = cur.href;
        return acc;
      }, {}); // Remove duplicates
      Object.keys(styles).forEach((key) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = styles[key];
        link.onload = cssLoaded(key);
        head.appendChild(link);
      });
      logger.info('Main css loaded!');
    }
  }, [props.styles]);

  return (
    <>
      <RegisterServiceWorker />
      <Website {...props} />
    </>
  );
}

const mapStateToProps = (state) => {
  const article = TopSelectors.articles(state);
  const ui = TopSelectors.ui(state);
  const template = TopSelectors.template(state);
  return {
    analyticsPageType: PageSelectors.analyticsPageType(state),
    article,
    articleType: ArticleSelectors.articleType(state),
    assetURL: ui.assetURL,
    author: article.author,
    deviceFamily: ui.userAgent && ui.userAgent.os ? ui.userAgent.os.family : false,
    displayTag: ArticleSelectors.displayTag(state),
    isHidden: {
      footer: ui.hideFooter || ui.isEmbedded,
      footerLinks: ui.hideFooterLinks,
      gettyCopyright: ui.hideGettyCopyright,
      nav: ui.hideNav,
      share: ui.hideShare,
    },
    leadImage: ArticleSelectors.selectLeadImage(state),
    nav: {
      navigation: NavSelectors.navData(state),
      path: NavSelectors.navPath(state),
    },
    notificationsData: TopSelectors.notification(state),
    page: TopSelectors.page(state),
    permissions: UserSelectors.selectPermissions(state),
    promos: TopSelectors.promo(state),
    searchResults: TopSelectors.search(state).site.results,
    styles: template.styles,
    tags: TopSelectors.tags(state),
    teamStream: selectTeamStream(state),
    ui,
    user: TopSelectors.user(state),
    userAgent: ui.userAgent,
    videoPlaylistData: {
      videoPlaylist: ArticleSelectors.videoPlaylist(state),
      videoPlaylistStatus: state.articles.video_playlist_status,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addScript: (script) => dispatch(AppActions.loadScript(script)),
    addStyle: (script) => dispatch(AppActions.addStyle(script)),
    notificationCallbacks: {
      checkNotification: (data) => dispatch(NotificationActions.checkNotification(data)),
      onCloseNotification: (event, name) =>
        dispatch(NotificationActions.closeNotification(event, name)),
    },
    onChangeContactInfo: (event, campaign) =>
      dispatch(PromoActions.contactInfoEntered(event.target.value, campaign)),
    onCloseFlyin: (campaign, account) => dispatch(PromoActions.closeFlyin(campaign, account)),
    onHideFlyin: (campaign) => dispatch(PromoActions.hideFlyin(campaign)),
    onSendToPhone: (event) => {
      event.preventDefault();
      return dispatch(PromoActions.sendAppInfo());
    },
    onShowFlyin: (campaign) => dispatch(PromoActions.showFlyin(campaign)),
    onSmartBannerMounted: (displayTag, teamStream) =>
      dispatch(PromoActions.smartBannerMounted(displayTag, teamStream)),
    playNextVideo: (...args) => dispatch(ArticleActions.playNextVideo(...args)),
    promoCallbacks: {
      closeClicked: (campaign, account) => dispatch(PromoActions.closeFlyin(campaign, account)),
      onChange: (event, campaign) =>
        dispatch(PromoActions.contactInfoEntered(event.target.value, campaign)),
      onHideFlyin: (campaign) => dispatch(PromoActions.hideFlyin(campaign)),
      mobileWebAppPromoMounted: (data) => dispatch(PromoActions.onMobileWebAppPromoMounted(data)),
      onShowFlyin: (campaign) => dispatch(PromoActions.showFlyin(campaign)),
      handleSubmit: (event) => {
        event.preventDefault();
        return dispatch(PromoActions.sendAppInfo());
      },
    },
    searchCallbacks: {
      queryEntered(event) {
        return dispatch(SearchActions.siteSearch(event.target.value));
      },
    },
    setTveAuthContext: (authContext) => {
      return dispatch(AppActions.setTveAuthContext(authContext));
    },
    setTveProviderLogo: (imageUrl) => {
      return dispatch(AppActions.setTveProviderLogo(imageUrl));
    },
    storeReferrer: (referrer) => dispatch(AppActions.storeReferrer(referrer)),
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
