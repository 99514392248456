import React from 'react';

export interface TeamListingProps {
  team: string;
  icon: string;
}

export const TeamListing: React.FC<TeamListingProps> = ({team, icon}) => {
  return (
    <span className="teamListing">
      {icon && <img className="teamListing__icon" src={icon} />}
      {team}
    </span>
  );
};
