import {useEffect, useState} from 'react';

// This hook allow us to determine an accurate number for the viewport height of device
// It address an known issue in iOS where viewport height is taller than the visible part of the document when using 100vh.
export function useViewportHeight(): number | null {
  const [height, setHeight] = useState<number | null>(() => window.innerHeight);

  useEffect(() => {
    // prevent SSR error with window object
    // calculate innerHeight after first render when window object is available
    function updateViewportHeight() {
      setHeight(window.innerHeight);
    }

    window.addEventListener('resize', updateViewportHeight);
    return () => window.removeEventListener('resize', updateViewportHeight);
  }, []);

  return height;
}
