export function hasClass(el, className) {
  let hasClass;
  if (el.classList) {
    hasClass = el.classList.contains(className);
  } else {
    // older browsers
    hasClass = !!el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`));
  }
  return hasClass;
}

export function addClass(el, className) {
  if (el.classList) {
    el.classList.add(className);
  } else if (!hasClass(el, className)) {
    // older browsers
    el.className += ` ${className}`;
  }
}

export function removeClass(el, className) {
  if (el.classList) {
    el.classList.remove(className);
  } else if (hasClass(el, className)) {
    // older browsers
    const reg = new RegExp(`(\\s|^)${className}(\\s|$)`);
    el.className = el.className.replace(reg, ' ');
  }
}

export function appendStyle(styleData = '') {
  if (styleData) {
    const style = document.createElement('style');
    style.id = 'skin-styles';
    style.type = 'text/css';
    style.innerHTML = styleData;
    global.document.head.appendChild(style);
  }
}

export function removeElementBySelector(selector) {
  const elems = global.document.querySelectorAll(selector);
  [...elems].forEach((elem) => {
    elem.parentNode.removeChild(elem);
  });
}
