import {handleActions} from 'redux-actions';

import {
  setGamecast,
  setPollingUpdates,
  updateGamecastWithPolledData,
} from '../actions/gamecastActions';
import {PREVIEW, LIVE, RECAP} from '../constants/gameStatus';
import {TS_STREAM} from '../constants/streamTypes';
import {getGameStatus, gamecastModules} from '../helpers/scores/gamecast';

export const initialState = {
  live_game: {
    sharing: {},
  },
  meta: {},
  programmed: {
    tracks: [],
  },
  social: {
    tracks: [],
  },
  pollingUpdates: {},
};

export const configureLineScore = (lineScore) => {
  if (!lineScore) {
    return null;
  }

  const totalHeaderColumns = lineScore.headers.length;
  const configureTeam = (team) => {
    const numOfPlaceholders = totalHeaderColumns - team.values.length;
    const values = [...team.values, ...Array(numOfPlaceholders).fill({value: '-'})];

    return {
      ...team,
      values,
    };
  };

  return {
    ...lineScore,
    team_one: configureTeam({...lineScore.team_one}),
    team_two: configureTeam({...lineScore.team_two}),
  };
};

/**
 * This function returns an key/value pair of the type of tab {key} and related modules
 * @param {Object} gameValues - statmlik live game fields
 * @see test/stubs/scores/gamecast.json for example data
 * @returns {{key: String, modules: []GamecastModule}} Dictonary for preview modules
 */
export const previewModules = (gameValues) => {
  const modules = [];

  if (gameValues.betting_link) {
    modules.push({...gamecastModules.bettingLink, placement: 'Preview'});
  }

  if (gameValues.match_info) {
    modules.push(gamecastModules.matchInfo);
  }

  if (gameValues.injuries) {
    modules.push(gamecastModules.injuries);
  }

  if (gameValues.odds) {
    modules.push(gamecastModules.odds);
  }

  if (gameValues.team_performance) {
    modules.push(gamecastModules.teamPerformance);
  }

  if (gameValues.league_rankings) {
    modules.push(gamecastModules.leagueRankings);
  }

  if (gameValues.stat_leaders) {
    modules.push(gamecastModules.statLeaders);
  }

  modules.push(gamecastModules.spredfast);

  modules.push(gamecastModules.teamStream);

  if (modules.length >= 3) {
    modules.splice(2, 0, gamecastModules.adSlot1);
  }

  if (modules.length >= 5) {
    modules.splice(5, 0, gamecastModules.adSlot2);
  }

  // Must be set at end so disclaimer shows at the bottom of module
  if (gameValues.betting_link) {
    modules.push({...gamecastModules.bettingDisclaimer, disclaimerType: 'betting_link'});
  }

  return modules;
};

export const liveModules = (gameValues) => {
  const modules = [];

  if (gameValues.betting_link) {
    modules.push({...gamecastModules.bettingLink, placement: 'Live'});
  }

  if (gameValues.match_timeline && gameValues.sport === 'soccer') {
    modules.push(gamecastModules.matchTimeline);
  }

  if (gameValues.linescore) {
    modules.push(gamecastModules.lineScore);
  }

  if (gameValues.pbp) {
    modules.push(gamecastModules.plays);
  }

  modules.push(gamecastModules.spredfast);

  modules.push(gamecastModules.teamStream);

  if (gameValues.odds && gameValues.sport === 'soccer') {
    modules.push(gamecastModules.odds);
  }

  if (gameValues.match_info) {
    modules.push(gamecastModules.matchInfo);
  }

  if (modules.length >= 4) {
    modules.splice(3, 0, gamecastModules.adSlot1);
  }

  if (modules.length >= 6) {
    modules.splice(5, 0, gamecastModules.adSlot2);
  }

  // Must be set at end so disclaimer shows at the bottom of module
  if (gameValues.betting_link) {
    modules.push({...gamecastModules.bettingDisclaimer, disclaimerType: 'betting_link'});
  }

  return modules;
};

export const recapModules = (gameValues) => {
  const modules = [];

  if (gameValues.betting_link) {
    modules.push({...gamecastModules.bettingLink, placement: 'Recap'});
  }

  if (gameValues.match_timeline && gameValues.sport === 'soccer') {
    modules.push(gamecastModules.matchTimeline);
  }

  if (gameValues.linescore) {
    modules.push(gamecastModules.lineScore);
  }

  if (gameValues.pbp && gameValues.sport === 'soccer') {
    modules.push(gamecastModules.plays);
  }

  if (gameValues.sport === 'baseball') {
    if (gameValues.wls_pitchers) {
      modules.push(gamecastModules.pitchers);
    }
  }

  modules.push(gamecastModules.spredfast);

  modules.push(gamecastModules.teamStream);

  if (gameValues.odds && gameValues.sport === 'soccer') {
    modules.push(gamecastModules.odds);
  }

  if (gameValues.match_info) {
    modules.push(gamecastModules.matchInfo);
  }

  if (modules.length >= 3) {
    modules.splice(2, 0, gamecastModules.adSlot1);
  }

  // Must be set at end so disclaimer shows at the bottom of module
  if (gameValues.betting_link) {
    modules.push({...gamecastModules.bettingDisclaimer, disclaimerType: 'betting_link'});
  }

  return modules;
};

export const getModulesByGameStatus = (gameStatus, {...liveGamePayload}) => {
  switch (gameStatus) {
    case LIVE: {
      return liveModules(liveGamePayload);
    }
    case RECAP: {
      return recapModules(liveGamePayload);
    }
    case PREVIEW:
    default: {
      return previewModules(liveGamePayload);
    }
  }
};

export const getModuleDictionaryForTabs = ({scoreboard, site = '', ...liveGamePayload}) => {
  if (!scoreboard) {
    throw new Error('Error displaying gamecast');
  }

  const gameStatus = getGameStatus(scoreboard.status);
  const stats = site && site.toLowerCase() === 'mlb' ? 'box score' : 'stats';
  const standings = liveGamePayload.sport === 'soccer' ? 'table' : 'standings';
  const tabs = {};
  const setModules = (tabName, modules = []) => {
    if (modules.length !== 0) {
      tabs[tabName] = modules;
    }
  };

  const statsModules = [];
  if (liveGamePayload.player_stats) {
    statsModules.push(gamecastModules.stats);
    if (['NFL', 'CFB'].includes(site)) {
      statsModules.push(gamecastModules.adSlot2);
    }
  }
  if (liveGamePayload.comparison) {
    if (liveGamePayload.sport === 'soccer') {
      statsModules.push(gamecastModules.adSlot1);
    }
    statsModules.push(gamecastModules.comparison);
  }

  const playByPlayModules = [];
  if (liveGamePayload.pbp && liveGamePayload.sport !== 'soccer') {
    playByPlayModules.push(gamecastModules.plays);
  }

  const standingsModules = [];
  if (liveGamePayload.standings && liveGamePayload.standings.standings) {
    standingsModules.push(gamecastModules.standings);
  }

  const lineupModules = [];
  if (liveGamePayload.lineup) {
    lineupModules.push(gamecastModules.lineups);
  }

  setModules(gameStatus, getModulesByGameStatus(gameStatus, liveGamePayload));
  setModules('lineups', lineupModules);
  setModules(stats, statsModules);
  setModules('plays', playByPlayModules);

  if (gameStatus !== PREVIEW && liveGamePayload.sport !== 'soccer') {
    const oddsModules = [];
    if (liveGamePayload.match_info) {
      oddsModules.push(gamecastModules.matchInfo);
      oddsModules.push(gamecastModules.adSlot1);
    }

    if (liveGamePayload.odds) {
      oddsModules.push(gamecastModules.odds);
    }

    if (gameStatus === LIVE) {
      if (liveGamePayload.team_performance) {
        oddsModules.push(gamecastModules.teamPerformance);
      }

      if (liveGamePayload.league_rankings) {
        oddsModules.push(gamecastModules.leagueRankings);
      }
    }

    // Must be set at end so disclaimer shows at the bottom of module
    if (liveGamePayload.partner_odds) {
      oddsModules.push({...gamecastModules.bettingDisclaimer, disclaimerType: 'partner_odds'});
    }
    setModules('odds', oddsModules);
  }

  setModules(standings, standingsModules);

  return tabs;
};

const getPollType = (pollingUpdates) =>
  pollingUpdates.type === TS_STREAM ? 'programmed' : 'social';

const gamecast = handleActions(
  {
    [setGamecast]: (state, {payload: {live_game = {}, ...values}}) => ({
      ...state,
      ...values,
      live_game: {
        ...live_game,
        linescore: configureLineScore(live_game.linescore),
        gamecastTabs: getModuleDictionaryForTabs(live_game),
      },
    }),
    [setPollingUpdates]: (state, {payload}) => ({
      ...state,
      pollingUpdates: {
        ...payload,
      },
    }),
    [updateGamecastWithPolledData]: (state) => ({
      ...state,
      [getPollType(state.pollingUpdates)]: state.pollingUpdates,
      pollingUpdates: {},
    }),
  },
  initialState
);

export default gamecast;
