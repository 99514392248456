import jwtDecode from 'jwt-decode';

import {Gatekeeper} from '../endpoints';
import StorageAPI from './storage_api';
import * as Fetch from './helpers/fetch';

export const fetchJWT = (body, refresh) => {
  const opts = {
    method: 'POST',
    body: JSON.stringify(body),
  };
  if (refresh) {
    opts.JWTrefresh = true;
  }
  // use the raw fetchServiceFactory here, because otherwise we end up with screwed up dependancies.
  // so the params may look a bit strange.
  opts.headers = new Headers();
  opts.headers.append('Content-Type', 'application/json');
  const url = Gatekeeper.oauth();
  return Fetch.fetchServiceFactory('JWT API', url, [url, opts]);
};

export function refreshJWT(refresh) {
  const refresh_token = refresh || StorageAPI.get('br_jwt_refresh', true);
  const grant = {
    grant_type: 'refresh_token',
    refresh_token,
  };

  return refresh_token ? fetchJWT(grant, true) : Promise.reject(new Error('No refresh Token'));
}

export function updateAuth(refresh) {
  return refreshJWT(refresh)
    .then((data) => {
      StorageAPI.setAccountCookies({auth: data});
      return data.access_token;
    })
    .catch((error) => {
      if (error.data && error.data.options.JWTrefresh) {
        // it's failed to refresh the JWT, so we should log the user out.
        return StorageAPI.deauthorize();
      }
      throw error;
    });
}

export async function checkAndRefreshJWT() {
  const jwt = StorageAPI && StorageAPI.get('br_jwt', true);
  if (!jwt) return;

  const decodedJWT = jwtDecode(jwt);
  const jwtExpiration = decodedJWT.exp;
  const currentTime = new Date().getTime() / 1000;

  if (currentTime > jwtExpiration) {
    await updateAuth();
  }
}
