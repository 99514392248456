const REGEXP_BAD_PHONE_CHARS = new RegExp('[^0-9\\s()+-]', 'g');
const REGEXP_EMAIL = /^.+@(\[?)[a-zA-Z0-9\-.]+\.([a-zA-Z]{2,3}|[0-9]{1,3})(\]?)$/;

function isValidEmail(string) {
  return REGEXP_EMAIL.test(string);
}

function isValidPhone(string) {
  if (!string || !string.length) {
    return false;
  }
  const sanitizedPhoneNumber = string.replace(REGEXP_BAD_PHONE_CHARS, '');
  const badCharacterPosition = string.search(REGEXP_BAD_PHONE_CHARS);
  return sanitizedPhoneNumber.length > 9 && badCharacterPosition === -1;
}

export {isValidEmail, isValidPhone};
