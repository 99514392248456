export const Leagues = {
  COLLEGE_BASKETBALL: 'NCAABB',
  COLLEGE_FOOTBALL: 'NCAAF',
  GOLF: 'GOLF',
  MLB: 'MLB',
  NBA: 'NBA',
  NFL: 'NFL',
  NHL: 'NHL',
  TENNIS: 'TENNIS',
  WNBA: 'WNBA',
  WORLD_FOOTBALL: 'SOCCER',
};
