import {Armchair} from '../endpoints';
import {createFetchService} from './fetch_utils';

import {logger} from '../logger';

const fetchJSON = createFetchService('Armchair');

function fetchPost(trackId) {
  if (!trackId) {
    return Promise.reject(new Error('missing track ID'));
  }
  return fetchJSON(Armchair.userPost(trackId)).catch((error) => {
    logger.error('fetching from Armchair failed', error);
    throw error;
  });
}

export default {
  fetchPost,
};
