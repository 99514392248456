import {buildQueryStringURL, getParams} from 'url-params-helper';

import logger from '../logger';
import reporter from '../reporter';

import {Cloudinary} from '../endpoints';

import {homePageBreakpoints, moduleImageSizes} from '../constants/breakpoints';
import {contentTypes} from '../constants/contentTypes';

const defaultImgWidth = homePageBreakpoints.pointGuard;
const defaultImgHeight = 434;

const widthRegex = /\bw_([0-9]+)\b/;
const heightRegex = /\bh_([0-9]+)\b/;
const qualityRegex = /\bq_([0-9]+)\b/;

export const imgRegex = {widthRegex, heightRegex, qualityRegex};

export const isBRImage = (url = '') => {
  return url.includes('//img.bleacherreport.net');
};

export const isCloudinaryHosted = (url = '') => {
  return url.includes('//media.bleacherreport.com');
};

export const getCloudinaryImgParams = (url = '') => {
  const widthMatch = url.match(widthRegex);
  const heightMatch = url.match(heightRegex);

  return {
    w: Number((widthMatch && widthMatch[1]) || defaultImgWidth),
    h: Number((heightMatch && heightMatch[1]) || defaultImgHeight),
  };
};

export function convertToCloudinaryUrl(url = '', options = {}) {
  // Only BR images hosted in our s3 image buckets or served by cloudinary are supported
  if (
    process.env.USE_CLOUDINARY_IMAGES !== 'true' ||
    (!isBRImage(url) && !isCloudinaryHosted(url))
  ) {
    return url;
  }
  // Save url query params for later use
  const urlParams = {
    ...getParams(url),
    ...options,
  };
  // Strip url query params (keeping it simple, but we probably want something more robust here)
  const cleanUrl = url.replace(/[?#].*$/, '');

  // Limit max image width to larger breakpoint
  if (urlParams.w > homePageBreakpoints.center) {
    urlParams.w = homePageBreakpoints.center;
    urlParams.h = 0; // let cloudinary use the default image ratio based on width
  }

  const urlDelimiters = {
    cms: '/cms/media/image/',
    images: '/img/images/photos/',
    article: '/img/article/media_slots/photos/',
    slides: '/img/slides/photos/',
  };

  if (cleanUrl.includes(urlDelimiters.cms)) {
    return Cloudinary.cms(cleanUrl.split(urlDelimiters.cms)[1], urlParams);
  }
  if (cleanUrl.includes(urlDelimiters.images)) {
    return Cloudinary.images(cleanUrl.split(urlDelimiters.images)[1], urlParams);
  }
  if (cleanUrl.includes(urlDelimiters.article)) {
    return Cloudinary.article(cleanUrl.split(urlDelimiters.article)[1], urlParams);
  }
  if (cleanUrl.includes(urlDelimiters.slides)) {
    return Cloudinary.slides(cleanUrl.split(urlDelimiters.slides)[1], urlParams);
  }

  return url;
}

// This will parse image elements and replace urls to use cloudinary ones
export const parseImages = (track = {}) => {
  // Don't attempt to convert if feature flag is off
  if (process.env.USE_CLOUDINARY_IMAGES !== 'true') return track;

  switch (track.content_type) {
    case contentTypes.IMAGE:
      if (!track.content) return track;
      return {
        ...track,
        content: {
          ...track.content,
          url: convertToCloudinaryUrl(track.content.url),
        },
      };
    case contentTypes.INTERNAL_ARTICLE:
    case contentTypes.EXTERNAL_ARTICLE:
    case contentTypes.VIDEO_ARTICLE:
    case contentTypes.HIGHLIGHT:
    case contentTypes.VIDEO:
    case contentTypes.LIVE_VIDEO:
    case contentTypes.PHOTO:
    case contentTypes.DEEPLINK:
      if (!track.content) return track;
      return {
        ...track,
        content: {
          ...track.content,
          thumbnail_url: convertToCloudinaryUrl(track.content.thumbnail_url),
        },
      };
    case contentTypes.SLIDE:
      const trackElements = track.elements;
      const firstTrackElement = trackElements && trackElements.length && trackElements[0];
      // In some cases the first element of a slide (media element) contains no no media
      // If that's the case just return the track for exsiting behavior
      if (!firstTrackElement || !firstTrackElement.content) return track;
      return {
        ...track,
        elements: [
          {
            ...firstTrackElement,
            content: {
              ...firstTrackElement.content,
              url: convertToCloudinaryUrl(firstTrackElement.content.url),
            },
          },
          ...track.elements.slice(1),
        ],
      };
    default:
      // Videos fetched from the vid api have no `content_type`, they use a `type` instead
      const isVideoType = [contentTypes.VIDEO, contentTypes.HIGHLIGHT].includes(track.type);
      if (isVideoType) {
        if (!track.thumbnail_url) return track;
        return {
          ...track,
          thumbnail_url: convertToCloudinaryUrl(track.thumbnail_url),
        };
      }

      return track;
  }
};

function determineSrcSetImageUrl({srcUrl, newWidth, newHeight, qualityParam}) {
  const isCloudinaryImage = isCloudinaryHosted(srcUrl);
  if (isCloudinaryImage) {
    if (srcUrl.match(/\/image\/upload\//)) {
      // The raw image is larger, but the user has cropped to a section of the image.
      // The x/y/w/h coordinates reflect the crop selected by the user.
      // https://media.bleacherreport.com/image/upload/x_1959,y_258,w_1169,h_776,c_crop/v1616636044/fqetcwusr7cz6vqhoret.jpg
      // We will add params to further scale the result of the user's crop.
      return srcUrl.replace(',c_crop/', `,c_crop/w_${newWidth},h_${newHeight},c_fill/`);
    }
    // https://media.bleacherreport.com/f_auto,w_5240,h_3501,q_90/br-s3-cms/29/5f/3b/c0/05f5/4512/b3d5/7dcd9ba4deca/crop_exact_GettyImages-1200227393.jpeg
    let srcSetImageUrl = srcUrl.replace(widthRegex, `w_${newWidth}`);
    srcSetImageUrl = srcSetImageUrl.replace(heightRegex, `h_${newHeight}`);
    srcSetImageUrl = srcSetImageUrl.replace(qualityRegex, `q_auto`); // Always use auto for best optimization
    return srcSetImageUrl;
  }
  // https://img.bleacherreport.net/cms/media/image/29/5f/3b/c0/05f5/4512/b3d5/7dcd9ba4deca/crop_exact_GettyImages-1200227393.jpeg?q=90&w=768&h=513
  return `${srcUrl}?q=${qualityParam}&w=${newWidth}&h=${newHeight}`;
}

export const generateResponsiveImgSrcSet = (
  imgWidth = defaultImgWidth,
  imgHeight = defaultImgHeight,
  quality = 90,
  moduleType,
  thumbnail_url
) => {
  if (!thumbnail_url) {
    throw new Error('Image thumbnail url must be present');
  }

  const photoUrl = new URL(thumbnail_url);
  const srcUrl = `${photoUrl.origin}${photoUrl.pathname}`;
  const originalImgWidth = parseInt(imgWidth, 10);
  const originalImgHeight = parseInt(imgHeight, 10);

  const breakpointSrcSets = Object.keys(homePageBreakpoints)
    .filter((breakpointType) => {
      const breakpointWidth = homePageBreakpoints[breakpointType];
      // ensure only required srcSets are included
      return breakpointWidth <= originalImgWidth;
    })
    .map((breakpointType) => {
      let screenView;
      const breakpointWidth = homePageBreakpoints[breakpointType];

      if (breakpointWidth >= 1024) {
        screenView = 'desktop';
      } else if (breakpointWidth >= 768) {
        screenView = 'tablet';
      } else {
        screenView = 'mobile';
      }

      const maxImageSize = moduleType ? moduleImageSizes[moduleType][screenView] : undefined; //eslint-disable-line no-undefined
      const newWidth = maxImageSize < breakpointWidth ? maxImageSize : breakpointWidth;
      const qualityParam = process.env.IMAGE_QUALITY || quality;
      // calculate new height and maintain aspect ratio
      const newHeight = Math.round((originalImgHeight / originalImgWidth) * newWidth);

      const srcSetImageUrl = determineSrcSetImageUrl({srcUrl, newWidth, newHeight, qualityParam});
      return `${srcSetImageUrl} ${newWidth}w`;
    });
  // check if src sets were added for each breakpoint(mobile,tablet,desktop). If missing use original image as largest src set
  if (breakpointSrcSets.length < 3) {
    breakpointSrcSets.push(thumbnail_url);
  }
  return breakpointSrcSets;
};

export const imgSizes = `
  (max-width: ${homePageBreakpoints.shootingGuard}px) 100vw,
  (max-width: ${homePageBreakpoints.center}px) 60vw,
  ${homePageBreakpoints.center}px
`;

export const getArticleThumb = (url, size) => {
  if (!url || !size) {
    return false;
  }

  const isCloudinaryImage = isCloudinaryHosted(url);

  if (!isBRImage(url) && !isCloudinaryImage) {
    return url;
  }

  if (isCloudinaryImage) {
    if (url.includes(',c_crop/')) {
      // The source image has been uploaded to Cloudinary, but the author has specified a particular
      // area of the image to crop to. Do not modify the crop dimensions; we will add a new
      // transformation afterwards to resize the result of the crop.
      return url.replace(',c_crop/', `,c_crop/w_${size.w},h_${size.h},c_fill/`);
    }
    if (url.includes(',c_fill/')) {
      if (!size.h) {
        // When height is not passed Cloudinary can figure it out based on the existing ratio
        return url.replace(/\/[^/]*c_fill[^/]*\//, `/w_${size.w},c_fill/`);
      }
      // The source image is not cropped; we can modify the dimensions directly.
      return url.replace(/\/[^/]*c_fill[^/]*\//, `/w_${size.w},h_${size.h},c_fill/`);
    }
    if (url.includes('/image/upload/')) {
      if (!size.h) {
        return url.replace('/image/upload/', `/image/upload/w_${size.w},c_fill/`);
      }
      // Writer did not crop the source image (left it full-frame)
      return url.replace('/image/upload/', `/image/upload/w_${size.w},h_${size.h},c_fill/`);
    }
    logger.error('Unrecognized Cloudinary URL', {url, size});
    reporter.inform('Unrecognized Cloudinary URL', {url, size});
    return url;
  }

  // image is on BR's (legacy) img.br.net server, which can resize/crop images
  // based on URL query params
  const {w: width, h: height} = size;
  const src = url.includes('?') ? url.replace(url.substring(url.indexOf('?')), '') : url; //truncate before query string.
  const params = {
    h: height,
    w: width,
    q: 70,
    crop_x: 'center',
    crop_y: 'top',
  };

  return buildQueryStringURL(params, src);
};

export const useImgSrcSet = ({src, width, height, moduleType}) => {
  const isCloudinaryImage = isCloudinaryHosted(src);
  const imgParams = isCloudinaryImage ? getCloudinaryImgParams(src) : getParams(src);
  const result = {
    src,
    srcSet: '',
  };

  if (imgParams && typeof imgParams === 'object') {
    const {w, h, q} = imgParams;

    const srcSet = generateResponsiveImgSrcSet(width || w, height || h, q, moduleType, src);
    if (srcSet && srcSet.length !== 0) {
      result.srcSet = srcSet.join(',');
      result.src = srcSet[srcSet.length - 1].split(' ')[0];
    }
  }

  return Object.values(result);
};
