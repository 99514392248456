import {Promos} from '../endpoints';

export const appInstallLink = (
  userAgent,
  feature,
  deeplinkPath,
  channel,
  additionalParams = {}
) => {
  const isInterstitial = feature === 'interstitial';
  const isAndroid = userAgent && userAgent.os && userAgent.os.family === 'Android';
  const params = {
    deeplink_path: deeplinkPath ? deeplinkPath : '',
    channel,
    feature,
  };
  // add UTM params for android insterstitials (supposedly temporarily)
  if (isAndroid && isInterstitial) {
    params.utmParams = '&utm_source=mobile_web&utm_medium=interstitial';
  }
  return Promos.teamstream({...params, ...additionalParams});
};

export const brDeeplinkPath = (page) => {
  if (page.type === 'article') {
    return `article/bleacherreport.com/articles/${page.article}`;
  }
  if (page.type === 'section' && page.id !== 'front-page' && page.id !== 'uk') {
    return `stream/${page.id}`;
  }
  return false;
};

export const determineBranchChannel = (customBrowser) => {
  switch (customBrowser) {
    case 'facebook':
      return 'facebook';
    case 'twitter':
      return 'twitter';
    default:
      return 'mobile_web';
  }
};

/**
 * Handy function to determine the deeplink to use for comment upsell.
 * @param customBrowser
 * @param userAgent
 * @param track	The article's track info
 * @returns {string|null}
 */
export const determineCommentsUpsellDeepLink = (customBrowser, userAgent, track) => {
  if (!track || !track.tag) return null;
  const feature = 'comments_upsell';
  const channel = determineBranchChannel(customBrowser);
  return appInstallLink(userAgent, feature, 'article/comments', channel, {
    trackId: track.id,
    playlistName: track.tag.unique_name,
    contentHash: track.url_hash,
  });
};

/**
 * Function to determine the deeplink to use for alert Banner.
 * @param customBrowser
 * @param userAgent
 * @returns {string}
 */
export const determineAlertBannerDeepLink = (customBrowser, userAgent, page) => {
  const channel = determineBranchChannel(customBrowser);
  const deepLinkPath = brDeeplinkPath(page);
  return appInstallLink(userAgent, 'web_alerts', deepLinkPath, channel);
};

/**
 * Utility function to flatten nested comment array into a one dimensional array.
 * @param data
 * @param limit The maximum size of the flattened array.
 * @returns {[]}
 */
export const flattenCommentsData = (data, limit = 3) => {
  const flattened = [];
  (function flat(array, path = 0) {
    array.forEach(function(el) {
      if (flattened.length < limit) {
        const {comments = {}, ...comment} = el;
        comment.path = path;
        flattened.push(comment);
        if (comments.data && comments.data.length > 0) {
          flat(comments.data, comment.path + 1);
        }
      }
    });
  })(data);
  return flattened;
};
