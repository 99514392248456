import {distanceInWordsStrict} from 'date-fns';

const formatAlertText = (text = '') => {
  if (text.includes('second')) {
    return text.replace(/\sseconds?/, 's');
  } else if (text.includes('minute')) {
    return text.replace(/\sminutes?/, 'm');
  } else if (text.includes('hour')) {
    return text.replace(/\shours?/, 'h');
  } else if (text.includes('day')) {
    return text.replace(/\sdays?/, 'd');
  }
  return text;
};

// This function determines if a date is greater than 1 day ago.
const isMoreThanOneDayAgo = (alertDate) => {
  const today = new Date().getDate();
  const alertDay = new Date(alertDate).getDate();
  const currentMonth = new Date().getMonth();
  const alertMonth = new Date(alertDate).getMonth();
  if (currentMonth !== alertMonth) {
    return true;
  }
  return alertDay < today - 1;
};

const setAlertTimeAgo = (timestamp) => {
  return timestamp && formatAlertText(distanceInWordsStrict(new Date(timestamp), new Date()));
};

// We are interested in the first five alerts with no duplicates
const addAlertdata = (alerts = [], tagInfo = {}, trendingTagInfo = {}) => {
  const updatedAlerts = alerts.reduce((updatedAlerts, alert) => {
    const item = updatedAlerts.find((item) => item.alert_id === alert.alert_id);
    if (!item) {
      alert.timeAgo = setAlertTimeAgo(alert.created_at);
      alert.tagInfo = alert.tag_id === 23319 ? trendingTagInfo : tagInfo;
      updatedAlerts.push(alert);
    }
    return updatedAlerts;
  }, []);
  return updatedAlerts.slice(0, 5);
};

export {addAlertdata, isMoreThanOneDayAgo, setAlertTimeAgo};
