function makeCookieName(notificationName) {
  return `${notificationName}_kill`;
}

function midnightTonight() {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date.setDate(date.getDate() + 1);
  return date;
}

export {makeCookieName, midnightTonight};
