import {loadableReady} from '@loadable/component';

import {store} from './browserSetup';
// Polyfill fetch (if needed)
import 'isomorphic-fetch';
// Now the react basics
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

// Now import the actual application.
import App from './components/app';

import * as AnalyticsSelectors from './selectors/analyticsSelectors';
import AnalyticsApi from './apis/analytics_api';
import {skinsSetup} from './apis/ads_api';
import bugsnag from '@bugsnag/js';
import {registerOrbisAnonymousUser} from './actions/userActions';

// Get the state for selectors and skins
const state = store.getState();

// Set analytics default payload data
AnalyticsApi.setAnalyticsDefaultPayload(AnalyticsSelectors.defaultPayload(state));

// Register orbis anonymous user
store.dispatch(registerOrbisAnonymousUser());

// set up BugSnag
if (
  process.env.RELEASE_ENV &&
  process.env.NR_CLIENT_BUGSNAG_API &&
  process.env.USE_BUGSNAG === 'true'
) {
  global.bugsnag = bugsnag({
    apiKey: process.env.NR_CLIENT_BUGSNAG_API,
    appVersion: process.env.GIT_SHA,
    releaseStage: process.env.RELEASE_ENV,
  });
}

// This global is required for Ads Skins.
// The skins ads iframe calls BR.Skins.noSkin, or BR.Skins.setup.
global.BR = {
  Skins: skinsSetup(state.articles ? state.articles.hide_skin : false),
};

// Now, all that done, we can add the App to the DOM.
// The App is wrapped by a non-client-React controlled layout so it's injected
// to the specific tag ID where it's supposed to live.
loadableReady(() => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <App store={store} />
    </Provider>,
    document.getElementById('app')
  );
});
