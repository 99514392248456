/**
 * Scripts with preload attribute will take precedence over other scripts
 */
const scripts = [
  {
    key: 'rwxsawuta',
    src: '//cadmus.script.ac/d2uap9jskdzp2/script.js',
    preload: true,
  },
];

export default scripts;
