import {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {format, isSameDay} from 'date-fns';
import {oneMinute} from '../../apis/storage_api';
import {DEFAULT_TVE_EXPIRATION} from '../molecules/video/tve/tveConfig';
import {videoTypes} from '../../constants/videoTypes';
import {videoStates} from '../../constants/videoStates';

const FS_CONTAINER_ID_PREFIX = 'fullscreen-video-ui-container-';
const FS_CONTAINER_CLASS = 'fullscreen-video-ui-container';

const dateFormat = 'dddd[,] MMMM Do [•] hh:mm A';
const todayFormat = '[Today •] hh:mm A';

const noop = () => {};
const formatTime = (ms) => format(ms, 'm:ss');
const isPPVTrack = (metadata) => metadata.live_video_type || metadata.type === videoTypes.PPV;

const getFormattedLiveEventDate = (eventMetadata = {}, isVideoLiveNow = false) => {
  const liveVideoState = eventMetadata?.state;
  const eventStartDate = eventMetadata?.starts_at;
  const isEventToday = isSameDay(eventStartDate, new Date());
  // State and start dates are used to determine appropriate date format
  const eventDateFormat = liveVideoState ? (isEventToday ? todayFormat : dateFormat) : dateFormat;
  return liveVideoState === videoStates.LIVE || isVideoLiveNow
    ? 'LIVE NOW'
    : isPPVTrack(eventMetadata) && format(eventStartDate, eventDateFormat);
};

// A custom hook which returns the current value of a countdown timer which starts at the given value and counts down in real time, only displays minutes and seconds.
const useSecondsCountdown = (expiration, onTimerComplete) => {
  const timeRemaining = Math.max(0, expiration - Date.now());
  const [displayTime, setDisplayTime] = useState(formatTime(timeRemaining));

  useEffect(() => {
    if (timeRemaining === 0) return noop;

    const countdown = setInterval(() => {
      const newTimeRemaining = Math.max(0, expiration - Date.now());
      setDisplayTime(formatTime(newTimeRemaining)); // set the display time to the formatted version
      if (newTimeRemaining === 0) {
        onTimerComplete();
        clearTimeout(countdown);
      }
    }, 250);

    return () => clearTimeout(countdown);
  }, [expiration, onTimerComplete, timeRemaining]);

  if (expiration === DEFAULT_TVE_EXPIRATION) return formatTime(10 * oneMinute);
  return displayTime;
};

/**
 * This function will render the given content on top of the full-screen video element if possible (inside a new div) and will return either an ID for the root content element or null if it was not able to be rendered for some reason
 */
const showFullScreenContent = (content, overrideID = null) => {
  const fullscreenElement = document.fullscreenElement;
  if (!fullscreenElement) return null;

  const tui = fullscreenElement.querySelector('.tui');
  if (!tui) return null;

  const container = document.createElement('div');
  container.id = overrideID || FS_CONTAINER_ID_PREFIX + Date.now();
  container.classList.add(FS_CONTAINER_CLASS);
  ReactDOM.render(content, container);
  tui.appendChild(container);
  return container.id;
};

const removeFullScreenElement = (contentID) => {
  const el = document.getElementById(contentID);
  if (el) el.remove();
};

const removeAllFullScreenElements = () => {
  const elements = document.querySelectorAll(`.${FS_CONTAINER_CLASS}`);
  elements.forEach((el) => el.remove());
};

export {
  useSecondsCountdown,
  showFullScreenContent,
  removeFullScreenElement,
  removeAllFullScreenElements,
  getFormattedLiveEventDate,
};
