// League Dropdown for subnavbar links

import React, {useState} from 'react';
import {DropdownData} from '../types';
import {Variant, Typography} from '../../Shared/Typography/Typography';
import {Color} from '../../types';
import {TeamListing} from '../DivisionDropdown/TeamListing/TeamListing';
import classnames from 'classnames';
import {NavLink} from '../../Shared/NavLink/NavLink';
import {Scroll, ScrollDirection} from '../../Shared/Scroll/Scroll';

export interface LeagueDropDownProps {
  leagues: DropdownData[];
  hideScrollbar?: boolean;
}

export const LeagueDropdown: React.FC<LeagueDropDownProps> = ({leagues, hideScrollbar = false}) => {
  const [currentLeague, setCurrentLeague] = useState(leagues[0]);

  const handleLeagueClick = (league: string) => {
    const newLeague = leagues.find((leagueItem) => leagueItem.name === league);
    setCurrentLeague(newLeague || leagues[0]);
  };

  return (
    <div className="navBarFonts leagueDropdown">
      <Scroll scrollDirection={ScrollDirection.Horizontal} hideScrollbar={hideScrollbar}>
        {leagues.map((league: DropdownData, index: number) => (
          <div
            className={classnames({leagueDropdown__item: true, '-first': index === 0})}
            key={league.name}
          >
            <Typography
              color={Color.Disabled}
              variant={Variant.H6}
              className={classnames({
                leagueDropdown__leagueLink: true,
                '-active': league === currentLeague,
              })}
              // this is for readability and there isn't a large enough usecase for a separate component
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => handleLeagueClick(league.name)}
              // eslint-disable-next-line react/jsx-no-bind
              onMouseEnter={() => handleLeagueClick(league.name)}
            >
              {league.name}
            </Typography>
          </div>
        ))}
      </Scroll>

      <div className="leagueDropdown__gridContainer">
        <Scroll scrollDirection={ScrollDirection.Vertical} hideScrollbar={hideScrollbar}>
          <div className="leagueDropdown__grid">
            {currentLeague.teams.map((team) => (
              <div className="leagueDropdown__team" key={team.title}>
                <NavLink href={team.href} key={team.title} variant={Variant.Body1}>
                  <TeamListing team={team.title} icon={team.icon as string} />
                </NavLink>
              </div>
            ))}
          </div>
        </Scroll>
      </div>
    </div>
  );
};
