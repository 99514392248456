const initialState = {};

function profiles(state = initialState, action) {
  switch (action.type) {
    case 'LOAD_USER_PROFILE': {
      const {articles = {}, user} = action.payload;
      const data = {
        ...user,
        tracks: articles.tracks || [],
      };
      return {
        ...state,
        [user.id]: data, // `.id` is the Gatekeeper UUID
        [user.profile_id]: data, // `.profile_id` is the (optional) BReport ID
      };
    }
    default: {
      return state;
    }
  }
}

export default profiles;
