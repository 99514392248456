import {combineReducers} from 'redux';
import ads from './ads';
import articles from './articles';
import careers from './careers';
import gamecast from './gamecast';
import nav from './nav';
import notification from './notification';
import page from './page';
import profiles from './profiles';
import promo from './promo';
import schedules from './schedules';
import search from './search';
import sections from './sections';
import sitemap from './sitemap';
import stub from './stub';
import template from './template';
import ui from './ui';
import user from './user';
import user_post from './user_post';
import video from './video';
import videos from './videos';

import {playlists, layout, tracks, tags} from './streams';

export default combineReducers({
  ads,
  articles,
  careers,
  gamecast,
  layout,
  nav,
  notification,
  page,
  playlists,
  profiles,
  promo,
  schedules,
  search,
  sections,
  sitemap,
  stub,
  tags,
  template,
  tracks,
  ui,
  user,
  user_post,
  video,
  videos,
});
