import debounce from 'lodash.debounce';
import * as StorageAPI from '../apis/storage_api';
import * as AdsAPIHelper from '../apis/helpers/ads_api_helper';
import * as IASOptimizationContext from '../apis/ads/ias_optimization';
import {replaceDashesWithUnderscores, sanitizeTitle} from '../helpers/stringHelpers';
import {GAMECAST} from '../constants/pageTypes';

import logger from '../logger';

import {
  APPNEXUS_MEMBER_MAPPING,
  CRITEO_NETWORK_ID,
  CRITEO_NETWORK_ID_INTL,
  CRITEO_ZONE_ID_INTL,
  IX_SITE_ID_MAPPING,
  RUBICON_ACCOUNT_ID,
  RUBICON_SITE_MAPPING,
  RUBICON_ZONE_MAPPING,
} from './tokens/ads';

import {SIZES} from '../constants/ads';
import {COUNTRY_CODES} from '../constants/locales';
import {createPromiseAndResolver} from './helpers/promises';
import {parseTagManagerObject} from './helpers/tagManager_helpers';
import {addClass, removeClass, appendStyle, removeElementBySelector} from './helpers/dom_helpers';

const networkId = 8663477;
const FULL_WIDTH = 'full_width';
const skinsCookie = 'disable_skins';
const disableAdsCookie = 'disable_ads';
const sessionCookieName = 'ads_session';
export let skin_data = null;

export let browserWidth = 0;
export let baseAdsData = {};

// the slots array holds {id, slot} objects
// it's filled by generateAdSlot (and anything that uses the export setSlots)
// it's used by reloadSlots and slotRenderEnded (and anything that uses the export getSlots)
export const slots = [];

// The allKnownSlots object is a mapping of domIds to Google.Slot objects.
const allKnownSlots = {};

/*
 – CONSENT_TIMEOUT is how long the Consent Management module waits to get consent
   data (from TagManager?)
 – PREBID_TIMEOUT is how long the Prebid (client-side) system waits for bidders
   to respond before considering them a no-bid for an ad slot. (We also use
   this to set up a failsafe.)
 – PREBID_SERVER_TIMEOUT is how long the Prebid Server (i.e. not client-side)
   system waits for bidders?
*/
const CONSENT_TIMEOUT = 1000;
const PREBID_TIMEOUT = 1150;

/*
  – BIDS_TIMEOUT is used by the Amazon ads system (A9).
 */
export const BIDS_TIMEOUT = 750;

const PREBID_SIZES = [SIZES.SIDEKICK, SIZES.LEADER, SIZES.MOBILE_LEADER, SIZES.MEDIUM_RECT];

export const SKIN_POSITION = 'oop_skin';
const MOBILE_DEVICE_WIDTH_CUTOFF = 768; // pixel width

export const AD_SIZES = [
  SIZES.SIDEKICK.array,
  SIZES.LEADER.array,
  SIZES.MOBILE_LEADER.array,
  SIZES.MEDIUM_RECT.array,
  SIZES.LARGE_MOBILE_BANNER.array,
].map((dimensions) => dimensions.join(','));

const AD_CATEGORIES = [
  'Boxing',
  'Cricket',
  'College_Basketball',
  'College_Football',
  'Ditch_the_Playbook',
  'Gaming',
  'gamecast',
  'Golf',
  'Horse_Racing',
  'Home_Page',
  'Kicks',
  'MLB',
  'MMA',
  'Motorsports',
  'NBA',
  'NFL',
  'NHL',
  'ROS', // "run of site" is an adtech term
  'Olympics',
  'Sports_Odds',
  'Take_it_There',
  'Tennis',
  'The_Champions',
  'Untold_Stories',
  'Video_Landing_Page',
  'video',
  'World_Football',
];
// For some reason, the alphabetical order is messed up
// because of B_R_Studios, thus disabling checking on this property.
// eslint-disable-next-line @br/laws-of-the-game/alphabetize-properties
const AD_CATEGORY_SYNONYMS = {
  AEW: 'pro_wrestling',
  All_Elite_Wrestling: 'pro_wrestling',
  B_R_Studios: 'Gridiron_Heights',
  home: 'Home_Page',
  NBA2K: 'Gaming',
  NFL_Fantasy: 'NFL',
  pro_wrestling: 'pro_wrestling',
  Simms_And_Lefkoe: 'Lefkoe_Show',
  Sports__Society: 'Sports_Odds',
  WNBA: 'NBA',
  Womens_College_Basketball: 'College_Basketball',
  WWE: 'pro_wrestling',
};

export function setTargeting(key, value, reset) {
  if (typeof value === 'undefined' || value === '') {
    return;
  }
  global.googletag.pubads().setTargeting(key, reset ? '' : value);
}

/**
 * Determines the Layout placement of a given Ad (full width || not)
 * @param {*} adUnitName
 */
export function determineAdLayout({adsData = {}, unitName} = {}) {
  let constructedUnitName = unitName;
  // If this is the full width layout
  if (adsData.layout === FULL_WIDTH) {
    constructedUnitName += `/${FULL_WIDTH}`;
  } else if (adsData.sitePlatform) {
    constructedUnitName += `/${adsData.sitePlatform}`;
  }
  return constructedUnitName;
}

function determineAdsTag(tag) {
  // Determines a high level tag for a given tag.
  // Ad sales breaks our content into these categories when putting together packages.
  if (AD_CATEGORIES.includes(tag)) {
    if (tag === 'Video_Landing_Page') {
      return 'Video';
    }
    return tag;
  }
  return AD_CATEGORY_SYNONYMS[tag] || 'ROS';
}

export function generateUnitName(adsData = baseAdsData) {
  let unitName = `/${networkId}/BR/`;
  const determinedSite = determineAdsTag(adsData.site);
  if (adsData.render_strategy && adsData.zone !== 'video') {
    unitName += `${determinedSite}/${adsData.render_strategy}`;
  } else if (adsData.zone === 'main' && adsData.site !== 'video') {
    unitName += `${determinedSite}/${adsData.zone}/web`;
  } else {
    unitName += `${determinedSite}/${adsData.zone}`;
  }
  return determineAdLayout({
    adsData,
    unitName,
  });
}

export function randomAdSessionId() {
  return String(Math.floor(Math.random() * 10));
}

export function optionallySetThenGetCookie(cookieName, valueCallback) {
  const initialValue = StorageAPI.get(cookieName, true);
  if (typeof initialValue !== 'undefined' && initialValue !== '') {
    return initialValue;
  }
  const newValue = valueCallback();
  StorageAPI.set(cookieName, newValue, true);
  return newValue;
}

export function generateGlobalTargeting(adsData = baseAdsData) {
  return function() {
    const wmukid = StorageAPI.get('datid', true) || StorageAPI.get('WMUKID_STABLE', true);

    setTargeting('app', `${adsData.embedded}`);
    setTargeting('cnn', `${adsData.cnn}`);
    setTargeting('social', adsData.social);
    setTargeting('cob', adsData.cobrand);
    setTargeting('layout', adsData.layout);
    setTargeting('sid', optionallySetThenGetCookie(sessionCookieName, randomAdSessionId));

    if (wmukid) {
      setTargeting('wmuk', wmukid);
    }

    // Try to access Krux
    try {
      if (global.Krux) {
        setTargeting('khost', encodeURIComponent(global.location.hostname));
        setTargeting('ksg', global.Krux.segments);
        setTargeting('kuid', global.Krux.user);
      }
    } catch (_err) {
      // do nothing
    }

    global.googletag.companionAds().setRefreshUnfilledSlots(true);
    global.googletag.pubads().enableAsyncRendering();
    global.googletag.pubads().enableSingleRequest();
    global.googletag.pubads().enableVideoAds();
    global.googletag.pubads().setCentering(true);
    if (wmukid) {
      global.googletag.pubads().setPublisherProvidedId(wmukid);
    }
    global.googletag.enableServices();
  };
}

export function generateVictoryTags(tags) {
  if (tags) {
    if (tags.includes('victory-winners')) {
      return 'winners';
    }
    if (tags.includes('victory-losers')) {
      return 'losers';
    }
  }
  return 'none';
}

export function generatePageTargeting(adsData = baseAdsData, referrer, reset) {
  const normalizedTags = replaceDashesWithUnderscores(adsData.tags);
  // Manually add 'gamecast' tag on gamecast pages
  const tags =
    adsData.pageType === GAMECAST
      ? normalizedTags
          .split(',')
          .concat('gamecast')
          .join(',')
      : normalizedTags;

  return function() {
    setTargeting('ARTICLE', `${adsData.article}`, reset);
    setTargeting('DIVISION', adsData.division, reset);
    setTargeting('EVENT', adsData.event, reset);
    setTargeting('TEAM', adsData.team, reset);
    setTargeting('tags', tags, reset);
    setTargeting('victory', generateVictoryTags(tags), reset);
    // sets the pg value in ad targeting.
    setTargeting('pg', AdsAPIHelper.determinePGValue(adsData), reset);

    if (adsData.buzz) {
      setTargeting('buzz', adsData.buzz, reset);
    } else {
      setTargeting('buzz', 'none', reset);
    }

    if (adsData.video_id) {
      setTargeting('video', `${adsData.video_id}`, reset);
    }

    // If there is an ad article id
    if (adsData.article) {
      let adArticleId = `${adsData.article}`.substr(-2);
      adArticleId = adArticleId.substring(0, 1) === '0' ? adArticleId.substr(-1) : adArticleId;
      setTargeting('aid', adArticleId, reset);
    }

    // If the gp flag is set
    if (adsData.gp_flag) {
      setTargeting('excl_cat', adsData.gp_flag.split('=')[1].replace(';', ''), reset);
    }

    // If the tag id is set
    if (adsData.tag_id) {
      setTargeting('tag_id', `${adsData.tag_id}`, reset);
    }

    // If the page is embedded and there is a referrer
    if (adsData.embedded && referrer) {
      let referrerStream = null;
      const streamPosition = referrer.lastIndexOf('/stream');
      if (streamPosition > 0) {
        const permalinkPosition = referrer.lastIndexOf('/', streamPosition - 1);
        // If the permalink was found
        if (permalinkPosition > 0) {
          referrerStream = referrer.substring(permalinkPosition + 1, streamPosition);
        }
      }

      // If the referrer stream was found
      if (referrerStream) {
        setTargeting('stream', referrerStream, reset);
      }
    }
  };
}

export function allowedBidAdSize(size) {
  // Note: these sizes are strings with comma separators
  return AD_SIZES.includes(size.toString());
}

export function formatAdSizes(sizes) {
  // Deduped sizes as strings
  return [...new Set(sizes.map((size) => size.toString()))];
}

// Get responsive size based on window width
export function responsiveSizeByWidth(sizes) {
  const totalSizes = sizes.length;
  let finalSize = [];

  for (let index = 0; index < totalSizes; index++) {
    const size = sizes[index];
    const sizeWidth = size[0][0];

    if (browserWidth > sizeWidth) {
      finalSize = size[1];
      break;
    }
  }

  // Array of sizes for current window width
  return finalSize;
}
function removeDuplicateSizes(sizes) {
  return sizes.reduce((uniqueArray, size) => {
    if (
      !uniqueArray.find(
        (item) => item.length > 1 && size.length > 1 && item[0] === size[0] && item[1] === size[1]
      )
    ) {
      uniqueArray.push(size);
    }
    return uniqueArray;
  }, []);
}
export function getAmazonBidsData(adSlots, unitName) {
  return adSlots.reduce((slots, slot) => {
    const ad = global.document.querySelector(`#${slot.id}`);
    if (!ad) {
      return slots;
    }

    const position = ad.getAttribute('data-position');
    // Exclude skins
    if (position && position.includes(SKIN_POSITION)) {
      return slots;
    }
    // Other slots
    const adSize = JSON.parse(ad.getAttribute('data-size'));
    const responsiveSize = JSON.parse(ad.getAttribute('data-responsive-size'));
    const slotID = ad.id;
    const slotName = unitName ? `${unitName}/${position}` : '';
    const allowedSizes = [...adSize, ...responsiveSizeByWidth(responsiveSize)].filter(
      allowedBidAdSize
    );

    // Removing duplicate sizes
    const sizes = removeDuplicateSizes(allowedSizes);
    return [
      ...slots,
      {
        slotID,
        slotName,
        sizes,
      },
    ];
  }, []);
}

export function sortBySize(currentSize, nextSize) {
  const [currentWidth, currentHeight] = currentSize.split(',');
  const [nextWidth, nextHeight] = nextSize.split(',');
  if (currentWidth - nextWidth === 0) {
    return currentHeight - nextHeight;
  }
  return currentWidth - nextWidth;
}

export function sortAdSizes(sizes) {
  return sizes.sort(sortBySize).reverse();
}

export function selectLargestAdSize(sizes) {
  const orderedBySize = sortAdSizes(sizes);
  return orderedBySize.length && orderedBySize[0];
}

export function fetchAmazonBids(adSlots, unitName) {
  const {promise, resolver} = createPromiseAndResolver();
  // Resolve with empty bids if timed out (default when no bids are returned from call)
  const timer = setTimeout(resolver, BIDS_TIMEOUT, []);
  if (global.apstag && global.apstag.fetchBids) {
    const slots = getAmazonBidsData(adSlots, unitName);
    // Resolve with bids response otherwise
    global.apstag.fetchBids(
      {
        slots,
        timeout: BIDS_TIMEOUT,
      },
      (bids) => {
        clearTimeout(timer);
        resolver(bids);
      }
    );
  }

  return promise.then((bids) => {
    global.googletag.cmd.push(() => {
      global.apstag.setDisplayBids();
    });

    return bids;
  });
}

const emailRegex = /(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})/i;

// Returns true if an email address is found in the request
export function isEmailAddressInRequest(url = false) {
  const href =
    url || (global.document && global.document.location.href ? global.document.location.href : '');
  const decodedHref = global.decodeURIComponent(href) || '';
  const referrer = (global.document && global.document.referrer) || '';
  const decodedReferrer = global.decodeURIComponent(referrer) || '';
  // If an email address is found in the request
  if (emailRegex.test(`${href} ${referrer} ${decodedHref} ${decodedReferrer}`)) {
    logger.warn('Refusing to load ads: an email address was found in the request.');
    return true;
  }
  return false;
}

export function isDisabled() {
  const isNode = process && process.browser !== true;
  if (isNode) {
    return true;
  }
  if (global.location && global.location.search.includes('ads=0')) {
    return true;
  }
  if (StorageAPI.get(disableAdsCookie, true)) {
    return true;
  }
  if (isEmailAddressInRequest()) {
    return true;
  }
  return false;
}

function isVisitorInUS() {
  if (!global.brVisitorLocale) {
    // this will happen in Dev, because Gatekeeper receives the IP 127.0.0.1 and doesn't know what to make of it
    return true; // default to USA behavior if detection somehow fails
  }
  // This should be the `country` value, from Gatekeeper's user data.
  // GK sometimes gives "US" and sometimes "USA"?
  return [COUNTRY_CODES.US, COUNTRY_CODES.USA].includes(global.brVisitorLocale);
}

function isVisitorOnMobile() {
  return !!global.brIsVisitorOnMobile; // this value is set by the Advert component on mount.
}

// exported for testing, not as an API endpoint
export function buildAppNexusId(tag, adDomElement) {
  // Constructs a string for AppNexus Inv Code, such as "BR_Home_bnr_atf_01" or "BR_INTL_NBA_bnr_atf_03"
  const invCodeParts = ['BR'];
  if (!isVisitorInUS()) {
    invCodeParts.push('INTL');
  }
  invCodeParts.push(determineAdsTag(tag));
  invCodeParts.push(adDomElement.getAttribute('data-position'));
  return invCodeParts.join('_');
}

function determineIXSiteId(adDomElement) {
  const locale = isVisitorInUS() ? 'domestic' : 'international';
  const deviceVariation = isVisitorOnMobile() ? 'mobile' : 'desktop';
  const position = adDomElement.getAttribute('data-position');
  const siteMapping = IX_SITE_ID_MAPPING[locale][deviceVariation][position];
  if (siteMapping) {
    return siteMapping.toString();
  }
  global.console.error('Could not determine IX Site ID for', position);
  return '0';
}

function numericSortByChildOfChild([[aVal]], [[bVal]]) {
  return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
}

/**
 * Check whether a given ad size is supported by the Prebid Ads system.
 *
 * @param {array,string} size [integer, integer] = width, height OR a string "fluid"
 * @returns {boolean} whether or not the specified size can be handled by Prebid
 */
function isSizeOkayForPrebid(size) {
  if (size === 'fluid') {
    return false;
  }
  // On a wide screen, filter out any instances of the 320x50 (MOBILE_LEADER) size.
  // These can be substituted in to replace whenever "fluid" is in the list of sizes,
  // but Prebid doesn't understand "fluid", and we don't want a 320px-wide ad to appear
  // in a wide browser.
  if (global.innerWidth > MOBILE_DEVICE_WIDTH_CUTOFF && SIZES.MOBILE_LEADER.equalsArray(size)) {
    return false;
  }
  return PREBID_SIZES.some((prebidValidAdSize) => prebidValidAdSize.equalsArray(size));
}
function extractPrebidSizes(adDomElement) {
  const responsiveSizes = JSON.parse(adDomElement.getAttribute('data-responsive-size'));
  const appropriateSizesForBrowserWidth = responsiveSizes
    .sort(numericSortByChildOfChild)
    .reduce((appropriateSizes, [minBrowserDimensions, arrSizes]) => {
      // Determine which responsiveSize array is appropriate to use for the current browser width.
      // The responsiveSize array's first element is an array of [width, height] specifying the lower bound limit of browser dimensions;
      // the second element is an array containing [width, height] pairs which are the ad dimensions that are appropriate for a browser of at least the specified dimensions.
      if (global.innerWidth > minBrowserDimensions[0]) {
        return arrSizes; // not returning `acc` because this is the new most-appropriate-array
      }
      return appropriateSizes;
    }, []);
  return appropriateSizesForBrowserWidth.filter(isSizeOkayForPrebid);
}

function fetchPrebidBids(adSlots, unitName) {
  const googletag = global.googletag || {};
  googletag.cmd = googletag.cmd || [];
  const content_words = AdsAPIHelper.getKVMetaData();
  const {promise, resolver} = createPromiseAndResolver();
  const [_blank, _number, _BR, tag, _type] = unitName.split('/');
  googletag.cmd.push(() => {
    const platform = isVisitorOnMobile() ? 'mobile' : 'desktop';
    const locale = isVisitorInUS() ? 'domestic' : 'international';
    setTargeting('hbg', locale === 'domestic' ? COUNTRY_CODES.US : COUNTRY_CODES.EU); // Note: EU for all non-US traffic, not just European countries
    const adUnits = adSlots
      .map((adSlot) => {
        const adDomElement = global.document.getElementById(adSlot.id);
        const sizes = extractPrebidSizes(adDomElement);
        if (!sizes || !sizes.length) {
          // Don't bid if none of the sizes are  appropriate for Prebid.
          // Returning null here, to be filtered out after this `.map()`.
          return null;
        }
        const bidders = [
          {
            bidder: 'appnexus',
            params: {
              member: APPNEXUS_MEMBER_MAPPING[locale],
              invCode: buildAppNexusId(tag, adDomElement),
              keywords: {
                metaTags: content_words,
              },
            },
          },
          {
            bidder: 'ix', // index exchange
            params: {
              siteId: determineIXSiteId(adDomElement),
              size: sizes[0],
            },
          },
          {
            bidder: 'rubicon',
            params: {
              accountId: RUBICON_ACCOUNT_ID[locale],
              siteId: RUBICON_SITE_MAPPING[locale][platform],
              zoneId: RUBICON_ZONE_MAPPING[locale][platform],
            },
          },
        ];
        if (isVisitorInUS()) {
          bidders.push({
            bidder: 'criteo',
            params: {
              networkId: CRITEO_NETWORK_ID,
            },
          });
        } else {
          bidders.push({
            bidder: 'criteo',
            params: {
              networkId: CRITEO_NETWORK_ID_INTL,
              zoneId: CRITEO_ZONE_ID_INTL,
            },
          });
        }
        return {
          code: adSlot.id,
          mediaTypes: {
            banner: {
              sizes,
            },
          },
          bids: bidders,
        };
      })
      .filter((obj) => !!obj); // filter out nulls
    const pbjs = global.pbjs || {};
    pbjs.que = pbjs.que || [];
    pbjs.que.push(function() {
      pbjs.addAdUnits(adUnits);
      pbjs.requestBids({
        adUnitCodes: adUnits.map((unit) => unit.code),
        bidsBackHandler: (bids, timedOut) => {
          resolver(bids, timedOut);
        },
      });
    });
  });
  setTimeout(() => {
    // in case PBJS fails to load...
    resolver([], true);
  }, PREBID_TIMEOUT + 50);

  return promise;
}

/**
 * Note: adSlots is an array of either DOM elements, or objects with the shape {id, adSlot} where adSlot is a DOM element
 */
export function fetchBids(adSlots, unitName) {
  return Promise.all([fetchPrebidBids(adSlots, unitName), fetchAmazonBids(adSlots, unitName)]);
}

export function reloadSlots(adsData = baseAdsData) {
  return () => {
    if (isDisabled()) {
      return false;
    }
    if (!browserWidth && global.innerWidth) {
      browserWidth = global.innerWidth;
    } else if (global.innerWidth && slots.length && browserWidth !== global.innerWidth) {
      // If there are responsive ad slots and the browser width changed
      const slotList = slots.map((slot) => slot.slot);
      browserWidth = global.innerWidth;
      global.googletag.pubads().clear(slotList);
      const pbjs = global.pbjs || {};
      pbjs.que = pbjs.que || [];
      return fetchBids(slots, generateUnitName(adsData)).then(() => {
        pbjs.que.push(() => {
          pbjs.setTargetingForGPTAsync(slots.map((slot) => slot.id));
          global.googletag.pubads().refresh();
        });
      });
    }
    return false;
  };
}

export function generateAdSlot(ad, unitName) {
  const googletag = global.googletag || {};
  googletag.cmd = googletag.cmd || [];
  return function() {
    googletag.cmd.push(() => {
      const divId = ad.getAttribute('id');
      const responsiveSizes = JSON.parse(ad.getAttribute('data-responsive-size'));
      const slot = global.googletag
        .defineSlot(unitName, JSON.parse(ad.getAttribute('data-size')), divId)
        .addService(global.googletag.pubads())
        .setTargeting('pos', ad.getAttribute('data-position'));
      // Now we setup responsive sizes
      const mapping = global.googletag.sizeMapping();
      // Loop through the responsive sizes
      for (let index = 0, length = responsiveSizes.length; index < length; index++) {
        mapping.addSize(responsiveSizes[index][0], responsiveSizes[index][1]);
      }

      slot.defineSizeMapping(mapping.build());
      slots.push({id: divId, slot});
      allKnownSlots[divId] = slot;
      IASOptimizationContext.iasPixelOptimization(divId);
    });
  };
}

export function slotRenderEnded(event) {
  slots.forEach((slot) => {
    // If we found the matching slot for this event
    if (slot.slot === event.slot) {
      const elem = global.document.querySelector(`#${slot.id}`);
      const wrapper = elem.parentNode;
      // If the event is not empty (ad was loaded)
      if (!event.isEmpty) {
        elem.classList.add('loaded');
      } else {
        wrapper.classList.add('collapsed');
      }
    }
  });
}

export function loadSlots(slots = [], adsData = baseAdsData) {
  if (isDisabled()) {
    return false;
  }
  if (!browserWidth && global.innerWidth) {
    browserWidth = global.innerWidth;
  }
  const unitName = generateUnitName(adsData);
  const debouncedHandler = debounce(() => {
    global.googletag.cmd.push(reloadSlots());
  }, 250);
  global.addEventListener('resize', debouncedHandler);

  const cmds = [];

  slots.forEach((slot) => {
    cmds.push(generateAdSlot(slot, unitName));
  });

  cmds.push(() => {
    return fetchBids(slots, unitName)
      .then(() => {
        const pbjs = global.pbjs || {};
        pbjs.que = pbjs.que || [];
        pbjs.que.push(function() {
          pbjs.setTargetingForGPTAsync(slots.map((slot) => slot.id));
          global.googletag.pubads().refresh(slots.map((slot) => allKnownSlots[slot.id]));
          global.googletag.pubads().addEventListener('slotRenderEnded', slotRenderEnded);
        });
      })
      .catch((error) => {
        throw error;
      });
  });

  // We spread the commands so they can all be executed together by GPT.
  // It would otherwise incurr extra function call overhead.
  return global.googletag.cmd.push(...cmds);
}

export function loadSingleSlot(slots = [], adsData = baseAdsData) {
  if (isDisabled()) {
    return false;
  }
  const unitName = generateUnitName(adsData);
  const cmds = [];

  slots.forEach((slot) => {
    cmds.push(generateAdSlot(slot, unitName));
  });

  if (cmds.length) {
    cmds.push(() => {
      return fetchBids(slots, unitName)
        .then(() => {
          // Check on an interval if GPT is ready (otherwise the refresh call fails)
          // This can happen for lazy load slots that are trying to load immediately
          // (For example if the user is scrolled down the page and refreshes)
          const pbjs = global.pbjs || {};
          pbjs.que = pbjs.que || [];
          pbjs.que.push(function() {
            if (global.googletag.pubadsReady) {
              pbjs.setTargetingForGPTAsync(slots.map((slot) => slot.id));
              global.googletag.pubads().refresh(slots.map((slot) => allKnownSlots[slot.id]));
            } else {
              const statusCheck = setInterval(function slotsReady() {
                if (global.googletag.pubadsReady) {
                  clearInterval(statusCheck);
                  // Make sure that we have slots to refresh or GPT will throw a warning
                  if (slots.length) {
                    pbjs.setTargetingForGPTAsync(slots.map((slot) => slot.id));
                    global.googletag.pubads().refresh(slots.map((slot) => allKnownSlots[slot.id]));
                  }
                }
              }, 250);
            }
          });
        })
        .catch((err) => {
          global.console.warn('loadSingleSlot THREW ERROR', err);
          throw err;
        });
    });
    // We spread the commands so they can all be executed together by GPT.
    // It would otherwise incurr extra function call overhead.
    return global.googletag.cmd.push(...cmds);
  }
  return false;
}

// Next is the skins code
// Removes the skin
export function removeSkin() {
  removeElementBySelector('#skin-styles');
  removeClass(global.document.querySelector('html'), 'skin');
}

export function setupStyles({
  skin_image: image,
  skin_top_position: position,
  skin_color: color,
  skin_fixed: fixed,
}) {
  // If there was a skin image and it is different
  const skinImageIsDifferent = skin_data && skin_data.skin_image && skin_data.skin_image !== image;
  if (skinImageIsDifferent) {
    removeSkin();
  }
  // If the skin color is set and is not zero (the default from DFP)
  const skin_color =
    color && color !== '0'
      ? `
		html.skin {
			background-color: #${color};
		}`
      : '';
  // If there is a skin image and it is different
  const container_styles =
    image && (!skin_data || image !== skin_data.skin_image)
      ? `
		#outer-container {
			background-image: url(${image});
			background-position: 50% ${position || 0}px;
			background-repeat: no-repeat;
			background-attachment: ${fixed ? 'fixed' : 'scroll'};
		}`
      : '';
  // now build the final styles
  const skin_styles = `
		${skin_color}
		${container_styles}
	`.trim();

  if (skin_styles) {
    appendStyle(skin_styles);
    addClass(global.document.querySelector('html'), 'skin');
  }
}

// Removes the skin pixels
export function removePixels() {
  // If there are pixels
  if (skin_data && skin_data) {
    removeElementBySelector('#pixels img.br-pixel-skin, #pixels img.br-pixData-skin');
  }
}

// Sets up the skin pixels
export function setupPixels(pixels) {
  removePixels();
  // If there are pixels
  if (pixels) {
    pixels.forEach(function(pixData) {
      // If the pixel is set
      if (pixData) {
        let src;
        let className;
        // If the pixel doesn't already have an ord
        if (pixData.indexOf('ord=') < 0) {
          src = `${pixData}?ord=${Math.floor(Math.random() * 10e12)}`;
          className = 'br-pixData-skin';
        } else {
          src = pixData;
          className = 'br-pixel-skin';
        }
        const pixel = document.createElement('img');
        pixel.src = src;
        pixel.alt = 'BR-Live pixel skin';
        pixel.className = className;

        pixel.onerror = (event) => {
          event.target.style.display = 'none';
        };
        global.document.querySelector('#pixels').appendChild(pixel);
      }
    });
  }
}
//TODo : This is a temporary approach - We have plan to refactor : RWB-1405
const adSkinAreas = ['ad', 'br-ad-wrapper', 'br-ad'];
function isSkinClicked(element) {
  if (element.id === 'main-content' || element.parentNode.id === 'main-content') {
    return true;
  }
  //If top banner wrappers (except the iframe) is clicked
  const classNames =
    element.parentNode &&
    element.parentNode.className !== '' &&
    element.parentNode.className.split(' ');
  if (classNames) {
    const isAdClicked = classNames.some((className) => adSkinAreas.includes(className));
    if (isAdClicked) {
      const adContainer = element.closest('div.ad');
      const isTopBanner = adContainer !== null && adContainer.parentNode.id === 'outer-container';
      return isTopBanner;
    }
  }
  return false;
}
// Handles the skin being clicked on
export function skinClick(event) {
  // If the body is clicked on
  if (isSkinClicked(event.target) && event.currentTarget.id === 'outer-container') {
    window.open(skin_data.skin_click_tag);
  }
}

// Removes the skin events
export function removeEvents() {
  const container = global.document.querySelector('#outer-container');
  return container ? container.removeEventListener('click', skinClick) : false;
}

// Sets up the skin events
export function setupEvents(event) {
  // If there was a skin click tag and it is different
  if (skin_data && skin_data.skin_click_tag && skin_data.skin_click_tag !== event) {
    removeEvents();
  }

  // If there is a skin click tag and it is different
  if (event && (!skin_data || event !== skin_data.skin_click_tag)) {
    global.document.querySelector('#outer-container').addEventListener('click', skinClick);
  }
}

export function remove3rdPartyTrackingCode() {
  removeElementBySelector('#thirdpartytracker-skins');
}

export function setup3rdPartyTrackingCode(url = false) {
  if (url && (!skin_data || url !== skin_data.third_party_url)) {
    remove3rdPartyTrackingCode();

    const app = global.document.querySelector('#app');
    const appParent = app.parentNode;
    const trackingScript = global.document.createElement('script');

    trackingScript.type = 'text/javascript';
    trackingScript.id = 'thirdpartytracker-skins';
    appParent.insertBefore(trackingScript, app);
    trackingScript.src = url;
  }
}
export function removeDoubleVerifyViewablity() {
  removeElementBySelector('#br_skin_dv');
}

export function setupDoubleVerifyViewablity(url = false) {
  if (url && (!skin_data || url !== skin_data.dv_url)) {
    removeDoubleVerifyViewablity();

    const adRail = global.document.querySelector('#ad-skin-measurability-r');
    const iframe = global.document.createElement('iframe');
    iframe.id = 'br_skin_dv';
    if (!iframe.style) iframe.style = {};
    iframe.style.backgroundColor = 'transparent';
    iframe.style.border = 'transparent';
    iframe.width = '15';
    iframe.height = '100';
    const dvScript = global.document.createElement('script');
    dvScript.type = 'text/javascript';
    dvScript.src = url;
    dvScript.id = 'doubleverify-skins';
    iframe.addEventListener(
      'load',
      function() {
        iframe.contentDocument.body.appendChild(dvScript);
      },
      false
    );
    adRail.appendChild(iframe);
  }
}
export function setup(data) {
  try {
    // If the skins cookie is not set
    if (!StorageAPI.get(skinsCookie, true) && data) {
      logger.info('Setting up the current Ads Skin');
      setupStyles(data);
      setupPixels(data.pixels);
      setupEvents(data.skin_click_tag);
      setup3rdPartyTrackingCode(data.third_party_url);
      setupDoubleVerifyViewablity(data.dv_url);
      skin_data = data;
    }
  } catch (err) {
    logger.warn('Failed to setup Skin', err);
  }
}

export function noSkin() {
  logger.info('Clearing any Ads Skin data.');
  if (skin_data) {
    removeSkin();
    removePixels();
    removeEvents();
    skin_data = null;
  }
}

export function skinsSetup(hideSkins = false) {
  return hideSkins || isDisabled()
    ? {
        setup: () => {}, // do nothing. We don't need skins.
        noSkin,
      }
    : {
        setup,
        noSkin,
      };
}

export function setAdsData(data) {
  baseAdsData = data;
}

export function getAdsData() {
  return baseAdsData;
}

export function getSkinData() {
  return skin_data;
}

export function setSkinData(data = {}) {
  skin_data = data;
}

export function clearSlots() {
  slots.length = 0;
}

export function setSlots(data = []) {
  clearSlots();
  slots.push(...data);
}

export function getSlots() {
  return slots;
}

export const initAmazonAds = (dataLayer) => {
  if (global.apstag && global.apstag.init) {
    const usPubId = '3159';
    const internationalPubId = '3288';
    const adServer = 'googletag';
    const pubID = [COUNTRY_CODES.CA, COUNTRY_CODES.US, COUNTRY_CODES.USA].includes(
      dataLayer.country_code
    )
      ? usPubId
      : internationalPubId;
    global.apstag.init({
      pubID,
      adServer,
    });
  } else {
    logger.error('Error initalizing Amazon Ads');
  }
};

export const initAds = (dataLayer) => {
  const {promise, resolver} = createPromiseAndResolver();
  if (!global.googletag) {
    global.googletag = {};
  }
  if (!global.googletag.cmd) {
    global.googletag.cmd = [];
  }
  global.googletag.cmd.push(function() {
    global.googletag.pubads().disableInitialLoad();
    const sanitizedTitle = sanitizeTitle(dataLayer.video_title);
    const adsData = parseTagManagerObject({
      article: dataLayer.article_id,
      buzz: dataLayer.buzz,
      cnn: dataLayer.cnn,
      division: dataLayer.division,
      embedded: dataLayer.embedded,
      event: dataLayer.event,
      fantasy: dataLayer.fantasy,
      gp_flag: dataLayer.gp_flag,
      layout: dataLayer.layout,
      league: dataLayer.league,
      override: dataLayer.override,
      pageType: dataLayer.tag_manager_event,
      render_strategy: dataLayer.render_strategy,
      site: dataLayer.site,
      social: dataLayer.social,
      tag_id: dataLayer.tag_id,
      tags: dataLayer.tags.replace('The_Match_135296', 'The_Match'), //This is a one-off solution since we are not able to change the underlying event type in Faust (https://statmilk.atlassian.net/browse/WEB-296)
      team: dataLayer.team,
      video_id: dataLayer.video_id,
      writer: dataLayer.author_name,
      zone: dataLayer.zone,
    });
    if (typeof adsData.social !== 'string') {
      adsData.social = adsData.social.toString();
    }
    if (adsData.site === 'video') {
      if (adsData.tags.length > 0) {
        adsData.tags += ',';
      }
      adsData.tags += `video_player,${sanitizedTitle}`;
      adsData.zone = 'main';
    }

    const adsDisabled = isDisabled() || adsData.override;
    if (adsDisabled) {
      return;
    }

    const priceBuckets = {
      buckets: [
        {
          precision: 2,
          min: 0,
          max: 5,
          increment: 0.01,
        },
        {
          precision: 2,
          min: 5,
          max: 30,
          increment: 0.05,
        },
        {
          precision: 2,
          min: 30,
          max: isVisitorInUS() ? 115 : 50,
          increment: 1.0,
        },
      ],
    };

    const pbjs = global.pbjs || {};
    pbjs.que = pbjs.que || [];
    pbjs.que.push(function() {
      pbjs.setConfig({
        bidderTimeout: PREBID_TIMEOUT, // how long the Prebid system waits for bidders to respond before considering them a no-bid for an ad slot
        ...(isVisitorInUS()
          ? {}
          : {
              consentManagement: {
                gdpr: {
                  cmpApi: 'iab',
                  timeout: CONSENT_TIMEOUT,
                  allowAuctionWithoutConsent: false,
                },
                gpp: {
                  cmpApi: 'iab',
                  timeout: CONSENT_TIMEOUT,
                },
              },
            }),
        priceGranularity: priceBuckets,
        userSync: isVisitorInUS()
          ? {}
          : {
              userIds: [
                {
                  name: 'id5Id',
                  params: {
                    partner: 963,
                  },
                  storage: {
                    type: 'html5', // 'html5' is the required storage type
                    name: 'id5id', // 'id5id' is the required storage name
                    expires: 90, // storage lasts for 90 days
                    refreshInSeconds: 8 * 3600, // refresh ID every 8 hours to ensure it's fresh
                  },
                },
              ],
              auctionDelay: 250, // 250ms maximum auction delay, applies to all userId modules
            },
      });
      pbjs.aliasBidder('appnexus', 'pangaea');
      pbjs.bidderSettings = {
        appnexus: {
          adserverTargeting: [
            {
              key: 'hb_appnexus_tier',
              // disabling eslint rule because this implementation is from AppNexus:
              // "to add in the custom key-value to prioritize higher deal tiers differently in Google as we are doing for the rest of the Turner sites"
              /* eslint-disable-next-line consistent-return */
              val(bidResponse) {
                if (bidResponse.appnexus.dealPriority !== null) {
                  return bidResponse.appnexus.dealPriority;
                }
              },
            },
          ],
        },
      };
      resolver();
    });
    // retrofit the tags for specific items
    adsData.tags = AdsAPIHelper.retrofitTags(adsData.tags);
    setAdsData(adsData); // n.b. this mutates `baseAdsData` which many functions have as a default parameter
    global.googletag.cmd.push(
      generateGlobalTargeting(adsData),
      generatePageTargeting(adsData, global.document.referrer || adsData.referrer, false)
    );
    const DOMslots = [...global.document.querySelectorAll('.br-ad')]; // convert the NodeList into an Array.
    loadSlots(DOMslots);
  });
  return promise;
};
