import {createAction} from 'redux-actions';
import * as StorageAPI from '../apis/storage_api';
import * as AppActions from '../actions/appActions';
import {appInstallLink, brDeeplinkPath, determineBranchChannel} from '../helpers/promoHelpers';
import {makeCookieName, midnightTonight} from './helpers';

const updateNotificationDataAction = createAction('UPDATE_NOTIFICATION_DATA');
const updateNotificationData = (notification) => {
  return (dispatch, getState) => {
    const {page, ui} = getState();
    const deepLinkPath = brDeeplinkPath(page);
    const channel = determineBranchChannel(ui.customBrowser);
    return dispatch(
      updateNotificationDataAction({
        notification,
        interstitialInstallLink: appInstallLink(
          ui.userAgent,
          'interstitial',
          deepLinkPath,
          channel
        ),
      })
    );
  };
};

function yearFromNow() {
  const date = new Date();
  date.setYear(date.getFullYear() + 1);
  return date;
}

const closeNotification = (event, notificationName) => {
  return (dispatch, getState) => {
    if (event) {
      event.preventDefault();
    }
    const cookieName = makeCookieName(notificationName);
    if (notificationName === 'mobile_interstitial') {
      StorageAPI.set(cookieName, true, {expires: midnightTonight()});
      // ...and don't close the interstitial
    } else {
      if (notificationName === 'privacy_notice') {
        StorageAPI.set(cookieName, true, {expires: yearFromNow()});
      } else if (notificationName === 'smart_banner') {
        StorageAPI.set(cookieName, true, {expires: midnightTonight()});
      } else {
        StorageAPI.set(cookieName, true, true);
      }
      dispatch(updateNotificationData({name: notificationName, value: false})); // value=false closes the interstitial
    }

    // https://statmilk.atlassian.net/browse/CONS-2936
    const {articles, user, page, ui, notification} = getState();
    const hideSkin = articles.hide_skin;
    return dispatch(AppActions.setUiShowData({hideSkin, user, page, ui, notification}));
  };
};

const checkNotification = (notificationName, useCookie = true) => {
  return (dispatch, getState) => {
    const cookieValue = StorageAPI.get(makeCookieName(notificationName), true);
    const shouldDisplayNotification = useCookie
      ? !cookieValue
      : getState().notification[notificationName];

    dispatch(updateNotificationData({name: notificationName, value: shouldDisplayNotification}));

    // https://statmilk.atlassian.net/browse/CONS-2936
    const {articles, user, page, ui, notification} = getState();
    const hideSkin = articles.hide_skin;
    return dispatch(AppActions.setUiShowData({hideSkin, user, page, ui, notification}));
  };
};

export {closeNotification, checkNotification, updateNotificationData};
