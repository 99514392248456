const initialState = {};

function search(state = initialState, action) {
  switch (action.type) {
    case 'GET_CAREERS': {
      const departments = action.payload.departments;
      if (departments.length) {
        return {
          jobs: action.payload.jobs.filter((job) =>
            departments.includes(job.department.toLowerCase())
          ),
        };
      }
      return {
        jobs: action.payload.jobs,
      };
    }
    default:
      return state;
  }
}

export default search;
