// Store operataes on localStorage only. It's important to note that its a no-op in Node.
// To test it it requires a `global.localStorage` interface of some kind.
import store from 'store';
// Cookie merely provides operates to create/set/get strings from cookie data passed to it.
// The server or browser is responsible for setting cookies.
// Due to this the "set" and "remove" opts here are not that useful for server side.
// Cookies _should be avoided_ if at all possible.
import cookie from 'cookie';
import {BR} from '../endpoints';

export const baseCookieConfig = {
  domain: `.${BR.host()}`,
  isSecure: false, // hapi config
  isHttpOnly: false, // hapi config
  secure: false, // generic cookie config
  httpOnly: false, // generic cookie config
  path: '/',
};
export const oneSecond = 1000;
export const oneMinute = 60 * oneSecond;
export const oneHour = 60 * oneMinute;
export const oneDay = 24 * oneHour;
export const oneWeek = 7 * oneDay;
export const oneMonth = 30 * oneDay; // just an average (technically 30.44, if you want to be pedantic)
export const oneYear = 365 * oneDay;

// This is _just_ after the UNIX epoch (1am, Jan 1st, 1970)
// Some software can be weird about the exact midnight epoch, just best be careful.
export const expiryDate = new Date(1970, 0, 1, 1);

// Really simple check, could have additional validation but isn't really required.
const isCookieData = (data) => {
  return data && typeof data === 'string';
};

// Get data from localStorage of a key or parse the cookie data for the key.
export const get = (key, cookieData) => {
  if (cookieData) {
    if (isCookieData(cookieData)) {
      return cookie.parse(cookieData)[key];
    } else if (global.document && global.document.cookie) {
      return cookie.parse(global.document.cookie)[key];
    }
  }
  return store.get(key);
};

// Sets a localStorage key, or sets and returns a cookie string with various options.
// This method, while useful for the browser, is not very useful on the server.
export const set = (key, value, cookieOpts) => {
  if (cookieOpts && global.document && global.document.cookie) {
    const opts =
      typeof cookieOpts === 'object' ? {...baseCookieConfig, ...cookieOpts} : baseCookieConfig;
    const cookieString = cookie.serialize(key, value, opts);
    global.document.cookie = cookieString;
    return cookieString;
  }

  return store.enabled ? store.set(key, value) : null;
};

// Checks to see if a value exists, returns true if it does, false if not.
export const exists = (key, cookieData) => {
  if (isCookieData(cookieData)) {
    const data = cookie.parse(cookieData);
    return typeof data[key] !== 'undefined';
  }
  const data = store.get(key);
  return typeof data !== 'undefined';
};

// Returns all the data available from storage.
// If passed a cookie string it will return all the parsed key/value pairs.
export const getAll = (cookieData) => {
  if (isCookieData(cookieData)) {
    return cookie.parse(cookieData);
  }
  return store.getAll();
};

// Removes data from localStorage or, if is a cookie, will return a cookie string set to expire instantly
// Key can be a single string or an array of strings
export const remove = (key, isCookie) => {
  if (Array.isArray(key)) {
    return key.map((key) => {
      return remove(key, isCookie);
    });
  }
  if (isCookie) {
    const baseCookie = cookie.serialize(key, 'removed', {
      ...baseCookieConfig,
      expires: expiryDate,
      domain: baseCookieConfig.domain || BR.host(),
    });
    global.document.cookie = baseCookie;
    // now we do it without a specific domain - backwards compatibility.
    // We can remove this after ~1 month as that's our cookie expiry time.
    const baseCookieNoDomain = cookie.serialize(key, 'removed', {
      ...baseCookieConfig,
      domain: false,
      expires: expiryDate,
    });
    global.document.cookie = baseCookieNoDomain;
    return baseCookie;
  }
  return store.remove(key);
};

// Works the same as remove but clears all keys. For cookies it returns an array of cookie strings.
export const clearAll = (cookieData) => {
  if (isCookieData(cookieData)) {
    const data = cookie.parse(cookieData);
    return Object.keys(data).map((key) => {
      return cookie.serialize(key, 'removed', {expires: expiryDate});
    });
  }
  return store.clear();
};

export const deauthorize = (redirectURL = '/sign_in') => {
  // Forces log out then redirects to the redirectURL (default is login)
  if (global.document) {
    // First, clear all possible cookies.
    remove(['br_user', 'br_jwt', 'br_jwt_refresh', 'br_userid'], true);
    // Now redirect to login
    global.document.location.href = redirectURL;
  } else {
    throw new Error('serverside deauthorize required');
  }
};

export const encodeUser = (user) => {
  return Buffer.from(JSON.stringify(user)).toString('base64');
};

export function setAccountCookies({auth, user}) {
  // Overwrite encoding function because == at end of base64 encoding converted to %3D%3D
  const encode = (cookie) => cookie;

  if (auth) {
    set('br_jwt', auth.access_token, {...baseCookieConfig, encode, ttl: oneMonth});
    set('br_jwt_refresh', auth.refresh_token, {...baseCookieConfig, encode, ttl: oneMonth});
  }
  if (user) {
    set('br_user', encodeUser(user), {
      ...baseCookieConfig,
      encode,
    });
  }
}

export default {deauthorize, get, set, setAccountCookies, exists, getAll, remove, clearAll};
