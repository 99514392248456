export enum Color {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  Disabled = 'Disabled',
  Inherit = 'Inherit',
}

export enum UserType {
  Anonymous = 'Anonymous',
  Registered = 'Registered',
}
