import {createStore, applyMiddleware} from 'redux';
import promiseMiddleware from 'redux-promise';
import thunkMiddleware from 'redux-thunk';
import reducers from '../reducers';
import logger from '../logger';

const errorHandler = () => {
  return (next) => {
    return (action) => {
      if (action.error) {
        logger.error(`\x1b[91m 💩 Action Error: ${action.payload.message} \x1b[39m`, action);
        throw action.payload;
      }
      return next(action);
    };
  };
};

const createStoreWithMiddleWare = applyMiddleware(
  promiseMiddleware,
  thunkMiddleware,
  errorHandler
)(createStore);
const devToolsMiddleware = global.__REDUX_DEVTOOLS_EXTENSION__
  ? global.__REDUX_DEVTOOLS_EXTENSION__()
  : (func) => func;

const store = (state = {}) => {
  const initialState = global.INITIAL_STORE_STATE || state;
  return createStoreWithMiddleWare(reducers, initialState, devToolsMiddleware);
};

export default store;
