import React from 'react';
import classnames from 'classnames';
import debounce from 'lodash.debounce';

import {Button, SVG} from '@br/br-components';
import {isFullyInViewport} from '../helpers/domHelpers';

class MobileInterstitial extends React.PureComponent {
  constructor(props) {
    super(props);
    this.firePromoSelectedEvent = this.firePromoSelectedEvent.bind(this);
    this.handleScrollEvent = debounce(this.handleScrollEvent.bind(this), 66);
    this.isPromoImpressionTracked = false;
  }

  componentDidMount() {
    this.props.onCloseNotification(null, 'mobile_interstitial');

    if (isFullyInViewport(this.button)) {
      this.firePromoImpressionEvent();
    }

    global.addEventListener('scroll', this.handleScrollEvent, false);
  }

  componentWillUnmount() {
    global.removeEventListener('scroll', this.handleScrollEvent, false);
  }

  firePromoImpressionEvent() {
    this.props.trackEvent({
      promo_name: 'app_interstitial',
      promo_type: 'app_promo',
      tag_manager_event: 'promo_impression',
    });
  }

  firePromoSelectedEvent() {
    this.props.trackEvent({
      promo_name: 'app_interstitial',
      promo_type: 'app_promo',
      tag_manager_event: 'promo_selected',
    });
  }

  handleScrollEvent() {
    if (isFullyInViewport(this.button)) {
      if (!this.isPromoImpressionTracked) {
        this.firePromoImpressionEvent();
        this.isPromoImpressionTracked = true;
      }
    } else {
      this.isPromoImpressionTracked = false;
    }
  }

  render() {
    if (!this.props.leadImage || !this.props.displayTag) {
      return false;
    }

    const classes = classnames({
      mobileInterstitial: true,
      molecule: true,
      [this.props.customBrowser]: !!this.props.customBrowser,
      [this.props.os]: !!this.props.os,
    });

    return (
      <div className={classes}>
        <div className="hero">
          <div className="leadImage" style={{backgroundImage: `url(${this.props.leadImage})`}} />
        </div>
        <div className="wrapper">
          <div className="body">
            <div className="banner">
              <SVG type="logo" target="brAppIos" />
              <div className="description">
                <h3>
                  Get the Latest{' '}
                  {this.props.displayTag.short_name || this.props.displayTag.display_name} Alerts
                </h3>
                <p>Use the Bleacher Report app for free</p>
              </div>
            </div>
            <div className="button-wrapper" ref={(el) => (this.button = el)}>
              <Button href={this.props.appInstallLink} onClick={this.firePromoSelectedEvent}>
                Install the App
              </Button>
            </div>
            <footer>
              <small>scroll to see the article</small>
              <SVG type="icon" target="downArrow" />
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

MobileInterstitial.defaultProps = {
  customBrowser: false,
};

export default MobileInterstitial;
