import React from 'react';
function ProviderInfo({imageURL, context}) {
  const {authenticatedProvider} = context;
  return imageURL ? (
    <div className="tve-logo__container">
      <img className="tve-logo" src={imageURL} />
    </div>
  ) : authenticatedProvider ? (
    <span>{authenticatedProvider.displayName}</span>
  ) : null;
}

export default ProviderInfo;
