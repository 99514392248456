import React from 'react';
import {getGameStatus} from '../../helpers/scores/gamecast';
import TeamContent from '../cells/scoreCard/teamContent';
import Header from '../cells/scoreCard/header';
import {BR} from '../../endpoints';
import classnames from 'classnames';
import {Leagues} from '../../constants/leagues';

const ScoreCard = (props) => {
  const {game, title, onScoreCardClick} = props;
  const gameStatus = game ? getGameStatus(game.status) : '';
  const scoreCardContainer = classnames('scoreCardContainer', {
    notClickable: !game,
  });

  const getGameUrl = (game) => {
    if (game.gamecast && game.gamecast.length > 0) {
      return BR.gamecast(game.gamecast);
    }
    if (game.box_score && game.box_score.length > 0) {
      return game.box_score;
    }
    return '';
  };
  const renderContent = () => {
    const componentProps = {
      header: null,
      teamOne: null,
      teamTwoProp: null,
    };

    if (game) {
      const status = gameStatus;
      componentProps.header = {
        gameDate: game.game_date,
        gameProgress: game.game_progress,
        tbd: game.time_tbd,
        status,
        showLive: game.league.toUpperCase() !== Leagues.MLB,
      };
      componentProps.teamOne = {team: game.team_one, status};
      componentProps.teamTwo = {team: game.team_two, status};
    }

    return (
      <React.Fragment>
        <Header isEmpty={!game} {...componentProps.header} />
        <TeamContent isEmpty={!game} {...componentProps.teamOne} />
        <TeamContent isEmpty={!game} {...componentProps.teamTwo} />
      </React.Fragment>
    );
  };
  function scoreCardClicked() {
    onScoreCardClick(game);
  }
  const gameUrl = game ? getGameUrl(game) : '';
  const clickableCard = () => {
    const content = renderContent();
    if (gameUrl.length > 0) {
      return (
        <a className={scoreCardContainer} href={gameUrl} onClick={scoreCardClicked}>
          {content}
        </a>
      );
    }
    return <span className={scoreCardContainer}>{content}</span>;
  };

  return (
    <div className="atom score-card">
      <div className="scoreCardTitle">{title}</div>
      {clickableCard()}
    </div>
  );
};

export default ScoreCard;
