// Added as a refactoring conduit for the bloated ads_api.js
import * as PageTypes from '../../constants/pageTypes';
const HOME_PAGE = 'Home_Page';
const HOME_PAGE_UK = 'Home_Page_UK';
const VIDEO_LANDING_PAGE = 'Video_Landing_Page';

const RETROFITTABLE_TAGS = {
  Ditch_Playbook_161318: 'ditch_the_playbook',
  GridironHeights_95852: 'gridiron_heights',
  Simms_And_Lefkoe: 'lefkoe_show',
  NBA2K_98354: 'nba2k',
  Untold_Stories_161317: 'untold_stories',
};

/**
 * Extracts from the DOM the meta tag element containing the keywords
 * that are used as meta tag data for setting up appNexus prebidding bid object.
 */
export function getKVMetaData() {
  const meta = global.document.querySelector("meta[name='keywords']");
  if (meta) {
    return Object.freeze(
      meta
        .getAttribute('content')
        .replace(/ /g, '_')
        .split(',_')
    );
  }
  return Object.freeze([]);
}

/**
 * Determines the pg value to set for ad targeting.
 *
 * The home page semantics (US | UK) dictate that we return HOME_PAGE | HOME_PAGE_UK.
 * If the render strategy is set use that, else use the zone.
 *
 * @param {*} adsData - the adsData sent over from faust.
 */
export function determinePGValue(adsData) {
  if (adsData.tags === VIDEO_LANDING_PAGE || adsData.site === 'video') {
    return 'video';
  }
  if (adsData.tags === HOME_PAGE || adsData.tags === HOME_PAGE_UK) {
    return HOME_PAGE;
  }
  if ([PageTypes.STUB, PageTypes.USER_POST].includes(adsData.pageType)) {
    return PageTypes.STUB;
  }
  return adsData.pg || adsData.render_strategy || adsData.zone;
}

/**
 * Retrofits some ad unit tag names to match those that are defined in google ad manager (that are used for tracking)/
 *
 * @param {String} tagName - The tagName (from faust) to retrofit.
 */
export function retrofitTags(tagName) {
  if (Object.keys(RETROFITTABLE_TAGS).includes(tagName)) {
    return RETROFITTABLE_TAGS[tagName];
  }
  return tagName;
}
