import {createFetchService} from './fetch_utils';

const fetchText = createFetchService('Twilio', {responseFormat: 'text'});

function sendDownloadAppTextMessage(phoneNumber) {
  return fetchText('/twilio/download-link', {
    method: 'POST',
    body: {to: phoneNumber, from: '+14153478721'},
  });
}

export default {
  sendDownloadAppTextMessage,
};
