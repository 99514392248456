import React from 'react';
import classnames from 'classnames';
import {Button} from '@br/br-components';

class Notification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.closeNotification = this.closeNotification.bind(this);
    this.checkNotification = this.checkNotification.bind(this);
  }
  componentDidMount() {
    global.setTimeout(this.checkNotification, 10);
  }
  checkNotification() {
    this.props.checkNotification(this.props.name);
  }
  closeNotification(event) {
    this.props.onCloseNotification(event, this.props.name);
  }
  render() {
    if (this.props.hide) {
      return false;
    }
    if (!this.props.ui[`show_${this.props.name}`]) {
      return false;
    }
    const device = this.props.deviceFamily ? this.props.deviceFamily.toLowerCase() : false;

    const classes = classnames({
      notification: true,
      molecule: true,
      show: this.props.data[this.props.name],
      [device]: device,
      [this.props.name]: true,
    });
    const button = this.props.buttonText ? (
      <Button onClick={this.closeNotification}>{this.props.buttonText}</Button>
    ) : (
      false
    );
    return (
      <div className={classes}>
        <div className="content">
          {this.props.children}
          {button}
        </div>
      </div>
    );
  }
}

Notification.defaultProps = {
  onCloseNotification: () => {},
  deviceFamily: false, // We only care about android vs ios (as of March 2017)
};

export default Notification;
