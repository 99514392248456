const REGEX_UUID = /^[a-g\d]{8}-[a-g\d]{4}-[a-g\d]{4}-[a-g\d]{4}-[a-g\d]{12}$/i;
function isValidUUID(string) {
  if (typeof string !== 'string') {
    return false;
  }
  if (string.length !== 36) {
    return false;
  }
  return REGEX_UUID.test(string);
}

function truncateAtWordBoundary(string, maxLength) {
  if (maxLength <= 0) {
    throw new Error('Cannot truncate string to less than 1 character.');
  }
  if (string.length <= maxLength) {
    return string;
  }
  const indexOfLastSpace = string.lastIndexOf(' ', maxLength);
  return `${string.substring(0, indexOfLastSpace)}…`;
}

function replaceDashesWithUnderscores(str = '') {
  return typeof str === 'string' ? str.replace(/-/g, '_') : str;
}

function sanitizeTitle(str = '') {
  return typeof str === 'string'
    ? str
        ?.replace(/\s/g, '_') // replace all spaces with underscores
        .replace(/\W/g, '') // remove all non-alphanumeric characters (but keep underscores)
        .replace(/^_+|_+$/g, '') // remove leading/trailing underscores
    : str;
}

export {isValidUUID, truncateAtWordBoundary, replaceDashesWithUnderscores, sanitizeTitle};
