export const getAdsProfileId = (platform) => {
  const profileIdConfig = {
    mobile: {
      production: process.env.ADS_PROFILE_ID_MOBILE_PROD,
      staging: process.env.ADS_PROFILE_ID_MOBILE_STAG,
    },
    desktop: {
      production: process.env.ADS_PROFILE_ID_DESKTOP_PROD,
      staging: process.env.ADS_PROFILE_ID_DESKTOP_STAG,
    },
  };

  const deviceProfile = platform === 'mobile' ? profileIdConfig.mobile : profileIdConfig.desktop;
  const isProd = process.env.LIVE_VIDEO_SERVICES_ENV === 'prod';

  return isProd ? deviceProfile.production : deviceProfile.staging;
};

export const getCsid = (platform) => {
  const confCsidConfig = {
    mobile: 'br_ondomain_live_mobileweb',
    desktop: 'br_ondomain_live_desktop',
  };

  return platform === 'mobile' ? confCsidConfig.mobile : confCsidConfig.desktop;
};

export function getAppCsid(platform) {
  // No preroll ads on stub pages / rooms
  // https://statmilk.atlassian.net/wiki/spaces/TPM/pages/2670920187/TOP+Video+Ads+Config#Free-Live-Originals
  const confCsidConfig = {
    mobile: 'br_ondomain_live_mobileweb_rooms',
    desktop: 'br_ondomain_live_desktop_rooms',
  };

  return platform === 'mobile' ? confCsidConfig.mobile : confCsidConfig.desktop;
}

export const getAppCsidForFreeView = (platform, useFreePreview) => {
  // eslint-disable-next-line no-undefined
  if (!useFreePreview) return undefined;

  return `${getCsid(platform)}_preview`;
};
