export const DISABLE_USER_CONSENT_SCRIPT = 'DISABLE_USER_CONSENT_SCRIPT';
export const LOAD_ALERT_DETAILS = 'LOAD_ALERT_DETAILS';
export const LOAD_COMMENT_DATA_FOR_ARTICLE = 'LOAD_COMMENT_DATA_FOR_ARTICLE';
export const LOAD_COMMENT_DATA_FOR_STUB = 'LOAD_COMMENT_DATA_FOR_STUB';
export const LOAD_TRACK_DATA_FOR_ARTICLE = 'LOAD_TRACK_DATA_FOR_ARTICLE';
export const LOAD_TRACK_DATA_FOR_STUB = 'LOAD_TRACK_DATA_FOR_STUB';
export const MOBILE_WEB_APP_PROMO_MOUNTED = 'MOBILE_WEB_APP_PROMO_MOUNTED';
export const SET_COMMENTS_UPSELL_DATA = 'SET_COMMENTS_UPSELL_DATA';
export const SET_SMART_BANNER_DATA = 'SET_SMART_BANNER_DATA';
export const SET_ALERT_BANNER_DATA = 'SET_ALERT_BANNER_DATA';
export const STORE_APP_VERSION = 'STORE_APP_VERSION';
export const GET_DALTON_TOKEN = 'GET_DALTON_TOKEN';
export const GET_DALTON_EMAILS = 'GET_DALTON_EMAILS';
export const SET_TEALIUM_LOAD_RULES = 'SET_TEALIUM_LOAD_RULES';
export const SET_TVE_AUTH_CONTEXT = 'SET_TVE_AUTH_CONTEXT';
export const SET_TVE_PROVIDER_LOGO = 'SET_TVE_PROVIDER_LOGO';
