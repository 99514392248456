// Desktop SubNavbar
// https://www.figma.com/file/Jvg8aH9QISdVEkqFChRrKI/Enhanced-Web-Navigation?node-id=3371%3A154741

import React, {useState} from 'react';
import {NavBarLink} from '../types';
import {Typography, Variant} from '../../Shared/Typography/Typography';
import {NavigationTabs} from '../NavigationTabs/NavigationTabs';

export interface SubNavBarProps {
  href: string;
  links: NavBarLink[];
  title: string;
  value: string;
  onLinkClick: (link: NavBarLink) => void;
}

export const SubNavBar: React.FC<SubNavBarProps> = ({
  href,
  links,
  title,
  value: originalValue,
  onLinkClick,
}) => {
  const [initialValue, setInitialValue] = useState<string | null>(originalValue);
  const [value, setValue] = useState<string | null>(originalValue);

  return (
    <div className="subNavBar">
      <div className="subNavBar__title">
        <a href={href}>
          <Typography variant={Variant.H3}>{title}</Typography>
        </a>
      </div>

      <NavigationTabs
        links={links}
        initialValue={initialValue}
        value={value}
        // eslint-disable-next-line react/jsx-no-bind
        setValue={(key: string) => setValue(key)}
        // eslint-disable-next-line react/jsx-no-bind
        setInitial={(key: string) => setInitialValue(key)}
        onLinkClick={onLinkClick}
      />
    </div>
  );
};
