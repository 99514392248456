const IASPET_TIMEOUT = 2000;
const THREE_BY_THREE_AD_SLOT = '3x3';
const BACKGROUND_SKIN_ID = 'oop_skin_01';
const VIDEO_INTELLIGENCE_ORDER_ID = [2395575265, 2985118074];

/**
 * Prevent the skin ad from being part of the IAS refresh spec,
 * A refresh can cause a big jump in impressions, might break 100% share of page for advertisers.
 */
function setIASRefreshExceptions() {
  global.__iasAdRefreshConfig.excludeList = {
    adSize: [THREE_BY_THREE_AD_SLOT],
    custom: [BACKGROUND_SKIN_ID],
    campaignId: VIDEO_INTELLIGENCE_ORDER_ID, //To exclude Video Intelligence creatives
  };
}

/**
 * Performs the actual work of requesting for an ad.
 *
 * (This has been exported for testing purposes).
 *
 * @param {*} divId - the id of the div in the HTML structure that is being targeted.
 */
export function requestAds(divId) {
  global.googletag.display(divId);
}

/**
 * Creates the ad slot to be used by IAS' Publisher Optimization slot.
 *
 * Note that the returned object does not have Object as protoype.
 *
 * @param {*} sizes - the sizes of the slot.
 *
 * size: can either be a single size (e.g., [728, 90])
 * or an array of sizes (e.g., [[728, 90], [970, 90]])
 *
 * @param {*} id - The ad slot id
 * @param {*} path - The ad slot path
 */
export function createPETSlot({sizes, id, path}) {
  const slot = Object.create(null);
  slot.size = sizes;
  slot.adSlotId = id;
  slot.adUnitPath = path;
  return slot;
}

/**
 * Converts the suggested ad element width and heigth into PET sizes.
 *
 * @param {*} sizes The PET sized size slots.
 */
export function prepareSizes(sizes) {
  return sizes.map(function(size) {
    if (size.getWidth && size.getHeight) {
      return [size.getWidth(), size.getHeight()];
    }
    return [1, 1];
  });
}

/**
 * Reads the currently defined GPT ad slots for sending to the PET endpoint
 * All GPT ad slots should be defined before calling PET
 */
export function prepareAdSlots() {
  const slots = [];
  const gptSlots = global.googletag.pubads().getSlots();
  gptSlots.forEach((slot) => {
    slots.push(
      createPETSlot({
        path: slot.getAdUnitPath(),
        id: slot.getSlotElementId(),
        sizes: prepareSizes(slot.getSizes()),
      })
    );
  });
  return slots;
}

/**
 * Prepares the ad slots to be optimized by IAS.
 *
 * @param {*} divId - The id of the div being targeted for the Ad.
 */
export const iasPixelOptimization = (divId) => {
  const __iasPET = global.__iasPET || {};
  __iasPET.queue = __iasPET.queue || [];
  __iasPET.pubId = process.env.IAS_PUB_ID;
  // function to fetch ads
  const iasPETTimeoutRequestAds = setTimeout(requestAds.bind(null, divId), IASPET_TIMEOUT);
  // make the PET request
  global.googletag.cmd.push(function() {
    const iasPETSlots = prepareAdSlots();
    __iasPET.queue.push({
      adSlots: iasPETSlots,
      dataHandler: (__) => {
        clearTimeout(iasPETTimeoutRequestAds);
        __iasPET.setTargetingForGPT();
        requestAds(divId);
      },
    });
  });
  global.__iasAdRefreshConfig.refreshTargeting = {
    enabled: true,
    targetingKey: 'rfv',
  };
  setIASRefreshExceptions();
};
