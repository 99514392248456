const mParticleEventKeysMap = {
  accountType: 'accountType',
  ad_type: 'adType',
  affiliate: 'affiliate',
  app_download_link_selected: 'app_download_link_selected',
  app_download_page_selected: 'app_download_page_selected',
  app_download_page_viewed: 'app_download_page_viewed',
  article_id: 'articleID',
  article_type: 'articleType',
  author: 'author',
  autoplay: 'autoplay',
  away_score: 'awayScore',
  away_team_name: 'awayTeamName',
  bet_availability: 'betAvailability',
  bet_name: 'betName',
  bet_percentage_outcome_a: 'betPercentageOutcomeA',
  bet_percentage_outcome_b: 'betPercentageOutcomeB',
  bet_platform: 'betPlatform',
  bet_type: 'betType',
  betting_legal: 'bettingLegal',
  br_logged_in: 'brLoggedIn',
  captions_enabled: 'captionsEnabled',
  carousel: 'carousel',
  casting_success: 'casting_success',
  clickable: 'clickable',
  completion_interval: 'completionInterval',
  content_duration: 'contentDuration',
  content_id: 'contentID',
  content_placement: 'contentPlacement',
  content_type: 'contentType',
  cta_text: 'ctaText',
  currency_code: 'currencyCode',
  error_code: 'errorCode',
  error_time: 'errorTime',
  experiment_type: 'experimentType',
  free_preview: 'freePreview',
  fullscreen_enabled: 'fullscreenEnabled',
  game_period: 'gamePeriod',
  game_permalink: 'gamePermalink',
  game_start: 'gameStart',
  game_status: 'gameStatus',
  home_score: 'homeScore',
  home_team_name: 'homeTeamName',
  hook: 'hook',
  is_live: 'isLive',
  item_price: 'itemPrice',
  league: 'league',
  link_type: 'linkType',
  live_video_type: 'liveVideoType',
  mparticleEventName: 'mparticleEventName',
  nav_bar_tier: 'navBarTier',
  newsletter_sign_up: 'newsletter_sign_up',
  onboarding: 'onboarding',
  orientation: 'orientation',
  outcome: 'outcome',
  partner: 'partner',
  partner_url: 'partnerUrl',
  permalink: 'permalink',
  placement_type: 'placementType',
  platform: 'platform',
  platform_app_vs_web: 'Platform_App_vs_Web',
  player_time: 'playerTime',
  preview_left: 'previewLeft',
  product_title: 'productTitle',
  product_type: 'productType',
  progress_type: 'progressType',
  promo_type: 'promoType',
  published_at: 'publishedAt',
  referrer_source: 'referrer_source',
  screen: 'screen',
  sku_id: 'skuID',
  sound_enabled: 'soundEnabled',
  source: 'source',
  spring_type: 'springType',
  start_location: 'startLocation',
  stream_id: 'streamID',
  stream_name: 'streamName',
  tags_string: 'tagsString',
  team: 'team',
  title: 'title',
  total_loops: 'totalLoops',
  total_time_spent: 'totalTimeSpent',
  total_videos_available: 'totalVideosAvailable',
  total_videos_viewed: 'totalVideosViewed',
  url: 'url',
  url_sha: 'urlSha',
  video_id: 'videoID',
  video_player_experience: 'videoPlayerExperience',
  video_title: 'videoTitle',
};

const mParticleEventNameMap = {
  account_created: 'Account Created',
  account_verified: 'Account Verified',
  ad_play: 'Ad Play',
  ad_play_summary: 'Ad Play Summary',
  app_download_link_selected: 'App Download Link Selected',
  app_download_page_selected: 'App Download Page Selected',
  bet_track_impression: 'Bet Track Impression',
  bet_track_link_selected: 'Bet Track Link Selected',
  betting_link_impression: 'Betting Link Impression',
  betting_link_selected: 'Betting Link Selected',
  birthday_updated: 'Birthday Updated',
  casting_success: 'Casting Success',
  comments_expanded: 'Comments Expanded',
  content_cc: 'Content Closed Captions',
  content_fullscreen: 'Content Fullscreen',
  content_mute: 'Content Mute',
  content_pause: 'Content Pause',
  content_play: 'Content Play',
  content_play_error: 'Content Play Error',
  content_play_summary: 'Content Play Summary',
  content_selected: 'Content Selected',
  content_unmute: 'Content Unmute',
  content_viewed: 'Content Viewed',
  create_account: 'Create Account',
  event_details_selected: 'Event Details Selected',
  forgot_password: 'Forgot Password',
  free_preview_expired: 'Free Preview Expired',
  gamecast_interacted: 'Gamecast Interacted',
  gamecast_selected: 'Gamecast Selected',
  gamecast_viewed: 'Gamecast Viewed',
  login: 'Login',
  logout: 'Log Out',
  newsletter_sign_up: 'Newsletter Sign Up',
  placeholder_impression: 'Placeholder Impression',
  ppv_purchase_initiated: 'Purchase Initiated',
  ppv_purchase_success: 'Purchase Success',
  promo_impression: 'Promo Impression',
  promo_selected: 'Promo Selected',
  resend_verification: 'Resend Verification',
  scores_selected: 'Scores Selected',
  screen_viewed: 'Screen Viewed',
  slide_viewed: 'Slide Viewed',
  stream_selected: 'Stream Selected',
  stream_summary: 'Stream Summary',
  ticket_link_selected: 'Ticket Link Selected',
  tve_initiated: 'TVE Initiated',
  tve_linked: 'TVE Linked',
  tve_provider_selected: 'TVE Provider Selected',
  tve_unlinked: 'TVE Unlinked',
  user_error: 'User Error',
  username_updated: 'Username Updated',
  video_playlist_summary: 'Video Playlist Summary',
};

const screenNames = {
  article: 'Article',
  editorial: 'Editorial',
  gamecast: 'Gamecast',
  home: 'Home',
  live: 'Live Blog',
  liveEvents: 'Video Page - Live',
  login: 'Login',
  myEvents: 'My Events',
  schedule: 'Schedule',
  section: 'Section',
  stub: 'Stub',
  video: 'Stub',
  'video-landing-page': 'Video Destination - Home',
};

const getScreenType = (pageType, subType) => {
  if (pageType === 'home' && subType === 'video') {
    return screenNames['video-landing-page'];
  }
  return screenNames[pageType];
};

export {mParticleEventNameMap, mParticleEventKeysMap, screenNames, getScreenType};
