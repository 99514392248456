export const streamTypes = {
  BREAKING: 'breaking',
  GAME_STREAM: 'game_stream',
  LIVE_EVENT: 'live_event',
  PERMANENT: 'permanent',
  RELATED_CONTENT: 'relatedContent',
};

export const TI_STREAM = 'ti';
export const TS_STREAM = 'ts';
