import React from 'react';
import {NavLink} from '../../../Shared/NavLink/NavLink';
import {Button, ButtonVariant, ButtonSize} from '../../../Shared/Button/Button';
import {NavBarLink} from '../../types';
import {Variant} from '../../../Shared/Typography/Typography';
import {BackIcon} from '../../../Icons/BackIcon/BackIcon';
import {Color} from '../../../types';
import {Scroll} from '../../../Shared/Scroll/Scroll';
import {useViewportHeight} from '../../useViewportHeight';

export interface MobileSideSubMenuProps {
  title: string;
  links: NavBarLink[];
  onBackButtonClick?: () => void;
}

export const MobileSideSubMenu = ({links, title, onBackButtonClick}: MobileSideSubMenuProps) => {
  const fullViewportHeight = useViewportHeight();
  return (
    <div
      className="navBarFonts mobileSideSubMenu"
      style={{height: `calc(${fullViewportHeight}px - var(--br-navbar_height))`}}
    >
      <Button
        variant={ButtonVariant.Text}
        className="mobileSideSubMenu__backButton"
        color={Color.Inherit}
        onClick={onBackButtonClick}
        size={ButtonSize.Medium}
      >
        <BackIcon classnames="mobileSideSubMenu__backIcon" />
        {title}
      </Button>

      <div className="mobileSideSubMenu__linkSection">
        <Scroll>
          {links?.map((link) => {
            return (
              <NavLink
                key={link.title}
                href={link.href}
                variant={Variant.H2}
                className="mobileSideSubMenu__link"
                color={Color.Primary}
              >
                {link.title}
              </NavLink>
            );
          })}
        </Scroll>
      </div>
    </div>
  );
};
