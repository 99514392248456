import React, {MouseEventHandler, ReactNode, useEffect, useState} from 'react';
import classnames from 'classnames';
import {Typography, Variant} from '../Typography/Typography';
import {Color} from '../../types';

export const enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonClass {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Disabled = 'disabled',
  Inherit = 'inherit',
}

export const enum ButtonVariant {
  Contained = 'contained',
  Text = 'text',
}

export interface ButtonProps {
  children?: ReactNode;
  color?: Color;
  onClick?: () => void;
  onMouseUp?: MouseEventHandler;
  isDisabled?: boolean;
  className?: string;
  size?: ButtonSize;
  variant?: ButtonVariant;
}

const fontSize = {
  [ButtonSize.Small]: Variant.Button,
  [ButtonSize.Medium]: Variant.H3,
  [ButtonSize.Large]: Variant.H1,
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    color = Color.Primary,
    onClick = () => {},
    onMouseUp = () => {},
    isDisabled,
    className,
    size = ButtonSize.Small,
    variant = ButtonVariant.Contained,
  } = props;

  const classes = classnames({
    button: true,
    [`-${ButtonClass[color]}`]: true,
    [`-${size}`]: true,
    [`-${variant}`]: variant,
    [`${className}`]: className,
  });

  // eslint-disable-next-line no-use-before-define
  const [textColor, setTextColor] = useState(() => getTextColor(variant, color));

  useEffect(() => {
    // disabling for ease of readability by keeping all hooks at the top of our component
    // eslint-disable-next-line no-use-before-define
    setTextColor(getTextColor(variant, color));
  }, [variant, color]);

  function getTextColor(buttonVariant: ButtonVariant, color: Color): Color {
    if (buttonVariant === ButtonVariant.Text || color === Color.Inherit) {
      return color;
    }
    return Color.Secondary;
  }

  return (
    <button
      className={classes}
      onClick={onClick}
      onMouseUp={onMouseUp}
      disabled={isDisabled}
      ref={ref}
    >
      <Typography variant={fontSize[size]} color={textColor}>
        {children}
      </Typography>
    </button>
  );
});
