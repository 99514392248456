import React from 'react';

function Shimmer() {
  return (
    <div className="atom shimmer br">
      <div className="wrapper">
        <div className="tag animate" />
        <div className="heading w80 animate" />
        <div className="photo animate" />
        <div className="comment br animate" />
        <div className="comment br animate" />
        <div className="comment br w80 animate" />
      </div>
    </div>
  );
}

export default Shimmer;
