const initialState = {
  email_confirmation: false,
  privacy_notice: false,
  smart_banner: false,
  mobile_interstitial: false,
};

function notification(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_NOTIFICATION_DATA': {
      const {interstitialInstallLink, notification} = action.payload;
      return {
        ...state,
        interstitialInstallLink,
        [notification.name]: notification.value,
      };
    }

    default:
      return state;
  }
}

export default notification;
