import Boom from 'boom';
import {createAction} from 'redux-actions';

import ArmchairAPI from '../apis/armchair_api';
import {fetchUser} from '../apis/account_api';
import LayserAPI from '../apis/layser_api';

import {isValidUUID} from '../helpers/stringHelpers';

const fetchUserPost = createAction('FETCH_USER_POST', async (postId) => {
  if (!postId || !isValidUUID(postId)) {
    return Promise.reject(Boom.notFound(`${postId} is not a valid Post id`));
  }
  const [postData, aggregateData] = await Promise.all([
    ArmchairAPI.fetchPost(postId),
    LayserAPI.fetchAggregateCountsForUserPost(postId),
  ]);
  const authorData = await fetchUser(postData.user_id);
  return {
    ...postData,
    aggregateData,
    author: authorData.user,
  };
});

export {fetchUserPost};
