import stringify from 'json-stringify-safe';

const baseData = {
  service: process.env.SERVICE || 'nodereport',
  env: process.env.ENV || 'dev',
};

// Only log certain levels on prod and all on staging evns
const isProd = process.env.RELEASE_ENV === 'production';

function argsToJson(args) {
  return {
    message: args.map((arg) => (typeof arg === 'string' ? arg : stringify(arg))),
    timestamp: new Date().toISOString(), // must be ISO-8601 in Zulu timezone
    ...baseData,
  };
}

export const logger = {
  debug(...args) {
    if (isProd) return;
    global.console.debug(
      stringify({
        ...argsToJson(args),
        level: 'notice',
      })
    );
  },
  log(...args) {
    if (isProd) return;
    global.console.log(
      stringify({
        ...argsToJson(args),
        level: 'notice',
      })
    );
  },
  warn(...args) {
    global.console.warn(
      stringify({
        ...argsToJson(args),
        level: 'warning',
      })
    );
  },
  error(...args) {
    global.console.error(
      stringify({
        ...argsToJson(args),
        level: 'error',
      })
    );
  },
  info(...args) {
    if (isProd) return;
    global.console.info(
      stringify({
        ...argsToJson(args),
        level: 'info',
      })
    );
  },
};

export default logger;
