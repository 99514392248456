import Boom from 'boom';
import {createAction} from 'redux-actions';
import {getGamecast, fetchGamecastStream, emptyTrackData} from '../apis/layser_api';

import {Layser} from '../endpoints';
import {parseImages} from '../helpers/imageHelpers';

export const setGamecast = createAction('SET_GAMECAST');
export const setPollingUpdates = createAction('SET_POLLING_UPDATES');
export const updateGamecastWithPolledData = createAction('UPDATE_GAMECAST_WITH_POLLED_DATA');

/**
 * @name fetchGamecast
 * @param {string} slug
 */
export const fetchGamecast = (slug, countryCode, regionCode) => {
  return async (dispatch) => {
    const data = await getGamecast(slug, countryCode, regionCode);

    if (!data || data.error) {
      throw data.error || Boom.notFound('Problem loading gamecast');
    }

    const {meta = {}} = data;
    dispatch(
      setGamecast({
        ...data,
        social: {
          ...data.social,
          tracks: data.social.tracks ? data.social.tracks.map(parseImages) : [],
        },
        programmed: {
          ...data.programmed,
          tracks: data.programmed.tracks ? data.programmed.tracks.map(parseImages) : [],
        },
        meta: {
          ...meta,
          live_game_sse: Layser.liveGameEvents(meta.live_game_sse),
        },
      })
    );
  };
};

/**
 * @name fetchStreamToPoll
 * @param {string} path
 * @param {string} country - vistor country code
 */
export const fetchStreamToPoll = (path, country) => {
  return async (dispatch) => {
    const data = await fetchGamecastStream(path, country);

    dispatch(setPollingUpdates(data));
  };
};

/**
 * @name fetchLiveGame
 * @param {string} path
 * @param {string} country - vistor country code
 */
export const fetchLiveGame = (path, country) => {
  return async (dispatch) => {
    const liveGameData = await fetchGamecastStream(path, country);
    // Prevent state update when error caught by layser_api and returning emptyTrackData
    if (liveGameData !== emptyTrackData) {
      dispatch(
        setGamecast({
          live_game: liveGameData,
        })
      );
    }
  };
};
