export const FRONT_PAGE = 'front-page';

export const HOME_PAGE = 'home-page';

export const UK = 'uk';
export const HOME_PAGE_UK = 'home-page-uk';

export const HOME_TEST = 'home-test';
export const HOME_TEST_UK = 'home-test-uk';

const VIDEO_HOME_PAGE = 'videos';
export const VIDEO_HOME_PAGE_BRICKLAYER_NAME = 'video-landing-page';


//Test pages for ad/revenue to use. "homepage-ad-test-1"  through 10
export const AD_TESTS = [];

for (let int = 1; int <= 10; int++) {
  AD_TESTS.push(`homepage-ad-test-${int}`);
}
///////

const homePageTags = {
  adTest: AD_TESTS,
  all: [...AD_TESTS, FRONT_PAGE, UK, HOME_PAGE, HOME_PAGE_UK, HOME_TEST, HOME_TEST_UK, VIDEO_HOME_PAGE],
  classic: [FRONT_PAGE, UK],
  redesign: [HOME_PAGE, HOME_PAGE_UK, HOME_TEST, HOME_TEST_UK],
  prod: [HOME_PAGE, HOME_PAGE_UK],
  test: [HOME_TEST, HOME_TEST_UK],
  uk: [UK, HOME_PAGE_UK, HOME_TEST_UK],
  us: [FRONT_PAGE, HOME_PAGE, HOME_TEST],
};

const homePageTagsMap = new Map();
homePageTagsMap.set(FRONT_PAGE, HOME_PAGE);
homePageTagsMap.set(UK, HOME_PAGE_UK);

homePageTagsMap.set(HOME_PAGE_UK, HOME_PAGE_UK);
homePageTagsMap.set(HOME_PAGE, HOME_PAGE);

homePageTagsMap.set(VIDEO_HOME_PAGE, VIDEO_HOME_PAGE_BRICKLAYER_NAME);

export {homePageTags, homePageTagsMap};
