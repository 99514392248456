import {createFetchService} from './fetch_utils';
import {Portmeirion} from '../endpoints';
import logger from '../logger';

const fetchJSON = createFetchService('Portmeirion');

const onError = (error) => {
  logger.error('Error hitting Portmeirion', error);
  return {};
};

const identify = (uuid) => {
  return fetchJSON(Portmeirion.identify(uuid)).catch(onError);
};

const login = (warehouseId, loginId) => {
  return fetchJSON(Portmeirion.login(warehouseId, loginId)).catch((error) => error);
};

const facebookLogin = (warehouseId, facebookId) => {
  return fetchJSON(Portmeirion.facebookLogin(warehouseId, facebookId)).catch(onError);
};

const move = (deviceId, warehouseId) => {
  return fetchJSON(Portmeirion.move(deviceId, warehouseId)).catch(onError);
};

export default {
  facebookLogin,
  identify,
  login,
  move,
};
