import React, {useEffect} from 'react';
import {
  tveUICustomizations,
  setupTVEPickerTitle,
  renderSignInLoadingSlateLogo,
  renderLoginSuccessLogo,
} from './tveUICustomizations';
import logger from '../../../../logger';
import loadable from '@loadable/component';
import storage_api from '../../../../apis/storage_api';
import {getParams} from 'url-params-helper';
import {getAdsProfileId, getCsid, getAppCsidForFreeView} from '../liveVideoAdsprofile';

import FreePreviewTimer from './freePreviewTimer';
import SignInButton from './signInButton';
import ProviderInfo from './providerInfo';
import TveFreePreviewEndedThumbnail from './tveFreePreviewEndedThumbnail';

import * as VideoHelpers from '../../../helpers/videoHelpers';

const CACHED_EXPIRATION_KEY = 'TVE_FREE_PREVIEW_EXPIRY';
const CACHED_EXPIRATION_RESET_KEY = 'TVE_FREE_PREVIEW_EXPIRY_RESET';

/**
 * This value will display as 10:00 Remaining and the timer will not tick.
 */
export const DEFAULT_TVE_EXPIRATION = -1;
export const MAX_FREEVIEW_TIME = 600000;

const SERVICE_APP_ID =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwbGF0Zm9ybSI6InRlc3QiLCJwcm9kdWN0IjpudWxsLCJuZXR3b3JrIjoidGVzdCIsImFwcElkIjoidGVzdC10ZXN0LXRkN2szdCJ9.xk2vEp3nVlZgzsBxAnDI8zd4MGKIyOQYMz8ZSfRc1l4';
const currentBrand = 'Bleacher';
const MVPDBrand = 'bleacherreport';

function setupSignInLoadingSlateLogo(authBlock, providerId) {
  authBlock
    .buildImageURL({
      context: 'cobranding',
      providerId,
      color: 'black',
      width: 200,
      height: 46,
    })
    .then((result) => {
      if (result.imageURL) {
        renderSignInLoadingSlateLogo(result.imageURL);
      }
    })
    .catch((err) => {
      logger.error(err);
    });
}

export function useCustomTVEPicker(authBlock) {
  useEffect(() => {
    if (!authBlock) return;
    authBlock.events.listen({
      pickerViewStateChanged: setupTVEPickerTitle,
      selectProviderRequested: (request) => {
        setupSignInLoadingSlateLogo(authBlock, request.provider.id);
      },
      loginSuccess: (request) => {
        authBlock
          .buildImageURL({
            context: 'cobranding',
            providerId: request.context.authenticatedProvider?.id,
            color: 'black',
            width: 200,
            height: 46,
          })
          .then((result) => {
            if (result.imageURL) {
              renderLoginSuccessLogo(result.imageURL);
            }
          })
          .catch((err) => {
            logger.error(err);
          });
      },
    });
  }, [authBlock]);
}

const bleacherCompanyId = '980e2b29-3c22-4d43-ab80-dc4fdcb599c0';
const companyIDMap = {
  tbs: 'de4c1d30-ac22-4669-8824-19ba9a1dc128',
  tnt: 'f5e82db2-269e-4bfc-a420-50cd1b3d775e',
  trutv: '257f917e-78e4-4021-8f3c-27ca1efa63a5',
};

export function getCompanyID(channel) {
  if (process.env.TESTING_TVE === 'true') {
    return companyIDMap[channel.toLowerCase()];
  }
  return bleacherCompanyId;
}

function initializePlayerAuth({
  authWebDefault,
  authBlockRef,
  prepareAuth,
  setTveAuthContext,
  setTveProviderLogo,
}) {
  const {AuthConfigBuilder, configurePicker} = authWebDefault;
  const AuthBlock = authWebDefault.default;

  configurePicker({
    onRender: tveUICustomizations,
  });

  const authConfig = new AuthConfigBuilder()
    .withPlatform('web')
    .withBrand(currentBrand)
    .withMVPDConfigURL(`https://tvem.cdn.turner.com/v2/getConfig?platform=web&brand=${MVPDBrand}`)
    .withServiceAppId(SERVICE_APP_ID)
    .withEnvironment(process.env.AUTH_ENV || 'production')
    .withClientfulConfiguration(process.env.TVE_SOFTWARE_STATEMENT)
    .build();

  // Instantiate auth, passing in the configuration data
  authBlockRef.current = new AuthBlock(authConfig);
  if (prepareAuth) {
    prepareAuth({
      authBlock: authBlockRef.current,
      setTveAuthContext,
      setTveProviderLogo,
    });
  }
}

async function setImageURL(authBlock, context, setTveProviderLogo) {
  const {imageURL} = await authBlock.buildImageURL({
    context: 'cobranding',
    providerId: context.authenticatedProvider.id,
    color: 'black',
    width: 200,
    height: 26,
  });
  imageURL && setTveProviderLogo(imageURL);
}

export function handleLogin({
  authBlock,
  setTveAuthContext,
  authorizeAndPlay,
  setTveProviderLogo,
  isValidTveStubTrack,
  tveStubURL,
}) {
  authBlock
    .login()
    .then((context) => {
      if (!context || context.authenticatedState !== 'authenticated') return;

      setTveAuthContext({context, authBlock});
      setImageURL(authBlock, context, setTveProviderLogo);
      if (authorizeAndPlay) {
        if (isValidTveStubTrack) {
          authorizeAndPlay(authBlock);
        } else if (tveStubURL) {
          window.location.href = tveStubURL.replace(/""/, '');
        }
      }
    })
    .catch((error) => {
      logger.error('Login failed: ', error);
    });
}

export function handleLogout({authBlock, setTveAuthContext, setTveProviderLogo}) {
  authBlock
    .logout()
    .then((context) => {
      setTveAuthContext({context, authBlock});
      setTveProviderLogo('');
    })
    .catch((error) => {
      logger.error('Logout failed: ', error);
    });
}

function prepareAuth({authBlock, setTveAuthContext, setTveProviderLogo}) {
  // Start up the auth process, waiting for authorization to play content
  authBlock
    .prepare()
    .then((context) => {
      setTveAuthContext({context, authBlock});
      if (context.authenticatedProvider && context.authenticatedProvider.id) {
        setImageURL(authBlock, context, setTveProviderLogo);
      }
    })
    .catch((error) => {
      logger.error('Auth lib prepare failed: ', error);
    });
}

export async function prepareTveAuth({authBlockRef, setTveAuthContext, setTveProviderLogo}) {
  const authBlockWeb = loadable.lib(() => import('@top/auth-block-web'), {ssr: false});
  await authBlockWeb.load().then((authWebDefault) => {
    initializePlayerAuth({
      authWebDefault,
      authBlockRef,
      setTveAuthContext,
      setTveProviderLogo,
      prepareAuth,
    });
  });
}

function getFreePreviewConfig(channel) {
  return {
    channel,
    temppassProviderId: 'TempPass_10min',
  };
}

/**
 * Gets the cached free preview expiry value. If it isn't found or the reset date in local storage indicates it has been reset, then -1 is returned
 */
export function getFreePreviewExpiration() {
  const cachedExpiryString = storage_api.get(CACHED_EXPIRATION_KEY);
  const expiryResetDateString = storage_api.get(CACHED_EXPIRATION_RESET_KEY);
  if (cachedExpiryString && expiryResetDateString) {
    try {
      const expiryResetDate = parseInt(expiryResetDateString, 10);
      const hasBeenReset = expiryResetDate <= Date.now();
      if (!hasBeenReset) {
        const cachedExpiry = parseInt(cachedExpiryString, 10);
        return cachedExpiry;
      }
    } catch {
      storage_api.remove(CACHED_EXPIRATION_KEY);
      storage_api.remove(CACHED_EXPIRATION_RESET_KEY);
    }
  }
  return DEFAULT_TVE_EXPIRATION;
}

/**
 * Stores the given expiry value in local storage, it will also set a reset date, this expiry will be reset and will no longer be valid
 */
function setFreePreviewExpiration(expiration) {
  storage_api.set(CACHED_EXPIRATION_KEY, expiration);
  const expiryResetDate = new Date();
  expiryResetDate.setUTCHours(24, 0, 0, 0); // next UTC midnight
  storage_api.set(CACHED_EXPIRATION_RESET_KEY, expiryResetDate.getTime());
}

export function getFreePreviewToken(authBlock, channel) {
  return authBlock
    .fetchFreePreviewToken(getFreePreviewConfig(channel))
    .then(
      (tokenData) => {
        setFreePreviewExpiration(tokenData.expiration);
        return tokenData;
      },
      (error) => {
        logger.error(error);
        if (error.message === 'FreePreview Expired') {
          const expiration = Date.now() - 1; // Already expired
          setFreePreviewExpiration(expiration);
          return {expiration};
        }
        throw error;
      }
    )
    .catch((error) => {
      logger.error(error);
      throw error;
    });
}

export function getAdsConfig(playerProps, context, useFreePreview) {
  const {ui, oneTrustPreferences = {}} = playerProps;
  const params = getParams(location.href) || {};
  const platform = ui.isMobileDevice ? 'mobile' : 'desktop';
  const tveAdsProfile = getAdsProfileId(platform);
  const csid = getCsid(platform);
  const app_csid = getAppCsidForFreeView(platform, useFreePreview);
  const mvpIdHash =
    context && context.authenticatedProvider && context.authenticatedProvider.hashId;
  const hasOptOutSalePersonalData = !oneTrustPreferences.social;

  const adFuelUtilsLoaded =
    window.AdFuelUtils && typeof window.AdFuelUtils.getUMTOCookies === 'function';

  const umtoCookieString = adFuelUtilsLoaded ? window.AdFuelUtils.getUMTOCookies() : '';
  const urlParams = new URLSearchParams(umtoCookieString);
  const userIds = Object.fromEntries(urlParams);

  return {
    profile: tveAdsProfile,
    kvps: {
      ...(mvpIdHash && {_fw_ae: mvpIdHash}),
      ...(params.tearsheet && {tearsheet: params.tearsheet}),
      ...(app_csid && {app_csid}),
      ...userIds,
      conf_csid: csid,
      _fw_ar: 1,
      _fw_us_privacy: `1Y${hasOptOutSalePersonalData ? 'Y' : 'N'}N`,
    },
  };
}

export const showFullScreenTVEInfo = (
  context,
  useFreePreview,
  freePreviewConfig,
  onTveLogin,
  tveProviderLogo
) => {
  const fullscreenTVEInformation = (
    <div className="free-preview-countdown-container">
      {useFreePreview ? (
        <>
          <FreePreviewTimer config={freePreviewConfig} />
          &nbsp;|&nbsp;
          <SignInButton handleLogin={onTveLogin} />
        </>
      ) : (
        <ProviderInfo context={context} imageURL={tveProviderLogo} />
      )}
    </div>
  );
  VideoHelpers.showFullScreenContent(fullscreenTVEInformation);
};

export const freePreviewExpiredThumbnail = (
  authInitialized,
  onTveLogin,
  posterImage,
  isNCAAGame
) => {
  return (
    <TveFreePreviewEndedThumbnail
      authInitialized={authInitialized}
      onTveLogin={onTveLogin}
      posterImage={posterImage}
      isNCAAGame={isNCAAGame}
    />
  );
};

export async function startFreeViewSession(SessionContentBuilder, freeView, mediaId) {
  const content = SessionContentBuilder.create()
    .withMediaId(mediaId)
    .build();

  freeView.current.startSession(content);
}

export function freePreviewPlay(authBlock, dispatch, channel, state, playTVEWithToken) {
  if (state.hasStartedPlayingPreview) return;
  dispatch({payload: {hasStartedPlayingPreview: true}});
  getFreePreviewToken(authBlock, channel).then((tokenData) => {
    dispatch({
      payload: {
        freePreviewExpiration: tokenData.expiration,
      },
    });
    if (tokenData.expiration > Date.now()) playTVEWithToken(tokenData.token);
  });
}
