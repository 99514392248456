import React from 'react';
import loadable from '@loadable/component';
import {
  GAMECAST,
  RECAPTCHA_VERIFICATION,
  CHECKOUT,
  EDIT_ACCOUNT,
  MY_EVENTS,
  NEWSLETTER_SUBSCRIPTION,
} from '../../constants/pageTypes';
import Shimmer from '../atoms/shimmer';

const AccountConfirmedPage = loadable(() => import('./accountConfirmedPage'));
const ActivatePage = loadable(() => import('./activatePage'));
const AppInstallPage = loadable(() => import('./appInstallPage'));
const ArticlePage = loadable(() => import('./articlePage'));
const BetsIframePage = loadable(() => import('./betsIframePage'));
const BrShellPage = loadable(() => import('./brShellPage'));
const CareersPage = loadable(() => import('./careersByDepartment'));
const CheckoutPage = loadable(() => import('./checkoutPage'));
const ChoosePasswordPage = loadable(() => import('./choosePasswordPage'));
const EditAccountPage = loadable(() => import('./editAccountPage'));
const FrontPage = loadable(() => import('./frontPage'));
const GamecastPage = loadable(() => import('./gamecastPage'));
const LivePage = loadable(() => import('./livePage'));
const LiveEventsPage = loadable(() => import('./liveEventsPage'));
const LoginPage = loadable(() => import('./loginPage'));
const MagArticlePage = loadable(() => import('./magArticlePage'));
const NewsletterSubscriptionPage = loadable(() => import('./newsletterSubscriptionPage'));
const ProfilePage = loadable(() => import('./profilePage'));
const RecaptchaVerificationPage = loadable(() => import('./recaptchaVerificationPage'));
const SchedulePage = loadable(() => import('./schedulePage'));
const SectionPage = loadable(() => import('./sectionPage'));
const SignupPage = loadable(() => import('./signupPage'));
const SitemapPage = loadable(() => import('./sitemapPage'));
const StaticPage = loadable(() => import('./staticPage'));
const StubPage = loadable(() => import('./stubPage'));
const YahooSyncPage = loadable(() => import('./yahooSyncPage'));
const VideoPage = loadable(() => import('./videoPage'));

const MyEventsPage = loadable(() => import('./myEvents'), {
  fallback: <Shimmer />,
});

function Page({
  isBrMag,
  pageType,
  store,
  tveAuthContext,
  setTveAuthContext,
  setTveProviderLogo,
  tveProviderLogo,
  showTve,
}) {
  const pages = {
    [CHECKOUT]: CheckoutPage,
    [GAMECAST]: GamecastPage,
    [MY_EVENTS]: MyEventsPage,
    [NEWSLETTER_SUBSCRIPTION]: NewsletterSubscriptionPage,
    [RECAPTCHA_VERIFICATION]: RecaptchaVerificationPage,
    accountConfirmed: AccountConfirmedPage,
    activate: ActivatePage,
    'app-install': AppInstallPage,
    article: isBrMag ? MagArticlePage : ArticlePage,
    betsIframe: BetsIframePage,
    brShell: BrShellPage,
    careers: CareersPage,
    choosePassword: ChoosePasswordPage,
    editAccount: EditAccountPage,
    error: StaticPage,
    home: FrontPage,
    live: LivePage,
    liveEvents: LiveEventsPage,
    login: LoginPage,
    profile: ProfilePage,
    schedule: SchedulePage,
    section: SectionPage,
    signup: SignupPage,
    sitemap: SitemapPage,
    static: StaticPage,
    stub: StubPage,
    yahooSync: YahooSyncPage,
    video: VideoPage,
  };
  const isEditAccount = pageType === EDIT_ACCOUNT;
  const Page = pages[pageType];
  const additionalProps = isEditAccount
    ? {tveAuthContext, setTveAuthContext, setTveProviderLogo, tveProviderLogo, showTve}
    : {};

  const isRenderingOnServer = typeof window === 'undefined' && typeof process === 'object';
  //if we're rendering on the client, we use the server-side-rendered page-content as a fallback for the lazy loaded Page component. This makes the hydration appear seamless.
  const fallbackContent = !isRenderingOnServer ? (
    <div dangerouslySetInnerHTML={{__html: document.getElementById('page-content').innerHTML}} />
  ) : (
    <></>
  );
  return Page ? (
    <div id="page-content">
      <Page fallback={fallbackContent} store={store} {...additionalProps} />
    </div>
  ) : (
    false
  );
}

Page.defaultProps = {
  isBrMag: false,
};

export default Page;
