import * as AdsAPI from '../ads_api';
import AnalyticsAPI from '../analytics_api';

import {createPromiseAndResolver} from './promises';

import logger from '../../logger';

// TagManager objects cannot be
// - null
// - undefined
// - objects
// - numbers
// - booleans
// These values can only be strings or arrays of strings
// We will default values to empty strings
const convertValuesToStrings = (acc, [key, val]) => {
  if (typeof val === 'object' && !Array.isArray(key)) {
    logger.error(`${key} should not be an object.`);
  }

  const formattedValue = ['boolean', 'number'].includes(typeof val) ? val.toString() : val;

  acc[key] = formattedValue || '';
  return acc;
};

export const formatTagManagerObject = (utagData) => {
  const formattedObject = Object.entries(utagData).reduce(convertValuesToStrings, {});
  return formattedObject;
};

const REGEX_NUMBER = /^\d+$/;
export const parseTagManagerObject = (dataLayer) => {
  const parser = (acc, [key, val]) => {
    try {
      let newVal = val;
      if (val === 'true' || val === 'false') {
        newVal = JSON.parse(val);
      } else if (REGEX_NUMBER.test(val)) {
        newVal = parseInt(val);
      }

      return {
        ...acc,
        [key]: newVal,
      };
    } catch (err) {
      logger.error(`Error parsing ${key} and ${val}`);
      logger.error(err);
      return acc;
    }
  };
  const parsedDataLayer = Object.entries(dataLayer).reduce(parser, {});

  return parsedDataLayer;
};

export const euCountryTable = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom (Great Britain)',
  GR: 'Greece',
  HR: 'Croatia',
  HU: 'Hungary',
  IE: 'Ireland, Republic of (EIRE)',
  IT: 'Italy',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SE: 'Sweden',
  SI: 'Slovenia',
  SK: 'Slovakia',
};

// TagManager Tag Callbacks
global.amazonCallback = (dataLayer) => AdsAPI.initAmazonAds(dataLayer);

global.criteoCallback = () => {}; // no-op; Criteo now runs through Prebid

global.fbCallback = () => {
  global.FB.init({
    appId: '135174055162',
    status: true,
    version: 'v2.9',
    xfbml: true,
  });
};

const {promise: gptPromise, resolver: resolveGpt} = createPromiseAndResolver();
global.gptCallback = (dataLayer) => resolveGpt(dataLayer);

global.kruxCallback = () => AnalyticsAPI.kruxTrack();

global.liverampCallback = () => AnalyticsAPI.liveRampSync();

global.mParticleInit = () => AnalyticsAPI.initMParticle();

global.mParticleCallback = () => AnalyticsAPI.onMParticleReady(); // Different from mParticleInit. This is called when the mparticle tag on tagManager finishes loading the _actual mparticle script tag_.

const {promise: brAdSystemPromise, resolver: adSystemPromiseResolved} = createPromiseAndResolver();
global.brAdSystemPromise = brAdSystemPromise;

global.prebidCallback = () => {
  gptPromise
    .then((gptDataLayer) => AdsAPI.initAds(gptDataLayer))
    .then(() => adSystemPromiseResolved());
};

// TagManager is set up with Custom Containers
// The following callbacks are available to be used
// global.instagramCallback = () => {};
// global.trusteCallback = () => {};
// global.twitterCallback = () => {};
