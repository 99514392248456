import {createSelector} from 'reselect';
import {articles, page} from './topLevelSelectors';

// Page data
const league = createSelector(page, (pageState) => pageState.league || '');
const team = createSelector(page, (pageState) => pageState.team || '');
const tagsString = createSelector(page, (pageState) => pageState.tags || '');
const brMag = createSelector(page, (pageState) => pageState.br_mag || '');
const pageFormat = createSelector(page, (pageState) => pageState.format || '');
const pageType = createSelector(page, (pageState) => pageState.type || '');
const streamId = createSelector(page, (pageState) => pageState.tag_id || '');
const streamName = createSelector(page, (pageState) => pageState.id || '');

// Article data
const articleId = createSelector(articles, (articleState) => articleState.breport_id);
const permalink = createSelector(articles, (articleState) => articleState.permalink);
const renderStrategy = createSelector(articles, (articleState) => articleState.render_strategy);

const articleType = createSelector([renderStrategy, brMag], (renderStrategy, brMag) => {
  if (brMag) {
    return 'mag';
  }
  // Article and Uber Article are the same and can be grouped as Standard Article
  // Make sure to check for live blog as well
  return renderStrategy === 'slideshow' ? 'slideshow' : 'standard_article';
});

const screen = createSelector(
  [articleId, pageFormat, pageType],
  (articleId, pageFormat, pageType) => {
    if (articleId) {
      return 'Article';
    }
    if (pageFormat === 'editorial') {
      return 'Editorial';
    } else if (pageType === 'live') {
      return 'Live Blog';
    }
    return 'Stream';
  }
);

export const defaultPayload = createSelector(
  [screen, league, team, tagsString, streamName, streamId, articleId, articleType, permalink],
  (screen, league, team, tagsString, streamName, streamId, articleId, articleType, permalink) => {
    const payload = {
      league,
      screen,
      tags_string: tagsString,
      team,
    };

    // Article pages
    if (articleId) {
      return {
        ...payload,
        article_id: articleId,
        article_type: articleType,
        permalink,
      };
    }

    return {
      ...payload,
      stream_id: streamId,
      stream_name: streamName,
    };
  }
);
